import React, { Component } from 'react';
import DoctorCard from '../../component/DoctorCard';
import { GET_DOCTOR_LIST_REQUEST, GETPATIENT_DETAILS_REQUEST } from '../../actions/Action';
import { connect } from 'react-redux';
import queryString from 'query-string';
import DocFilter from '../../component/DocFilter';
import axios from 'axios';
import Modal from 'react-modal';
import { Baseurl } from '../../utils/Baseurl';
import { toast } from 'react-toastify';
import { ToastMessage } from '../../component/ToastMessage';
import '../../styles/SearchDoctor.css';



const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    }
};

Modal.setAppElement('#root')
Modal.defaultStyles.overlay.backgroundColor = 'rgba(111, 111, 111, 0.75)';


class SearchDoctor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            mainData:[],
            doctorsResults: [],
            practiceList: [],
            pracList: [],
            docList: [],
            practiceNameForShow: '',

            specialityData: [],
            docdropdownlist: [],
            docfilterData: [],
            insurance_provider: '',
            insuranceProviderArr: [],
            speciality: '',
            zip: '',
            patient_id: '',
            zipCodes: [],
            zipList: [],
            filter: {
                gender: [],
                hospital_affiliation: [],
                languages: []
            },
            patientName: '',
            practiceName: '',
            self_name: '',
            isModal: false,
            zipDropdown: false,
            zipDoctors: [],
            zipcheckArr: [],
            isOpen: false,
            specialityLoader: false
        }
        this.handleZipInputClick = this.handleZipInputClick.bind(this);
        this.hideZipDropdown = this.hideZipDropdown.bind(this);
    }

    UNSAFE_componentWillMount() {
        let values = queryString.parse(this.props.location.search);
        this.setState({ speciality: values.name, practiceName: values.practice_name, insurance_provider: values.insurance_provider,zip: values.zip, patient_id: values.patient });
    }

    componentDidMount() {
        let values = queryString.parse(this.props.location.search);
        if(this.state.insurance_provider==='cash pay')
        {
            this.props.handlegetDocList({ insurance_provider: '', specaility: '', practice: '' });
            this.handleGetZipDoctors('',this.state.speciality,this.state.zip,this.state.practiceName);  
        }
        else
        {
            this.props.handlegetDocList({ insurance_provider: this.state.insurance_provider, specaility: '', practice: '' });
            this.handleGetZipDoctors(this.state.insurance_provider,this.state.speciality,this.state.zip,this.state.practiceName);  
        }
        

        this.props.handleGetPatientDetails(values.patient);
        this.handleSelfDetailsProfile();
        this.handleGetPayersFromPverify();
    }



    /*--------------------------------------getInsurance-provider from p-verify-----------------------------------------*/
    handleGetPayersFromPverify = () => {
        axios({
            method: 'get',
            url: Baseurl + 'api/user/get_payers',
            headers: { 'content-type': 'application/json' }
        }).then((res) => {
            // console.log("response-------->", res);            
            this.setState({ insuranceProviderArr: res.data });
        }).catch((error) => {
            console.log("error-------->", error.response);
        })
    }



    handleSelfDetailsProfile = () => {
        let id = localStorage.getItem('uid');
        axios({
            method: 'get',
            url: Baseurl + `api/doc/getDetails?userId=${id}`,
            headers: { 'content-type': 'application/json' },
        }).then((res) => {
            // console.log("self-record->", res.data);
            this.setState({ self_name: `${res.data.user.first_name} ${res.data.user.last_name}` });
        }).catch((error) => {
            console.log("error---->", error);
        })
    }


    /*-----------------------------------------------handle-doc-name-onchange------------------------------------------------------*/

    handleDocNameOnChange = (e) => {
        var q = e.target.value.toLowerCase();

        let filterLocation = this.state.pracList.filter(data => {
            return data.practice_name.toLowerCase().includes(q);
        });

        let filterDoctor = this.state.docList.filter(dataIs => {
            let name = `${dataIs.first_name} ${dataIs.last_name}`;
            return name.toLowerCase().includes(q)
        });

        if (e.target.value.length > 2) {
            this.setState({ practiceName: e.target.value, doc_dropDownDisplay: true, practiceList: filterLocation, doctorsResults: filterDoctor });
        }
        else {
            this.setState({ practiceName: e.target.value, doc_dropDownDisplay: false });
        }

    }


     /*------------------------------------------------------handle-zip-dropdown--------------------------------------------------*/


     handleZipInputClick = () => {
        this.setState({ zipDropdown: false }, () => {
            document.addEventListener('click', this.hideZipDropdown);
        });
    }


    hideZipDropdown = () => {
        this.setState({ zipDropdown: false }, () => {
            document.removeEventListener('click', this.hideZipDropdown);
        });

    }


    /*------------------------------------------------------handle-doc-search-drop-down--------------------------------------------------*/


    opendocSearchDropDown = () => {
        this.setState({ doc_dropDownDisplay: false }, () => {
            document.addEventListener('click', this.hidedocSearchDropDown);
        });
    }

    hidedocSearchDropDown = () => {
        this.setState({ doc_dropDownDisplay: false }, () => {
            document.removeEventListener('click', this.hidedocSearchDropDown);
        });

    }

    componentWillUnmount = () => {
        document.removeEventListener('click', this.hidedocSearchDropDown);
        document.removeEventListener('click', this.hideZipDropdown);
    };



    /*-----------------------------------------------handle-seach-doctor-list------------------------------------------------------*/

    handleSearchDoc = (e) => {
        e.preventDefault();
        this.setState({ practiceNameForShow: this.state.practiceName });

        if (this.state.insurance_provider === '') {
            this.setState({ isModal: true });
        }
        else {
            this.props.history.push(`/searchdoc?name=${this.state.speciality}&practice_name=${this.state.practiceName}&insurance_provider=${this.state.insurance_provider}&zip=${this.state.zip}&patient=${this.state.patient_id}`);
            this.componentDidMount();
        }
    }

    /*-----------------------------------------------handle-render-doctor-list------------------------------------------------------*/

    renderDocList = () => {

        if (this.state.docfilterData.length === 0) {
            return (
                <React.Fragment>
                    <h4 className="text-center" style={{ color: 'rgb(0, 35, 75)', fontWeight: 'bold' }}>We couldn't find doctors  or practices for you</h4>
                    <p className="text-center"> {this.state.practiceNameForShow ? `Your search for text search in  ${this.state.practiceNameForShow}  didn't match anything.` : ''}</p>
                </React.Fragment>
            )
        }
        else {
            return this.state.docfilterData.map((value, key) => {
                return <DoctorCard key={key}
                    doc_name={`${value.first_name} ${value.last_name}`}
                    primary_speciality={value.primary_speciality}
                    doc_image={value.profile_image}
                    location={value.practice}
                    id={value.uid}
                    patientId={this.state.patient_id}
                    insurance_providers={value.insurance_providers}
                    distance={value.zip_distance?`${value.zip_distance} miles`:''}
                    selectSpeciality={this.state.speciality}
                />;
            })
        }
    }


    getFromPracticeNameOrDoctorName = (list, values) => {
        let docFromPracticeName = list.filter(data => {
            return data.practice.find(pract => pract.practice_name.toLowerCase().includes(values.practice_name.toLowerCase()))
        });

        let docFromName = list.filter(data => {
            let name = `${data.first_name} ${data.last_name}`;
            return name.toLowerCase().includes(values.practice_name.toLowerCase());
        });

        let newDocList = docFromPracticeName.filter(value => {
            return docFromName.length ? docFromName.some(val => val.uid !== value.uid) && value : value
        });

        return [...docFromName, ...newDocList]
    };

    /*-----------------------------------------------handle-componentWillRecieveProps------------------------------------------------------*/

    UNSAFE_componentWillReceiveProps(props) {

        let values = queryString.parse(this.props.location.search);
        let doctorListResult = [];

        let arr = [];
        let zipCodes = [];
        let zipArr = [];
        props.doctorslist.map(value => {
            arr = arr.concat(value.speciality);
        })

        let newSpecialityArr = arr.filter((a, b) => arr.indexOf(a) === b);

        let specialitydataFilter = props.doctorslist.filter(data => data.speciality.find(special => special === values.name));

        if (values.name) {
            doctorListResult = values.practice_name ?
            this.getFromPracticeNameOrDoctorName(props.doctorslist, values)
            :
            values.zip?
            props.doctorslist.filter(data => data.practice.find(value => value.zip === values.zip))
                // props.doctorslist.filter(data => data.practice.find(pract => pract.practice_name === values.practice_name))
                :
                specialitydataFilter

        }
        else if (values.practice_name) {
            doctorListResult = this.getFromPracticeNameOrDoctorName(props.doctorslist, values)
            // doctorListResult = props.doctorslist.filter(data => data.practice.find(pract => pract.practice_name === values.practice_name))
        }
        else if(values.zip)
        {
            doctorListResult = props.doctorslist.filter(data => data.practice.find(value => value.zip === values.zip));
        }

        else {
            doctorListResult = props.doctorslist
        }


        let isSpeciality = specialitydataFilter.length > 0 ? specialitydataFilter : props.doctorslist;
        let zipdata = doctorListResult.map(value => {
            return value.practice;
        })

        zipdata.map(data => {
            return zipCodes.push(...data);
        });

        zipCodes.map(value => {
            zipArr = zipArr.concat(value.zip);
        });

        let uniqueZip = [...new Set(zipArr)];

        const sortZipData = uniqueZip.sort((a, b) => {
            var keyA = a,
                keyB = b;
            if (keyA < keyB) return -1;
            if (keyB > keyA) return 1;
            return 0;
        });


        /*----------------------spread all practice-list-----------------------------*/

        let Parr = [];

        let practiceArr = doctorListResult.map(value => {
            return value.practice;
        })

        practiceArr.map(d => {
            return Parr.push(...d);
        });

        var handleDuplicateLocation = Parr.reduce((unique, o) => {
            if (!unique.some(obj => obj.practice_name === o.practice_name && obj.zip === o.zip)) {
                unique.push(o);
            }
            return unique;
        }, []);




        const sortDocfilterData = doctorListResult.sort((a, b) => {
            var keyA = a.reviewAverage,
                keyB = b.reviewAverage;
            if (keyB < keyA) return -1;
            if (keyA > keyB) return 1;
            return 0;
        });

        if (this.state.insurance_provider !== '') {
            this.setState({
                patientName: `${props.patientDetails.first_name} ${props.patientDetails.last_name}`,
                data: props.doctorslist.length > 0 ? props.doctorslist : [],
                // docfilterData: sortDocfilterData.length > 0 ? sortDocfilterData : [],
                docdropdownlist: props.searchDocResult,
                specialityData: newSpecialityArr,
                // zipList: sortZipData,
                doctorsResults: sortDocfilterData.length > 0 ? sortDocfilterData : [],
                docList: sortDocfilterData.length > 0 ? sortDocfilterData : [],
                practiceList: handleDuplicateLocation,
                pracList: handleDuplicateLocation,
                specialityLoader: props.isLoading
            }
            // , () => {
            //     this.state.zip && this.handleGetZipDoctors(this.state.zip);
            // }
            );
        }
    }



    handleSetfilterOptions = () => {
        let filterArr = [
            // { name: 'Illness' },
            { name: 'Gender' },
            // { name: 'Availability' },
            { name: 'Hospital Affiliation' },
            // { name: 'Special Hours' },
            // { name: 'Child Friendly' },
            // { name: 'Languages' },
        ];

        let gender = [];
        let languages = [];
        let affiliation = [];

        var uniqueArray_validater = (arr) => {
            const stringifiedArray = arr.map((item) => JSON.stringify(item));
            const set = new Set(stringifiedArray);
            return Array.from(set).map((item) => JSON.parse(item));
        }

        if (this.state.docfilterData.length > 0) {

            this.state.docfilterData.map((value) => {

                if (value.gender) {
                    gender.push(value.gender);
                }
                if (value.languages) {

                    value.languages.map(v => {
                        languages.push(v);
                        return null;
                    })
                }
                if (value.hospital_affiliations) {
                    value.hospital_affiliations.map(v => {
                        affiliation.push(v);
                        return null;
                    })
                }
                return null;
            })

            /*-------------------gender-unique---------------------------*/

            const gArr = uniqueArray_validater(gender);

            /*-------------------languages-unique---------------------------*/

            const lArr = uniqueArray_validater(languages);

            /*-------------------languages-unique---------------------------*/

            const aArr = uniqueArray_validater(affiliation);


            /*-------------------set-filter-data---------------------------*/

            // this.state.filterArr

            filterArr.map((value) => {

                if (value.name === "Gender") {
                    value.filterData = gArr;
                }
                if (value.name === 'Languages') {
                    value.filterData = lArr;
                }
                if (value.name === 'Hospital Affiliation') {
                    value.filterData = aArr;
                }
                return null;
            });

            return filterArr.map((val, key) => {
                return <DocFilter key={key}
                    filterData={val}
                    filterApply_Handler={this._handleFilterApply}
                    setFilterState={this.setFilterState} />
            })

        }
        else {
            return null;
        }
    }



    setFilterState = (state, value) => {
        state = state.replace('CheckedArr', '');
        this.setState(({ filter }) => ({ filter: { ...filter, [state]: value } }));
    };


    multiPropsFilter = (products, filters) => {
        const filterKeys = Object.keys(filters);
        return products.filter(product => {
            return filterKeys.every(key => {
                if (!filters[key].length) return true;
                // Loops again if product[key] is an array (for material attribute).
                if (Array.isArray(product[key])) {
                    return product[key].some(keyEle => filters[key].includes(keyEle));
                }
                return filters[key].includes(product[key]);
            });
        });
    };



    /*-----------------------------------------------handle-filter-of filter-data------------------------------------------------------*/

    _handleFilterApply = () => {
        const { gender, hospital_affiliation, languages } = this.state.filter;

        if (gender.length || hospital_affiliation.length || languages.length) {

            let filters = {};
            if (gender.length) { filters.gender = gender }
            if (hospital_affiliation.length) { filters.hospital_affiliations = hospital_affiliation }
            if (languages.length) { filters.languages = languages }
            // console.log(this.state.data, filters);

            this.setState({ docfilterData: this.multiPropsFilter(this.state.data, filters) });

        } else {
            console.log('nothing..', gender, hospital_affiliation, languages, gender.length && hospital_affiliation.length && languages.length)
            this.setState({ docfilterData: this.state.data });
        }
    }



    /*-----------------------------------handle save consults------------------------------------*/

    handleSaveConsult = (e) => {
        e.preventDefault();
        let docId = localStorage.getItem('uid');

        let data = {
            patient_id: this.state.patient_id,
            patient_name: this.state.patientName,
            doc_id: docId,
            doctor_name: this.state.self_name,
            speciality: this.state.specaility
        }

        axios({
            method: 'post',
            url: Baseurl + 'api/consult/save_consult',
            headers: { 'content-type': 'application/json' },
            data
        }).then((res) => {
            console.log("save-consult-response----->", res);
            this.sendMailToAllSearchDoctor(res.data.createdAt);
        }).catch((error) => {
            console.log("save-consult-error------>", error);
        })
    }




    /*-----------------------------------send mail-to all-search doc for consults------------------------------------*/

    sendMailToAllSearchDoctor = (time) => {
        const { patientName, self_name } = this.state;

        let docArr = [];
        this.state.docfilterData.map(value => {
            docArr.push(value);
            return null;
        })


        let data = {
            time: time,
            self_name: self_name,
            patientName: patientName,
            patient_id: this.state.patient_id,
            docArr: docArr,
            subject: `Consultation opportunity from Dr. ${self_name} for ${patientName}`,
        }

        if (docArr.length !== 0) {
            axios({
                method: 'post',
                url: Baseurl + 'api/appointment/sentallconsultdoctors',
                headers: { 'content-type': 'application/json' },
                data
            }).then((res) => {
                console.log("handle-all-search-doc-mail-res------->", res);
                // toast.success('Email successfully sent !', { position: toast.POSITION.TOP_CENTER });
                toast.success(<ToastMessage message="Email successfully sent." />, { position: toast.POSITION.TOP_CENTER });
            }).catch((err) => {
                console.log("handle-all-search-doc-mail-error------->", err);
            })
        }
        else {
            console.log("no doctor to invite.");
        }
    }


    /*-------------------------------------handleInsuranceOnchange---------------------------------*/

    handleInsuranceProviderOnchange = (e) => {
        if(e.target.value==='cash pay')
        {
            this.setState({ insurance_provider: e.target.value, speciality: '', practiceName: '' }, () => {
                this.props.handlegetDocList({ insurance_provider: '', specaility: '', practice: '' });
            });
        }
        else
        {
            this.setState({ insurance_provider: e.target.value, speciality: '', practiceName: '' }, () => {
                this.props.handlegetDocList({ insurance_provider: this.state.insurance_provider, specaility: '', practice: '' });
            });
        }
        
    }

    /*-------------------------------------handleInsuranceOnchange---------------------------------*/

    handleSpecialityOnchange = (e) => {

        if (e.target.value) {
            let filterSpecaility = this.state.data.filter(data => data.speciality.find(special => special === e.target.value));
            let Parr = [];
            let zipArr = [];
            let practiceArr = filterSpecaility.map(value => {
                return value.practice;
            })

            practiceArr.map(d => {
                return Parr.push(...d);
            });


            var handleDuplicateLocation = Parr.reduce((unique, o) => {
                if (!unique.some(obj => obj.practice_name === o.practice_name && obj.zip === o.zip)) {
                    unique.push(o);
                }
                return unique;
            }, []);

            this.setState({ doctorsResults: filterSpecaility, docList: filterSpecaility, practiceList: handleDuplicateLocation, pracList: handleDuplicateLocation, speciality: e.target.value, practiceName: '' });
        }
        else {

            let Parr = [];
            let zipArr = [];
            let practiceArr = this.state.data.map(value => {
                return value.practice;
            })

            practiceArr.map(d => {
                return Parr.push(...d);
            });

            var DuplicateLocation = Parr.reduce((unique, o) => {
                if (!unique.some(obj => obj.practice_name === o.practice_name && obj.zip === o.zip)) {
                    unique.push(o);
                }
                return unique;
            }, []);

            this.setState({doctorsResults: this.state.data, docList: this.state.data, practiceList: DuplicateLocation, pracList: DuplicateLocation, speciality: e.target.value, practiceName: '' });
        }
    }




     /*--------------------------------handleZipOnchange--------------------------*/


     handleZipOnchange = (e) => {

        this.setState({zip: e.target.value, practiceName: '', zipDoctors: [], zipDropdown: false });

        // var zipValue = e.target.value.toLowerCase();

        // if (e.target.value && e.target.value.length > 2) {
        //     let filterZip = this.state.docList.filter(data => data.practice.find(value => value.zip === e.target.value));
        //     let Parr = [];
        //     let practiceArr = filterZip.map(value => {
        //         return value.practice;
        //     })

        //     practiceArr.map(d => {
        //         return Parr.push(...d);
        //     });

        //     var handleDuplicateLocation = Parr.reduce((unique, o) => {
        //         if (!unique.some(obj => obj.practice_name === o.practice_name && obj.zip === o.zip)) {
        //             unique.push(o);
        //         }
        //         return unique;
        //     }, []);

        //     let filterZipList = this.state.zipList.filter(value => {
        //         return value.toLowerCase().includes(zipValue);
        //     });

        //     let asendingZip = filterZipList.sort((a, b) => a - b);

        //     this.setState({ zipShowList: asendingZip, doctorsResults: filterZip, docList: filterZip, practiceList: handleDuplicateLocation, pracList: handleDuplicateLocation, zip: e.target.value, practiceName: '', zipDropdown: true });
        // }
        // else {

        //     let Parr = [];
        //     let practiceArr = this.state.data.map(value => {
        //         return value.practice;
        //     })

        //     practiceArr.map(d => {
        //         return Parr.push(...d);
        //     });

        //     var DuplicateLocation = Parr.reduce((unique, o) => {
        //         if (!unique.some(obj => obj.practice_name === o.practice_name && obj.zip === o.zip)) {
        //             unique.push(o);
        //         }
        //         return unique;
        //     }, []);

        //     let filterZipList = this.state.zipList.filter(value => {
        //         return value.toLowerCase().includes(zipValue);
        //     });

        //     let asendingZip = filterZipList.sort((a, b) => a - b);

        //     this.setState({ zipShowList: asendingZip, doctorsResults: this.state.data, docList: this.state.data, practiceList: DuplicateLocation, pracList: DuplicateLocation, zip: e.target.value, practiceName: '', zipDoctors: [], zipDropdown: false });
        // }


    }



    /*--------------------------------handleZipOnCLick--------------------------*/

    handleZipOnCLick = (e, zip) => {
        e.preventDefault();
        this.setState({ zip: zip });
    }

    /*--------------------------------handlePracticeClick--------------------------*/

    handlePracticeClick = (e, practice) => {
        e.preventDefault();
        this.setState({ practiceName: practice });
    }

    handleSelectInsuranceforModal = () => {
        this.setState({ isModal: false });
    }


    closeModal = () => {
        this.setState({ isModal: false });
    }


    /*----------------------------------------------handleGetZipDoctors---------------------------------------*/

    handleGetZipDoctors = (insurance , speciality, zip, practice) => {
        axios({
            method: 'get',
            url: Baseurl + `api/doc/search_doctors?ins_provider=${insurance}&speciality=${speciality}&zip=${zip}&dr_name=${practice}`,
            headers: { 'content-type': 'application/json'}
        }).then((response) => {
            // console.log("zip-response-------------------->", response.data.data);

            let zipCodes=[];
            let zipArr=[];


            let zipdata = response.data.data.map(value => {
                return value.practice;
            })
    
            zipdata.map(data => {
                return zipCodes.push(...data);
            });
    
            zipCodes.map(value => {
                zipArr = zipArr.concat(value.zip);
            });
    
            let uniqueZip = [...new Set(zipArr)];


            var sortZipData = uniqueZip.sort(function(a, b) {
                return a > b ? 1 : -1;
              });

            
              /*-----------------------sort-by ditance-and average-----------*/
              var sortDocfilterData;

                if(zip)
                {
                    sortDocfilterData= response.data.data;
                }
                else
                {
                    sortDocfilterData = response.data.data.sort((a, b) => {
                        var keyA = a.reviewAverage,
                            keyB = b.reviewAverage;
                        if (keyB < keyA) return -1;
                        if (keyA > keyB) return 1;
                        return 0;
                    });
                }
            
        

            this.setState({ docfilterData: sortDocfilterData, zipList: sortZipData, mainData:response.data.data });
        }).catch((error) => {
            console.log("zip-error----------------------->", error);
        })
    }




    // renderZipDoctors = () => {
    //     return this.state.zipDoctors.map((value, key) => {
    //         return <DoctorCard key={key}
    //         doc_name={`${value.first_name} ${value.last_name}`}
    //         primary_speciality={value.primary_speciality}
    //         doc_image={value.profile_image}
    //         location={value.practice}
    //         id={value.uid}
    //         patientId={this.state.patient_id}
    //         insurance_providers={value.insurance_providers}
    //         distance={`${value.zip_distance} miles `}
    //     />;
    //     })
    // }


    handleIgnoreZip = () => {
        this.setState({ zip: '', zipDoctors: [] });
        this.props.history.push(`/searchdoc?name=${this.state.speciality}&practice_name=${this.state.practiceName}&insurance_provider=${this.state.insurance_provider}&zip=`);
        this.componentDidMount();
    }


    /*----------------handle-zipcheckbox------------------------------*/

    handlezipFilterBox=(e)=>{
        
        let index = this.state.zipcheckArr.indexOf(e.target.value);
        if (index < 0) {
            this.state.zipcheckArr.push(e.target.value);
            this.setState({ zipcheckArr: this.state.zipcheckArr })
        }
        else {
            this.state.zipcheckArr.splice(index, 1);
            this.setState({ zipcheckArr: this.state.zipcheckArr })
        }
    }


    /*--------------------------handle-zip apply button------------------------------*/


    handleAppyZip=()=>{

        if(this.state.zipcheckArr.length === 0)
        {
            this.setState({ docfilterData:this.state.mainData, isOpen: false});    
        }
        else
        {
            let filterzipData= this.state.mainData.filter(value=>value.practice.find(data=>this.state.zipcheckArr.find(zip=>data.zip === zip)));
            this.setState({ docfilterData: filterzipData, isOpen: false});
        }
    }



    handleSortByZip=()=>{
        if (this.state.zipList && this.state.zipList.length !== 0) {
            this.setState({ isOpen: !this.state.isOpen });
        }
        else {
            return null;
        }
    }


    handleBackdrop=()=>{
        this.setState({ isOpen: false });
    }

    render() {
        const displayDropdown = (this.state.doctorsResults.length > 0 && this.state.doc_dropDownDisplay) || (this.state.practiceList.length > 0 && this.state.doc_dropDownDisplay);
        let values = queryString.parse(this.props.location.search);
        
        return (
            <React.Fragment>
                <Modal
                    isOpen={this.state.isModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Modal"
                >
                    <div className="text-center">
                        <h5>Please select insurance provider first.</h5>
                        <button className="btn" onClick={this.handleSelectInsuranceforModal}>OK</button>
                    </div>
                </Modal>
                <div className="main-container">
                    <section className="bg-primary pt48 pb32 customsec" style={{ overflow: 'visible' }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-10 col-md-offset-1 col-sm-12 text-left">
                                    <form className="" data-success="" data-error="" onSubmit={this.handleSearchDoc} autoComplete="off">
                                        <div className="col-lg-12">

                                            {/* -----------choose-insurance-provider-------------- */}
                                            <div className="col-lg-2" style={{ paddingLeft: 1, paddingRight: 1, height: 'auto', overflow: 'hidden' }}>
                                                <select name="Insurance_Provider" value={this.state.insurance_provider} onChange={(e) => this.handleInsuranceProviderOnchange(e)} style={{ width: '100%', background: '#f5f5f5' }}>
                                                    <option defaultValue value="" disabled>In-network insurances</option>
                                                    <option value="cash pay">Cash Pay</option>
                                                    {
                                                        this.state.insuranceProviderArr.map((name, index) => (
                                                            <option value={name.payerName} key={index}>{name.payerName}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>

                                            {/* -----------choose-Speciality-------------- */}

                                            <div className="col-lg-2" style={{ paddingLeft: 1, paddingRight: 5, height: 'auto', overflow: 'hidden',position:'relative' }}>
                                            {
                                                    this.state.specialityLoader?
                                                        <div 
                                                            style={{display:'flex',
                                                            justifyContent:'center',
                                                            alignItems:'center', 
                                                            position:'absolute', 
                                                            zIndex:1,right: -5, top: 0}}>
                                                            <img alt="loader" src={require('../../assets/img/smartappt_preloader.gif')} style={{ height: 50, width: 50 }} />
                                                        </div>
                                                        :
                                                        null
                                                }  

                                                <select disabled={this.state.insurance_provider === '' ? true : false} value={this.state.speciality} style={{ width: '100%', background: '#f5f5f5' }} onChange={(e) => this.handleSpecialityOnchange(e)}>
                                                    <option defaultValue value="">All Speciality</option>
                                                    {
                                                        this.state.specialityData.map((value, key) => {
                                                            return <option key={key} value={value}>{value}</option>
                                                        })

                                                    }
                                                </select>
                                            </div>


                                             {/* ------------- choose-zip-------------- */}

                                             <div className="col-lg-2" style={{ paddingLeft: 1, paddingRight: 5, height: 'auto', position: 'relative' }}>
                                                <input
                                                    disabled={this.state.insurance_provider==='' ? true : false}
                                                    onClick={this.handleZipInputClick}
                                                    type="text" value={this.state.zip} name="zip" placeholder="Zip code" onChange={(e) => this.handleZipOnchange(e)} style={{ color: '#333', marginBottom: 16 }} />
                                            </div>

                                            {/* -----------choose-doctor-------------- */}

                                            <div className="col-lg-2" style={{ paddingLeft: 1, paddingRight: 2, position: 'relative' }}>
                                                <input onClick={this.opendocSearchDropDown}
                                                    type='text'
                                                    value={this.state.practiceName} name="speciality"
                                                    placeholder='doctor name...' style={{ marginBottom: 2 }}
                                                    onChange={(e) => this.handleDocNameOnChange(e)}
                                                    disabled={this.state.insurance_provider === '' ? true : false}
                                                    style={{ color: '#414146', marginBottom: 16 }}
                                                />

                                                {
                                                    displayDropdown &&
                                                    <div className="search_dropdown_box"
                                                        style={{ height: 'auto', display: displayDropdown ? 'block' : 'none' }}>
                                                        <React.Fragment>
                                                            {
                                                                this.state.practiceList.length === 0 ?
                                                                    null
                                                                    :
                                                                    <div className="searchDoc_speciality_group">
                                                                        <div id="searchDoc_group_header">Practice</div>
                                                                        {
                                                                            this.state.practiceList.map((data, key) => {
                                                                                return <div className="searchDoc_speciality_group_data" key={key} onClick={(e) => this.handlePracticeClick(e, data.practice_name)}>
                                                                                    <p style={{ color: '#787887', fontSize: 13 }}>{data.practice_name}</p>
                                                                                    <p style={{ color: '#787887', fontSize: 11 }}>{data.zip}</p>
                                                                                </div>
                                                                            })
                                                                        }
                                                                    </div>

                                                            }

                                                            {
                                                                this.state.doctorsResults.length === 0 ?
                                                                    null
                                                                    :
                                                                    <div className="searchDoc_doctor_group">
                                                                        <div id="searchDoc_group_header">Doctors</div>
                                                                        {
                                                                            this.state.doctorsResults.map((value, key) => {
                                                                                return (
                                                                                    <div onClick={() => this.props.history.push(`/doctor_profile?doc=${value.uid}&patient=${this.state.patient_id}&m=${values.m}`)} id="doc_search_box" className="text-left" key={key}>
                                                                                        <img alt="profile placeholder" src={value.profile_image ? value.profile_image : 'https://cdn1.vectorstock.com/i/thumb-large/75/85/default-placeholder-doctor-half-length-portrait-vector-20847585.jpg'} style={{ height: 30, width: 30, borderRadius: 50 }} />
                                                                                        <div style={{ paddingLeft: 15 }}>
                                                                                            <p style={{ fontSize: 13, color: '#414146', letterSpacing: 0.5, marginBottom: 0 }}>{`${value.first_name} ${value.last_name}`}</p>
                                                                                            <p style={{ marginBottom: 0, color: '#787887', fontSize: 11, letterSpacing: 0.5 }}>{value.primary_speciality}</p>
                                                                                        </div>

                                                                                    </div>
                                                                                )

                                                                            })
                                                                        }
                                                                    </div>
                                                            }
                                                        </React.Fragment>
                                                    </div>
                                                }

                                            </div>

                                            <div className="col-lg-2" style={{ paddingLeft: 1, paddingRight: 1, marginBottom: 16 }}>
                                                <button type="submit" className="btn mb0" onClick={this.handleSearchDoc}>search</button>

                                            </div>
                                            <div className="col-lg-2" style={{ paddingLeft: 1, paddingRight: 1, display: this.state.docfilterData.length === 0 ? 'none' : 'block' }} >
                                                <button type="submit" className="btn mb0" onClick={this.handleSaveConsult}>invite for consult</button>
                                            </div>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="pb24 pt24 customsec bg-secondary" style={{ overflow: 'visible' }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-12 text-center" style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>
                                    <div className="filter_option_mobile" style={{ display: 'flex', flexWrap: 'wrap' }}>
                                        <span className=""> {this.state.docfilterData.length} Providers  &nbsp;&nbsp;</span>
                                        <span className=""> &nbsp;&nbsp;|&nbsp;&nbsp; Filter by:&nbsp;&nbsp;</span>
                                    </div>

                                    {

                                        this.handleSetfilterOptions()
                                    }
                                    <div className="shortedBy_button" style={{ display: 'flex', flexWrap: 'wrap', position:'relative' }}>
                                        <span>&nbsp;&nbsp; Sorted by: &nbsp;&nbsp;</span>
                                        <button className="btn btn-sm btn-rounded mb0" onClick={this.handleSortByZip} style={{ outline: 'none' }}>Zip</button>

                                        {
                                            this.state.isOpen &&
                                                <div id="flot-unset"  className="zipfilterdropDown" style={{ height: this.state.zipList.length === 2 ? 180 : 350}}>
                                                    <ul className="text-left" style={{ width: '100%' }}>
                                                        {
                                                            this.state.zipList.map((value, key)=>{
                                                                return <li key={key} style={{ padding: 10, background: '', margin: 2, borderBottom: '1px solid lightgray' }}>
                                                                <input type="checkbox"
                                                                    name={value} value={value}
                                                                    checked={this.state.zipcheckArr.filter(v => v === value).length}
                                                                    onChange={(e) => this.handlezipFilterBox(e)} />&nbsp; &nbsp;<span>{value}</span>
                                                            </li>
                                                            })
                                                        }
                                                    </ul>
                                                    <button className="btn btn-sm mb0" onClick={this.handleAppyZip} style={{ marginRight: 5 }}>APPLY</button>
                                                </div>
                                        }
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>


                    <section style={{ padding: '40px 0' }}>
                        <div className="container">
                            <div className="row">
                                {
                                    this.props.isLoading ?
                                        <div className="text-center">
                                            <img alt="loader" src={require('../../assets/img/smartappt_preloader.gif')} style={{ height: 150, width: 150 }} />
                                        </div>
                                        :
                                        <React.Fragment>
                                            {this.renderDocList()}
                                            {/* {this.state.zip && this.state.zipDoctors.length ? this.renderZipDoctors() : null} */}
                                        </React.Fragment>
                                }

                            </div>
                            {
                                this.state.zip && this.state.zipDoctors.length ?
                                    <section style={{ padding: 0, }} className="text-center">
                                        <span >we have more doctors for your search but they are further away <span className="bold" onClick={this.handleIgnoreZip} style={{ color: '#47b475' }}>Ignore Zip Search</span></span>
                                    </section>
                                    : null
                            }

                        </div>
                    </section>
                    <div className="backdrop" onClick={this.handleBackdrop} style={{ display: this.state.isOpen ? 'block' : 'none'}}></div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        doctorslist: state.docList,
        searchDocResult: state.searchDocResult,
        zipResult: state.zipResult,
        isLoading: state.isLoading,
        patientDetails: state.patient_details,
    }
};

const mapDispatchToProps = (dispatch) => { 
    return {
        handlegetDocList: data => dispatch({ type: GET_DOCTOR_LIST_REQUEST, data }),
        // handleGetSearchDocResult: data => dispatch({ type: SEARCH_DOC_SPECIALITY_REQUEST, data }),
        // handleGetSearchZipResult: data => dispatch({ type: SEARCH_ZIP_REQUEST, data }),
        handleGetPatientDetails: data => dispatch({ type: GETPATIENT_DETAILS_REQUEST, data }),

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchDoctor);
