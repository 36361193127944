import React, { Component } from 'react';
import { handleTimeFormat } from '../utils/DateMethod';
// import PatientName from './PatientName';
import { Baseurl } from '../utils/Baseurl';
import axios from 'axios';


export default class UpcomingPastAppointmentMobileView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggle: false,
            patientName: '',
            patientEmail: '',
            patientMobile: ''
        }
    }

    handleToggle = () => {
        this.setState({ toggle: !this.state.toggle });
    }


    UNSAFE_componentWillReceiveProps(props) {
        if (props.data) {
            this.handleGetPatientDetails(props.data);
             //console.log('patient_id',props.data.patient_id);
            //console.log('props.data',props.data);
        }
    }


    handleGetPatientDetails = (propdata) => {

    this.setState({ patientName: `${propdata.patient_name} `, patientEmail: propdata.patient_email, patientMobile: propdata.patient_mobile });
      /*  axios({
            method: 'get',
            url: Baseurl + `api/user/getuserdetails?userId=${id}`,
            headers: { 'content-type': 'application/json' }
        }).then((res) => {
            // console.log("upcomint-mobile-patient-details------>", res);
            this.setState({ patientName: `${res.data.user.first_name} ${res.data.user.last_name}`, patientEmail: res.data.user.email, patientMobile: res.data.user.mobile });
        }).catch((error) => {
            console.log("in-error---->", error);
        })*/
    }
   
    handleUpcomingConsultsButton = (data) => {
        this.props.history.push(`/searchdoc?name=&practice_name=&insurance_provider=&zip=&patient=${data.patient_id}`);
    }


    handleUpcomingCancelButton = (data) => {
        data.patientEmail = this.state.patientEmail;
        data.patientName = this.state.patientName;
        data.patientMobile = this.state.patientMobile;
        const modalFor = 'Cancel';
        this.props.setModalState(modalFor, data);
    }


    render() {
        const year = this.props.data.date.slice(0, 4);
        const month = this.props.data.date.slice(4, 6);
        const day = this.props.data.date.slice(6, 8);
        const newDate = `${month}/${day}/${year}`;
        const groupRole = this.props.grouprole;

        let locationName = this.props.docPractice.filter(value => value ? value._id === this.props.data.practice_id : '');

        return (
            <React.Fragment>
                <li className={this.state.toggle ? "active" : ""}>
                    <div onClick={this.handleToggle} className="title upcomingPastAppointment_mobileview_title_box">
                        <div className="text-left" style={{ height: 'auto', width: '33.3%', fontWeight: 'bold' }}>{this.props.data.patient_name}</div>

                        <div className="text-center" style={{ height: 'auto', width: '33.3%', fontWeight: 'bold' }}>{newDate}</div>
                        <div className="text-right" style={{ height: 'auto', width: '33.3%', fontWeight: 'bold' }}>
                            {handleTimeFormat(this.props.data.time)}
                            <span style={{ paddingLeft: 12 }}>
                                <img alt='logo' src={require('../assets/logo/down-arrow.png')}
                                    style={{
                                        height: 20, width: 20,
                                        WebkitTransform: this.state.toggle ? 'rotate(180deg)' : '',
                                        MozTransformStyle: this.state.toggle ? 'rotate(180deg)' : '',
                                        msTransform: this.state.toggle ? 'rotate(180deg)' : '',
                                        OTransform: this.state.toggle ? 'rotate(180deg)' : '',
                                        transform: this.state.toggle ? 'rotate(180deg)' : ''
                                    }}
                                />
                            </span>
                        </div>
                    </div>

                    <div className="content upcomingPastAppointmentmobile_content_table">
                        <table style={{ width: '100%' }}>
                            <tbody>
                                <tr>
                                    <th>Date Created</th>
                                    <td>{new Date(this.props.data.createdAt).toLocaleString()}</td>
                                </tr>
                                <tr>
                                    <th>Patient Details</th>
                                    <td>
                                        <p style={{ marginBottom: 0, padding: 0 }}>{this.props.data.newPatient===1 ? <img alt='new' src={require('../assets/img/new1.png')} rounded={true} /> : '' } {this.props.data.patient_name}</p><br />
                                        <p style={{ marginBottom: 0, padding: 0 }}>{this.props.data.patient_email}</p><br />
                                        <p style={{ marginBottom: 0, padding: 0 }}>{this.props.data.patient_mobile}</p><br />
                                        <p style={{ marginBottom: 0, padding: 0, textTransform: 'capitalize' }}>{this.props.data.payment_mode}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Doctor Details</th>
                                    <td>
                                        <p style={{ marginBottom: 0, padding: 0 }}>{this.props.data.doctor_name}</p><br />
                                        <p style={{ marginBottom: 0, padding: 0 }}>{this.props.data.doctor_email}</p><br />
                                        <p style={{ marginBottom: 0, padding: 0 }}>{this.props.data.doctor_mobile}</p><br />                                        
                                    </td>
                                </tr>
                                <tr>
                                    <th>Date</th>
                                    <td>{newDate}</td>
                                </tr>
                                <tr>
                                    <th>Time </th>
                                    <td>{handleTimeFormat(this.props.data.time)}</td>
                                </tr>
                                <tr>
                                    <th>Status</th>
                                    <td style={{ color: this.props.data.status === 'Confirmed' ? 'green' : 'tomato' }}>{this.props.data.status}</td>
                                </tr>
                               
                                    <tr>
                                        <th>Action</th>
                                        <td className="cu-389">
                                         {groupRole===7 || groupRole===8 ?
                                        <button className="btn btn-teal btn-rounded btn-sm m-0" onClick={() => this.handleUpcomingConsultsButton(this.props.data)} style={{ opacity: this.props.data.status === 'Cancelled' ? '0.5' : '1', background: '#47b475', color: '#fff',marginBottom:0,paddingLeft:3,paddingRight:3 }} disabled={this.props.data.status === 'Cancelled'} >Consults</button>:''}
                                         {
                                    this.props.upcoming &&
                                            <button className="btn btn-teal btn-rounded btn-sm m-0"
                                                disabled={this.props.data.status === 'Cancelled'}
                                                onClick={() => this.handleUpcomingCancelButton(this.props.data)}
                                                style={{ opacity: this.props.data.status === 'Cancelled' ? '0.5' : '1', background: '#47b475', color: '#fff', marginBottom: 0, height: 25, padding: 5, lineHeight: 0 }}
                                            >Cancel</button>  }

                                        </td>
                                    </tr>
                               

                                <tr>
                                    <th>Practice Location</th>
                                    <td>
                                        {
                                            locationName.map(value => {
                                                return `${value.practice_name},  ${value.zip}`
                                            })
                                        }
                                    </td>
                                </tr>

                                <tr>
                                    <th>Consult From</th>
                                   <td>{this.props.data.consult_from ==='undefined'? '--' : <span>{this.props.data.consult_from}</span> }
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </li>
            </React.Fragment>
        )
    }
}
