import React from "react"
import Picker, { SKIN_TONE_MEDIUM_DARK } from 'emoji-picker-react';
import { uploadApi } from "../../../utils/Api-interface";
import axios from "axios";
import { Baseurl } from "../../../utils/Baseurl";

class ChatFooter extends React.Component {
    state={
      message:"",
      emoji:false,
      uploadFiles:[],
      uploadFileWithCount:[]
    }
    handleChange=(e)=>{
      const message = e.target.value;
      this.setState({message})
    }
    handleSubmitMessage=(e)=>{
      e.preventDefault();
      const {message} = this.state;
      const {socket, senderId,reciverId,conversationId} = this.props;
     
      if(message !== ""){ 
        const sendMsgObj = {
          senderId : senderId,
          reciverId: reciverId,
          conversationId: conversationId,
          messageText: message,
          containsFile:false,
          files:[]       
        } 
        // const sendMsgObj = {
        //   senderId :reciverId,
        //   reciverId: senderId,
        //   conversationId: conversationId,
        //   messageText: message       
        // }     
        // console.log(sendMsgObj,'sendMsgObj')
       socket.emit("sendMessage",sendMsgObj);
       this.setState({message:"",emoji:false})
      }
    }

    handleEnter=(e)=>{
      if(e.keyCode === 13){
        this.handleSubmitMessage(e)
      }
    }
    handleEmoji=(emoji)=>{
      this.setState({emoji})
    }
    handleFileSelect=async(file)=>{
     

 
        const uploadFiles=file.target.files;
        // console.log(uploadFiles,'uploadFilesd')
        let fileArray =Object.entries(uploadFiles).map(([k,v])=>v); 
        let uploadFileState = fileArray.map(el=>{
          const o = {
            status:0,
            file:el
          }
          return o
        })
        this.setState({uploadFiles:uploadFileState},async()=>{
          let ufs = []
          fileArray.forEach(async(el)=>{
            // let reader = new FileReader();
            // reader.onloadend = () => {
      
            // }
            // reader.readAsDataURL(el);
            let formData = new FormData();
            formData.append("files", el);
            const uf=await axios.post(
              Baseurl + `chat_user/upload_chat_files`,
              formData,
              { 
                headers: {
                "content-type": "multipart/form-data",
              }, 
              onUploadProgress: data => {
                //Set the progress value to show the progress bar
                // let uploadFilestate = this.state.uploadFiles;
                // uploadFilestate = uploadFilestate.
                // console.log(data,'data',this.state.uploadFiles)
                // setProgress(Math.round((100 * data.loaded) / data.total))
              },
            })
            if(uf.status === 200){
              const oj = {
                ...uf.data,
                name:el.name
              }
              ufs.push(oj)
              this.setState({uploadFileWithCount:ufs},()=>{
                if(this.state.uploadFiles.length === this.state.uploadFileWithCount.length){
                  const {socket, senderId,reciverId,conversationId} = this.props;
                  // console.log(el,'sss')
                  const f = this.state.uploadFileWithCount.map(ell=>{return {url:ell.url, name:ell.name}})
                  const sendMsgObj = {
                    senderId : senderId,
                    reciverId: reciverId,
                    conversationId: conversationId,
                    messageText: "File attachment",
                    containsFile:true,
                    files:f       
                  } 
                  socket.emit("sendMessage",sendMsgObj);
                  this.setState({
                    uploadFileWithCount:[],
                    uploadFiles:[]
                  })
                  // console.log('done')
                }
              })
            }
          })
         
        })
      
    
    }
    render(){
      const {message, emoji, uploadFiles,uploadFileWithCount} = this.state;
      const {chatInputRef, handleChatInputRef, handleInputFocus} = this.props;
      const visibleEmojis = {
        flags: false, Symbol: false, objects: false, activities: false, travel_places
          : false, food_drink: false, animals_nature: false, animals_nature: false
      }
      
        return(
            <form style={{position:'relative'}}>
             {uploadFiles.length ? <span style={{position:"absolute",bottom:'100%', left:40}}>
               {uploadFiles.length === uploadFileWithCount.length ? `${uploadFileWithCount.length} file${uploadFileWithCount.length>1 ? `s`:``} uploaded` : `Attachment uploading please wait ...`}
              </span>:""}
              {emoji &&   <div className="emoji-fade" onClick={()=>this.handleEmoji(false)}/>}
              <div className="all_wp_section position-relative">
                <div className={emoji ? 'emoji_section' : 'emoji_section_hide'}>
                
              <Picker pickerStyle={{ width: '500px' }} skinTone={SKIN_TONE_MEDIUM_DARK} disableSearchBar={true}  preload={true} groupVisibility={visibleEmojis} onEmojiClick={(event, emojiObject) => { this.setState({message: this.state.message + emojiObject.emoji},()=> this.props.handleChatInputRef()); }} />
            </div>
                <div className="flex align-items-center">
                  <div className="sm_icon_show arrow_section border-top-0 p-0 mr-2">
                    <i
                      className="fas fa-chevron-right"
                      id="click_event"
                      role="button"
                    />
                  </div>

                  <div className="chat_input_section p_16 flex w-100">
                    <div className="chat_container_wp w-100 flex flex_center">
                      <input
                      ref={chatInputRef}
                        className="table_con mb-0"
                        name="message"
                        id="message"
                        type="text"
                        placeholder="Type here..."
                        value={message}
                        contentEditable="true"
                        data-tab="3"
                        dir="ltr"
                        spellCheck="true"
                        onFocus={()=>handleInputFocus(true)}
                        onBlur={()=>handleInputFocus(false)}
                        onChange={this.handleChange}
                        onKeyPress={this.handleEnter}
                      />
                      <i
                        className="far fa-grin-squint mr-4"
                        onClick={()=>this.handleEmoji(!emoji)}
                        style={{ fontSize: "1.3rem", cursor: "pointer" }}
                        data-backdrop="static"
                        data-keyboard="false"
                      />

                      <label className="file_upload real_position" htmlFor="attachment_file">
                        <i
                          className="fas fa-paperclip"
                          data-toggle="modal"
                          data-target="#exampleModalCenter"
                          data-backdrop="static"
                          data-keyboard="false"
                        />
                      </label>
                      <input type="file" id="attachment_file" multiple={true} style={{height:0,width:0}} onChange={this.handleFileSelect}/>
                    </div>
                    <div className="text-right">
                      <button
                       onClick={this.handleSubmitMessage}
                        className="cs_button focus_out mb-0"
                      >
                        Send
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
        )
    }
}

export default ChatFooter