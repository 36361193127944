import React, { Component } from "react";
import { connect } from "react-redux";
import "../styles/Subscription.css";
import SubscriptionUpdate from "./my-transaction/subscription";
import Moment from "react-moment";
import Doc from "../pdfExporter/DocService";
import PdfContainer from "../pdfExporter/PdfContainer";
import TransactionDetails, {
  PrintableTransactionDetails,
} from "../component/transaction-details";
import { withRouter, Link } from "react-router-dom";
class Transaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: this.props.location.pathname === "/my-transaction" ? 1 : 0,
    };
  }
  createPdf = (html) =>
    Doc.createPdf(html, this.props.transactiondata.subscriptionId);
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      if (this.props.location.pathname === "/my-transaction") {
        this.setState({ activeTab: 1 });
      } else {
        this.setState({ activeTab: 0 });
      }
    }
  }
  handleActiveTab=(activeTab)=>{
    this.setState({ activeTab })
  }
  render() {
    // console.log(this.props.location.pathname);
    const { transactiondata, customerprofiledata } = this.props;
    const { activeTab } = this.state;
    return (
      <React.Fragment>
        <div className="container transition">
          <div className="row">
            <ul
              className="nav nav-tabs nav-overflow header-tabs"
              id="myTab"
              role="tablist"
            >
              <li className={`nav-item ${activeTab === 0 ? `active` : ``}`} disabled={activeTab === 2}>
                <Link className="nav-link" to="/my-subscription" onClick={()=>this.handleActiveTab(0)}>
                  My Subscription
                </Link>
              </li>
              {activeTab === 2 && <li className={`nav-item ${activeTab === 2 ? `active` : ``}`}>
                <Link className="nav-link" to="/my-subscription">
                  Update Payment Profile
                </Link>
              </li>}
              <li className={`nav-item ${activeTab === 1 ? `active` : ``}`}>
                <Link className="nav-link" to="/my-transaction">
                  My Transaction
                </Link>
              </li>
             
            </ul>
            <div className="tab-content" id="myTabContent">
              <SubscriptionUpdate activeTab={activeTab} handleActiveTab={this.handleActiveTab}/>
              <div
                className={`tab-pane ${activeTab === 1 ? `active` : ``}`}
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                {!transactiondata?.length ? (
                  <div className="not-subscribed">No transactions found.</div>
                ) : (
                  <React.Fragment>
                    <h4>Transaction Details</h4>
                    {transactiondata.map((el, key) => {
                      return (
                        <React.Fragment key={`transaction-${key}`}>
                          <TransactionDetails transactiondata={el} />
                          <PdfContainer
                            createPdf={this.createPdf}
                            subscriptionId={el.subscriptionId}
                          >
                            <PrintableTransactionDetails
                              transactiondata={el}
                              customerprofiledata={customerprofiledata}
                            />
                          </PdfContainer>
                        </React.Fragment>
                      );
                    })}
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    transactiondata: state.transactionData,
    customerprofiledata: state.customerProfileData,
  };
};

export default withRouter(connect(mapStateToProps, null)(Transaction));
