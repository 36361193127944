import React, { Component } from 'react';
import { connect } from 'react-redux';
import { GET_DOC_REVIEWS_REQUEST } from '../../actions/Action';
import DoctorReviewMobileView from '../../component/DoctorReviewMobileView';
import { Baseurl } from '../../utils/Baseurl';
import axios from 'axios';
import { ToastMessage } from '../../component/ToastMessage';
import { toast } from 'react-toastify';
import Modal from "react-modal";
const validator = require("email-validator");

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        width:"50rem",
        transform: "translate(-50%, -50%)",
    },
};

Modal.setAppElement("#root");
Modal.defaultStyles.overlay.backgroundColor = "rgba(111, 111, 111, 0.75)";

class ManageStaffMember extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: '',
            listProviderStaff: [],
            isLoading: false,
            isModal: false,
            isDelete: false,
            providerName: '',
            addName: '',
            addEmail: '',
            addContact: '',
            errorIs: {
                addName: '',
                addEmail: '',
                addContact: '',
            },
            LoadingIs: false,
            providerId: '',
            toggle: false
        }
    }


    UNSAFE_componentWillMount() {
        window.scrollTo(0, 0);
        let id = localStorage.getItem('uid');
        this.props.handleGetDocReviews(id);
    }

    componentDidMount() {
        this.handleListProviderStaff();
        if(this.props?.loginuserdetails?._id){
            // console.log('000000',this.props.loginuserdetails?._id)
            this.setState({id:this.props.loginuserdetails?._id})
        }
    }

    /*----------------------------------------------------handle-save-review-------------------------------*/

    UNSAFE_componentWillReceiveProps(props) {
        // console.log("props",props.loginuserdetails?._id)
        if (props.loginuserdetails) {
            this.setState({ id: props.loginuserdetails?._id });
        }
    }

    handleListProviderStaff = () => {
        let drUid = localStorage.getItem("uid");
        this.setState({ isLoading: true });
        axios({
            method: "get",
            url: Baseurl + `api/staff/list_provider_staff?drUid=${drUid}`,
            headers: { "content-type": "application/json" },
        })
            .then((res) => {
                let response = res.data
                // console.log('handleListProviderStaff +++++++++++', response)
                this.setState({ listProviderStaff: response.data, isLoading: false });
            })
            .catch((error) => {
                // console.log("handleListProviderStaff error--->", error);
            });

    };

    handleAddProviderStaff = () => {
        let drUid = localStorage.getItem("uid");
        const { addName, addEmail, addContact, id } = this.state;
        console.log('====',id)

        if (this.validateForm()) {
            this.setState({ LoadingIs: true });
            let data = { "drId": id, 'drUid': drUid, 'name': addName, 'email': addEmail, 'mobile': addContact }
            axios({
                method: "post",
                url: Baseurl + `api/staff/save_provider_staff`,
                headers: { "content-type": "application/json" },
                data: data,
            })
                .then((res) => {
                    let response = res.data
                    // console.log('handleAddProviderStaff ==========', response)
                    this.resetField();
                    this.closeModal();
                    toast.success(<ToastMessage message={"Staff member added successfully"} />, { position: toast.POSITION.TOP_CENTER });
                    this.handleListProviderStaff();
                })
                .catch((error) => {
                    toast.error(<ToastMessage message={error.message} />, { position: toast.POSITION.TOP_CENTER });
                    // console.log("handleAddProviderStaff error--->", error);
                    this.closeModal();
                });
        }

    };

    handleDeleteProvider = () => {
        axios({
            method: "get",
            url: Baseurl + `api/staff/delete_provider_staff?staffId=${this.state.providerId}`,
            headers: { "content-type": "application/json" },
        })
            .then((res) => {
                let response = res.data
                // console.log('handleDeleteProvider', response)
                this.closeModal();
                toast.success(<ToastMessage message={"Staff member deleted successfully"} />, { position: toast.POSITION.TOP_CENTER });
                this.handleListProviderStaff();
            })
            .catch((error) => {
                toast.error(<ToastMessage message={error.message} />, { position: toast.POSITION.TOP_CENTER });
                // console.log("handleDeleteProvider error--->", error);
                this.closeModal();
            });

    };

    resetField = () => {
        this.setState({
            providerId: '',
            addName: '',
            addEmail: '',
            addContact: '',
            errorIs: {
                addName: '',
                addEmail: '',
                addContact: '',
            },
            LoadingIs: false
        });
    };

    validation(name, value) {
        const { errorIs } = this.state;
        // console.log('validation errorIs', errorIs)
        switch (name) {
            case "addName":
                errorIs.addName = !value.length ? "Please provide name" : "";
                break;
            case "addContact":
                errorIs.addContact = !value.length ? "Please provide member contact no." : value.length === 10 ? "" : "Please enter a valid phone number";
                break;
            case "addEmail":
                errorIs.addEmail = !value.length
                    ? "Please provide member email"
                    : !validator.validate(value)
                        ? "Invalid Email"
                        : "";
                break;
            default:
                break;
        }
    }

    validateForm() {
        const {
            errorIs,
            addName,
            addEmail,
            addContact
        } = this.state;
        errorIs.addName = !addName.length ? "Please provide name" : "";
        errorIs.addContact = addContact.length === "" ? "Please provide member contact no." : addContact !== undefined && addContact.toString().length === 10 ? "" : "Please enter a valid phone number";
        errorIs.addEmail = !addEmail.length
            ? "Please provide member email"
            : !validator.validate(addEmail)
                ? "Invalid Email"
                : "";

        // console.log('validateForm ++ errorIs ==', errorIs)
        this.setState({ errorIs });

        return (
            !errorIs.addName && !errorIs.addEmail && !errorIs.addContact
        );
    }

    handleToggle = () => {
        this.setState({ toggle: !this.state.toggle });
    }

    closeModal = () => {
        this.setState({ isModal: false, isDelete: false }, () => {
            this.resetField();
        });
    };

    handleOnchange = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value,
        });
        this.validation(name, value);
    };

    renderReviewsTable = () => {

        return this.state.listProviderStaff.map((value, key) => {

            return <div className="doc_review_resp-table-row" key={key}>
                <div className="doc_reivew_table-body-cell">{key + 1}</div>
                <div className="doc_reivew_table-body-cell">{value.name}</div>
                <div className="doc_reivew_table-body-cell">{value.email}</div>
                <div className="doc_reivew_table-body-cell">{value.mobile}</div>
                <div className="doc_reivew_table-body-cell" ><i onClick={() => this.setState({ isDelete: true, providerName: value.name, providerId: value._id })} className='ti-trash' style={{ cursor: 'pointer' }}></i></div>
            </div>
        })
    }



    render() {
        const { providerName, errorIs, LoadingIs } = this.state;

        return (
            <React.Fragment>
                <Modal
                    isOpen={this.state.isModal}
                    // onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Modal"
                >
                    <div style={{ height: '5rem', marginBottom: '1.5rem' }}>
                        <h4 style={{ paddingTop: '.7rem',fontWeight:400 }}>Fill Member Details</h4>
                    </div>
                    <div className="input-with-label text-left">
                        <span>Name</span>
                        <input
                            style={{  minWidth: '30rem', border: errorIs.addName ? `1px solid red` : `none` }}
                            type="text"
                            name="addName"
                            value={this.state.addName}
                            placeholder="Please enter name"
                            onChange={(e) => this.handleOnchange(e)}
                        />
                        {errorIs.addName ? (
                            <span style={{ color: 'red', marginBottom: '24px' }}>{errorIs.addName}</span>
                        ) : null}
                    </div>
                    <div className="input-with-label text-left">
                        <span>Email</span>
                        <input
                            style={{ width: '100%', background: '#f5f5f5', border: errorIs.addEmail ? `1px solid red` : `none`, height: '50px', paddingLeft: '20px', fontWeight: '500', marginBottom: '24px', borderRadius: 0 }}
                            type="email"
                            name="addEmail"
                            value={this.state.addEmail}
                            placeholder="Please enter email"
                            onChange={(e) => this.handleOnchange(e)}
                        />
                        {errorIs.addEmail ? (
                            <span style={{ color: 'red', marginBottom: '24px' }}>{errorIs.addEmail}</span>
                        ) : null}
                    </div>
                    <div className="input-with-label text-left">
                        <span>Contact</span>
                        <div className="input-group" style={{width:"100%", display:"flex", marginBottom:"24px"}}>
                        <span class="input-group-addon" style={{display:"inline-block", width:"auto",padding:"16px 15px",fontSize:"16px", fontWeight:"400"}}>+1</span>
                        <input
                            style={{  width:"100%", background: '#f5f5f5', border: errorIs.addContact ? `1px solid red` : `none`, height: '50px', paddingLeft: '20px', fontWeight: '500', borderRadius: 0 , marginBottom:0}}
                            type="number"
                            name="addContact"
                            value={this.state.addContact}
                            placeholder="Please enter contact no"
                            onChange={(e) => this.handleOnchange(e)}
                        />
                        </div>
                        {errorIs.addContact ? (
                            <span style={{ color: 'red', marginBottom: '24px' }}>{errorIs.addContact}</span>
                        ) : null}
                    </div>
                    <div style={{ textAlign: "center" }}>
                        <button
                            className="btn btn-sm btn-filled"
                            style={{ height: 50, marginRight: '1rem', width: '10rem' }}
                            onClick={this.handleAddProviderStaff}
                        >
                            {LoadingIs ?
                                <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div> : ''
                            }
                            Save
                        </button>
                        <button
                            className="btn btn-sm btn-filled"
                            style={{ height: 50, marginLeft: '1rem', width: '10rem' }}
                            onClick={this.closeModal}
                        >
                            Cancel
                        </button>
                    </div>
                </Modal>
                <Modal
                    isOpen={this.state.isDelete}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Modal"
                >
                    <h3 className="text-center" style={{fontWeight:400, maxWidth:"480px" , fontSize:"26px"}}> Are you sure You Want To Delete?</h3>
                    <div style={{ textAlign: "center" }}>
                        <button
                            className="btn btn-sm btn-filled"
                            style={{ height: 50, marginRight: '1rem', width: '10rem' }}
                            onClick={this.handleDeleteProvider}
                        >
                            Yes
                        </button>
                        <button
                            className="btn btn-sm btn-filled"
                            style={{ height: 50, marginLeft: '1rem', width: '10rem' }}
                            onClick={this.closeModal}
                        >
                            No
                        </button>
                    </div>
                </Modal>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <h4>Staff Members List</h4>
                    {/* {this.state.listProviderStaff.length > 4 ? '' :
                     
                    } */}
                       <button onClick={() => this.setState({ isModal: true })} className="btn btn-md btn-filled btn-rounded" style={{ lineHeight: 0 }}>Add Staff Member</button>
                </div>
                <hr />
                <div className="review_main_container">
                    {
                        this.state.isLoading ?
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img alt="placeholder" src="https://luxysource.com/pub/static/frontend/Magento/luxysource/en_US/custom/img/preloader.gif" style={{ height: 100, width: 100 }} />
                            </div>
                            : this.state.listProviderStaff.length === 0 ?
                                <h4 className="text-center" style={{ color: 'rgb(0, 35, 75)', fontWeight: 'bold' }}>No Results</h4>
                                :
                                <React.Fragment>

                                    <div className="doc_review_for_web">
                                        <div className="doc_review_resp-table-header">
                                            <div className="doc_review_table-header-cell">#</div>
                                            <div className="doc_review_table-header-cell">Name</div>
                                            <div className="doc_review_table-header-cell">Email</div>
                                            <div className="doc_review_table-header-cell">Contact</div>
                                            <div className="doc_review_table-header-cell">Action</div>
                                        </div>

                                        <div className="doc_reivew_resp-table-body">
                                            {
                                                this.renderReviewsTable()
                                            }
                                        </div>
                                    </div>


                                    <ul className="accordion accordion-1 doc_review_for_mobile">
                                        {
                                            this.state.listProviderStaff.map((value, key) => {
                                                return <li className={this.state.toggle ? "active" : ""} key={key}>
                                                    <div onClick={this.handleToggle} className="title doc_review_title_box">
                                                        <p>{value.name}</p>
                                                    </div>

                                                    <div className="content doc_review_mobile_content">
                                                        <table style={{ width: '100%' }}>
                                                            <tbody>
                                                                <tr>
                                                                    <th>Name</th>
                                                                    <td>{value.name}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Email</th>
                                                                    <td>{value.email}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th style={{border:'1px solid lightGrey'}}>Contact</th>
                                                                    <td> {value.mobile}</td>
                                                                </tr>

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </li>
                                            })
                                        }
                                    </ul>
                                </React.Fragment>

                    }
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loginuserdetails: state.loginuserdetails?.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        handleGetDocReviews: data => dispatch({ type: GET_DOC_REVIEWS_REQUEST, data }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageStaffMember);