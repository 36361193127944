import React, { Component } from "react";
import { GetAPI, PostAPI } from "../../utils/Api-interface";
import Modal from "react-modal";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { GET_SUBSCRIPTION_REQUEST } from "../../actions/Action";
import * as moment from "moment";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

class TrialPeriod extends Component {
  state = {
    ModalIsOpen: false,
    underTrialIs: false,
    trailCheck: false,
  };

  closeModal = () => {
    this.setState({ ModalIsOpen: false });
  };

  // handleGetSubscription = () => {
  //   const uid = localStorage.getItem("uid");
  //   if (uid) {
  //     console.log('check1')
  //     GetAPI(`api/subscription/get_subscription?doctor_uid=${uid}`)
  //       .then((res) => {
  //         const response = res.data.data;
  //         const resIs = res.data
  //         if (response.length > 0 && resIs.status === "1") {
  //           console.log("Doctor Subscribed");
  //         } else {
  //           this.handleTrialPeriod(uid);
  //         }
  //       })
  //   }
  // };
  getDays = (days) => {
    // console.log(days, "dff");
    if (days > 1) {
      return `Your trial period will expire in ${days > 30 ? 30 : days} days`;
    } else if (days === 1) {
      return `Your trial period will expire tomorrow`;
    } else if (days === 0) {
      return `Your trial period will expire today`;
    } else return false;
  };
  handleTrialPeriod = (uid) => {
    if (uid) {
      const currentData = { doctor_uid: uid };
      PostAPI(`api/subscription/trial_period`, currentData).then((res) => {
        const responseIs = res.data.data;

        if (responseIs.trail_end_date) {
          const expiry = moment(
            new Date(responseIs.trail_end_date).toDateString()
          );
          const today = moment(new Date().toDateString());
          const leftDays = expiry.diff(today, "days");
          // const endDate = moment(
          //   responseIs.trail_end_date.split("T").slice(0)[0]
          // );

          if (this.getDays(leftDays)) {
            this.setState({ underTrialIs: this.getDays(leftDays) });
          } else {
            const ptahIs = window.location.pathname;
            this.setState({
              ModalIsOpen: ptahIs === "/subscription" ? false : true,
              underTrialIs: false,
            });
          }
        }
      });
    }
  };

  componentDidMount() {
    if (!this.state.trailCheck) {
      this.setState({ trailCheck: true }, () => {
        setTimeout(() => {
          const uid = localStorage.getItem("uid");
          // this.handleGetSubscription();
          this.props.getSubscription(uid);
          setTimeout(() => {
            if (!this.props.subscriptiondata) {
              this.handleTrialPeriod(uid);
            }
          }, 800);
        }, 800);
      });
    }
  }
  componentDidUpdate() {
    if (!this.state.trailCheck) {
      this.setState({ trailCheck: true }, () => {
        setTimeout(() => {
          const uid = localStorage.getItem("uid");
          // this.handleGetSubscription();
          this.props.getSubscription(uid);
          setTimeout(() => {
            if (!this.props.subscriptiondata) {
              this.handleTrialPeriod(uid);
            }
          }, 800);
        }, 800);
      });
    }
  }
  isSubscriptionValid = () => {
    if (this.props.subscriptiondata) {
      const s_end_date = this.props.subscriptiondata.subscription_end_date;
      const expiry = moment(new Date(s_end_date).toDateString());
      const today = moment(new Date().toDateString());
      const leftDays = expiry.diff(today, "days");
      return leftDays >= 0;
    } else {
      return true;
    }
  };
  render() {
    const { ModalIsOpen, underTrialIs } = this.state;
    const { subscriptiondata } = this.props;
    return (
      <React.Fragment>
        {underTrialIs && !subscriptiondata && (
          <div className="alert alert-soft-danger text-center" role="alert">
            Your are on 30 day free trial plan. Subscribe to Paid plan to keep
            receiving benefits of Smart Appointment. <br />
            {underTrialIs} <Link to="/subscription">Subscribe now</Link>
          </div>
        )}
        <div>
          {this.props.location.pathname !== "/subscription" &&
            !this.props.subscriptiondata && (
              <Modal
                isOpen={ModalIsOpen}
                style={customStyles}
                contentLabel="Example Modal"
              >
                <div className="text-center pt-2">
                  <h5>
                    Your Trial Period has been finished. Please Take
                    Subsciption.
                  </h5>
                  <Link
                    className="btn btn-md btn-filled btn-rounded"
                    to="/subscription"
                  >
                    Subscribe now
                  </Link>
                </div>
              </Modal>
            )}
          {this.props.location.pathname !== "/subscription" &&
            !this.isSubscriptionValid() && (
              <Modal
                isOpen={true}
                style={customStyles}
                contentLabel="Example Modal"
              >
                <div className="text-center pt-2">
                  <h5>
                    Your Subsription has been expired. Please Take Subsciption.
                  </h5>
                  <Link
                    className="btn btn-md btn-filled btn-rounded"
                    to="/subscription"
                  >
                    Subscribe now
                  </Link>
                </div>
              </Modal>
            )}
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    subscriptiondata: state.subscriptionData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubscription: (data) =>
      dispatch({ type: GET_SUBSCRIPTION_REQUEST, data }),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TrialPeriod)
);
