import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  GET_DOC_DETAILS_REQUEST,
  GETPATIENT_DETAILS_REQUEST,
  GET_DOCTOR_LIST_REQUEST,
} from "../../actions/Action";
import { Baseurl } from "../../utils/Baseurl";
import axios from "axios";
import queryString from "query-string";
import ConfirmAppointmentPopup from "../../component/ConfirmAppointmentPopup";
import ConsultScheduleMobileView from "../../component/ConsultScheduleMobileView";

// import { ScheduleAppointmentMailToDoctor } from '../../component/ScheduleAppointmentMailToDoctor';
// import { ScheduleAppointmentMailToPatient } from '../../component/ScheduleAppointmentMailToPatient';
// import { ConsultConfirmAppointmentMail } from '../../component/ConsultConfirmAppointmentMail'
// import { ConsultConfirmAppointmentMailToPatient } from '../../component/ConsultConfirmAppointmentMailToPatient';

// import { ConfirmAppointmentMailToPatient } from '../../component/ConfirmAppointmentMailToPatient';

import { handleTimeFormat, HandleScheduleDate } from "../../utils/DateMethod";
import "../../styles/DocHubSchedule.css";

class ScheduleConsults extends Component {
  constructor(props) {
    super(props);
    this.state = {
      doc_name: "",
      selectedPracticeLocationId: "",
      docPracticeLocationArr: [],
      profile: "",
      speciality: [],
      days: 0,
      appointData: [],
      rows: 5,
      currentFiveDaysData: [],
      currentDocEmail: "",
      userEmail: "",
      patientName: "",
      isLoading: false,
      patient_insurance_id: "",
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      self_name: "",
      doc_insurance: [],
      doc_medicaid_insurance: [],
      patient_hasInsurance: "",
      currentGrpDocEmail: "",
      appointModel: false,
      modelAppintData: {},
      reminder: true,
      groupRole: 7,
    };
  }

  UNSAFE_componentWillMount() {
    let values = queryString.parse(this.props.location.search);
    this.props.handlegetDocProfile(values.doc);
    this.props.handleGetPatientDetails(values.patient);
    this.handleSelfDetailsProfile(values.doc);
    this.props.handlegetDocList({
      insurance_provider: "",
      specaility: "",
      practice: "",
    });
    // this.handleGetAppointmentData();
  }

  // UNSAFE_componentWillMount() {
  // let values = queryString.parse(this.props.location.search);
  // this.props.handleGetPatientDetails(values.patient);
  // this.handleSelfDetailsProfile();
  // }

  handleSelfDetailsProfile = (id) => {
    // let id = localStorage.getItem("uid"); 

    axios({
      method: "get",
      url: Baseurl + `api/doc/getDetails?userId=${id}`,
      headers: { "content-type": "application/json" },
    })
      .then((res) => {
        console.log("self-record->", res.data);
        this.setState({
          self_name: `${res.data.user.first_name} ${res.data.user.last_name}`,
        });
        
      })
      .catch((error) => {
        console.log("error---->", error);
      });
  };

  setProfileData = (d) => {
    if (d.docProfile) {
      this.setState(
        {
          patient_insurance_id: d.patientDetails.insurance_id,
          patient_hasInsurance: d.patientDetails.hasInsurance,
          patientName: `${d.patientDetails.first_name} ${d.patientDetails.last_name}`,
          doc_name: `${d.docProfile.first_name} ${d.docProfile.last_name}`,
          groupRole: `${d.docProfile.groupRole}`,
          docPracticeLocationArr:
            d.docProfile.practice && d.docProfile.practice.length > 0
              ? d.docProfile.practice
              : [],
          selectedPracticeLocationId:
            d.docProfile.practice && d.docProfile.practice.length > 0
              ? d.docProfile.practice[0]._id
              : "none",
          // practice: d.docProfile.practice && d.docProfile.practice.length > 0 ? [d.docProfile.practice[0]] : [],
          doc_insurance:
            d.docProfile.insurance_providers &&
            d.docProfile.insurance_providers.length > 0
              ? d.docProfile.insurance_providers
              : [],
          doc_medicaid_insurance:
            d.docProfile.medicaid_insurance_providers &&
            d.docProfile.medicaid_insurance_providers.length > 0
              ? d.docProfile.medicaid_insurance_providers
              : [],
          speciality: d.docProfile.speciality ? d.docProfile.speciality : [],
          profile: d.docProfile.profile_image,
          currentDocEmail: d.docProfile.email,
          currentGrpDocEmail: d.docProfile.groupDrEmail,
          userEmail: d.patientDetails.email,
          patientMobile: d.patientDetails.mobile,
        },
        () => {
          this.handleGetAppointmentData();
        }
      );
    }
  };
  UNSAFE_componentWillReceiveProps(props) {
    this.setProfileData(props);
  }

  /*----------------------------------------------------render-doc-location-----------------------------------------------*/

  renderLocation = () => {
    let l = this.state.docPracticeLocationArr.filter(
      (k) => k._id === this.state.selectedPracticeLocationId
    );
    return l.map((value) => {
      return `${value.practice_name}, ${value.practice_location}, ${value.zip}`;
    });
  };

  /*----------------------------------------------------handle location-onChange-----------------------------------------------*/

  handleDocLocationOnchange = (e) => {
    this.setState({ selectedPracticeLocationId: e.target.value }, () => {
      this.handleGetAppointmentData();
    });
  };

  /*----------------------------------------------------render-week-header-----------------------------------------------*/

  renderTableRow = () => {
    const { days } = this.state;

    let toDay = HandleScheduleDate(
      new Date(new Date().getTime() + days * 24 * 60 * 60 * 1000)
    );
    let tomoro1 = HandleScheduleDate(
      new Date(new Date().getTime() + (days + 1) * 24 * 60 * 60 * 1000)
    );
    let tomoro2 = HandleScheduleDate(
      new Date(new Date().getTime() + (days + 2) * 24 * 60 * 60 * 1000)
    );
    let tomoro3 = HandleScheduleDate(
      new Date(new Date().getTime() + (days + 3) * 24 * 60 * 60 * 1000)
    );
    let tomoro4 = HandleScheduleDate(
      new Date(new Date().getTime() + (days + 4) * 24 * 60 * 60 * 1000)
    );

    return (
      <tbody id="demo">
        <tr className="text-center">
          <th>{toDay.date}</th>
          <th>{tomoro1.date}</th>
          <th>{tomoro2.date}</th>
          <th>{tomoro3.date}</th>
          <th>{tomoro4.date}</th>
        </tr>
        {this.rendertiemRow(
          toDay.timezone,
          tomoro1.timezone,
          tomoro2.timezone,
          tomoro3.timezone,
          tomoro4.timezone
        )}
      </tbody>
    );
  };

  /*----------------------------------------------------render-time-Row-method-----------------------------------------------*/

  rendertiemRow = (a, b, c, d, e) => {
    const items = [];
    for (var i = 0; i < this.state.rows; i++) {
      items.push(
        <tr key={i}>
          <td>{this.handleToday(a, i)}</td>
          <td>{this.handleToday(b, i)}</td>
          <td>{this.handleToday(c, i)}</td>
          <td>{this.handleToday(d, i)}</td>
          <td>{this.handleToday(e, i)}</td>
        </tr>
      );
    }

    return items;
  };

  handleSetReminder = () => {
    this.setState({ appointModel: false });
  };

  /*------------------------------------------handle-schedule-time and put clint and update time --------------------------------------*/

  handleSetClientInScheduleTime = (appoint, i, telestatusIs) => {
    var tele = this.refs.tele;
    var teleStatus = tele.getAttribute("data-tele");
    console.log("data-tele", tele.getAttribute("data-tele"), telestatusIs);
    if (this.state.patient_hasInsurance === "0") {
      this.setState({
        appointModel: true,
        modelAppintData: {
          appoint,
          index: i,
          docName: this.state.doc_name,
          docSpeciality: this.state.speciality,
          patientName: this.state.patientName,
          patientMobile: this.state.patientMobile,
          practice_arr: this.renderLocation(),
          practice_id: this.state.selectedPracticeLocationId,
          patientEmail: this.state.userEmail,
          docEmail: this.state.currentDocEmail,
          grpDocEmail: this.state.currentGrpDocEmail,
          insuranceName: "cash",
          self_name: this.state.self_name,
          hasInsurance: "0",
          modalStatus: "0",
          teleStatus: telestatusIs,
        },
        isLoading: false,
      });
    } else if (this.state.patient_hasInsurance === "1") {
      this.setState({
        appointModel: true,
        modelAppintData: {
          appoint,
          index: i,
          docName: this.state.doc_name,
          docSpeciality: this.state.speciality,
          patientName: this.state.patientName,
          patientMobile: this.state.patientMobile,
          practice_arr: this.renderLocation(),
          practice_id: this.state.selectedPracticeLocationId,
          patientEmail: this.state.userEmail,
          docEmail: this.state.currentDocEmail,
          grpDocEmail: this.state.currentGrpDocEmail,
          insuranceName: "cash",
          self_name: this.state.self_name,
          hasInsurance: "1",
          modalStatus: "1",
          teleStatus: telestatusIs,
        },
        isLoading: false,
      });
    } else {
      this.setState({ isClickTime: true });
      const { patientDetails, docProfile } = this.props;

      axios({
        method: "get",
        url:
          Baseurl +
          `api/report/getreport_lastappointment?patientObj_id=${patientDetails._id}&member_id=${patientDetails.insurance_id}`,
        headers: { "content-type": "application/json" },
      })
        .then((res) => {
          if (res.data) {
            let isMedicaidInsurancenew = 0;
            let filterdocinsurance = this.state.doc_insurance.filter(
              (v) => v.code === patientDetails.payerCode
            );

            let filterdocMedicaidinsurance = this.state.doc_medicaid_insurance.filter(
              (v) => v.code === patientDetails.payerCode
            );
            isMedicaidInsurancenew = res.data.isMedicaidInsurance;
            if (
              filterdocinsurance.length !== 0 &&
              isMedicaidInsurancenew == 0
            ) {
              this.setState({
                appointModel: true,
                modelAppintData: {
                  appoint,
                  index: i,
                  docName: this.state.doc_name,
                  docSpeciality: this.state.speciality,
                  patientName: this.state.patientName,
                  patientMobile: this.state.patientMobile,
                  practice_arr: this.renderLocation(),
                  practice_id: this.state.selectedPracticeLocationId,
                  patientEmail: this.state.userEmail,
                  docEmail: this.state.currentDocEmail,
                  grpDocEmail: this.state.currentGrpDocEmail,
                  self_name: this.state.self_name,
                  hasInsurance: "2",
                  modalStatus: "2",
                  teleStatus: telestatusIs,
                  insuranceName: filterdocinsurance[0].provider_name,
                },
                isLoading: false,
                isClickTime: false,
              });
            } else if (
              filterdocMedicaidinsurance.length !== 0 &&
              isMedicaidInsurancenew == 1
            ) {
              this.setState({
                appointModel: true,
                modelAppintData: {
                  appoint,
                  index: i,
                  docName: this.state.doc_name,
                  docSpeciality: this.state.speciality,
                  patientName: this.state.patientName,
                  patientMobile: this.state.patientMobile,
                  practice_arr: this.renderLocation(),
                  practice_id: this.state.selectedPracticeLocationId,
                  patientEmail: this.state.userEmail,
                  docEmail: this.state.currentDocEmail,
                  grpDocEmail: this.state.currentGrpDocEmail,
                  self_name: this.state.self_name,
                  hasInsurance: "2",
                  modalStatus: "2",
                  teleStatus: telestatusIs,
                  insuranceName: filterdocMedicaidinsurance[0].provider_name,
                },
                isLoading: false,
                isClickTime: false,
              });
            } else {
              this.setState({
                appointModel: true,
                modelAppintData: {
                  appoint,
                  index: i,
                  docName: this.state.doc_name,
                  docSpeciality: this.state.speciality,
                  patientName: this.state.patientName,
                  patientMobile: this.state.patientMobile,
                  practice_arr: this.renderLocation(),
                  practice_id: this.state.selectedPracticeLocationId,
                  patientEmail: this.state.userEmail,
                  docEmail: this.state.currentDocEmail,
                  grpDocEmail: this.state.currentGrpDocEmail,
                  insuranceName: "cash",
                  self_name: this.state.self_name,
                  hasInsurance: "4",
                  modalStatus: "4",
                  teleStatus: telestatusIs,
                },
                isLoading: false,
                isClickTime: false,
              });
            }
          } else {
            let date = new Date();
            let yy = date.getFullYear();
            let mm =
              date.getMonth() + 1 > 9
                ? date.getMonth() + 1
                : "0" + (date.getMonth() + 1);
            let dd = date.getDate() > 9 ? date.getDate() : "0" + date.getDate();
            let fullCurrentDate = `${mm}/${dd}/${yy}`;

            let data = {
              d_firstname: docProfile.first_name,
              d_lastname: docProfile.last_name,
              npi: docProfile.npi_number,

              payerCode: patientDetails.payerCode,
              dob: patientDetails.dob,
              memberID: patientDetails.insurance_id,
              p_firstname: patientDetails.first_name,
              p_lastname: patientDetails.last_name,
              doS_StartDate: fullCurrentDate,
              doS_EndDate: fullCurrentDate,
              user_obj_id: patientDetails._id,
              doctor_obj_id: docProfile._id,
            };

            if (parseInt(this.state.groupRole) === 7) {
              axios({
                method: "post",
                url: Baseurl + "api/user/GetInsuranceDetails",
                headers: { "content-type": "application/json" },
                data,
              })
                .then((res) => {
                  this.setState({ isClickTime: false });
                  const response = res.data.data;
                  const isMedicaidInsurance = res.data.isMedicaidInsurance;
                  // console.log("handle user insurance response ------>", res.data);
                  let filterdocinsurance = this.state.doc_insurance.filter(
                    (v) => v.code === response.extensionProperties.payerCode
                  );

                  let filterdocMedicaidinsurance = this.state.doc_medicaid_insurance.filter(
                    (v) => v.code === response.extensionProperties.payerCode
                  );

                  if (response.status === "Active") {
                    if (
                      filterdocinsurance.length !== 0 &&
                      isMedicaidInsurance == 0
                    ) {
                      this.setState({
                        appointModel: true,
                        modelAppintData: {
                          appoint,
                          index: i,
                          docName: this.state.doc_name,
                          docSpeciality: this.state.speciality,
                          patientName: this.state.patientName,
                          patientMobile: this.state.patientMobile,
                          practice_arr: this.renderLocation(),
                          practice_id: this.state.selectedPracticeLocationId,
                          patientEmail: this.state.userEmail,
                          docEmail: this.state.currentDocEmail,
                          grpDocEmail: this.state.currentGrpDocEmail,
                          self_name: this.state.self_name,
                          hasInsurance: "2",
                          modalStatus: "2",
                          teleStatus: telestatusIs,
                          insuranceName: filterdocinsurance[0].provider_name,
                        },
                        isLoading: false,
                        isClickTime: false,
                      });
                    } else if (
                      filterdocMedicaidinsurance.length !== 0 &&
                      isMedicaidInsurance == 1
                    ) {
                      this.setState({
                        appointModel: true,
                        modelAppintData: {
                          appoint,
                          index: i,
                          docName: this.state.doc_name,
                          docSpeciality: this.state.speciality,
                          patientName: this.state.patientName,
                          patientMobile: this.state.patientMobile,
                          practice_arr: this.renderLocation(),
                          practice_id: this.state.selectedPracticeLocationId,
                          patientEmail: this.state.userEmail,
                          docEmail: this.state.currentDocEmail,
                          grpDocEmail: this.state.currentGrpDocEmail,
                          self_name: this.state.self_name,
                          hasInsurance: "2",
                          modalStatus: "2",
                          teleStatus: telestatusIs,
                          insuranceName:
                            filterdocMedicaidinsurance[0].provider_name,
                        },
                        isLoading: false,
                        isClickTime: false,
                      });
                    } else {
                      this.setState({
                        appointModel: true,
                        modelAppintData: {
                          appoint,
                          index: i,
                          docName: this.state.doc_name,
                          docSpeciality: this.state.speciality,
                          patientName: this.state.patientName,
                          patientMobile: this.state.patientMobile,
                          practice_arr: this.renderLocation(),
                          practice_id: this.state.selectedPracticeLocationId,
                          patientEmail: this.state.userEmail,
                          docEmail: this.state.currentDocEmail,
                          grpDocEmail: this.state.currentGrpDocEmail,
                          insuranceName: "cash",
                          self_name: this.state.self_name,
                          hasInsurance: "4",
                          modalStatus: "4",
                          teleStatus: telestatusIs,
                        },
                        isLoading: false,
                        isClickTime: false,
                      });
                    }
                  } else {
                    this.setState({
                      appointModel: true,
                      modelAppintData: {
                        appoint,
                        index: i,
                        docName: this.state.doc_name,
                        docSpeciality: this.state.speciality,
                        patientName: this.state.patientName,
                        patientMobile: this.state.patientMobile,
                        practice_arr: this.renderLocation(),
                        practice_id: this.state.selectedPracticeLocationId,
                        patientEmail: this.state.userEmail,
                        docEmail: this.state.currentDocEmail,
                        grpDocEmail: this.state.currentGrpDocEmail,
                        insuranceName: "cash",
                        self_name: this.state.self_name,
                        hasInsurance: "3",
                        modalStatus: "3",
                        teleStatus: telestatusIs,
                      },
                      isLoading: false,
                      isClickTime: false,
                    });
                  }
                })
                .catch((error) => {
                  console.log("handle user insurance error --------->", error);
                  this.setState({ isClickTime: false });
                });
            } else {
              this.setState({
                appointModel: true,
                modelAppintData: {
                  appoint,
                  index: i,
                  docName: this.state.doc_name,
                  docSpeciality: this.state.speciality,
                  patientName: this.state.patientName,
                  patientMobile: this.state.patientMobile,
                  practice_arr: this.renderLocation(),
                  practice_id: this.state.selectedPracticeLocationId,
                  patientEmail: this.state.userEmail,
                  docEmail: this.state.currentDocEmail,
                  grpDocEmail: this.state.currentGrpDocEmail,
                  insuranceName: "cash",
                  self_name: this.state.self_name,
                  hasInsurance: "4",
                  modalStatus: "4",
                  teleStatus: telestatusIs,
                },
                isLoading: false,
                isClickTime: false,
              });
            }
          }
        })
        .catch((error) => {
          console.log("report-error-------->", error);
          this.setState({ isClickTime: false });
          toast.error("Something wrong.", {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    }
  };

  /*----------------------------------------------------return-times-----------------------------------------------*/

  handleToday = (d, i) => {
    let date = new Date();

    let cdd = String(date.getDate()).padStart(2, "0");
    let cmm = String(date.getMonth() + 1).padStart(2, "0");
    let cyy = date.getFullYear();

    let cHours = String(date.getHours()).padStart(2, "0");
    let cMin = String(date.getMinutes() + 1).padStart(2, "0");

    let cTime = String(cHours) + String(cMin);
    let cDate = String(cyy) + String(cmm) + String(cdd);

    var v = {
      time: 0,
    };

    this.state.currentFiveDaysData.map((value) => {
      if (value.date === cDate) {
        let filterAvalibility = value.availability.filter(
          (data) => parseInt(data.time) > cTime
        );
        value.availability = filterAvalibility;
      }

      let pdd = String(d.getDate()).padStart(2, "0");
      let pmm = String(d.getMonth() + 1).padStart(2, "0");
      let pyy = d.getFullYear();

      let eventDate = String(pyy) + String(pmm) + String(pdd);

      if (value.date === eventDate) {
        v.value = value;
        v.date = value.date;
        v.time = value.availability[i] ? value.availability[i].time : null;
        v.client = value.availability[i] ? value.availability[i].client : null;
        v.availabilityLength = value.availability.length;
        v.offer_telemedicine = value.availability[i]
          ? value.availability[i].offer_telemedicine
          : 0;
        v.background = "#47b475";
        if (v.offer_telemedicine == 1) {
          v.background = "#008b8b";
        }
        if (v.offer_telemedicine == 2) {
          v.background = "#D3D3D3";
        }
      }
      return null;
    });

    // let hours = v.time > 0 ? v.time.slice(0, 2) : '';
    // let min = v.time ? v.time.slice(2, 4) : '';

    // let apointDate = new Date(d);
    // apointDate.setHours(hours);
    // apointDate.setMinutes(min);
    // apointDate.setSeconds(0);
    // apointDate.setMilliseconds(0);
    // let apointTimeStamp = apointDate.getTime()

    return v.client ? (
      <span style={{ fontSize: 35, color: "gray" }}>-</span>
    ) : v.time ? (
      i === 4 && this.state.rows < 6 && v.availabilityLength > 5 ? (
        <button
          onClick={this.handleViewMore}
          className="btn btn-md btn-filled"
          style={{
            background: "#47b475",
            height: "auto",
            width: 100,
            minWidth: 0,
            color: "#fff",
            border: "1px solid #47b475",
          }}
        >
          view more
        </button>
      ) : (
        <button
          disabled={this.state.isClickTime}
          ref="tele"
          data-tele={v.offer_telemedicine}
          onClick={() =>
            this.handleSetClientInScheduleTime(v.value, i, v.offer_telemedicine)
          }
          className="btn btn-md btn-filled"
          style={{
            background: v.background,
            height: "auto",
            width: 100,
            minWidth: 0,
            color: "#fff",
            border: "1px solid #47b475",
          }}
        >
          {handleTimeFormat(v.time)}
        </button>
      )
    ) : (
      <span style={{ fontSize: 35, color: "gray" }}>-</span>
    );
  };

  /*----------------------------------------------------handle-next-week-----------------------------------------------*/

  handleNext = () => {
    this.setState({ days: this.state.days + 5, rows: 5 }, () => {
      this.handleGetAppointmentData();
    });
  };

  /*----------------------------------------------------handle-pre-week-----------------------------------------------*/

  handlePreview = () => {
    if (this.state.days === 0) {
      console.log("you cant go back now !");
      return null;
    } else {
      this.setState({ days: this.state.days - 5, rows: 5 }, () => {
        this.handleGetAppointmentData();
      });
    }
  };

  /*------------------------------convert date-to string---------------------*/

  convertDateToString = (date) => {
    let pdd = String(date.getDate()).padStart(2, "0");
    let pmm = String(date.getMonth() + 1).padStart(2, "0");
    let pyy = date.getFullYear();

    return String(pyy) + String(pmm) + String(pdd);
  };

  /*----------------------------------------------------handle-get-appointments-----------------------------------------------*/

  handleGetAppointmentData = () => {
    this.setState({ isLoading: true });
    const { days } = this.state;
    let values = queryString.parse(this.props.location.search);
    axios({
      method: "get",
      url:
        Baseurl +
        `api/schedule/get_schedules?uid=${values.doc}&practice_id=${this.state.selectedPracticeLocationId}`,
      headers: { "content-type": "application/json", Pragma: "no-cache" },
    })
      .then((response) => {
        // console.log("get-schedule-response------------->", response.data.data);

        let toDay = HandleScheduleDate(
          new Date(new Date().getTime() + days * 24 * 60 * 60 * 1000)
        );
        let tomoro1 = HandleScheduleDate(
          new Date(new Date().getTime() + (days + 1) * 24 * 60 * 60 * 1000)
        );
        let tomoro2 = HandleScheduleDate(
          new Date(new Date().getTime() + (days + 2) * 24 * 60 * 60 * 1000)
        );
        let tomoro3 = HandleScheduleDate(
          new Date(new Date().getTime() + (days + 3) * 24 * 60 * 60 * 1000)
        );
        let tomoro4 = HandleScheduleDate(
          new Date(new Date().getTime() + (days + 4) * 24 * 60 * 60 * 1000)
        );

        let filtertoDay = this.handleOnlyNull(
          response.data.data.filter(
            (value) => value.date === this.convertDateToString(toDay.timezone)
          )
        );
        let filtertomoro1 = this.handleOnlyNull(
          response.data.data.filter(
            (value) => value.date === this.convertDateToString(tomoro1.timezone)
          )
        );
        let filtertomoro2 = this.handleOnlyNull(
          response.data.data.filter(
            (value) => value.date === this.convertDateToString(tomoro2.timezone)
          )
        );
        let filtertomoro3 = this.handleOnlyNull(
          response.data.data.filter(
            (value) => value.date === this.convertDateToString(tomoro3.timezone)
          )
        );
        let filtertomoro4 = this.handleOnlyNull(
          response.data.data.filter(
            (value) => value.date === this.convertDateToString(tomoro4.timezone)
          )
        );

        this.setState({
          isLoading: false,
          currentFiveDaysData: [
            ...filtertoDay,
            ...filtertomoro1,
            ...filtertomoro2,
            ...filtertomoro3,
            ...filtertomoro4,
          ],
        });
      })
      .catch((error) => {
        console.log("get-schedule-error------------->", error);
        this.setState({ isLoading: false });
      });
  };

  /*----------------------------------------handle only null schedule time and date ----------------------------------*/

  handleOnlyNull = (data) => {
    if (data.length) {
      const a = data[0].availability.filter((v) => {
        return v.client === null;
      });
      data[0].availability = a;
      return data;
    } else {
      return data;
    }
  };

  /*----------------------------------handle view more timing availbility--------------------------------------*/

  handleViewMore = () => {
    var maxLength = 0;

    this.state.currentFiveDaysData.map((value) => {
      if (value.availability.length > maxLength) {
        maxLength = value.availability.length;
      }
      return null;
    });

    this.setState({ rows: maxLength });
  };

  handleLoader = (isloader) => {
    this.setState({ isLoading: isloader });
  };

  setAppointData = () => {
    this.setState({ appointData: this.state.appointData });
  };

  render() {
    //console.log('ggagagagagaga',this.props);
    return (
      <React.Fragment>
        <ConfirmAppointmentPopup
          loginuserdetails={this.props.loginuserdetails}
          hasModel={this.state.appointModel}
          modalData={this.state.modelAppintData}
          handleLoader={this.handleLoader}
          setAppointData={this.setAppointData}
          handleSetClientInScheduleTime={this.handleSetClientInScheduleTime}
          handleSetReminder={this.handleSetReminder}
          handleGetAppointmentData={() => this.handleGetAppointmentData()}
          disable_confirmation_email={
            this.props.docProfile.disable_confirmation_email
              ? this.props.docProfile.disable_confirmation_email
              : 0
          }
          isMedicaidInsurance={
            this.props.patientDetails.isMedicaid
              ? this.props.patientDetails.isMedicaid
              : 0
          }
        />
        {this.state.isLoading ? (
          <div
            style={{
              height: window.innerHeight + "px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              alt="loader_placeholder"
              src={require("../../assets/img/smartappt_preloader.gif")}
              style={{ height: 150, width: 150 }}
            />
          </div>
        ) : (
          <div className="main-container">
            <section>
              <div className="container">
                <div className="row">
                  <div className="col-md-12 col-sm-10">
                    <div className="feature feature-2 bordered mb-xs-24">
                      <img
                        alt="go_back"
                        title="go back"
                        src={require("../../assets/logo/arrow_left.png")}
                        style={{ height: 25, width: 23, cursor: "pointer" }}
                        onClick={() => this.props.history.goBack()}
                      />
                      <div
                        className="text-center"
                        style={{
                          height: 150,
                          width: 150,
                          backgroundImage: `url('${
                            this.state.profile
                              ? this.state.profile
                              : "https://cdn1.vectorstock.com/i/thumb-large/75/85/default-placeholder-doctor-half-length-portrait-vector-20847585.jpg"
                          }')`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          borderRadius: "50%",
                          margin: "0 auto",
                        }}
                      ></div>

                      <div className="text-center doctor_intro_box">
                        <h3 className="bold mb0 mt16">{this.state.doc_name}</h3>
                        <h5 className="bold mb0">
                          {this.state.speciality.map((value, index) => {
                            return `${value} , `;
                          })}
                        </h5>

                        {/* <p style={{ marginBottom: 5 }} className="bold">Choose Practice Location</p> */}
                        <select
                          value={this.state.selectedPracticeLocationId}
                          onChange={(e) => this.handleDocLocationOnchange(e)}
                        >
                          {this.state.docPracticeLocationArr.map(
                            (value, key) => {
                              return (
                                <option
                                  key={key}
                                  value={value._id}
                                >{`${value.practice_name},${value.zip}`}</option>
                              );
                            }
                          )}
                        </select>
                        <h6 className="bold mb16 uppercase">
                          {this.renderLocation()}
                        </h6>
                      </div>
                      <div className="docHub_schedule_table_box">
                        <div className="docHubcalendar_container">
                          {/* --------------------------------for-web--------------------------------*/}
                          <div className="consult_schedule_webview_container">
                            <div id="dochubweek_left_btn">
                              <img
                                onClick={this.handlePreview}
                                alt="left"
                                src="https://image.flaticon.com/icons/png/512/25/25409.png"
                                style={{ height: 25, width: 25 }}
                              />
                            </div>
                            <div id="docHubweekend_box">
                              <table>
                                {this.renderTableRow()}
                                <tbody>
                                  <tr>
                                    <td>
                                      <div className="wraper-box-inf w-100">
                                        <ul className="info-caln">
                                          <li>
                                            <div className="wrap-flx">
                                              <div className="sqr-block bg-cl-one"></div>{" "}
                                              <span>Telemedicine</span>
                                            </div>
                                          </li>
                                          <li>
                                            <div className="wrap-flx">
                                              <div className="sqr-block bg-cl-two"></div>{" "}
                                              <span>Office visit</span>
                                            </div>
                                          </li>
                                          <li>
                                            <div className="wrap-flx">
                                              <div className="sqr-block bg-cl-three"></div>{" "}
                                              <span>Both</span>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div id="dochubweek_right_btn">
                              <img
                                onClick={this.handleNext}
                                alt="right"
                                src="https://icon-library.net/images/transparent-arrow-icon/transparent-arrow-icon-14.jpg"
                                style={{ height: 28, width: 28 }}
                              />
                            </div>
                          </div>

                          {/* -----------------------------for-mobile-----------------------------*/}

                          <div className="consult_schedule_mobile_container">
                            <ul className="accordion accordion-1 consult_schedule_mobile_view">
                              {this.state.currentFiveDaysData.length === 0 ? (
                                <div style={{ height: "auto", width: "100%" }}>
                                  <h4
                                    className="text-center"
                                    style={{
                                      color: "rgb(0, 35, 75)",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    No Results
                                  </h4>
                                </div>
                              ) : (
                                this.state.currentFiveDaysData.map(
                                  (value, key) => {
                                    return (
                                      <ConsultScheduleMobileView
                                        data={value}
                                        key={key}
                                        handleSetClientInScheduleTime={
                                          this.handleSetClientInScheduleTime
                                        }
                                        isClickTime={this.state.isClickTime}
                                      />
                                    );
                                  }
                                )
                              )}
                              <div className="consult_accordion_action_box">
                                <button
                                  className="btn btn-sm  btn-filled mb0"
                                  disabled={
                                    this.state.days === 0 ? true : false
                                  }
                                  onClick={this.handlePreview}
                                >
                                  Previous
                                </button>
                                <button
                                  className="btn btn-sm  btn-filled mb0"
                                  disabled={
                                    this.state.currentFiveDaysData.length === 0
                                      ? true
                                      : false
                                  }
                                  onClick={this.handleNext}
                                >
                                  Next
                                </button>
                              </div>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    docProfile: state.doctorDetails,
    patientDetails: state.patient_details,
    loginuserdetails: state.loginuserdetails
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handlegetDocList: (data) =>
      dispatch({ type: GET_DOCTOR_LIST_REQUEST, data }),
    handleGetPatientDetails: (data) =>
      dispatch({ type: GETPATIENT_DETAILS_REQUEST, data }),
    handlegetDocProfile: (data) =>
      dispatch({ type: GET_DOC_DETAILS_REQUEST, data }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleConsults);
