import { call, put } from "redux-saga/effects";
import axios from "axios";
import {
  GET_TRAIL_SUBSCRIPTION_SUCCESS_REQUEST,
  GET_TRAIL_SUBSCRIPTION_FAILURE_REQUEST,
} from "../../actions/Action";
import { Baseurl } from "../../utils/Baseurl";

export function trailSubscriptionRequest(url, data) {
  let headers = {
    "Content-Type": "application/json",
    "Pragma": "no-cache",
  };
  return axios.post(
    Baseurl + url,    
    data,
    headers
  );
}

export function* getTrailSubscriptionRequest(action) {
  try { 
    const response = yield call(
      trailSubscriptionRequest,
      `api/subscription/trial_period`, 
      {doctor_uid:action.data}
    );
    const trailsubscription = response.data.data;
    // dispatch a success action to the store with the new data
    yield put({
      type: GET_TRAIL_SUBSCRIPTION_SUCCESS_REQUEST,
      trailsubscription,
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_TRAIL_SUBSCRIPTION_FAILURE_REQUEST, error });
  }
}
