import React, { Component } from "react";
import { findDebitCardType } from "../../utils/FormValidater";
import MASTERCARD from "../../assets/img/mastercard.svg";
import JCB from "../../assets/img/jcb.svg";
import DINERS_CLUB from "../../assets/img/diners-club.svg";
import DISCOVER from "../../assets/img/discover.svg";
import AMERICAN_EXPRESS from "../../assets/img/american-express.svg";
import VISA from "../../assets/img/visa.svg";
import Moment from "react-moment";
export default class Payment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      acceptableCard: [
        {
          name: "MASTERCARD",
          image: MASTERCARD,
        },
        {
          name: "VISA",
          image: VISA,
        },
        {
          name: "AMERICAN_EXPRESS",
          image: AMERICAN_EXPRESS,
        },
        {
          name: "DISCOVER",
          image: DISCOVER,
        },
        {
          name: "DINERS_CLUB",
          image: DINERS_CLUB,
        },
        {
          name: "JCB",
          image: JCB,
        },
      ],
    };
  }
  render() {
    const { acceptableCard } = this.state;
    const {
      expiryMonth,
      expiryYear,
      cardNumber,
      cvv,
      handleFunc,
      errorPaymentIs,
      firstName,
      lastName,
      email,
      mobile,
      address,
      stateIs,
      city,
      street,
      country,
      zip

    } = this.props;
    const years = [
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "30",
      "31",
      "32",
      "33",
      "34",
      "35",
      "36",
      "37",
      "38",
      "39",
    ];
    const months = [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
    ];
    return (
      <React.Fragment>
        <div>
          <h4>Subscription Plan Details</h4>
        </div>
        <div className="table-responsive">
          <table className="table-hover sm_table_cs w-100">
            <thead>
              <tr>
                <th>Product</th>
                <th className="text-center">Type</th>
                <th className="text-center">Price</th>
                <th className="text-center">Subtotal</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>SmartAppointment Monthly Subscription</td>
                <td style={{ textAlign: "center" }}> Monthly </td>
                <td className=" text-center">$300</td>
                <td className=" text-center">$300</td>
              </tr>

              <tr>
                <td> &nbsp; </td>
                <td> &nbsp; </td>
                <td className="text-center">
                 
                  <p>
                    <strong>Tax:&nbsp;</strong>
                  </p>
                </td>
                <td className="text-center">
                
                  <p>
                    <strong>$0</strong>
                  </p>
                </td>
              </tr>
              <tr>
                <td> &nbsp; </td>
                <td> &nbsp; </td>
                <td className="text-center">
                  <h4>
                    <strong>Total:&nbsp;</strong>
                  </h4>
                </td>
                <td className="text-center text-danger">
                  <h4>
                    <strong>$300</strong>
                  </h4>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="address-detail">
                  <div className="row">
                    <div className="col-xs-6 col-sm-6 col-md-6">
                      <address>
                        <h5 className="title-name mb-1">
                          <strong>{firstName + " " + lastName}</strong>
                        </h5>
                        {email}<br/>{" "}
                        {address} <br /> {city}, {stateIs}, {zip}
                        <br />
                        P: {mobile}
                      </address>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 text-right">
                      <p>
                        <em>Date: <Moment format="MM-DD-YYYY">{new Date()}</Moment></em>
                      </p>
                      <p>
                        <em>Amount : $300.00</em>
                      </p>
                    </div>
                  </div>
                </div>
        {/* <form> */}
        <h4>Payment Information</h4>
        <ul className={`${findDebitCardType(cardNumber) ? 'active' : '' } card-imges`}>
          <li>
            <img 
              src={require("../../assets/img/atm.jpg")}
              className="img-fluid"
              style={{ maxWidth: "300px" }}
            />
          </li>
          {/* <li style={{ padding: "0 20px" }}>{findDebitCardType(cardNumber)}</li> */}

          {/* {acceptableCard.map((el, i) => {
            return (
              <li key={el.name} className={`${findDebitCardType(cardNumber) === el.name ? 'active' : '' }`}>
                <img
                  src={el.image}
                  alt={el.name}
                  className="img-fluid"
                  style={{ maxWidth: "300px" }}
                />
              </li>
            );
          })} */}
        </ul>
        <div className="row">
          <div className="col-sm-12">
            <div className="form-group position-relative">
              <input
                type="number"
                className={`form-control ${
                  errorPaymentIs.cardNumber ? `form-input-error` : ""
                }`}
                placeholder="Card Number"
                name="cardNumber"
                value={cardNumber}
                onChange={handleFunc.handleOnChange}
              />
                 <span className="fix-mid-right"><img src={acceptableCard.filter(el=>el.name === findDebitCardType(cardNumber))[0]?.image}/></span>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <select
                    className={`form-control ${
                      errorPaymentIs.expiryMonth ? `form-input-error` : ""
                    }`}
                    id="exampleFormControlSelect2"
                    name="expiryMonth"
                    value={expiryMonth}
                    onChange={handleFunc.handleOnChange}
                  >
                    <option>Expiry Month</option>
                    {months.map((el, index) => (
                      <option value={el} key={index}>
                        {el}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <select
                    className={`form-control `}
                    id="exampleFormControlSelect3"
                    className={`form-control ${
                      errorPaymentIs.expiryYear ? `form-input-error` : ""
                    }`}
                    value={expiryYear}
                    name="expiryYear"
                    onChange={handleFunc.handleOnChange}
                  >
                    <option>Expiry Year</option>
                    {years.map((el, index) => (
                      <option value={el} key={index}>
                        20{el}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-sm-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Expiry date"
                          name="expiryDate"
                          value={expiryDate}
                          onChange={handleFunc.handleOnChange}
                        />
                      </div>
                    </div> */}
          <div className="col-sm-6">
            <div className="form-group">
              <input
                type="Number"
                className={`form-control ${
                  errorPaymentIs.cvv ? `form-input-error` : ""
                }`}
                placeholder="CVV"
                name="cvv"
                value={cvv}
                onChange={handleFunc.handleOnChange}
              />
            </div>
          </div>
          <div className="col-sm-12">
          <button className="btn btn-primary" onClick={(e)=>{ handleFunc.handleTabChange(1)}}>
                  Prev
                        </button>
            <button
              className="btn btn-primary text-white"
              onClick={handleFunc.handleSubmit}
            >
              Save
            </button>
          </div>
        </div>
        {/* </form> */}
      </React.Fragment>
    );
  }
}
