import React, { Component } from "react";
import { validateEmail } from "../../utils/FormValidater";
import { Link } from "react-router-dom";
import firebaseApp from "../../utils/Fire";
import { toast } from "react-toastify";
import { Baseurl } from "../../utils/Baseurl";
import axios from "axios";
import "../../styles/Common.css";
import socketIOClient from "socket.io-client";
import {Redirect} from "react-router-dom"
export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      emailError: false,
      password: "",
      passwordError: false,
      authMessage: "",
      isLoading: false,
      routeState: {},
      endpoint: Baseurl
    };
  }

  componentDidMount() {
    this.setState({ routeState: this.props.location.state });
    const { endpoint } = this.state;
     //const socket = socketIOClient(endpoint);
     const socket = socketIOClient(endpoint,{ query: {doctorId:"wqwqhjh77q899q97qghq"} });

     var sendMsgObj ={
       senderId : "60b77969197ac78477f21dcc",
       reciverId:"60b991f8473d84fb930888dc",
       conversationId:null,
       messageText:"socket chat test 21"

     }

     socket.emit("sendMessage",sendMsgObj);

     socket.on("sendMessage",(reciveMsg) => {
       
       console.log('sendMessage from server',reciveMsg)

     });
  }

  /*------------------------------------------------------------handle-input-onchange-------------------------------------------------*/

  handleOnChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    e.target.name === "password" &&
      this.setState({ passwordError: false, tooLong: false });
    e.target.name === "email" && this.setState({ emailError: false });
  };

  /*------------------------------------------------------------handle-validateForm-------------------------------------------------*/

  validateForm() {
    const { password, email } = this.state;
    !validateEmail(email) && this.setState({ emailError: true });
    password.length < 6 && this.setState({ passwordError: true });
    password.length > 36 && this.setState({ tooLong: true });

    return (
      validateEmail(email) > 0 && password.length > 5 && password.length < 37
    );
  }

  /*------------------------------------------------------------handle-user-login-------------------------------------------------*/

  handleLogin = (e) => {
    e.preventDefault();
    const { email, password } = this.state;
    this.setState({ isLoading: true });
    if (this.validateForm()) {
      firebaseApp
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then((res) => {
    
          const userId = res.user.uid;
     
          console.log("signin-response------>userId", userId);
         if(userId){
          localStorage.setItem("uid", userId);
          window.location.href = "/" 
          axios({
            method: "get",
            url: Baseurl + `api/doc/getDetails?userId=${userId}`,
            headers: { "content-type": "application/json" },
          })
            .then((response) => {
              if (response) {
               
                this.props.handleIsAuth(true);
                this.setState({ isLoading: false });
                localStorage.setItem("uid", res.user.uid); 
                if (this.state.routeState) {
                  if (this.state.routeState.routename === "confirm") {
                    this.props.history.push(
                      `/confirm?date=${this.state.routeState.date}&time=${this.state.routeState.time}&id=${this.state.routeState.id}&patientId=${this.state.routeState.patientId}&status=${this.state.routeState.status}`
                    );
                  } else if (this.state.routeState.routename === "interest") {
                    this.props.history.push(
                      `/interest?id=${this.state.routeState.patient}&time=${this.state.routeState.time}&docId=${this.state.routeState.doctorId}&docName=${this.state.routeState.doctor_name}`
                    );
                  } else if (
                    this.state.routeState.routename === "verification"
                  ) {
                    this.props.history.push("/profile");
                  }
                } else {
                //  this.props.history.push('/');
                window.location.href = "/"
                }
              }
            })
            .catch((error) => {
              toast.error("This User is not exist ...Please login again.", {
                position: toast.POSITION.TOP_CENTER,
              });
            });
         }
        })
        .catch((error) => {
          console.log("signin-error------>", error);

          this.setState({ isLoading: false, emailError: true });
          if (error.code === "auth/user-not-found") {
            toast.error(
              "There is no user record corresponding to this identifier. The user may have been deleted.",
              { position: toast.POSITION.TOP_CENTER }
            );
          } else if (error.code === "auth/wrong-password") {
            toast.error(
              "The combination of email and password is not recognized.",
              { position: toast.POSITION.TOP_CENTER }
            );
          } else {
            toast.error(error.message, { position: toast.POSITION.TOP_CENTER });
          }
        });
    } else {
      console.log("in-validation-error");
      this.setState({ isLoading: false });
    }
  };

  render() {
    return (
      <React.Fragment>
        <h4 style={{ textAlign: "center" }}>
          Sign up or log in to manage your practice.
        </h4>
        <Link
          to="/access/signup"
          className="btn btn-lg btn-filled"
          style={{ width: "100%" }}
        >
          Sign Up With Email
        </Link>
        <p className="text-center mb16">
          <b>OR</b>
        </p>
        <p className="text-center mb16" style={{ fontSize: 15 }}>
          <b>Log In with Email</b>
        </p>
        <form className="text-left" onSubmit={this.handleLogin}>
          <input
            type="text"
            name="email"
            placeholder="Email address"
            onChange={(e) => this.handleOnChange(e)}
            style={{ border: this.state.emailError ? "1px solid #c64444" : "" }}
          />
          <input
            type="password"
            name="password"
            placeholder="Password"
            onChange={(e) => this.handleOnChange(e)}
            style={{
              border: this.state.passwordError ? "1px solid #c64444" : "",
            }}
          />
          <p
            style={{
              color: "#c64444",
              display: this.state.authMessage === "" ? "none" : "block",
            }}
          >
            *{this.state.authMessage}
          </p>

          <button
            type="submit"
            disabled={this.state.isLoading}
            onClick={this.handleLogin}
            style={{ textTransform: "none" }}
          >
            {this.state.isLoading && (
              <span className="spinner-border text-light mr8" role="status" />
            )}
            Log In
          </button>
          <p style={{ marginTop: 20 }}>
            Forgot your username or password?{" "}
            <Link to="/access/recover-account">Recover account</Link>
          </p>
        </form>
      </React.Fragment>
    );
  }
}
