import React, { Component } from "react";
import queryString from "query-string";
import { Baseurl } from "../../utils/Baseurl";
import axios from "axios";
import { toast } from "react-toastify";
import Modal from "react-modal";
import { ShownInterestMailtoPatient } from "../../component/ShownInterestMailtoPatient";
import { patientSiteUrl } from "../../utils/Baseurl";

const customStyles = {
  content: {
    width: 400,
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");
Modal.defaultStyles.overlay.backgroundColor = "rgba(111, 111, 111, 0.75)";

export default class Interest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasOpen: false,
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      modalMessage: "",
    };
  }

  UNSAFE_componentWillMount() {
    let param = queryString.parse(this.props.location.search);
    let currentloginDoc = localStorage.getItem("uid");

    if (param.id && param.time) {
      if (this.props.isAuth) {
        if (param.docId === currentloginDoc) {
          this.handleGetConsults();
        } else {
          this.setState({
            hasOpen: true,
            modalMessage:
              "You are not authorized to participate in this consultation opportunity.",
          });
        }
      } else {
        this.props.history.push({
          pathname: "/access/login",
          state: {
            routename: "interest",
            time: param.time,
            patient: param.id,
            doctorId: param.docId,
            doctor_name: param.docName,
          },
        });
      }
    } else {
      toast.error("Invalid url.", { position: toast.POSITION.TOP_CENTER });
      this.props.history.push("/");
    }
  }

  /*-----------------------handle-get-consult----------------------------*/
  handleGetConsults = () => {
    let param = queryString.parse(this.props.location.search);
    let docId = localStorage.getItem("uid");

    axios({
      method: "get",
      url:
        Baseurl + `api/consult/get_consult?id=${param.id}&time=${param.time}`,
      headers: { "content-type": "application/json" },
    })
      .then((res) => {
        console.log("get-consult-response------>", res.data.data.doctor_name);
        if (res.data.data.interested) {
          let interested = JSON.parse(res.data.data.interested);
          let some = interested.some((value) => value.docId === docId);

          if (some) {
            this.setState({
              hasOpen: true,
              modalMessage: "You have already shown interest.",
            });
          } else {
            interested.push({ docId, createdAt: Date.now() });
            this.handleSetInterestedDoctor(
              interested,
              res.data.data.doctor_name
            );
          }
        } else {
          this.handleSetInterestedDoctor(
            [{ docId, createdAt: Date.now() }],
            res.data.data.doctor_name
          );
        }
      })
      .catch((error) => {
        console.log("get-consult-error------>", error);
      });
  };

  /*-----------------------handle-set-interested----------------------------*/

  handleSetInterestedDoctor = (interestArr, self_name) => {
    let appdate = new Date();
    let day = appdate.toLocaleDateString("locale", { weekday: "short" });
    let month = this.state.months[appdate.getMonth()];
    let date = appdate.getDate();
    let year = appdate.getFullYear();
    let fulappointDate = `${day} ${date} ${month} ${year}`;

    let param = queryString.parse(this.props.location.search);

    let data = {
      interested_doc: JSON.stringify(interestArr),
    };

    axios({
      method: "post",
      url:
        Baseurl +
        `api/consult/setinterested_doc?id=${param.id}&time=${param.time}`,
      headers: { "content-type": "application/json" },
      data,
    })
      .then((res) => {
        // console.log("setInterested doctor response ------>", res);
        //---get-patient-details-------------------*/
        axios({
          method: "get",
          url: Baseurl + `api/user/getuserdetails?userId=${param.id}`,
          headers: { "content-type": "application/json" },
        })
          .then((patientData) => {
            // console.log("patient-response----->", patientData.data.user.email);
            //---send-mail-to-patient-------------------*/
            let data = {
              shownInterestMessage: `${param.docName} has shown interest in your consultation. Click on the link to accept the consultation - ${patientSiteUrl}/consults`,
              patientMobile: patientData.data.user.mobile,

              emailto_patient: patientData.data.user.email,
              patient_subject: "You have an application from consulting doctor",
              patient_message: ShownInterestMailtoPatient(
                self_name,
                fulappointDate,
                param.docName
              ),
            };

            axios({
              method: "post",
              url: Baseurl + "api/consult/showninterestmail",
              headers: { "Content-Type": "application/json" },
              data,
            })
              .then((res) => {
                // console.log("mail-sent-response----->",res);
                this.setState({
                  hasOpen: true,
                  modalMessage:
                    "Thank you for your interest. We have informed the patient. You will receive an appointment email if the patient selects you for consultation.",
                });
              })
              .catch((err) => {
                console.log("mail-sent-error---->", err);
              });
          })
          .catch((error) => {
            console.log("patient-error------->", error);
          });
      })
      .catch((error) => {
        console.log("setInterested doctor response ------>", error);
      });
  };

  handleOk = () => {
    this.setState({ hasOpen: false });
    this.props.history.push("/");
  };

  render() {
    return (
      <React.Fragment>
        <Modal
          isOpen={this.state.hasOpen}
          style={customStyles}
          contentLabel="Modal"
        >
          <div style={{ textAlign: "center" }}>
            <h5>{this.state.modalMessage}</h5>
            <button
              onClick={this.handleOk}
              className="btn btn-md btn-filled btn-rounded"
              style={{ lineHeight: 0 }}
            >
              Ok
            </button>
          </div>
        </Modal>
        <div
          style={{
            height: window.innerHeight + "px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            alt="Loading..."
            src={require("../../assets/img/smartappt_preloader.gif")}
            style={{ height: 150, width: 150 }}
          />
          <h4>Please wait while we are registering your interest.</h4>
        </div>
      </React.Fragment>
    );
  }
}
