import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import firebaseApp from '../utils/Fire';

class NotFound extends Component {


    handleGoToHome = () => {
        firebaseApp.auth().onAuthStateChanged((user) => {
            if (user) {
                this.props.history.push('/');
            }
            else {
                this.props.history.push('/access/login');
            }
        });
    }


    UNSAFE_componentWillMount(){
        firebaseApp.auth().onAuthStateChanged((user) => {
            if (user) {
                this.props.history.push('/');
            }
            else {
                this.props.history.push('/access/login');
            }
        });
    }

    render() {
        return (
            <React.Fragment>
                <section className="fullscreen">
                    <div className="container v-align-transform">
                        <div className="row">
                            <div className="col-sm-10 col-sm-offset-1">
                                <div className="text-center">
                                    <i className="ti-receipt icon icon-lg mb24 mb-xs-0"></i>
                                    <h1 className="large">Page Not Found</h1>
                                    <p>The page you requested couldn't be found - this could be due to a spelling error in the URL or a removed page.</p>
                                    <span className="btn" onClick={this.handleGoToHome}>Go Back Home</span>
                                </div>
                            </div>
                        </div>

                        <div className="embelish-icons">
                            <i className="ti-help-alt"></i>
                            <i className="ti-cross"></i>
                            <i className="ti-support"></i>
                            <i className="ti-announcement"></i>
                            <i className="ti-signal"></i>
                            <i className="ti-pulse"></i>
                            <i className="ti-marker"></i>
                            <i className="ti-pulse"></i>
                            <i className="ti-alert"></i>
                            <i className="ti-help-alt"></i>
                            <i className="ti-alert"></i>
                            <i className="ti-pulse"></i>
                        </div>
                    </div>

                </section>
            </React.Fragment>

        );
    }
}

export default withRouter(NotFound);
