import React, { Component } from "react";

import { Baseurl } from "../../../../../utils/Baseurl";
import axios from "axios";

import "../../../../../styles/Reporting.css";
import OldVsNewPatient from "./old-vs-new-patent-graph";
import Statistics from "./statistics";
import ReferralSpecialities from "./referrals-specialities";
export default class Reporting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      from: new Date(new Date().toDateString()),
      to: new Date(new Date().toDateString()),
      start: "",
      end: "",
      insurance_status: "",
      doctor_id: "",
      reportResult: [],
      limit: 25,
      pageCount: 0,
      page: 1,
      loading: false,
      verifiedPatients: "",
      unVerifiedPatients: "",
      totalVerificaitonFee: "",
      pataintCount: "",
      totalAppDownload: "",
      totalappointData: "",
      totalconsultData: "",
      yourTotalAppointData: "",
      totalConsultReferYou: "",
      consultSentYouOther: [],
      specialitydata: [],
      subdrdata: [],
      groupdr: "",
      groupRole: 7,
    };
  }

  componentDidMount() {
    this.handleGetDoctorDetails();
  }

  handleGetDoctorDetails = () => {
    let userId = localStorage.getItem("uid");
    this.setState({ loading: true });
    axios({
      method: "get",
      url: Baseurl + `api/doc/getDetails?userId=${userId}`,
      headers: { "content-type": "application/json" },
    })
      .then((res) => {
        // console.log("res----->",res);
        this.setState(
          {
            doctor_id: res.data.user._id,
            groupdr: res.data.user._id,
            groupRole: res.data.user.groupRole,
          },
          () => {
            this.handleGetReport(
              this.state.limit,
              this.state.page,
              res.data.user._id,
              this.state.start,
              this.state.end,
              this.state.insurance_status
            );
            // console.log(res, "resres");
            let data = { refid: res.data.user.drLinkToGroup, grpuid: userId };
            axios({
              method: "post",
              url: Baseurl + `api/doc/get_group_doctors`,
              headers: { "content-type": "application/json" },
              data,
            })
              .then((drdata) => {
                this.setState({ subdrdata: drdata.data.data });
              })
              .catch((error) => {
                this.setState({ loading: false });
                console.log("error--->", error);
              });
          }
        );
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.log("error--->", error);
      });
  };

  // fromDateOnChange = (from) => {
  //   const fdate = ("0" + new Date(from).getDate()).slice(-2);
  //   const fmont = ("0" + (new Date(from).getMonth() + 1)).slice(-2);
  //   const fyears = new Date(from).getFullYear();
  //   const FromDate = `${fyears}-${fmont}-${fdate}`;

  //   this.setState({ from: from, to: from, start: FromDate, end: FromDate });
  // };

  // toDateOnchange = (toDate) => {
  //   const tdate = ("0" + new Date(toDate).getDate()).slice(-2);
  //   const tmont = ("0" + (new Date(toDate).getMonth() + 1)).slice(-2);
  //   const tyears = new Date(toDate).getFullYear();
  //   const ToDate = `${tyears}-${tmont}-${tdate}`;

  //   this.setState({ to: toDate, end: ToDate });
  // };

  /*-----------------------------------------get-report----------------------------------------------*/

  handleGetReport = (limit, page, doc_id, from, to, status) => {
    this.setState({ loading: true });

    axios({
      method: "get",
      url:
        Baseurl +
        `api/report/get_report?insurance_status=${status}&doctor_id=${doc_id}&from_date=${from}&to_date=${to}&limit=${limit}&page=${page}`,
      headers: { "content-type": "application/json" },
    })
      .then((res) => {
        // console.log("ger-report-response----->",res.data);

        this.setState({
          reportResult: res.data.data,
          pageCount: res.data.upcomint_count / 25,
          loading: false,
          verifiedPatients: res.data.verfied,
          unVerifiedPatients: res.data.unVerfied,
          totalVerificaitonFee: res.data.totalCost,
          pataintCount: res.data.pataintCount,
          totalAppDownload: res.data.totalAppDownload,
          totalappointData: res.data.totalappointData,
          totalconsultData: res.data.totalconsultData,
          yourTotalAppointData: res.data.yourTotalAppointData,
          totalConsultReferYou: res.data.totalConsultReferYou,
          consultSentYouOther: res.data.consultSentYouOther,
          specialitydata: res.data.specialitydata,
        });
      })
      .catch((error) => {
        console.log("get-report-error-------->", error);
        this.setState({ loading: false });
      });
  };

  /*-----------------------------------------handleFilter .btn----------------------------------------------*/

  // handleFilter = () => {
  //   this.handleGetReport(
  //     this.state.limit,
  //     this.state.page,
  //     this.state.doctor_id,
  //     this.state.start,
  //     this.state.end,
  //     this.state.insurance_status
  //   );
  // };

  // handlePageClick = (data) => {
  //   let selected = data.selected;
  //   this.setState({ page: selected + 1 }, () => {
  //     this.handleGetReport(
  //       this.state.limit,
  //       selected + 1,
  //       this.state.doctor_id,
  //       this.state.start,
  //       this.state.end,
  //       this.state.insurance_status
  //     );
  //   });
  // };

  render() {
    const {loading} = this.state;
    const coins = Object.keys(this.state.specialitydata).map((key) => (
      <span key={`badge-${key}`}>
        <span className="badge badge-dark">
          {key} : {this.state.specialitydata[key]}{" "}
        </span>
        <span>&nbsp;&nbsp;</span>
      </span>
    ));
    const doctorid = localStorage.getItem("uid");
    return (
      <React.Fragment>
        <div className="main-container">
         {!loading ? <div className="row">
          <div className="col-md-12">
              <p className="h3">Smart Appointment Statistics</p>
              <table className="table ">
                <tbody>
                  <tr>
                    <td width="15%">
                      <strong>Total Patients - </strong>
                    </td>
                    <td width="15%">{this.state.pataintCount}</td>
                    <td width="30%">
                      <strong>Your Total appointments - </strong>
                    </td>
                    <td width="40%">{this.state.yourTotalAppointData}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Total App Downloads - </strong>
                    </td>
                    <td>{this.state.totalAppDownload}</td>
                    <td>
                      <strong>Total Inbound Referrals- </strong>
                    </td>
                    <td>{this.state.totalConsultReferYou}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Total Consults - </strong>
                    </td>
                    <td>{this.state.totalconsultData}</td>
                    <td>
                      <strong>Total Outbound Referrals - </strong>
                    </td>
                    <td>{this.state.consultSentYouOther}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Total Appointments - </strong>
                    </td>
                    <td>{this.state.totalappointData}</td>

                    <td colSpan="2"> {coins}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className=" col-md-12">
            <p className="h3">Old Patient Vs New Patient Graph</p>
              <OldVsNewPatient doctorid={doctorid}/>
            </div>
            <div className=" col-md-6">
            <p className="h3">Statistics</p>
              <Statistics {...this.state}/>
              <br/>
              <br/>
              <br/>
           
            </div>
           
            <div className=" col-md-6">
            <p className="h3">Referrals to Specialities</p>
              <ReferralSpecialities {...this.state}/>
            </div>
            
         
          </div>
          :
          <div
          style={{
            height: "auto",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            alt="placeholder"
            src="https://luxysource.com/pub/static/frontend/Magento/luxysource/en_US/custom/img/preloader.gif"
            style={{ height: 80, width: 80 }}
          />
        </div>}
        </div>
      </React.Fragment>
    );
  }
}
