import React, { Component } from 'react';
import { Baseurl } from '../utils/Baseurl';
import axios from 'axios';

export default class PatientName extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
        }
    }

    componentDidMount() {
        this.handleGetDocNames();
    }

    /*----------------------------------------handle get-doctors-names---------------------------------------------------*/


    handleGetDocNames = () => {
        axios({
            method: 'get',
            url: Baseurl + `api/user/getuserdetails?userId=${this.props.patientId}`,
            headers: { 'content-type': 'application/json' },

        }).then((res) => {
            this.setState({ name: `${res.data.user.first_name} ${res.data.user.last_name}` });
            // console.log("doc-name-response------>", res.data);

        }).catch((err) => {
            console.log("doc-name-error------>", err);
        })
    }

    render() {
        return (
            <React.Fragment>
                {
                    this.props.tag === 'td' ?
                        <td>{this.state.name}</td>
                        : this.props.tag === 'res_table' ?
                            <div className="doc_appointment-table_body_cell">{this.state.name}</div>
                            : this.props.tag === 'for_mobile' ?
                                <div className="text-left" style={{ height: 'auto', width: '33.3%', fontWeight: 'bold' }}>{this.state.name}</div>
                                : this.props.tag === "doc_review" ?
                                    <div className="text-left" style={{ height: 'auto', width: '50%', fontWeight: 'bold' }}>{this.state.name}</div>
                                    : this.props.tag === "reviewForWeb" ?
                                    <div className="doc_reivew_table-body-cell">{this.state.name}</div>
                                    :
                                    <b>{this.state.name}</b>
                }
            </React.Fragment>
        )
    }
}


