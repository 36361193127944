import React, { Component } from "react";
import Imgone from "../../../assets/img/bg_im.png";
import "../../../assets/css/chat.css";
import ChatSectionHeader from "./chat-header";
import ChatFooter from "./chat-footer";
import ChatSearch from "./chat-search";
import ListUserChatItem from "./list-user-chat-item";
import ListUserChatItemSearch from "./list-user-chat-item-search"
import FooterLeftPanel from "./footer-left-panel";
import SentChatMessages from "./sent-chat-messages";
import RecivedChatMessages from "./received-chat-messages";
import { GetAPI, PostAPI } from "../../../utils/Api-interface";
import socketIOClient from "socket.io-client";
import { Baseurl } from "../../../utils/Baseurl";
import { connect } from "react-redux";
import moment from "moment";
import axios from 'axios';
import { Alert, Button } from "reactstrap";
import { toast } from 'react-toastify';
import UploadChatFile from "./chat-file-upload-modal";
class ChatPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listProviderStaff: [],
      isLoading: true,
      sidebarhide: false,
      showStaff: false,
      chatWithData: null,
      search: "",
      allMessages: [],
      socket: null,
      conversationId: null,
      doctorChatUser: null,
      page: 1,
      limit: 10,
      pageHaveDataOfPages: [1],
      chatMessageloading: false,
      searchWithUser: false,
      chatWithSearchData: [],
      totalPages: null,
      eligibleForChatSystem: false,
      inputFocus: false
    };
    this.movetoview = React.createRef()
    this.chatInput = React.createRef()
    this.chatList = React.createRef()
    this.chatMessagesRef = React.createRef()
  }

  startChatExecution = async () => {
    const docUid = localStorage.getItem("uid");
    const { eligibleForChatSystem } = this.state;

    if (docUid && !eligibleForChatSystem) {
      const res = await PostAPI(
        `chat_user/chat_user_deatil`, { drUid: docUid, userType: 2 }
      );
      if (res.status === 200) {
        const apiRes = res.data.data;
        if (!apiRes) {
          const resAddInChatUser = await PostAPI(
            `chat_user/add_in_chat_user`, { drUid: docUid }
          );

          if (resAddInChatUser.status === 200) {
            this.setState({ doctorChatUser: resAddInChatUser.data.data, eligibleForChatSystem: true }, () => {
              this.startChatExecution()
            });
          }

        } else {
          this.setState({ doctorChatUser: apiRes, eligibleForChatSystem: true }, () => {
            this.startChatExecution()
          });

        }

      }
    }
  }

  componentDidMount() {
    this.startChatExecution();
    this.handleListProviderStaff();
  }
  handleResetState = () => {
    this.setState({
      search: "",
      allMessages: [],
      page: 1,
      pageHaveDataOfPages: [1],
      chatMessageloading: false,
      totalPages: null,
    })
  }
  componentDidUpdate(prevProps, prevState) {
    const { loginUser, match } = this.props;
    const { eligibleForChatSystem, doctorChatUser } = this.state;
    if (prevProps.match.params !== match.params || prevState.doctorChatUser !== doctorChatUser) {
      this.setState({
        allMessages: [],
        socket: null,
        conversationId: null,
        page: 1,
        pageHaveDataOfPages: [1],
        totalPages: null,
      }, () => {
        if (doctorChatUser) {
          const socket = socketIOClient(Baseurl, { query: { conversationId: match?.params?.conversationId, userId: doctorChatUser._id } });
          this.setState({ conversationId: match?.params?.conversationId, socket })
          this.handleChatInputRef()
          socket.on("sendMessage",(reciveMsg) => {            
            if(reciveMsg && reciveMsg.length && reciveMsg[0].converstationId === this.state.conversationId){
              this.handleChatMessages(reciveMsg.reverse()) 
              if(this.state.inputFocus){
              setTimeout(()=>{
                let messageToRead=this.state.allMessages.filter(el=>el.sender._id !== doctorChatUser._id);
                if(messageToRead.length){
                  const ids = messageToRead.map(m=>{return m._id})
                  socket.emit("readMessage",ids)
                }
              
              },1000)
            }
            }
        
          });
          socket.on("refreshConverstationUserList", () => {
            socket.emit("converstationUserList", doctorChatUser._id)
          })
          socket.on("readMessage", (messageId) => {
            if (messageId) {
              const { allMessages } = this.state;
              let oldMessages = allMessages;
              oldMessages = oldMessages.map(el => { return el._id === messageId ? { ...el, isRead: true } : el })
              this.setState({ allMessages: [...oldMessages] })
            }
          })
          socket.on("deleteMessage", (messageId) => {
            if (messageId) {
              const { allMessages } = this.state;
              let oldMessages = allMessages;
              oldMessages = oldMessages.filter(el => el._id !== messageId)
              this.setState({ allMessages: [...oldMessages] })

            }
          })
          socket.on("clearChat", (chatRes) => {
            if (chatRes.length === 0) {
              this.handleResetState()
            }
          })
          socket.on("converstationUserList", (chatWithData) => {
            // console.log(chatWithData,'chatWithDatachatWithData')
            const c = chatWithData.sort(function(x, y){
              return y.lastMessage ? moment(y.lastMessage?.sentTime).diff(x.lastMessage?.sentTime) : -1
          })
            this.setState({ chatWithData:c });
          });

        }
      })
    }

    if (loginUser?.groupRole === 6) {
      this.props.history.push("/")
    }
  }

  handleListProviderStaff = () => {
    let drUid = localStorage.getItem("uid");
    axios({
      method: "get",
      url: Baseurl + `api/staff/list_provider_staff?drUid=${drUid}`,
      headers: { "content-type": "application/json" },
    })
      .then((res) => {
        let response = res.data
        this.setState({ listProviderStaff: response.data, isLoading: false })
      })
      .catch((error) => {
        // console.log("handleListProviderStaff error--->", error);
      });
  };

  handleChatMessages = (messages) => {
    if (messages) {
      const { allMessages } = this.state;
      if (allMessages.length) {
        const allMsgs = [...allMessages, ...messages];
        const ids = allMsgs.map(o => o._id)
        const unqAllMsgs = allMsgs.filter(({ _id }, index) => !ids.includes(_id, index + 1))

        this.setState({ allMessages: [...unqAllMsgs] }, () => {
          if (this.movetoview.current) {
            this.movetoview.current.scrollIntoView({ behaviour: 'smooth' })
          }
        })
      } else {
        this.setState({ allMessages: messages }, () => {
          if (this.movetoview.current) {
            this.movetoview.current.scrollIntoView({ behaviour: 'smooth' })
          }
        })
      }

    }
  }

  handleMessagesDateWise = (msgs) => {
    let stime = [];
    const dateWiseMessages = [];
    msgs.forEach(el => {
      stime.push(moment(el.sentTime).format("DD MMM YY"))
    });
    if (stime.length) {
      stime = stime.filter((v, i, a) => a.indexOf(v) === i)
    }

    stime.forEach(ell => {
      const obj = {
        date: ell,
        messages: msgs.filter(d => moment(d.sentTime).format("DD MMM YY") === ell)
      }
      dateWiseMessages.push(obj)
    })
    return dateWiseMessages
  }
  handleChatWithSearch = async (drID, search) => {
    const res = await GetAPI(
      `chat_user/chat_patient_list?drUid=${drID}&searchText=${search}&userType=1`
    );

    if (res.status === 200) { 
      this.setState({ chatWithSearchData: res.data.data });
    }
  };
  handleClickCreateConverstation=async(reciverId)=>{
    const senderId=this.state.doctorChatUser?._id;
    
    if(senderId){
      GetAPI(
        `chat_user/generate_converstation_id?id=${reciverId}&converstationStartWithId=${senderId}`
      ).then(rr=>{
        const cid = rr?.data?.conversationId;
        if(cid){
          this.props.history.push(`/chat/${reciverId}/${cid}`);
        }else{
          toast.error("Something went wrong")
        }
      }
      
      ).catch(err=>{
        toast.error("Something went wrong")
      }
  
      )
    }else{
      toast.error("You are not a chat user")
    }

  }
  getChatUserDetail = async () => {
    const docUid = localStorage.getItem("uid");
    if (docUid) {
      const res = await PostAPI(
        `chat_user/chat_user_deatil`, { drUid: docUid, userType: 2 }
      );
      if (res.status === 200) {
        this.setState({ doctorChatUser: res.data.data });
      }
    }
  }

  handleSideBar = (sidebarhide) => {
    this.setState({ sidebarhide });
  };

  handleSearch = (e) => {
    const search = e.target.value;
    this.setState({ search },()=>{
      const { searchWithUser} = this.state;
      if(searchWithUser){
        const drID= localStorage.getItem("uid");
       if(search?.length > 2 
        // || search?.length === 0
        ){
        this.handleChatWithSearch(drID,search);
       }else if(search?.length === 0){
        this.setState({chatWithSearchData:[]})
       }

      }else{
        this.setState({chatWithSearchData:[]})
      }
    });
  };
  handleChatInputRef = () => {
    if (this.chatInput.current) {
      this.chatInput.current.focus()
    }
  }

  handleScroll = async (e) => {
    const t = this.chatList.current.scrollTop;
    const { conversationId, page, limit, pageHaveDataOfPages, doctorChatUser } = this.state;
    const isDataHave = pageHaveDataOfPages.find(el => el === page + 1)
    if (t === 0 && !isDataHave) {
      this.setState({ pageHaveDataOfPages: [...pageHaveDataOfPages, page + 1] }, () => {
        this.handleGetChatMessages(conversationId, page + 1, limit, doctorChatUser._id)
      })

    }
  }

  handleGetChatMessages = async (converstationId, page, limit, userId) => {
    this.setState({ chatMessageloading: true })
    const ch = this.chatMessagesRef.current.clientHeight;
    const res = await GetAPI(
      `chat_user/chat_list_by_conversatationId?page=${page}&limit=${limit}&converstationId=${converstationId}&userId=${userId}`
    );
    if (res.status === 200) {
      const r = res.data?.messageData;
      const totalPages = Math.ceil(res.data.total_chat_count / limit);

      if (r?.length) {
        const newAllMessages = [...r.reverse(), ...this.state.allMessages]
        this.setState({ allMessages: newAllMessages, page: page, chatMessageloading: false, totalPages: totalPages }, () => { 
          this.chatList.current.scrollTop = this.chatMessagesRef.current.clientHeight - ch;
        });
      } else {
        this.setState({ chatMessageloading: false })
      }

    }
  }

  handleShowSearchData = (searchWithUser) => {
    setTimeout(() => {
      this.setState({ searchWithUser }, () => {
        if (!searchWithUser) {
          this.setState({ search: "",chatWithSearchData:[] })
        }
      })
    }, 1000)
  }

  download = (url, filename) => {
    fetch(url).then(async (t) => {
      return t.blob().then((b) => {
        var a = document.createElement("a");
        a.href = URL.createObjectURL(b);
        a.setAttribute("download", filename);
        a.click();
      }
      );
    });
  }

  handleDeleteMessage = (messageId) => {
    if (messageId) {
      const { socket, allMessages } = this.state;
      let oldMessages = allMessages; 
      oldMessages = oldMessages.filter(el => el._id !== messageId) 
      this.setState({ allMessages: [...oldMessages] }, () => {
        socket.emit("deleteMessage", [messageId])
      })

    }

  }

  handleInputFocus = (inputFocus) => {
    this.setState({ inputFocus }, () => {
      if (inputFocus) {
        setTimeout(() => {
          let messageToRead = this.state.allMessages.filter(el => el.sender._id !== this.state.doctorChatUser._id);
          if (messageToRead.length) {
            const ids = messageToRead.map(m => { return m._id })
            this.state.socket.emit("readMessage", ids)
          }

        }, 1000)
      }
    })
  }
  render() {

    const { path, params } = this.props.match; 
    const { sidebarhide, chatWithData, socket, search, allMessages, conversationId, doctorChatUser, chatWithSearchData, chatMessageloading, searchWithUser, listProviderStaff, isLoading } = this.state;
    const { loginUser } = this.props;
    const activeUser = chatWithData?.find(el => el._id === this.state.conversationId);
    return (
      <>
        {isLoading ? '' :
          listProviderStaff?.length ? '' :
            <Alert color="warning" className='alertCss'>
              To enable messaging feature, you have to assign staff — <a href="/staff-members" className='alert-link'>Assign Staff</a>
            </Alert>
        }

        <div className="conatiner_wrapper d-flex h_100 cu_78">

          <div
            className={`left_section flex flex_col sm_left_section ${sidebarhide ? `side_bar_hide` : ``
              }`}
          >
            {/* {console.log(chatWithData,'chatWithData')} */}
            {!sidebarhide && (
              <ChatSearch
                handleSearch={this.handleSearch}
                search={search}
                handleShowSearchData={this.handleShowSearchData}
              />
            )}
            {searchWithUser 
            ?
             <div className="search_result_box scroll_auto py_16 side_active">
              {chatWithSearchData?.length
                ? chatWithSearchData.map((el) => {
                  return <ListUserChatItemSearch handleChatInputRef={this.handleChatInputRef} handleClick={this.handleClickCreateConverstation} data={el} history={this.props.history} />;
                })
                : 
                <p style={{paddingLeft:'15px', paddingRight:'15px'}}>No search data to display</p>
                }
            </div>
              :
              <div className="search_result_box scroll_auto py_16 side_active">
                {chatWithData?.length
                  ? chatWithData.map((el) => {
                    return <ListUserChatItem doctorChatUser={doctorChatUser} conversationId={conversationId} handleChatInputRef={this.handleChatInputRef} data={el} history={this.props.history} />;
                  })
                  :  chatWithData === null ? 
                  <div className="text-center">
                  <img alt="placeholder" src="https://luxysource.com/pub/static/frontend/Magento/luxysource/en_US/custom/img/preloader.gif" style={{ height: "80px", width: "80px" }} />
                </div>
                  : <p style={{paddingLeft:'15px', paddingRight:'15px', textAlign:'center'}}>You don't have any active conversation, search patient name to start conversation. </p>}
              </div>}
         
            <FooterLeftPanel
              handleSideBar={this.handleSideBar}
              sidebarhide={sidebarhide}
            />
          </div>
          <div
            className={`right_section flex flex_col ${sidebarhide ? `main_container_full_width` : ``
              }`}
          >
            {path === "/chat" ? (
              <>
                <div className="welcome-chat">
                  <p>Welcome to Chat</p>
                </div>
              </>
            ) : (
              <React.Fragment>
                <div className="top_header flex border_bottom">
                  <ChatSectionHeader activeUser={activeUser} socket={socket} />
                </div>
                <div className="chat_show_container p_16 ">
                  <div
                    style={{
                      height: "calc(100vh - 258px)",
                      overflowY: "auto",
                    }}
                    id="chatSection"
                    onScroll={this.handleScroll}
                    ref={this.chatList}
                  >

                    {chatMessageloading &&
                      <div className="text-center">
                        <img alt="placeholder" src="https://luxysource.com/pub/static/frontend/Magento/luxysource/en_US/custom/img/preloader.gif" style={{ height: "80px", width: "80px" }} />
                      </div>
                    }
                    <div ref={this.chatMessagesRef}>
                      {allMessages.length ?
                        this.handleMessagesDateWise(allMessages).map(am => {
                          return (<div>
                            <div className="text-center">
                              <p> {am.date}</p>
                            </div>
                            {am.messages.map((el) => {
                              if (el.sender?._id === doctorChatUser?._id) {
                                return <SentChatMessages data={el} loginUser={loginUser} download={this.download} handleDeleteMessage={this.handleDeleteMessage} />
                              } else {
                                return <RecivedChatMessages data={el} loginUser={loginUser} isOnline={activeUser?.user.online} download={this.download} />
                              }

                            })}
                          </div>)
                        })
                        : null}


                      <div ref={this.movetoview}></div>
                    </div>
                  </div>

                  <ChatFooter handleInputFocus={this.handleInputFocus} handleChatInputRef={this.handleChatInputRef} chatInputRef={this.chatInput} socket={socket} senderId={doctorChatUser?._id} reciverId={params.reciverId} conversationId={conversationId} />
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginUser: state?.loginuserdetails?.user
  }
}
export default connect(mapStateToProps, null)(ChatPanel);