import React, { Component } from 'react';
import { connect } from 'react-redux';
import { GET_DOC_REVIEWS_REQUEST } from '../../actions/Action';
import PatientName from '../../component/PatientName';
import StarRatingComponent from 'react-star-rating-component';
import DoctorReviewMobileView from '../../component/DoctorReviewMobileView';
import ShowMoreText from 'react-show-more-text';
import { Baseurl, patientSiteUrl } from '../../utils/Baseurl';
import axios from 'axios';
import '../../styles/RatingAndReview.css';


class RatingAndReview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user_reviewData: [],
            subdrdata:[],
            groupdr:"",
            groupRole:7,
            doctor_id:localStorage.getItem('uid'),
        }
    }


    UNSAFE_componentWillMount() {
        window.scrollTo(0, 0);
        let id = localStorage.getItem('uid');
        this.props.handleGetDocReviews(id);
    }

    componentDidMount() {
        let id = localStorage.getItem('uid');
         this.handleGetDoctorDetails();

     }

     handleFilter (e) {         
        this.props.handleGetDocReviews(e.target.value);
     }


    /*----------------------------------------------------handle-save-review-------------------------------*/

    UNSAFE_componentWillReceiveProps(props) {
        if (props.doc_reviews) {
            this.setState({ user_reviewData: props.doc_reviews });
        }
    }

    handleGetDoctorDetails = () => {
    let userId = localStorage.getItem("uid");
    this.setState({ loading: true });
    axios({
      method: "get",
      url: Baseurl + `api/doc/getDetails?userId=${userId}`,
      headers: { "content-type": "application/json" },
    }).then((res) => { 
           this.setState({ doctor_id: res.data.user.uid,groupdr: res.data.user._id,groupRole:res.data.user.groupRole});        
         

             
           let data = {"refid":res.data.user.drLinkToGroup,grpuid:userId} 
           if(res.data.user.groupRole===6){
               axios({
               method: "post",
               url: Baseurl + `api/doc/get_group_doctors`,
               headers: { "content-type": "application/json" },
               data,
               }).then((drdata) => {                           
                this.setState({ subdrdata: drdata.data.data });            
               })
              .catch((error) => {
                this.setState({ loading: false });
                console.log("error--->", error);
              });   
          }


      })
      .catch((error) => {
        this.setState({ loading: false });
        console.log("error--->", error);
      });

  };




    renderReviewsTable = () => {

        return this.state.user_reviewData.map((value, key) => {
            let date = new Date(value.createdAt);
            let dd = String(date.getDate()).padStart(2, '0');
            let mm = String(date.getMonth() + 1).padStart(2, '0');
            let yy = date.getFullYear();

            let fullDate = String(mm) + "/" + String(dd) + "/" + String(yy);

            return <div className="doc_review_resp-table-row" key={key}>
                <div className="doc_reivew_table-body-cell">{fullDate}</div>
                <PatientName patientId={value.patient_id} tag="reviewForWeb" />
                 <div id="patient_name" style={{width:'40%'}}>{value.doctorName}</div>
                <div className="doc_reivew_table-body-cell">
                    <StarRatingComponent
                        name="userRating1"
                        starCount={5}
                        value={value.rating}
                        starColor={'#ec8a19'}
                        emptyStarColor={'lightgray'}

                    />
                </div>
                <div className="doc_reivew_table-body-cell">
                    <div style={{ width: 250 }}>
                        <ShowMoreText
                            lines={1}
                            more='Show more'
                            less='Show less'
                            anchorClass=''
                            onClick={this.executeOnClick}
                            expanded={false}
                        >
                            {value.dec}
                        </ShowMoreText>
                    </div>
                </div>
            </div>
        })
    }



    render() {
        return (
            <React.Fragment>
                <h4>Rating & Reviews</h4>
                <hr />
                {this.state.groupRole===6 ? <div className="input-with-label text-left">
          <span>Filter By Doctor</span><select
            className="col-md-12"
            name="groupRole" onChange={(e) => this.handleFilter(e)}>
              <option value={this.state.doctor_id}>All</option>
              {this.state.subdrdata.map((dr, key) => {
                   return (
                    <option value={dr.uid}>{dr.first_name} {dr.last_name}</option>
                     );       
                 })}
          </select></div>:''}
                <div className="review_main_container">
                    {
                        this.props.isLoading ?
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img alt="placeholder" src="https://luxysource.com/pub/static/frontend/Magento/luxysource/en_US/custom/img/preloader.gif" style={{ height: 100, width: 100 }} />
                            </div>
                            : this.state.user_reviewData.length === 0 ?
                                <h4 className="text-center" style={{ color: 'rgb(0, 35, 75)', fontWeight: 'bold' }}>No Results</h4>
                                :
                                <React.Fragment>

                                    <div className="doc_review_for_web">
                                        <div className="doc_review_resp-table-header">
                                            <div className="doc_review_table-header-cell">Date of Review</div>
                                            <div className="doc_review_table-header-cell">Patient Name</div>
                                            <div className="doc_review_table-header-cell">Doctor Name</div>
                                            <div className="doc_review_table-header-cell">Rating</div>
                                            <div className="doc_review_table-header-cell">Review</div>
                                        </div>

                                        <div className="doc_reivew_resp-table-body">
                                            {
                                                this.renderReviewsTable()
                                            }
                                        </div>


                                        {/* {
                                            this.state.user_reviewData.map((value, key) => {
                                                return (
                                                    <div className="review_inner_container" key={key}>
                                                        <div id="name_date_rating_box">
                                                            <div id="date"><b>{new Date(value.createdAt).toLocaleString()}</b></div>
                                                            <div id="patient_name"><PatientName patientId={value.patient_id} tag="b" /></div>
                                                            <div id="patient_name">{value.doctorName}</div>
                                                            <div id="rating">
                                                                <StarRatingComponent
                                                                    name="rate1"
                                                                    starCount={5}
                                                                    value={value.rating}
                                                                    starColor={'#ec8a19'}
                                                                    emptyStarColor={'lightgray'}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div id="dec"><b>{value.dec}</b></div>
                                                    </div>
                                                );
                                            })
                                        } */}
                                    </div>




                                    <ul className="accordion accordion-1 doc_review_for_mobile">
                                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', width: '100%', height: 'auto', padding: 10 }}>
                                            <div className="text-left" style={{ height: 'auto', width: '50%', fontWeight: 'bold' }}>Patient name</div>
                                            <div className="text-right" style={{ height: 'auto', width: '48%', fontWeight: 'bold' }}>Created at</div>
                                        </div>
                                        {
                                            this.state.user_reviewData.map((value, key) => {
                                                return <DoctorReviewMobileView data={value} key={key} />
                                            })
                                        }
                                    </ul>
                                </React.Fragment>

                    }
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        doc_reviews: state.docReviews,
        isLoading: state.isLoading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        handleGetDocReviews: data => dispatch({ type: GET_DOC_REVIEWS_REQUEST, data }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RatingAndReview);