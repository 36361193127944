import React from "react";
import Imgone from "../../../assets/img/bg_im.png";
import doctorPlaceholder from "../../../assets/img/doctor-placeholder.jpg";
import Moment from "react-moment";
import emoji from 'react-easy-emoji'
const SentChatMessages = ({ data, loginUser, download, handleDeleteMessage }) => {
  const { message, isRead, sentTime, containsFile, files, isDeleted } = data;
  return (
    <div className="box d-flex lr_user w_80 ml_auto">
      <div className="box_left ml-3 order_2">
        <div className="chat_user_icon">
          <img
            src={loginUser?.profile_image || doctorPlaceholder}
            alt="img_s"
          />
        </div>
      </div>
      <div className="box_right w_100">
        <div className="chat_box ">
          {/* <div className="chat_head flex">
              <p className="order_1 ml_auto">You</p>
            </div> */}
          <div className="chat_text right_text">
            <div className="inner_chat_info arrow_right bg_next">
              <div className="option_info flex flex_center op-chat">
                {!isDeleted && <div className="dropdown show">
                    <button
                      className="btn cs_drop"
                      role="button"
                      // id="dropdownMenuLinkf"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <div />
                      <div />
                      <div />
                    </button>
                    <div
                      className="dropdown-menu cs_drop_title"
                      // aria-labelledby="dropdownMenuLinkf"
                    >
                      <button className="dropdown-item" onClick={()=>handleDeleteMessage(data._id)}>Delete</button>
                    </div>
                  </div>}
              </div>
              <div>
                <p className="title_text" style={{paddingLeft:"15px"}}>
                  {isDeleted ? (
                    <i style={{ fontWeight: 600, fontSize:'12px' }}>Message deleted</i>
                  ) : containsFile ? (
                    <React.Fragment>
                      <i
                        style={{
                          display: "block",
                          textAlign: "left",
                          fontWeight: 600,
                        }}
                      >
                        <i
                          className="fas fa-paperclip"
                          style={{
                            verticalAlign: "middle",
                            color: "#000",
                            visibility: "hidden",
                          }}
                        />{" "}
                        {message}
                      </i>
                      {files.map((el) => {
                        return (
                          <i style={{ display: "block", textAlign: "left" }}>
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={() => download(el.url, el.name)}
                            >
                              <i
                                className="fas fa-download"
                                style={{
                                  verticalAlign: "middle",
                                  color: "#000",
                                }}
                                title={`Download ${el.name}`}
                              />{" "}
                              {el.name}
                            </a>
                          </i>
                        );
                      })}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                   {emoji(message)}
                   </React.Fragment>
                  )}
                </p>
                <h4
                  className="time order_2 ml_10"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Moment format="hh:mm a">{sentTime}</Moment>
                  &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                  {isRead ? (
                    <i
                      className="fas fa-check-double"
                      style={{ color: "#666" }}
                    />
                  ) : (
                    <i className="fas fa-check" style={{ color: "#666" }} />
                  )}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SentChatMessages;
