import React, { Component } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  BarChart,
  Bar,
  ResponsiveContainer,
  Cell
} from "recharts";
import Select from "react-select";
import { GetAPI } from "../../../../../utils/Api-interface";
import { Row, Col } from "reactstrap";
import { withRouter } from "react-router-dom";

class OldVsNewPatient extends Component {
  state = {
    weekToogle: false,
    monthToogle: false,
    DataWeek: [],
    DataMonth: [],
    selectYearChange: new Date().getFullYear(),
    optionNewDocYear: [
      { value: "2021", label: "2021" },
      { value: "2020", label: "2020" },
    ],
    activeWeek: true,
    activeMonth: false,
    searchName: "",
    docArray: [],
    resFirstNmae: "",
  };

  handleWeek = () => {
    this.setState({
      weekToogle: true,
      monthToogle: false,
      activeWeek: true,
      activeMonth: false,
    });
  };

  handleMonth = () => {
    this.setState({
      weekToogle: false,
      monthToogle: true,
      activeWeek: false,
      activeMonth: true,
    });
  };

  handleChangeYear = (selectedOption) => {
    this.setState({ selectYearChange: selectedOption.value }, () => {
      this.handleConsultMonthlyVolume();
    });
  };

  handleConsultMonthlyVolume = () => {
  
    GetAPI(
      `api/consult_report/drOldVsNewAppointments?doctor_uid=${this.props.doctorid}&year=${this.state.selectYearChange}`
    )
      .then((res) => {
        // if (res.data.status === "1") {
        let response = res.data.data;
        // console.log("old vs new is", response)

        var month = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        var countOld;
        var countNew;
        let headYear = "";

        if (response.length) {
          var monthArr = month.map(function (val, index) {
            if (val !== "") {
              countOld = 0;
              countNew = 0;
              headYear = response[0].year;
              var res = headYear.toString().substr(2, 2);
              val = val + "'" + res;

              for (var i = 0; i < response.length; i++) {
                if (index + 1 == response[i].month) {
                  countOld = response[i].olPatientcount;
                  countNew = response[i].newPatientcount;
                }
              }
              return {
                name: val,
                "Old Patient": countOld,
                "New Patient": countNew,
              };
            }
          });
          if (monthArr.length > 0) {
            this.setState({ DataMonth: monthArr }, () => {
              // console.log("WeekArr 555 are", this.state.DataMonth)
            });
          }
        } else {
          let year = this.state.selectYearChange;
          var monthArrExtra = month.map(function (val, index) {
            countOld = 0;
            countNew = 0;
            headYear = year;
            // headYear = '2020'
            var res = headYear.toString().substr(2, 2);
            val = val + "'" + res;
            return {
              name: val,
              "Old Patient": countOld,
              "New Patient": countNew,
            };
          });

          // console.log('monthArr 222222222', monthArr);

          this.setState({ DataMonth: monthArrExtra }, () => {
            // console.log("WeekArr 5 are", this.state.DataMonth)
          });
        }

        let oneArray = [];

        for (var i = 0; i < response.length; i++) {
          oneArray.push({
            num: response[i].month * 4 - 3,
            old: response[i].olPatientcount,
            new: response[i].newPatientcount,
          });
        }
        // console.log("oneArray are", oneArray)

        let WeekArr = [];
        let WeekArrExtra = [];
        let yearNum = [];
        for (var i = 0; i < 52; i++) {
          yearNum.push(i + 1);
        }
        let count;
        let judge;

        for (var j = 0; j < yearNum.length; ) {
          countOld = 0;
          countNew = 0;
          count = 1;
          judge = true;
          for (var i = 0; i < oneArray.length; i++) {
            if (j + 1 == oneArray[i].num) {
              for (var k = j + 1; k < j + 5; k++) {
                countOld = Math.ceil(response[i].olPatientcount / 4);
                countNew = Math.ceil(response[i].newPatientcount / 4);
                WeekArr.push({
                  name: k,
                  "Old Patient": countOld,
                  "New Patient": countNew,
                });
              }
              count += 3;
              judge = false;
            }
          }
          if (judge) {
            WeekArr.push({
              name: j + 1,
              "Old Patient": countOld,
              "New Patient": countNew,
            });
          }
          j += count;
        }

        for (var j = 0; j < yearNum.length; j++) {
          countOld = 0;
          countNew = 0;
          WeekArrExtra.push({
            name: j + 1,
            "Old Patient": countOld,
            "New Patient": countNew,
          });
        }

        if (WeekArr.length > 0) {
          this.setState({ DataWeek: WeekArr });
        }
     
      })
      .catch((err) => {
        if (err.response) {
          console.log("old vs new error is", err.response);
          this.props.history.push("/")
        }
      });
  };

  componentDidMount() {
    this.handleConsultMonthlyVolume();
  }
componentDidUpdate(prevProps){
  if(prevProps.doctorid !== this.props.doctorid){
    this.handleConsultMonthlyVolume();
  }
}
  render() {
    const {
      optionNewDocYear,
      activeWeek,
      activeMonth,
      monthToogle,
      DataMonth,
      DataWeek,
      selectYearChange,
    } = this.state;
   const chartData = monthToogle ? DataMonth : DataWeek;
    return (
      <React.Fragment>
        <div class="graph_block ">
          <Row>
            
            <Col sm="12" xs="12" xl="6">
              <Row>
                <Col lg="8" sm="8" xs="8">
                <button
                        className={
                          activeWeek ? "btn btn-filled" : "btn"
                        }
                        onClick={this.handleWeek}
                      >
                        Week
                      </button>
                      <button
                        className={
                          activeMonth
                            ? "btn btn-filled"
                            : "btn"
                        }
                        onClick={this.handleMonth}
                      >
                        Month
                      </button>
                </Col>
                <Col lg="4" sm="4" xs="4">
                  <Select
                  isSearchable={false}
                    placeholder={selectYearChange}
                    onChange={this.handleChangeYear}
                    options={optionNewDocYear}
                  />
                </Col>
              </Row>
            </Col>
            <Col sm="12" xs="12" xl="6">
            <ResponsiveContainer width="100%" height={350}>
            <BarChart
              width={670}
              height={350}
              data={monthToogle ? DataMonth : DataWeek}
              margin={{
                top: 10,
                right: 5,
                left: -35,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="4 4" interval={0} height={80} />
              {/* <XAxis /> */}
              {monthToogle ? (
                <XAxis dataKey="name" />
              ) : (
                <XAxis dataKey="name" />
              )}
              <YAxis tickLine={false} />
              <Tooltip />
              {/* <Legend /> */}

              {monthToogle ? (
                  <Bar label={false} dataKey="Old Patient" maxBarSize={30} fill={'#8884d8'}>
                  {/* {chartData &&
                    chartData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={'#8884d8'} />
                    ))} */}
                </Bar>
                // <Bar
                //   type="monotone"
                //   dataKey="Old Patient"
                //   stroke="#8884d8"
                //   activeDot={{ r: 8 }}
                // />
              ) : (
                <Bar label={false} dataKey="Old Patient" maxBarSize={30} fill={'#8884d8'}>
                {/* {chartData &&
                  chartData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={'#8884d8'} />
                  ))} */}
              </Bar>
                // <Bar
                //   type="monotone"
                //   dataKey="Old Patient"
                //   stroke="#8884d8"
                //   activeDot={{ r: 8 }}
                // />
              )}

              {monthToogle ? (
                   <Bar label={false} dataKey="New Patient" maxBarSize={30}  fill={'#82ca9d'}>
                   {/* {chartData &&
                     chartData.map((entry, index) => (
                       <Cell key={`cell-${index}`} fill={'#82ca9d'} />
                     ))} */}
                 </Bar>
                // <Bar type="monotone" dataKey="New Patient" stroke="#82ca9d" />
              ) : (
                <Bar label={false} dataKey="New Patient" maxBarSize={30} barSize={30}  fill={'#82ca9d'}>
                {/* {chartData &&
                  chartData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={'#82ca9d'} />
                  ))} */}
              </Bar>
              //  <Bar type="monotone" dataKey="New Patient" stroke="#82ca9d" />
              )}
            </BarChart>
          </ResponsiveContainer>
            </Col>
          </Row>
         
        </div>
      </React.Fragment>
    );
  }
}
export default withRouter(OldVsNewPatient);

const TiltedAxisTick = (props) => {

  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`} >
      <text 
        x={0} 
        y={0} 
        dy={16} 
        textAnchor="end" 
        fill="#666" 
        transform="rotate(-45)">
          {payload.value.length > 6 ? `${payload.value.slice(0,7)}...` : payload.value}
      </text>
    </g>
  );

};
