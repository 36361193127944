import { call, put } from "redux-saga/effects";
import axios from "axios";
import {
  GET_SUBSCRIPTION_SUCCESS_REQUEST,
  GET_SUBSCRIPTION_FAILURE_REQUEST,
  GET_CUSTOMER_PROFILE_REQUEST,
} from "../../actions/Action";
import store from "../../store/Store";
import { Baseurl } from "../../utils/Baseurl";

export function getSubscriptionRequest(url) {
  let headers = {
    "Content-Type": "application/json",
    Pragma: "no-cache",
  };
  return axios({
    method: "get",
    url: Baseurl + url,
    headers,
  });
}

export function* getSubscriptionDetails(action) {
  try {
    const response = yield call(
      getSubscriptionRequest,
      `api/subscription/get_subscription?doctor_uid=${action.data}`
    );

    const subscriptionData = response.data.data;
    if (subscriptionData.length) {      
      const data = subscriptionData[0].customerProfileId;  
      store.dispatch({ type: GET_CUSTOMER_PROFILE_REQUEST, data });
    }
    // dispatch a success action to the store with the new data
    yield put({ type: GET_SUBSCRIPTION_SUCCESS_REQUEST, subscriptionData });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_SUBSCRIPTION_FAILURE_REQUEST, error });
  }
}
