import React, { Component } from "react";
import axios from "axios";
import { toast } from "react-toastify";

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { GET_DOC_DETAILS_REQUEST } from '../../actions/Action';
import { Baseurl } from "../../utils/Baseurl";
import Modal from 'react-modal';
import { ToastMessage } from "../../component/ToastMessage";
import DatePicker from "react-date-picker";
import SchduleList from "../../component/SchduleList";
import "../../styles/scheduleAppointment.css";
import { handleTimeFormat } from "../../utils/DateMethod";
import ReactPaginate from 'react-paginate';
import moment from "moment";


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};



Modal.setAppElement('#root')
Modal.defaultStyles.overlay.backgroundColor = 'rgba(111, 111, 111, 0.75)';


class AppointmentSchedule extends Component {

    constructor(props) {
        super(props);
        this.state = {
            from_hours: "08",
            from_min: "00",
            from_ap: "AM",
            to_hours: "07",
            to_min: "00",
            to_ap: "PM",
            offer_telemedicine1: 0,
            hours: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
            minutes: ["00", "05","10","15","20","25", "30","35","40", "45", "50", "55"],
            myappointment: [],
            isButtonDisabled: false,
            interval: "30",
            date1: new Date(new Date().toDateString()),
            date2: new Date(new Date().toDateString()),
            isLoading: false,
            practice_id: 'none',
            practiceArr: [],
            appoint: [],
            isSaveLoading: false,
            modalMessage: '',
            modalFor: '',
            isModal: false,
            modalData: {},
            telemedicine: 0,

            //-start-addmore-state--------------------*/

            addMoreTime: 0,
            f_fromHours: "08",
            f_fromMin: "00",
            f_toHours: "07",
            f_toMin: "00",
            f_fromAP: "AM",
            f_toAP: "PM",
            offer_telemedicine2: 0,

            s_fromHours: "08",
            s_fromMin: "00",
            s_toHours: "07",
            s_toMin: "00",
            s_fromAP: "AM",
            s_toAP: "PM",
            offer_telemedicine3: 0,
            isModalLoading: false,

            isDisconnected : false,
            pagelimit : 15,
            currentPage : 1,
            totalPageIs : '',
            ListLoading : false
        };
    }

    handleConnectionChange = () =>{
        const isOnline = window !== "undefined" ? window.navigator.onLine : false
        if(isOnline){
            this.handleCalendar() 
        }else{
            toast.error(<ToastMessage message="Network not avalilabe. Please check your internet connection." />, { position: toast.POSITION.TOP_CENTER })
        }
        // fetch('//google.com', {
        //     mode: 'no-cors',
        //     })
        //   .then(() => {
        //     this.setState({ isDisconnected: false }, () => {
        //       this.handleCalendar()
        //     });
        //   }).catch((err) => this.setState({ isDisconnected: true },()=>{
        //       console.log(err,'errrrr')
        //     toast.error(<ToastMessage message="Network not avalilabe. Please check your internet connection." />, { position: toast.POSITION.TOP_CENTER })
        //   })
        //   )
    }



    UNSAFE_componentWillMount() {
        window.scrollTo(0, 0);
        let uid = localStorage.getItem('uid');
        this.props.handleGetDocDetails(uid);
    }



    UNSAFE_componentWillReceiveProps(props) {
        if (props.docDetails) {
            this.setState({
                practiceArr: props.docDetails.practice && props.docDetails.practice.length > 0 ? props.docDetails.practice : [],
                telemedicine: props.docDetails.telemedicine ? props.docDetails.telemedicine : 0,
                practice_id: props.docDetails.practice && props.docDetails.practice.length > 0 ? props.docDetails.practice[0]._id : 'none',
                modalFor: props.docDetails.practice && props.docDetails.practice.length === 0 ? 'practice' : '',
                isModal: props.docDetails.practice && props.docDetails.practice.length === 0,
                offer_telemedicine1:props.docDetails.telemedicine ? props.docDetails.telemedicine : 0,
                offer_telemedicine2:props.docDetails.telemedicine ? props.docDetails.telemedicine : 0,
                offer_telemedicine3:props.docDetails.telemedicine ? props.docDetails.telemedicine : 0,

            }, () => {
                this.handleGetAppointmentData();
            })
        }
    }



    closeModal = () => {
        this.setState({ isModal: false });
    }

    closeModelNew(){
        this.setState({ isModal: false });
    }


    /*-------------------------------------handle get appointments--------------------*/

    handlePageClick = (data) => { 
       this.setState({
        ListLoading : true,
         currentPage : data.selected+1
       },()=>{
           this.handleGetAppointmentData()
       })
    }


    handleGetAppointmentData = () => {
        this.setState({ isSaveLoading: true });
        let id = localStorage.getItem("uid");
        axios({
            method: "get",
            url: Baseurl + `api/schedule/get_schedules?uid=${id}&practice_id=${this.state.practice_id}&limit=${this.state.pagelimit}&page=${this.state.currentPage}`,
            headers: { "content-type": "application/json", 'Pragma': 'no-cache' }
        })
            .then(response => {
                const currentPages  = response.data.totalCount ? Math.ceil(response.data.totalCount / this.state.pagelimit) : ''
                this.setState({ totalPageIs : currentPages, ListLoading : false })
                const data = response.data.data;
                const date = ("0" + new Date().getDate()).slice(-2);
                const mont = ("0" + (new Date().getMonth() + 1)).slice(-2);
                const years = new Date().getFullYear();
                const today = `${years}-${mont}-${date}`;
                const add = data.filter(da => {
                    const year = da.date.slice(0, 4);
                    const month = da.date.slice(4, 6);
                    const day = da.date.slice(6, 8);
                    const fullDate = `${year}-${month}-${day}`;
                    return fullDate >= today;
                });
                const finalData = add.filter(value => value !== undefined);
                const newArr = finalData.map(final => {
                    const availability = final.availability;
                    return {
                        date: final.date,
                        appoint: availability,
                        value: final.calendarValue,
                        practice_id: final.practice_id ? final.practice_id : this.state.practice_id
                    }
                });

                const currentPracticeArr = newArr.sort((a, b) => {
                    var d1 = parseInt(a.date),
                        d2 = parseInt(b.date);
                    if (d2 > d1) return -1;
                    if (d1 < d2) return 1;
                    return 0;
                });
                this.setState({ myappointment: currentPracticeArr, isSaveLoading: false });
            })
            .catch(error => {
                console.log("get-appointments-error------------->", error);
                this.setState({ isSaveLoading: false });
            });

    };



    handleChange = (e) => {
        if (e.target.name === 'practice_id') {
            this.setState({ [e.target.name]: e.target.value }, () => {
                this.handleGetAppointmentData()
            })
        } else {
            this.setState({ [e.target.name]: e.target.value }, () => {
                this.handleGetAppointmentData()
            })
        }
    };


    handleRadioChecked = (e) => {
        this.setState({ interval: e });
    };

    fromChange = (date1) => {
        this.setState({ date1: date1, date2: date1 });
    };


    toChange = (date2) => {
        this.setState({ date2 });
    };




    getDateArray = (start, end) => {
        var arr = [];
        var startString = JSON.stringify(start);
        var dt = new Date(JSON.parse(startString));
        while (dt <= end) {
            arr.push({
                date:
                    String(dt.getFullYear()) +
                    String(dt.getMonth() + 1).padStart(2, "0") +
                    String(dt.getDate()).padStart(2, "0")
            });
            dt.setDate(dt.getDate() + 1);
        }
        return arr;
    };






    /*-----------------------------------------handleTimeArr----------------------------*/


    handleTimeArr = (startDate, endDate,offer_telemedicine) => {
       

        let timeArr = [];

        const starthours = startDate.getHours();
        const endhours = endDate.getHours();
        const start_min = startDate.getMinutes();
        const end_min = endDate.getMinutes();
        let start_milliseconds = Number(starthours * (60000 * 60)) + Number(start_min * 60000);
        let end_milliseconds = Number(endhours * (60000 * 60)) + Number(end_min * 60000);


        while (start_milliseconds < end_milliseconds) {
            const milliseconds = parseInt(start_milliseconds);
            const hours = String(Math.floor(milliseconds / 3600000)).padStart(2, "0");
            const minutes = String(Math.floor((milliseconds - hours * 3600000) / 60000)).padStart(2, "0");
            timeArr.push({ time: `${hours}${minutes}`, client: null,offer_telemedicine:offer_telemedicine, slot:this.state.interval });
            start_milliseconds = start_milliseconds + parseInt(this.state.interval)*60000; // interval time must be in minutes
            // if (this.state.interval === "30") {
            //     start_milliseconds = start_milliseconds + 1800000;
            // } else {
            //     start_milliseconds = start_milliseconds + 900000;
            // }
        }
        return timeArr;
    };


    /*-----------------------------------------intervals----------------------------*/

    intervals = (startTime, endTime, f_startTime, f_endTime, s_startTime, s_endTime,offer_telemedicine1,offer_telemedicine2,offer_telemedicine3) => {
        let timeArr = [];

        let start = startTime;
        let end = endTime;
        let offer_telemedicine = offer_telemedicine1;


        for (var i = 0; i <= this.state.addMoreTime; i++) {
            const newTimeArr = this.handleTimeArr(start, end,offer_telemedicine);
            timeArr = timeArr.concat(newTimeArr);

            if (i === 0) {
                start = f_startTime;
                end = f_endTime;
                 offer_telemedicine = offer_telemedicine2;
            }
            else {
                start = s_startTime;
                end = s_endTime;
                offer_telemedicine = offer_telemedicine3;
            }
        }
        return timeArr;
    };


    /*-----------------------------------------validateTimeSlot----------------------------*/


    validateTimeSlot = (startTime, endTime, f_startTime, f_endTime, s_startTime, s_endTime) => {
        const { addMoreTime } = this.state;

        const starthours = startTime.getHours();
        const endhours = endTime.getHours();
        const start_min = startTime.getMinutes();
        const end_min = endTime.getMinutes();
        let start_milliseconds = Number(starthours * (60000 * 60)) + Number(start_min * 60000);
        let end_milliseconds = Number(endhours * (60000 * 60)) + Number(end_min * 60000);


        const fstarthours = f_startTime.getHours();
        const fendhours = f_endTime.getHours();
        const fstart_min = f_startTime.getMinutes();
        const fend_min = f_endTime.getMinutes();
        let fstart_milliseconds = Number(fstarthours * (60000 * 60)) + Number(fstart_min * 60000);
        let fend_milliseconds = Number(fendhours * (60000 * 60)) + Number(fend_min * 60000);


        const s_starthours = s_startTime.getHours();
        const s_endhours = s_endTime.getHours();
        const s_start_min = s_startTime.getMinutes();
        const s_end_min = s_endTime.getMinutes();
        let s_start_milliseconds = Number(s_starthours * (60000 * 60)) + Number(s_start_min * 60000);
        let s_end_milliseconds = Number(s_endhours * (60000 * 60)) + Number(s_end_min * 60000);


        if (addMoreTime === 0) {
            return true;
        }
        else if (addMoreTime === 1) {
            let arr = [];
            let startMili = fstart_milliseconds;
            let endMili = fend_milliseconds;

            while (startMili <= endMili) {
                const milliseconds = parseInt(startMili);
                if (milliseconds > start_milliseconds && milliseconds < end_milliseconds) {
                    arr.push(milliseconds);
                }
                startMili = startMili + (this.state.interval === "30" ? 1800000 : 900000);
            }

            return arr.length === 0;
        }
        else {
            let sArr = [];
            let startMili1 = fstart_milliseconds;
            let endMili1 = fend_milliseconds;

            let startMili2 = s_start_milliseconds;
            let endMili2 = s_end_milliseconds;

            while (startMili1 <= endMili1) {
                const milliseconds = parseInt(startMili1);
                if (milliseconds > start_milliseconds && milliseconds < end_milliseconds) {
                    sArr.push(milliseconds);
                }
                else if (milliseconds > s_start_milliseconds && milliseconds < s_end_milliseconds) {
                    sArr.push(milliseconds);
                }

                // startMili1 = startMili1 + (this.state.interval === "30" ? 1800000 : 900000);
                startMili1 = startMili1 + parseInt(this.state.interval)*60000;
            }

            while (startMili2 <= endMili2) {
                const milliseconds = parseInt(startMili2);
                if (milliseconds > start_milliseconds && milliseconds < end_milliseconds) {
                    sArr.push(milliseconds);
                }
                else if (milliseconds > fstart_milliseconds && milliseconds < fend_milliseconds) {
                    sArr.push(milliseconds);
                }

                // startMili2 = startMili2 + (this.state.interval === "30" ? 1800000 : 900000);
                startMili2 = startMili2 + parseInt(this.state.interval)*60000;
            }

            return sArr.length === 0;

        }

    }


    /*----------------------handle-same-time-in other location---------------------------------*/


    isDateExist = (appointData) => {
        let uid = localStorage.getItem('uid');

        let data = {
            uid,
            appointData: appointData
        }

        return axios({
            method: 'post',
            url: Baseurl + 'api/schedule/get_scheduledata',
            headers: { "content-type": "application/json" },
            data
        });

    }

    /*---------------------------------------handle-save-------------------*/

    handleCalendar = () => {
        const {
            practice_id,
            date1,
            date2,
            from_hours,
            from_min,
            from_ap,
            to_hours,
            to_min,
            to_ap,
            offer_telemedicine1,

            f_fromHours,
            f_fromMin,
            f_fromAP,
            f_toHours,
            f_toMin,
            f_toAP,
            offer_telemedicine2,

            s_fromHours,
            s_fromMin,
            s_toHours,
            s_toMin,
            s_fromAP,
            s_toAP,
            offer_telemedicine3,
            addMoreTime

        } = this.state;

        let d = new Date();
        d.setHours(0);
        d.setMinutes(0);
        d.setSeconds(0);
        d.setMilliseconds(0);
        let CurrentDate = d.getTime()
        let FDate = date1.getTime();
        let EDate = date2.getTime();

        this.setState({ isSaveLoading: true });

        var start_time = new Date(`${date1.toDateString()} ${from_hours}:${from_min}:00 ${from_ap}`);
        var end_time = new Date(`${date2.toDateString()} ${to_hours}:${to_min}:00 ${to_ap}`);

        var f_startTime = new Date(`${date1.toDateString()} ${f_fromHours}:${f_fromMin}:00 ${f_fromAP}`);
        var f_endTime = new Date(`${date2.toDateString()} ${f_toHours}:${f_toMin}:00 ${f_toAP}`);

        var s_startTime = new Date(`${date1.toDateString()} ${s_fromHours}:${s_fromMin}:00 ${s_fromAP}`);
        var s_endTime = new Date(`${date2.toDateString()} ${s_toHours}:${s_toMin}:00 ${s_toAP}`);


        let interval = [];
        interval.push({ start: `${String(start_time.getHours()).padStart(2, "0")}${from_min}`, end: `${String(end_time.getHours()).padStart(2, "0")}${to_min}`, interval: 1 });
        addMoreTime === 1 && interval.push({ start: `${String(f_startTime.getHours()).padStart(2, "0")}${f_fromMin}`, end: `${String(f_endTime.getHours()).padStart(2, "0")}${f_toMin}`, interval: 2 })
        addMoreTime === 2 && interval.push({ start: `${String(s_startTime.getHours()).padStart(2, "0")}${s_fromMin}`, end: `${String(s_endTime.getHours()).padStart(2, "0")}${s_toMin}`, interval: 3 });

        let uid = localStorage.getItem('uid');

        if (start_time.getTime() < new Date().getTime()) {
            toast.error(<ToastMessage message="You can not enter time that has passed." />, { position: toast.POSITION.TOP_CENTER });
            this.setState({ isSaveLoading: false });
        } else {
            if (this.validateTimeSlot(start_time, end_time, f_startTime, f_endTime, s_startTime, s_endTime)) {
                if (this.state.practiceArr.length !== 0) {
                    if (FDate < CurrentDate || EDate < CurrentDate) {
                        this.setState({ isSaveLoading: false });
                        toast.error(<ToastMessage message="invalid date." />, { position: toast.POSITION.TOP_CENTER });
                    } else {
                        if (start_time < end_time && f_startTime < f_endTime && s_startTime < s_endTime) {
                            const dateArr = this.getDateArray(date1, date2);
                            const dateMatch = this.state.myappointment.filter(appoint => dateArr.find(s => s.date === appoint.date));
                            const newSchedule = dateArr.map(data => {
                                data.appoint = this.intervals(start_time, end_time, f_startTime, f_endTime, s_startTime, s_endTime,offer_telemedicine1,offer_telemedicine2,offer_telemedicine3);
                                data.value = interval;
                                data.practice_id = practice_id;
                                return data
                            });

                            this.isDateExist(newSchedule).then((response) => {
                                let practiceName, time;
                                let isExist = response.data.data.some(value => {
                                    let filterDate = newSchedule.filter(d => d.date === value.date);
                                    return value.calendarValue.some(val =>
                                        filterDate[0].appoint.some(data => {
                                            time = data.time;
                                            practiceName = this.props.docDetails.practice.find(p => p._id === value.practice_id).practice_name;

                                            return parseInt(data.time) >= parseInt(val.start) && parseInt(data.time) <= parseInt(val.end)
                                        })
                                    )
                                });

                               /*  if (isExist) {
                                    this.setState({ isSaveLoading: false });
                                    toast.error(<ToastMessage message={`You have already booked ${handleTimeFormat(time)} for ${practiceName}.`} />, { position: toast.POSITION.TOP_CENTER }); */
                               // } else {
                                    if (dateMatch.length) {
                                        toast.error(<ToastMessage message="Some dates already exist. Please select new dates or delete old dates first." />, { position: toast.POSITION.TOP_CENTER })
                                        this.setState({ isSaveLoading: false });
                                    } else {
                                        this.setState({ isLoading: true });

                                        const appointments = this.state.myappointment.concat(newSchedule);
                                        let data = { uid, appointments };

                                        this.setState({ isSaveLoading: false });
                                            axios({
                                                method: 'post',
                                                url: Baseurl + 'api/schedule/schedule_appointment',
                                                headers: { "content-type": "application/json" },
                                                data: data,
                                            }).then((response) => {
                                                // console.log("Saved-response--->", response);
                                                this.handleGetAppointmentData();
                                                this.setState({ isLoading: false, isSaveLoading: false });
                                                toast.success(<ToastMessage message="Successfully Saved." />, { position: toast.POSITION.TOP_CENTER })
                                            }).catch((error) => {
                                                 console.log("save-error--------------->", error);
                                                this.setState({ isLoading: false, isSaveLoading: false });
                                                toast.error(<ToastMessage message={`Something Went wrong ...`} />, { position: toast.POSITION.TOP_CENTER });
                                            })                                   
                                    }
                                //}
                            }).catch((error) => {
                                console.log("error--------------->", error);
                            })
                        } else {
                            this.setState({ isSaveLoading: false });
                            toast.error(<ToastMessage message="invalid time." />, { position: toast.POSITION.TOP_CENTER })
                        }
                    }
                }
                else {
                    this.setState({ isSaveLoading: false });
                    toast.error(<ToastMessage message="You need to setup your profile and add Practice details before you can schedule your calendar." />, { position: toast.POSITION.TOP_CENTER })
                }
            }
            else {
                this.setState({ isSaveLoading: false });
                toast.error(<ToastMessage message="it seems you have selected duplicate time slots." />, { position: toast.POSITION.TOP_CENTER });
            }
        }

    };



    renderSelectedLocation = () => {
        let filterCurrentLocation = this.state.practiceArr.filter(value => value._id === this.state.practice_id);
        return filterCurrentLocation.map(data => {
            return `${data.practice_name}, ${data.city !=="undefined" ? data.city : ""} ${data.practice_location}, ${data.zip}`
        })
    }





    /*------------------------------------deleteDay--------------------------------------------------------------*/

    handleSetDeleteDay = (value) => {
        let hasClient = value.appoint.some(d => d.client !== null);
        if (hasClient) {
            this.setState({ isModal: true, modalFor: 'hasClientOnDate' });
        }
        else {
            this.setState({ isModal: true, modalFor: 'deleteDay', modalData: value, modalMessage: 'Are you sure you want to delete this schedule?' });
        }
    }


    /*------------------------------------deleteTime--------------------------------------------------------------*/

    handleSetDeleteTimeState = (timeObj, date) => {
       
        this.setState({ isModal: true, modalFor: 'deleteTimes', modalData: { ...timeObj, date: date }, modalMessage: 'Are you sure you want to delete this schedule?' });
    }

    handleUpdateTelemedicine =(timeslot,upddata, date) => {
        let uid = localStorage.getItem('uid');
        this.setState({ isModalLoading: false, isModal: false });
        this.state.myappointment.map(value => {
            if (value.date === date) {
                let objIndex = value.appoint.findIndex((value => value.time === timeslot));
                value.appoint[objIndex].offer_telemedicine = upddata;
            }
        });

        this.setState({ myappointment: this.state.myappointment, isModalLoading: true }, () => {

            let data = { uid, appointments: this.state.myappointment };
            axios({
                method: 'post',
                url: Baseurl + 'api/schedule/schedule_appointment',
                headers: { "content-type": "application/json" },
                data: data
            }).then((response) => {
                this.handleGetAppointmentData();
                this.setState({ isModalLoading: false, isModal: false });
                toast.success(<ToastMessage message="Update Successfully." />, { position: toast.POSITION.TOP_CENTER, autoClose: 5000 });
            }).catch((error) => {
                console.log("save-error--------------->", error);
                this.setState({ isModalLoading: false, isModal: false });
            })
        });

    }



    /*-------------------------delete date from popup------------------------------------------*/

    deleteDate = () => {
        let doctor_id = localStorage.getItem('uid');
        axios({
            method: 'post',
            url: Baseurl + `api/schedule/delete_doc_avalibility?uid=${doctor_id}&practice_id=${this.state.practice_id}&date=${this.state.modalData.date}`,
            headers: { 'content-type': 'application/json' }
        }).then((response) => {
            // console.log("delete-avalibility-response----->", response);
            this.handleGetAppointmentData();
            this.setState({ isModal: false });
        }).catch((error) => {
            console.log("delete-avalibility-error----->", error);
            this.setState({ isModal: false });
        })
    }


    /*-------------------------delete time from popup------------------------------------------*/

    deleteTimes = () => {
        let uid = localStorage.getItem('uid');

        this.state.myappointment.map(value => {
            if (value.date === this.state.modalData.date) {
                value.appoint = value.appoint.filter(value => value.time !== this.state.modalData.time)
            }
        });

        this.setState({ myappointment: this.state.myappointment, isModalLoading: true }, () => {

            let data = { uid, appointments: this.state.myappointment };

            axios({
                method: 'post',
                url: Baseurl + 'api/schedule/schedule_appointment',
                headers: { "content-type": "application/json" },
                data: data
            }).then((response) => {
                this.handleGetAppointmentData();
                this.setState({ isModalLoading: false, isModal: false });
                toast.success(<ToastMessage message="Successfully Deleted." />, { position: toast.POSITION.TOP_CENTER, autoClose: 5000 });
            }).catch((error) => {
                console.log("save-error--------------->", error);
                this.setState({ isModalLoading: false, isModal: false });
            })
        });
    }





    /*-----------------------------renderPracticeMessage-------------------------------*/

    renderPracticeMessage = () => {
        return (
            <div style={{ textAlign: 'center' }}>
                <h5>You need to setup your profile and add Practice details before you can schedule your calendar.</h5>
                <Link to="/profile" className="btn btn-md btn-filled">Go to profile</Link>
            </div>
        )
    }

    /*------------------------------renderHasClientMessage---------------------------------*/

    renderHasClientMessage = () => {
        return (<div style={{ textAlign: 'center' }}>
            <h5>You can't delete this date cause an appointment exist on this date.</h5>
            <button className="btn btn-md btn-filled" onClick={this.closeModal}>Ok</button>
        </div>)
    }

    /*-------------------------------renderDeleteDayMessage--------------------------------------*/

    renderDeleteDayMessage = () => {
        return (<div style={{ textAlign: 'center' }}>
            <h5>{this.state.modalMessage}</h5>
            <button className="btn btn-md btn-filled" onClick={this.deleteDate}>Delete</button>
            <button className="btn btn-md btn-filled" onClick={this.closeModal}>Cancel</button>
        </div>)
    }


    /*------------------------------renderDeleteTimesMessage-------------------------------*/

    renderDeleteTimesMessage = () => {
        return (<div style={{ textAlign: 'center' }}>
            <h5>{this.state.modalMessage}</h5>
            <button className="btn btn-md btn-filled" onClick={this.deleteTimes}>Delete</button>
            <button className="btn btn-md btn-filled" onClick={this.closeModal}>Cancel</button>
        </div>)
    }


    /*-------------------------------------------------handleAddmoreTimeSlot--------------------------------------------*/

    handleAddmoreTimeSlot = () => {
        this.setState({ addMoreTime: this.state.addMoreTime + 1 });
    }

    /*-------------------------------------------------handleRemoveTimeSlot--------------------------------------------*/

    handleRemoveTimeSlot = () => {
        this.setState({ addMoreTime: this.state.addMoreTime - 1 });
    }


    render() {
        
        return (
            <React.Fragment>
                <Modal
                    isOpen={this.state.isModal}
                    onRequestClose={this.state.modalFor === 'practice' ? '' : this.closeModal}
                    style={customStyles}
                    contentLabel="Modal"
                >
                    {
                        this.state.isModalLoading ?
                            <div style={{ paddingTop: '2em', paddingBottom: '3em', width: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img alt="placeholder" src="https://luxysource.com/pub/static/frontend/Magento/luxysource/en_US/custom/img/preloader.gif" style={{ height: 100, width: 100 }} />
                            </div>
                            :
                            <div>
                                {this.state.modalFor === 'practice' && this.renderPracticeMessage()}
                                {this.state.modalFor === 'hasClientOnDate' && this.renderHasClientMessage()}
                                {this.state.modalFor === 'deleteDay' && this.renderDeleteDayMessage()}
                                {this.state.modalFor === 'deleteTimes' && this.renderDeleteTimesMessage()}
                            </div>
                    }

                </Modal>

                <div className="main_calendar_container">
                    <div className="calendar_header_box">
                        <div><h4>Calendar</h4></div>
                    </div>
                    <hr />
                    {/* ----------------------------------------------start-location-part---------------------------------------------- */}
                    <div className="choose_location_header">
                        <i className="ti-location-pin"></i><span><strong>Choose Practice Location</strong></span>
                    </div>
                    <div className="location_container">
                        <div id="l_input">
                            <select name='practice_id' value={this.state.practice_id} onChange={this.handleChange}>
                                {this.state.practiceArr.map((practice, i) => {
                                    return <option key={i} value={practice._id}>{`${practice.practice_name}, ${practice.zip}`}</option>
                                })}
                            </select>
                        </div>
                        <div id="l_name">
                            <p>{this.renderSelectedLocation()}</p>
                        </div>
                    </div>
                    <hr />
                    {/* ----------------------------end-location-part-and start-interval-part---------------------------- */}

                    <div className="select_interval_header">
                        <i className="ti-time" /><span><strong>Interval</strong></span>
                    </div>
                    <div className="interval_container">
                    <div className={`radio-option  ${this.state.interval === "10" ? "checked" : ""}`}
                            onClick={() => this.handleRadioChecked("10")}>
                            <div className="inner" />
                            <input type="radio" name="interval" onChange={e => this.setState({ interval: "10" })} />
                        </div>
                        <span style={{ marginLeft: "10px" }}>10 min</span>
                        <div className={`radio-option  ${this.state.interval === "15" ? "checked" : ""}`}
                            onClick={() => this.handleRadioChecked("15")}>
                            <div className="inner" />
                            <input type="radio" name="interval" onChange={e => this.setState({ interval: "15" })} />
                        </div>
                        <span style={{ marginLeft: "10px" }}>15 min</span>
                        <div className={`radio-option  ${this.state.interval === "20" ? "checked" : ""}`}
                            onClick={() => this.handleRadioChecked("20")}>
                            <div className="inner" />
                            <input type="radio" name="interval" onChange={e => this.setState({ interval: "20" })} />
                        </div>
                        <span style={{ marginLeft: "10px" }}>20 min</span>
                        <div className={`radio-option ${this.state.interval === "30" ? "checked" : ""}`}
                            onClick={() => this.handleRadioChecked("30")}>
                            <div className="inner" />
                            <input type="radio" name="interval" onChange={e => this.setState({ interval: "30" })} />
                        </div>
                        <span style={{ marginLeft: "10px" }}>30 min</span>
                    </div>
                    <hr />


                    {/* ----------------------------end-interval-part-and start-Date-part---------------------------- */}

                    <div className="select_date_header">
                        <i className="ti-calendar" /><span><strong>Date</strong></span>
                    </div>

                    <div className="calendar_container">
                        <div className="col-lg-6 col-sm-12 dateCalender" style={{ paddingLeft: 0 }}>
                            <label style={{ marginRight: "30px" }} htmlFor="from"><strong>From</strong></label>
                            <DatePicker
                                className="react-date-picker"
                                onChange={(e) => this.fromChange(e)}
                                value={this.state.date1}
                                clearIcon={null}
                                format="MM-dd-y"
                                minDate={new Date()}
                            />
                        </div>
                        <div className="col-lg-6 col-sm-12 dateCalender" style={{ paddingLeft: 0 }}>
                            <label style={{ marginRight: "30px" }} htmlFor="to"><strong>To</strong></label>
                            <DatePicker
                                className="react-date-picker"
                                onChange={(e) => this.toChange(e)}
                                value={this.state.date2}
                                clearIcon={null}
                                format="MM-dd-y"
                               
                                maxDate={new Date(moment(this.state.date1).add(1, 'months').format('YYYY-MM-DD'))} 
                            /><br/><span style={{color:'red',fontSize:'10px',marginLeft:'8%'}}>You can select max 30 days at a time.</span>
                        </div>
                        
                    </div>
                    <hr />

                    {/* ----------------------------end-date-part-and start-time-part---------------------------- */}

                    <div className="select_time_header">
                        <i className="ti-time" /><span><strong>Time</strong></span>
                    </div>

                    <div className="time_container">
                        <div className="col-md-6 col-xs-12" style={{ display: 'flex', paddingLeft: 0, paddingRight: 5, alignItems: 'center', justifyContent: 'center' }}>
                            <label style={{ marginRight: "20px" }} htmlFor="from"><strong>From</strong></label>
                            <select style={{ width: "30%" }} onChange={e => { this.setState({ from_hours: e.target.value }); }} value={this.state.from_hours}>
                                {
                                    this.state.hours.map((hour, key) => (
                                        <option key={key} value={hour}>{hour}</option>
                                    ))
                                }
                            </select>
                            <select style={{ width: "30%" }} onChange={e => { this.setState({ from_min: e.target.value }); }} value={this.state.from_min}>
                                {this.state.minutes.map((min, key) => (
                                    <option key={key} value={min}>{min}</option>
                                ))}
                            </select>
                            <select style={{ width: "30%" }} value={this.state.from_ap} onChange={e => { this.setState({ from_ap: e.target.value }); }}>
                                <option value="AM">AM</option>
                                <option value="PM">PM</option>
                            </select>
                        </div>




                        <div className="col-md-6 col-xs-12" style={{ display: "flex", paddingLeft: 0, paddingRight: 5, alignItems: 'center', justifyContent: 'center' }}>
                            <label style={{ marginRight: "20px" }} htmlFor="from"><strong>To</strong></label>
                            <select style={{ width: "30%" }} onChange={e => { this.setState({ to_hours: e.target.value }); }} value={this.state.to_hours}>
                                {this.state.hours.map((hour, key) => (
                                    <option key={key} value={hour}>
                                        {hour}
                                    </option>
                                ))}
                            </select>
                            <select style={{ width: "30%" }} onChange={e => { this.setState({ to_min: e.target.value }); }} value={this.state.to_min}>
                                {this.state.minutes.map((min, key) => (
                                    <option key={key} value={min}>
                                        {min}
                                    </option>
                                ))}
                            </select>
                            <select style={{ width: "30%" }} value={this.state.to_ap} onChange={e => { this.setState({ to_ap: e.target.value }); }}>
                                <option value="AM">AM</option>
                                <option value="PM">PM</option>
                            </select>
                        </div>

                        <div className="col-md-6 col-xs-12" style={{ display: "flex", paddingLeft: 0, paddingRight: 5, alignItems: 'center', justifyContent: 'left', marginLeft: "5%" }}>                          
                           

                        </div>
                    </div>

                    {
                            this.state.telemedicine === 1 ?
                            <div className="time_container">
                            <div  style={{ display: 'flex', paddingLeft: 0, paddingRight: 5, alignItems: 'top', justifyContent: 'center' }}>
                            <label style={{ marginRight: "20px" }} htmlFor="from"><strong>Offer</strong></label>                           
                            <div className="custom-control custom-radio" style={{paddingRight:"5px"}}>
                                <input type="radio" className="custom-control-input"  name="offer_telemedicine1" checked={this.state.offer_telemedicine1 == "1"}  onChange={e => { this.setState({ offer_telemedicine1: e.target.value }); }} value="1"  />
                                <label className="custom-control-label" htmlFor="customCheck1" >&nbsp; Telemedicine&nbsp;&nbsp;</label>
                            </div>
                            <div className="custom-control custom-radio" style={{paddingRight:"5px"}}>
                                <input type="radio" className="custom-control-input" name="offer_telemedicine1" checked={this.state.offer_telemedicine1 === "0"}   onChange={e => { this.setState({ offer_telemedicine1: e.target.value }); }} value="0" />
                                <label className="custom-control-label" htmlFor="customCheck1"> &nbsp;Office visit&nbsp;&nbsp;</label>
                            </div>
                            <div className="custom-control custom-radio">
                                <input type="radio" className="custom-control-input" name="offer_telemedicine1" checked={this.state.offer_telemedicine1 === "2"}  onChange={e => { this.setState({ offer_telemedicine1: e.target.value }); }} value="2" />
                                <label className="custom-control-label" htmlFor="customCheck1">&nbsp; Both</label>
                            </div>
                            </div>
                            </div>
                            :''}






                    {/* -----------------------first-addmore-time-container---------------------------- */}

                    <div className="time_container" style={{ display: this.state.addMoreTime === 1 || this.state.addMoreTime === 2 ? '' : 'none' }}>
                        <div className="col-md-6 col-xs-12" style={{ display: 'flex', paddingLeft: 0, paddingRight: 5, alignItems: 'center', justifyContent: 'center' }}>
                            <label style={{ marginRight: "20px" }} htmlFor="from"><strong>From</strong></label>
                            <select style={{ width: "30%" }} onChange={e => { this.setState({ f_fromHours: e.target.value }); }} value={this.state.f_fromHours}>
                                {
                                    this.state.hours.map((hour, key) => (
                                        <option key={key} value={hour}>{hour}</option>
                                    ))
                                }
                            </select>
                            <select style={{ width: "30%" }} onChange={e => { this.setState({ f_fromMin: e.target.value }); }} value={this.state.f_fromMin}>
                                {this.state.minutes.map((min, key) => (
                                    <option key={key} value={min}>{min}</option>
                                ))}
                            </select>
                            <select style={{ width: "30%" }} value={this.state.f_fromAP} onChange={e => { this.setState({ f_fromAP: e.target.value }); }}>
                                <option value="AM">AM</option>
                                <option value="PM">PM</option>
                            </select>
                        </div>




                        <div className="col-md-6 col-xs-12" style={{ display: "flex", paddingLeft: 0, paddingRight: 5, alignItems: 'center', justifyContent: 'center' }}>
                            <label style={{ marginRight: "20px" }} htmlFor="from"><strong>To</strong></label>
                            <select style={{ width: "30%" }} onChange={e => { this.setState({ f_toHours: e.target.value }); }} value={this.state.f_toHours}>
                                {this.state.hours.map((hour, key) => (
                                    <option key={key} value={hour}>
                                        {hour}
                                    </option>
                                ))}
                            </select>
                            <select style={{ width: "30%" }} onChange={e => { this.setState({ f_toMin: e.target.value }); }} value={this.state.f_toMin}>
                                {this.state.minutes.map((min, key) => (
                                    <option key={key} value={min}>
                                        {min}
                                    </option>
                                ))}
                            </select>
                            <select style={{ width: "30%" }} value={this.state.f_toAP} onChange={e => { this.setState({ f_toAP: e.target.value }); }}>
                                <option value="AM">AM</option>
                                <option value="PM">PM</option>
                            </select>
                        </div>

                        {
                            this.state.telemedicine === 1 ?
                            <div className="time_container">
                            <div  style={{ display: 'flex', paddingLeft: 0, paddingRight: 5, alignItems: 'top', justifyContent: 'center' }}>
                            <label style={{ marginRight: "20px" }} htmlFor="from"><strong>Offer</strong></label>                           
                           <div className="custom-control custom-radio" style={{paddingRight:"5px"}}>
                                <input type="radio" className="custom-control-input" checked={this.state.offer_telemedicine2 == "1"} name="offer_telemedicine2" onChange={e => { this.setState({ offer_telemedicine2: e.target.value }); }} value="1"  />
                                <label className="custom-control-label" htmlFor="customCheck1" >&nbsp; Telemedicine&nbsp;&nbsp;</label>
                            </div>
                           <div className="custom-control custom-radio" style={{paddingRight:"5px"}}>
                                <input type="radio" className="custom-control-input" name="offer_telemedicine2" checked={this.state.offer_telemedicine2 == "0"}  onChange={e => { this.setState({ offer_telemedicine2: e.target.value }); }} value="0" />
                                <label className="custom-control-label" htmlFor="customCheck1"> &nbsp;Office visit&nbsp;&nbsp;</label>
                            </div>
                            <div className="custom-control custom-radio ">
                                <input type="radio" className="custom-control-input" name="offer_telemedicine2" checked={this.state.offer_telemedicine2 == "2"} onChange={e => { this.setState({ offer_telemedicine2: e.target.value }); }} value="2" />
                                <label className="custom-control-label" htmlFor="customCheck1"> &nbsp;Both</label>
                            </div>
                            </div>
                            </div>
                            :''}
                    </div>

                  


                    {/* -----------------------second-addmore-time-container---------------------------- */}

                    <div className="time_container" style={{ display: this.state.addMoreTime === 2 ? '' : 'none' }}>

                        <div className="col-md-6 col-xs-12" style={{ display: 'flex', paddingLeft: 0, paddingRight: 5, alignItems: 'center', justifyContent: 'center' }}>
                            <label style={{ marginRight: "20px" }} htmlFor="from"><strong>From</strong></label>
                            <select style={{ width: "30%" }} onChange={e => { this.setState({ s_fromHours: e.target.value }); }} value={this.state.s_fromHours}>
                                {
                                    this.state.hours.map((hour, key) => (
                                        <option key={key} value={hour}>{hour}</option>
                                    ))
                                }
                            </select>
                            <select style={{ width: "30%" }} onChange={e => { this.setState({ s_fromMin: e.target.value }); }} value={this.state.s_fromMin}>
                                {this.state.minutes.map((min, key) => (
                                    <option key={key} value={min}>{min}</option>
                                ))}
                            </select>
                            <select style={{ width: "30%" }} value={this.state.s_fromAP} onChange={e => { this.setState({ s_fromAP: e.target.value }); }}>
                                <option value="AM">AM</option>
                                <option vlaue="PM">PM</option>
                            </select>
                        </div>




                        <div className="col-md-6 col-xs-12" style={{ display: "flex", paddingLeft: 0, paddingRight: 5, alignItems: 'top', justifyContent: 'center' }}>
                            <label style={{ marginRight: "20px" }} htmlFor="from"><strong>To</strong></label>
                            <select style={{ width: "30%" }} onChange={e => { this.setState({ s_toHours: e.target.value }); }} value={this.state.s_toHours}>
                                {this.state.hours.map((hour, key) => (
                                    <option key={key} value={hour}>
                                        {hour}
                                    </option>
                                ))}
                            </select>
                            <select style={{ width: "30%" }} onChange={e => { this.setState({ s_toMin: e.target.value }); }} value={this.state.s_toMin}>
                                {this.state.minutes.map((min, key) => (
                                    <option key={key} value={min}>
                                        {min}
                                    </option>
                                ))}
                            </select>
                            <select style={{ width: "30%" }} value={this.state.s_toAP} onChange={e => { this.setState({ s_toAP: e.target.value }); }}>
                                <option value="AM">AM</option>
                                <option value="PM">PM</option>
                            </select>
                        </div>

                        {
                            this.state.telemedicine === 1 ?
                            <div className="time_container">
                            <div  style={{ display: 'flex', paddingLeft: 0, paddingRight: 5, alignItems: 'top', justifyContent: 'center' }}>
                            <label style={{ marginRight: "20px" }} htmlFor="from"><strong>Offer</strong></label>                           
                            <div className="custom-control custom-radio" style={{paddingRight:"5px"}}>
                                <input type="radio" className="custom-control-input" name="offer_telemedicine3" checked={this.state.offer_telemedicine3 == "1"} onChange={e => { this.setState({ offer_telemedicine3: e.target.value }); }} value="1"  />
                                <label className="custom-control-label" htmlFor="customCheck1" >&nbsp;Telemedicine&nbsp;&nbsp;</label>
                            </div>
                            <div className="custom-control custom-radio" style={{paddingRight:"5px"}}>
                                <input type="radio" className="custom-control-input" checked={this.state.offer_telemedicine3 == "0"} name="offer_telemedicine3"  onChange={e => { this.setState({ offer_telemedicine3: e.target.value }); }} value="0" />
                                <label className="custom-control-label" htmlFor="customCheck1"> &nbsp;Office visit&nbsp;&nbsp;</label>
                            </div>
                            <div className="custom-control custom-radio">
                                <input type="radio" className="custom-control-input" checked={this.state.offer_telemedicine3 == "2"} name="offer_telemedicine3" onChange={e => { this.setState({ offer_telemedicine3: e.target.value }); }} value="2" />
                                <label className="custom-control-label" htmlFor="customCheck1"> &nbsp;Both</label>
                            </div>
                            </div>
                            </div>
                            :''}

                    </div>
                   


                    {
                        this.state.addMoreTime >= 2 ?
                            <p style={{ marginBottom: 0 }}><u style={{ cursor: 'pointer', fontWeight: 'bold', color: '#47b475' }} onClick={this.handleRemoveTimeSlot}>Remove</u></p>
                            :
                            <div>
                                {
                                    this.state.addMoreTime === 1 &&
                                    <p style={{ marginBottom: 0 }}><u style={{ cursor: 'pointer', fontWeight: 'bold', color: '#47b475' }} onClick={this.handleRemoveTimeSlot}>Remove</u></p>
                                }
                                <p style={{ marginBottom: 0 }}>Working in shifts?  <u style={{ cursor: 'pointer', fontWeight: 'bold', color: '#47b475' }} onClick={this.handleAddmoreTimeSlot}>Click here to add another time slot.</u></p>
                            </div>

                    }

                    {/* ----------------------------end-time-part-and start-save-button---------------------------- */}

                    <div style={{ textAlign: 'left' }}>
                        <button
                            type="submit"
                            style={{ width: 100, marginTop: 25 }}
                            onClick={this.handleConnectionChange}
                            disabled={this.state.isSaveLoading}
                        >
                            {this.state.isSaveLoading && <span className="spinner-border text-light mr8" role="status" />}
                            save
                    </button>
                    </div>
                    <hr />
                    {/* ----------------------------end-save-btn-part-and start-appointment-div---------------------------- */}
                    <div className="doc_schedule_avalibility_containter">
                        {
                            this.state.myappointment.length === 0 ?
                                <h1 className="text-center" style={{ color: 'rgb(0, 35, 75)', fontSize: '1.6em', fontWeight: 'bold' }}>No Results</h1>
                                :
                                this.state.ListLoading ?
                                <div className="text-center">
                                    <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                                :
                                <ul className="accordion accordion-1">
                                    {this.state.myappointment.map((value, key) => {
                                        return <SchduleList data={value} key={key} 
                                        handleSetUpdateTele={this.handleUpdateTelemedicine}
                                        handleSetDeleteTimeState={this.handleSetDeleteTimeState}
                                            removeDay={this.handleSetDeleteDay} intervals={this.intervals}
                                            telemedicine={this.state.telemedicine}
                                            handleTimeArr={this.handleTimeArr} isDateExist={this.isDateExist}
                                            docDetails={this.props.docDetails} handleGetAppointmentData={this.handleGetAppointmentData} />;
                                    })}
                                </ul>
                        }
                        { this.state.myappointment.length > 0 ?
                         <div className="col-sm-12">
                         <div className="past_appointment_pagination " style={{ textAlign: 'center',marginTop:10 }}>
                                <ReactPaginate
                                    previousLabel={'previous'}
                                    nextLabel={'next'}
                                    pageCount={this.state.totalPageIs}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={this.handlePageClick}
                                    containerClassName={'pagination'}
                                    subContainerClassName={'pages pagination'}
                                    activeClassName={'active'}
                                />
                         </div></div> : ""}
                    </div>{/* ----------------------------end-of-appointment-div---------------------------- */}
                </div>{/* ----------------------------end-of-main-container---------------------------- */}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        docDetails: state.doctorDetails
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        handleGetDocDetails: data => dispatch({ type: GET_DOC_DETAILS_REQUEST, data })
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(AppointmentSchedule);
