import React, { Component } from 'react';
import ErrorScreen from './ErrorScreen';

class ErrorHandling extends Component {

    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error, errorInfo) {

        this.setState({
            error: error,
            errorInfo: errorInfo
        }) 
    }


    render() {
        if (this.state.errorInfo) {
            return (
                <div>
                    <ErrorScreen />
                </div>
            );
        }
        return this.props.children;

    }
}

export default ErrorHandling;