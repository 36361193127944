import React, { Component } from 'react';
import '../styles/Filter.css';


export default class DocFilter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            open: false,
            genderCheckedArr: [],
            hospital_affiliationCheckedArr: [],
            languagesCheckedArr: [],
        }
    }




    handleFilDropDown = (e, data) => {
        e.preventDefault();
        if (data.filterData && data.filterData.length !== 0) {
            this.setState({ open: !this.state.open });
        }
        else {
            return null;
        }
    };

    handleBackdrop = () => {
        this.setState({ open: false });
    };

    handleApply = (name) => {
        this.props.filterApply_Handler();
        this.setState({ open: false });
    };

    /*---------------------------------------handleCheckedBox-------------------------------*/

    handleCheckfilterCheckbox = (e, name) => {

        //-------------------------------------genderCheckArr--------------------------------------*/

        if (name === 'Gender') {
            let index = this.state.genderCheckedArr.indexOf(e.target.value);
            if (index < 0) {
                this.state.genderCheckedArr.push(e.target.value);
                this.setState({ genderCheckedArr: this.state.genderCheckedArr })
                this.props.setFilterState("genderCheckedArr", this.state.genderCheckedArr);
            }
            else {
                this.state.genderCheckedArr.splice(index, 1);
                this.setState({ genderCheckedArr: this.state.genderCheckedArr })
                this.props.setFilterState("genderCheckedArr", this.state.genderCheckedArr);
            }
            // console.log("gender-checked----------->", this.state.genderCheckedArr);
        }

        //-------------------------------------Hospital Affiliation CheckArr--------------------------------------*/

        if (name === 'Hospital Affiliation') {
            let index = this.state.hospital_affiliationCheckedArr.indexOf(e.target.value);
            if (index < 0) {
                this.state.hospital_affiliationCheckedArr.push(e.target.value);
                this.setState({ hospital_affiliationCheckedArr: this.state.hospital_affiliationCheckedArr })
                this.props.setFilterState('hospital_affiliationCheckedArr', this.state.hospital_affiliationCheckedArr);
            }
            else {
                this.state.hospital_affiliationCheckedArr.splice(index, 1);
                this.setState({ hospital_affiliationCheckedArr: this.state.hospital_affiliationCheckedArr })
                this.props.setFilterState('hospital_affiliationCheckedArr', this.state.hospital_affiliationCheckedArr);
            }
            // console.log("affiliation-checked----------->", this.state.hospital_affiliationCheckedArr);
        }

        //-------------------------------------Languages CheckArr--------------------------------------*/

        if (name === 'Languages') {
            let index = this.state.languagesCheckedArr.indexOf(e.target.value);
            if (index < 0) {
                this.state.languagesCheckedArr.push(e.target.value);
                this.setState({ languagesCheckedArr: this.state.languagesCheckedArr })
                this.props.setFilterState('languagesCheckedArr', this.state.languagesCheckedArr);
            }
            else {
                this.state.languagesCheckedArr.splice(index, 1);
                this.setState({ languagesCheckedArr: this.state.languagesCheckedArr })
                this.props.setFilterState('languagesCheckedArr', this.state.languagesCheckedArr);
            }
            // console.log("languages-checked----------->", this.state.languagesCheckedArr);
        }

    }




    render() {
        // console.log(this.props.filterData);
        const filter = this.props.filterData;
        const cat_name = filter.name.toLowerCase().split(' ').join('_');

        return (
            <React.Fragment>
                <div className="filter_container" style={{ position: 'relative', marginLeft: 5, display: 'flex', flexWrap: 'wrap' }} >
                    <button onClick={(e) => this.handleFilDropDown(e, this.props.filterData)} className="btn btn-sm mb0" style={{ outline: 'none', lineHeight: 0 }}>{this.props.filterData.name}</button>
                    {
                        this.state.open &&
                        <div style={filtStyle}>
                            <ul className="text-left" style={{ width: '100%',overflow:'scroll', maxHeight:'220px',overflow:'auto'}}>
                                {
                                    filter.filterData && filter.filterData.map((value, key) => {
                                        return <li key={key} style={{ padding: 10, background: '', margin: 2, borderBottom: '1px solid lightgray' }}>
                                            <input type="checkbox"
                                                name={filter.name} value={value}
                                                checked={this.state[`${cat_name}CheckedArr`].filter(v => v === value).length}
                                                onChange={(e) => this.handleCheckfilterCheckbox(e, filter.name)} />&nbsp; &nbsp;<span>{value}</span>
                                        </li>
                                    })
                                }
                            </ul>
                            <button onClick={() => this.handleApply(filter.name)} className="btn btn-sm mb0" style={{ marginRight: 5 }}>APPLY</button>
                        </div>
                    }
                </div>
                <div onClick={this.handleBackdrop} style={{ height: '100%', width: '100%', position: 'fixed', top: 0, background: '', opacity: 1, zIndex: 50, display: this.state.open ? 'block' : 'none' }}></div>
            </React.Fragment>
        )
    }
}

const filtStyle = {
    height: 'auto',
    width: 'auto',
    minWidth: 200,
    borderRadius: 3,
    backgroundColor: '#fff',
    display: 'flex',
    position: 'absolute',
    top: 35,
    left: 0,
    zIndex: 100,
    padding: '10px 0px 10px 0px',
    flexWrap: 'wrap',
    border: '1px solid lightgray',
    justifyContent: 'flex-end',

}
