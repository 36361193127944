import React from "react";
import Moment from "react-moment";
import { getNameLetter } from "../../../utils/helperFunctions";
import Avatar from "react-avatar";
const ListUserChatItemSearch = ({ data, conversationId, doctorChatUser,handleClick }) => {
  const { first_name, last_name,_id, online } = data; 

  let cActive = conversationId === data?._id;
  return (
    <div
      className={`loop_box flex px_16 py_5 ${cActive ? `active` : ``} ${!cActive && (data.lastMessage?.isRead === false && data.lastMessage.reciver === doctorChatUser._id) ? `unread` : ``}`}
      style={{ cursor: "pointer" }}
      key={data._id}
      onClick={()=>handleClick(_id)}
    >
      <div className={`u_img ${online ? 'online' : 'offline'}`}>
      <Avatar name={getNameLetter(first_name, last_name)} size="40"  round="20px" maxInitials={2}/>
  
      </div>
      <div className="u_info">
        <div className="block_box flex">
          <h5 style={{ color: "#ffffff" }}>
            {" "}
            {first_name} {last_name}{" "}
          </h5>
        {/* <h2 className="ml_auto">
        {online ? "" :  <Moment fromNow>{logs?.last_login_date}</Moment>}
           {data.unreadMsgCount && !cActive? <span className="bg_age">{data.unreadMsgCount}</span> : ""}
          </h2> */}
        </div>

        {/* <p style={{textTransform:'unset'}}>
          {data.lastMessage && data.lastMessage.message}
        </p>       */}
      </div>
    </div>
  );
};
export default ListUserChatItemSearch;
