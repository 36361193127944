import React,{ Component } from "react";

export default class TermsCondition extends Component{
    render(){
        return(
            <React.Fragment>
                <section className="container">
                    <div className="col-8 mx-auto">
                       <h4 style={{fontWeight:'bold'}}>MASTER SERVICES AGREEMENT</h4>
                       <p className="sub-title" >This Services Agreement (the “Agreement”) sets forth terms under which MedSched LLC DBA Smart Appointment, a New York State corporation (“Company”) shall provide services to the Client under whose name the account is being registered (the “Client”). This Agreement is effective as of the day of creation of this account (“Effective Date”).</p>
                       <ul className="term-list">
                           <li className="mb-3"><span className="terms">1.</span> Services. Company shall provide online and application based patient scheduling services (“Services”) to Client as described in EXHIBIT A. Company shall maintain the Smart Appointment website and IOS and Android Applications so that patients may book online appointments with Client. Company shall provide client with the patients’ demographic information as inputted by the patient and provide for Client access to the metrics as described in EXHIBIT A.</li>
                           <li className="mb-3"><span style={{fontSize : "18px" , marginRight : "5px"}}>2.</span> Contract Price. For performance of the Services, A Monthly payment of $300 per month per doctor will be charged.</li>
                           <li><span>3. </span> Pass Through Cost. An insurance verification cost of $0.15 per verification will be passed along to the client. This will be aggregated monthly and charged when the monthly fee is paid.</li>
                           <li><span>4. </span> Dates of Performance. Company will begin performing services upon receipt of signed Agreement. The monthly fee will begin after Client has been “On Boarded” as described in EXHIBIT A. Unless terminated as provided in this Agreement, Company will continue to deliver Services.</li>
                           <li><span>5. </span> Termination. Company shall have the right to modify, reject, or terminate any Services with five days written notice to Client. In the event Company terminates services prior to monthly service payment, the Company shall reimburse Client the prorated fee for the remainder of the month not serviced. Client must give Company 30-day written notice to terminate Services.</li>
                           <li><span>6 </span> Representations and Warranties.</li>
                           <li><span>6.1 </span> Company’s Representation: Company represents that any materials used in the Services will not knowingly (a) infringe on the intellectual property rights of any third party or any rights of publicity or privacy or (b) violate any law, statute, ordinance or regulation.</li>
                           <li><span>6.2 </span> Client’s Representation: Client represents that any materials provided to Company by Client for incorporation into the Services will not (a) infringe on the intellectual property rights of any third party or any rights of publicity or privacy or (b) violate any law, statute, ordinance or regulation.</li>
                           <li><span>7 </span> Ownership of Services.“Intellectual Property Rights” means any and all (a) rights associated with works of authorship, including but not limited to copyrights, (b) trademark and trade name rights and similar rights, (c) trade secret rights, (d) patents and (c) all other intellectual property rights in any jurisdiction throughout the world. To the fullest extent permitted by law, Company retains ownership in all Intellectual Property rights of the Services.</li>
                           <li><span>8 </span> Indemnification. Client will defend, indemnify and hold Company harmless from any and all claims, losses, liabilities, damages, expenses and costs (including attorneys’ fees and court costs) arising from or relating to any claims regarding elements or materials provided by Client and incorporated into the Services.</li>
                           <li><span>9 </span> Limitation of Liability. COMPANY WILL NOT BE LIABLE FOR ANY LOSS OF USE, INTERRUPTION OF BUSINESS, LOST PROFITS, OR ANY INDIRECT, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES OF ANY KIND REGARDLESS OF THE FORM OF ACTION WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT PRODUCT LIABILITY, OR OTHERWISE, EVEN IF IT HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT SHALL COMPANY’S AGGREGATE LIABILITY UNDER THIS AGREEMENT EXCEED THE FEES PAID TO COMPANY HEREUNDER.</li>
                           <li><span>10 </span> Compliance with Laws. Each party shall perform all of its obligations under this Agreement in compliance at all times with all foreign, federal, state and local statutes, orders and regulations, including those relating to privacy and data protection.</li>
                           <li><span>11 </span> Compliance with Laws. Each party shall perform all of its obligations under this Agreement in compliance at all times with all foreign, federal, state and local statutes, orders and regulations, including those relating to privacy and data protection.</li>
                           <li><span>12 </span> General. Client may not assign this Agreement without the prior written consent of the Company and any attempt to do so will be void. Any notice or consent under this Agreement will be in writing to the address specified below. If any provision of this Agreement is adjudged by any court of competent jurisdiction to be unenforceable or invalid, that provision shall be limited or eliminated to the minimum extent necessary so that this Agreement will otherwise remain in full force and effect. Any waivers or amendments shall be effective only if made in writing signed by a representative of the respective parties. Both parties agree that this Agreement is the complete and exclusive statement of the mutual understanding of the parties, and supersedes and cancels all previous written and oral agreements and communications relating to the subject matter of this Agreement. Both parties agree that the Agreement is signed by a duly, authorized company representative authorized to bind the company to its terms and services and no consent from any third party is required.</li>
                           <li><span>13 </span> Choice of Law. This Agreement will be deemed to have been made in, and shall be construed pursuant to the laws of the State of New York and the United States without regard to conflicts of laws provisions thereof. Any suit or proceeding arising out of or relating to this Agreement shall be commenced in a federal or state court in Brookhaven, NY, and each party irrevocably submits to the jurisdiction and venue of such courts.</li>
                           <li><span>14 </span> Remedies. Company reserves all remedies available at law or equity for any disputes that arise under this Agreement. In the event of a suit or proceeding under this Agreement, Client agrees to pay all attorneys’ fees if the federal or state court renders judgment substantially in Company’s favor.</li>
                       </ul>
                       <h4 style={{fontWeight:'bold'}}>
                       EXHIBIT A
                       Services Description
                       </h4>
                       <p className="sub-title">Contract Signing. Company and Client agree to the Master Service Agreement as set forth above and sign the contract</p>
                       <p className="sub-title">On Boarding. Company will work with Client to enter all necessary Client information including but not limited to Client names, Client accepted insurances, Client addresses, etc. Company will train Client or Client designated person on the operation of the Services. The purpose of this is for the Client to have a “super user’ in the office or available to troubleshoot and know the system. The Company will also help train Client on proper methodologies to enroll their patients on the Applications or Website.</p>
                       <p className="sub-title">Services Period. This period will begin when the Client has been On Boarded or within 2 weeks of signing the Agreement.</p>
                       <ul className="term-list">
                           <li><span>1. </span>Services. Company will maintain the website, IOS and Android Applications in order for Client’s current patients or new patients to input their demographic information and book online appointments with Client. Company will also verify the patient’s insurance both at the time of booking and at the time of the appointment. Company will cross check patients Insurance with the accepted insurances of the Client (as provided to Company by Client). Client may also use the Services when sending patient to other participating Physicians in the Smart Appointment Ecosystem. Company will send a notice to Client when a patient books an appointment. Company will also provide Client with a Dashboard showing all past and future appointments booked through Smart Appointment.</li>
                           <li><span>2. </span> Fees and Terms.</li>
                       </ul>
                       <p className="sub-title">Start Date: Date of account registration.</p>
                       <p className="sub-title">$300 per month per enrolled physician</p>
                       <p className="sub-title">Pass through cost of $0.15 per insurance verification</p>
                    </div>
                </section>                

            <style jsx="true">
                {
                    `
                    p.sub-title {
                        font-size: 18px;
                        line-height: 28px;
                        letter-spacing: .2px;
                        color: #000;
                    }
                    ul.term-list li {
                        font-size: 16px;
                        margin-bottom: 20px;
                        color: #000;
                    }
                    `
                    
                }
            </style>
            </React.Fragment>
        )
    }
}