import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';
// import * as serviceWorker from './serviceWorker';

import './assets/css/font-awesome.min.css';
import './assets/css/bootstrap.css';
import './assets/css/themify-icons.css';
import './assets/css/font-roboto.css';
import './assets/css/flexslider.css';
import './assets/css/lightbox.min.css';
import './assets/css/theme.css';
import './assets/css/custom.css';



 

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
