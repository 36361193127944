import React from 'react';
import firebaseApp from '../utils/Fire';
import { withRouter } from 'react-router-dom';

const UpdatePasswordErrorToast = (props, { closeToast }) => {

    const handleLogout = () => {
        firebaseApp.auth().signOut()
            .then((res) => {
                setTimeout(() => {
                    localStorage.removeItem('uid');
                    this.props.history.push('/access/login');
                }, 500);

            }).catch((err) => {
                console.log(err);
            })
    }
    return (
        <React.Fragment>
            <div>
                <h5 className="text-center" style={{ color: '#fff' }}>{props.message}</h5>
                <div className="text-center">
                    <button onClick={()=>handleLogout()} className="btn btn-md btn-filled btn-rounded" style={{ background: '#fff', color: '#333', border: '1px solid #fff' }}>Logout</button>
                </div>
            </div>
        </React.Fragment>
    )
};


export default withRouter(UpdatePasswordErrorToast)