import React, { Component } from 'react'
import { Route, Switch } from "react-router-dom";
import ErrorHandling from '../component/ErrorHandling';
import NotFound from '../component/NotFound';
import Navbar from '../component/Navbar';
// import firebaseApp from '../utils/Fire';
import Signup from '../container/authContainer/Signup';
import Login from '../container/authContainer/Login';
import RecoverAccount from '../container/authContainer/RecoverAccount';
import TrainingVideo from '../container/authContainer/TrainingVideo';

export default class AuthWrapper extends Component {

    // UNSAFE_componentWillMount() {
    //     firebaseApp.auth().onAuthStateChanged((user) => {
    //         if (user) {
    //             this.props.history.push('/');
    //         }
    //     });
    // }


    render() {


        return (
            <React.Fragment>
                <Navbar isAuth={this.props.isAuth}/>
                <div className="main-container">
                    <section style={{ padding: '50px 0px' }}>
                        <div className="container">
                            <div className="row v-align-children">
                                <div className="col-md-6 col-md-offset-0 col-sm-6 col-sm-offset-3">
                                    <h1 className="text-center" style={{marginBottom:10}}>For Providers</h1>
                                   
                                    <div>
                                        <ErrorHandling>
                                            <Switch>
                                                <Route exact path={`${this.props.match.path}/login`} render={(props) => <Login {...props} handleIsAuth={this.props.handleIsAuth} />} />
                                                <Route path={`${this.props.match.path}/signup`} render={(props) => <Signup {...props} signingUp={this.props.signingUp} />} />
                                                <Route path={`${this.props.match.path}/traning_video`}  component={TrainingVideo} />
                                                <Route path={`${this.props.match.path}/recover-account`} component={RecoverAccount} />
                                                <Route render={() => <NotFound />} />
                                            </Switch>
                                        </ErrorHandling>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

            </React.Fragment>
        )
    }
}
