import React from "react"

class UploadChatFile extends React.Component{
   

    render(){
        const {selectedFile} = this.props;
        return(<div
            className="modal fade show"
            id="exampleModalCenter"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
            style={{opacity:1}}
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="cs_modal_header flex p-3">
                  <h3 className="title_model_header">upload file</h3>
                  <button
                    type="button"
                    className="btn_close focus_out"
                    data-dismiss="modal"
                    aria-label="Close"
                    // onClick={() => props.isOpen(false)}
                  >
                    <svg
                      version="1.1"
                      className="close_icon"
                      viewBox="0 0 512.001 512.001"
                      style={{ enableBackground: "new 0 0 512.001 512.001" }}
                      xmlSpace="preserve"
                    >
                      <g>
                        <g>
                          <path
                            d="M284.286,256.002L506.143,34.144c7.811-7.811,7.811-20.475,0-28.285c-7.811-7.81-20.475-7.811-28.285,0L256,227.717
                              L34.143,5.859c-7.811-7.811-20.475-7.811-28.285,0c-7.81,7.811-7.811,20.475,0,28.285l221.857,221.857L5.858,477.859
                              c-7.811,7.811-7.811,20.475,0,28.285c3.905,3.905,9.024,5.857,14.143,5.857c5.119,0,10.237-1.952,14.143-5.857L256,284.287
                              l221.857,221.857c3.905,3.905,9.024,5.857,14.143,5.857s10.237-1.952,14.143-5.857c7.811-7.811,7.811-20.475,0-28.285
                              L284.286,256.002z"
                          />
                        </g>
                      </g>
                    </svg>
                  </button>
                </div>
                <div className="modal-body">
                  <label className="drop_box mb-0" htmlFor="upload_input">
                    <input
                      type="file"
                      className="upload_file"
                      id="upload_input"
                    //   onChange={(e) => setSelectedFile(e.target.files[0])}
                      multiple
                      accept="image"
                    />
                      Drop files here to upload
                    </label>
      
                  <div className="upload_image_info pt-3">
                    <div className="upload_image_file flex">
                      <svg
                        className="up_icon"
                        id="Capa_1"
                        enableBackground="new 0 0 512 512"
                        viewBox="0 0 512 512"
                      >
                        <g>
                          <g>
                            <path
                              d="m377.333 79.667-34.833-72.167h-251.5c-13.807 0-25 11.193-25 25v447c0 13.807 11.193 25 25 25h295.984c5.475 0 10.535-1.765 14.653-4.75h21.069v-404.272z"
                              fill="#eaf6ff"
                            />
                            <path
                              d="m422.707 95.478-10.722-3.737v19.259 368.5c0 13.807-11.193 25-25 25h34.015c13.807 0 25-11.193 25-25v-368.5z"
                              fill="#d8ecfe"
                            />
                            <path
                              d="m367.5 111h78.5l-103.5-103.5v78.5c0 13.807 11.193 25 25 25z"
                              fill="#b3dafe"
                            />
                          </g>
                          <g>
                            <path d="m123.714 497h-32.714c-9.649 0-17.5-7.851-17.5-17.5v-447c0-9.649 7.851-17.5 17.5-17.5h129.942c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5h-129.942c-17.92 0-32.5 14.58-32.5 32.5v447c0 17.92 14.58 32.5 32.5 32.5h32.714c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5z" />
                            <path d="m386 164.781h-260c-4.142 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5h260c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5z" />
                            <path d="m386 231.448h-260c-4.142 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5h260c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5z" />
                            <path d="m386 298.115h-260c-4.142 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5h260c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5z" />
                            <path d="m386 364.781h-260c-4.142 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5h260c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5z" />
                            <path d="m343.565.08c-.411-.042-1.001-.08-1.065-.08h-86.558c-4.142 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5h79.058v71c0 17.92 14.58 32.5 32.5 32.5h71v361c0 9.649-7.851 17.5-17.5 17.5h-262.286c-4.142 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5h262.286c17.92 0 32.5-14.58 32.5-32.5v-368.5c0-1.892-.762-3.869-2.197-5.303l-103.5-103.5c-1.366-1.368-3.223-2.02-4.238-2.117zm23.935 103.42c-9.649 0-17.5-7.851-17.5-17.5v-60.394l77.894 77.894z" />
                          </g>
                        </g>
                      </svg>
                      <div className="file_info_text ml-1">
                        <h2> {selectedFile != null ? selectedFile.name : ""} </h2>
                        <h3 className="mb-0">
                          {" "}
                          {selectedFile != null ? selectedFile.size : ""}{" "}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    // onClick={() => upload()}
                    type="button"
                    className="btn btn_save_cs focus_out"
                    disabled={selectedFile!=null?false:true}
                  
                    style={{ width: '100%' }}
                  >
                    {/* {loading ? <Spinner small={true}></Spinner> : 'Send File'} */}
                  </button>
                </div>
              </div>
            </div>
          </div>)
    }
}

export default UploadChatFile;