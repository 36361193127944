import { call, put } from "redux-saga/effects";
import axios from "axios";
import {
  GET_LOGIN_USER_SUCCESS_REQUEST,
  GET_DOCTOR_LIST_FAILURE_REQUEST,
} from "../../actions/Action";
import { Baseurl } from "../../utils/Baseurl";

export function loginUserDetailsRequest(url) {
  let headers = {
    "Content-Type": "application/json",
    Pragma: "no-cache",
  };
  return axios({
    method: "get",
    url: Baseurl + url,
    headers,
  });
}

export function* getLoginUserRequest(action) {
  try {
    const response = yield call(
      loginUserDetailsRequest,
      `api/doc/get_details_selected?userId=${action.data}`
    );
    const loginuserdetails = response.data;
    // dispatch a success action to the store with the new data
    yield put({ type: GET_LOGIN_USER_SUCCESS_REQUEST, loginuserdetails });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_DOCTOR_LIST_FAILURE_REQUEST, error });
  }
}
