import React from 'react';

export const ToastMessage = (props, { closeToast }) => {
    return (
        <React.Fragment>
            <div>
                <h5 className="text-center" style={{ color: '#fff' }}>{props.message}</h5>
                {props.children}
                <div className="text-center">
                    <span className="btn btn-md btn-rounded" style={{ background: '#fff', color: '#333', border: '1px solid #fff' }}>OK</span>
                </div>
            </div>
        </React.Fragment>
    )
}
