import React from "react";
import ReactPaginate from "react-paginate";
import axios from "axios";
import { Baseurl } from "../../../../utils/Baseurl";
import { handleDateFormatMonthYear } from "../../../../utils/DateMethod";
import { connect } from "react-redux";
class ConsultCancelledOutgoing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      consultCancelledOutgoingData: null,
      page: 1,
      limit: 10,
    };
  }
  getCancelledAppointments = (fullname, page, limit) => {
    axios
      .get(
        `${Baseurl}api/consult_report/consultCancelledOutgoing?doctor_fullname=${fullname.toLowerCase()}&page=${page}&limit=${limit}`
      )
      .then((res) => {
        if (res.status === 200) {
          this.setState({ consultCancelledOutgoingData: res.data });
        }
        console.log(res, "res");
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
  componentDidMount() {
    const id = localStorage.getItem("uid");
    const { page, limit } = this.state;
   setTimeout(()=>{
    this.getCancelledAppointments(this.props.doctordetail.first_name +" "+ this.props.doctordetail.last_name, page, limit);
   },1000)
  }
  handlePageClick = (pages) => {
    const id = localStorage.getItem("uid");
    const { limit } = this.state;
    this.setState({ page: pages.selected + 1 }, () => {
      this.getCancelledAppointments(this.props.doctordetail.first_name +" "+ this.props.doctordetail.last_name, pages.selected + 1, limit);
    });
  };
  render() {
    const { consultCancelledOutgoingData } = this.state;
    const {doctordetail} = this.props;
        console.log(doctordetail, "doctordetail");
    return (
      <div className="consults-sent">
        <div class="col-12 mt-3">
          <div className="doc_appointment-resp_table">
            <table className="table table-striped table-bordered" id="myTable">
              <thead
                style={{
                  background: "#70ad47",
                  color: "#FFF",
                }}
              >
                <tr>
                  <th scope="col">
                    <span
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        whiteSpace: "nowrap",
                      }}
                    >
                      MONTH YEAR
                    </span>
                  </th>
                  <th scope="col" className="text-left">
                    <span
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        whiteSpace: "nowrap",
                      }}
                    >
                      CANCELLED OUTGOING APPOINTMENTS COUNT
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {consultCancelledOutgoingData?.data.length ? (
                  consultCancelledOutgoingData?.data.map((el) => {
                    return (
                      <tr key={el._id}>
                        <td nowrap="nowrap">
                          {handleDateFormatMonthYear(el._id)}
                        </td>
                        <td nowrap="nowrap" className="text-left">
                          {el.cancelledCount}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td className="text-center" colSpan={2}>
                      No data found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        {consultCancelledOutgoingData?.totalPages ? (
          <ReactPaginate
            previousLabel={"previous"}
            nextLabel={"next"}
            pageCount={consultCancelledOutgoingData.totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={this.handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    doctordetail: state.doctorDetails,
  };
};
export default connect(mapStateToProps, null)(ConsultCancelledOutgoing);
