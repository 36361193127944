import React from "react"

const ChatSearch=({handleSearch,search,handleShowSearchData})=>{
    return(
        <div className="header_section">
        <div className="search_box">
          <div className="search flex flex_center search_style">
            <i className="fas fa-search" />
            <input
              type="search"
              onChange={handleSearch}
              value={search}
              className="search_input focus_out mb-0"
              placeholder="Search Patient...(min 3 char required)"
              onFocus={()=>handleShowSearchData(true)}
              onBlur={()=>handleShowSearchData(false)}
            />
          </div>
        </div>
      </div>
    )
}
export default ChatSearch