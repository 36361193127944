import React from "react";
import { GetAPI, PostAPI } from "../../../utils/Api-interface";
import {
  validateEmail,
  validateName,
  findDebitCardType,
} from "../../../utils/FormValidater";
import BillingAddress from "./billing-address";
import { ToastMessage } from "../../ToastMessage";
import { toast } from "react-toastify";
import Payment from "./payment";
import { connect } from "react-redux";
import {
  GET_TRAIL_SUBSCRIPTION_REQUEST,
  GET_TRANSACTION_REQUEST,
} from "../../../actions/Action";
import Modal from "react-modal";
import ActiveSubscription from "./active-subscription";
import CancelSubscription from "./cancel-subscription";
import { Link } from "react-router-dom";
import Moment from "react-moment";
class SubscriptionUpdate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      update: false,
      activeTab: 0,
      dataFilled: false,
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      address: "",
      stateIs: "",
      city: "",
      country: "United States",
      zip: "",
      expiryMonth: "",
      expiryYear: "",
      cardNumber: "",
      cvv: "",
      cityList: [],
      errorBillingIs: {
        firstName: "",
        lastName: "",
        email: "",
        mobile: "",
        address: "",
        stateIs: "",
        city: "",
        country: "",
        zip: "",
      },
      errorPaymentIs: {
        cardNumber: "",
        expiryMonth: "",
        expiryYear: "",
        cvv: "",
      },
      paymentIs: false,
    };
  }

  validationBilling() {
    const {
      errorBillingIs,
      firstName,
      lastName,
      email,
      mobile,
      address,
      stateIs,
      city,
      country,
      zip,
    } = this.state;
    errorBillingIs.firstName = !validateName(firstName)
      ? "Please enter valid Firstname"
      : "";
    errorBillingIs.lastName = !validateName(lastName)
      ? "Please enter valid Lastname"
      : "";
    errorBillingIs.email = !validateEmail(email)
      ? "Please enter valid email"
      : "";
    errorBillingIs.mobile =
      mobile.length !== 10 ? "Please enter valid mobile no" : "";
    errorBillingIs.address =
      address.length < 3
        ? "Billing address should have min 3 characters"
        : address.length < 61 ? "" : "Address is too long please enter valid details";
    errorBillingIs.stateIs = !stateIs ? "Please select State" : "";
    errorBillingIs.city = !city ? "Please select your City name" : "";
    errorBillingIs.zip = zip.length !== 5 ? "Please enter valid zip code" : "";
    if (
      !errorBillingIs.firstName &&
      !errorBillingIs.lastName &&
      !errorBillingIs.email &&
      !errorBillingIs.mobile &&
      !errorBillingIs.address &&
      !errorBillingIs.stateIs &&
      !errorBillingIs.city &&
      !errorBillingIs.zip
    ) {
      this.setState({ errorBillingIs });
      return true;
    } else {
      this.setState({ errorBillingIs }, () => {
        const fields = Object.keys(errorBillingIs);
        const msg = fields
          .map((el) => {
            return { fieldname: el, value: errorBillingIs[el] };
          })
          .filter((el) => el.value !== "");
        toast.error(<ToastMessage message={msg[0].value} />, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
    }
  }

  validationPayment() {
    const {
      errorPaymentIs,
      cardNumber,
      expiryMonth,
      expiryYear,
      cvv,
    } = this.state;
    errorPaymentIs.cardNumber = !findDebitCardType(cardNumber)
      ? "Please enter valid Card number"
      : "";
    errorPaymentIs.expiryMonth = !expiryMonth
      ? "Please select expiry month"
      : "";
    errorPaymentIs.expiryYear = !expiryYear ? "Please select expiry year" : "";
    errorPaymentIs.cvv = cvv.length !== 3 ? "Please enter valid cvv no" : "";

    if (
      !errorPaymentIs.cardNumber &&
      !errorPaymentIs.expiryMonth &&
      !errorPaymentIs.expiryYear &&
      !errorPaymentIs.cvv
    ) {
      this.setState({ errorPaymentIs });
      return true;
    } else {
      this.setState({ errorPaymentIs }, () => {
        const fields = Object.keys(errorPaymentIs);
        const msg = fields
          .map((el) => {
            return { fieldname: el, value: errorPaymentIs[el] };
          })
          .filter((el) => el.value !== "");
        toast.error(<ToastMessage message={msg[0].value} />, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
    }
  }

  handleTabChange = (activeTab) => {
    this.setState({ activeTab });
  };

  handleCityList = (value) => {
    GetAPI(`api/subscription/city_list?state_id=${value}`).then((res) => {
      const response = res.data.data;
      if (response) {
        this.setState({
          cityList: response,
          city: response.length && response[0].city,
        });
      }
    });
  };

  handleOnChange = (e) => {
    const { name, value } = e.target;
    if (name === "mobile") {
      if (value.length <= 10) {
        this.setState({ [name]: value });
      }
    } else if (name === "cardNumber") {
      if (value.length <= 16) {
        this.setState({ [name]: value });
      }
    } else if (name === "cvv") {
      if (value.length <= 3) {
        this.setState({ [name]: value });
      }
    } else {
      this.setState({ [name]: value }, () => {
        if (name === "stateIs") {
          this.handleCityList(value);
        }
      });
    }
  };
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.state.dataFilled && this.props.customerprofiledata) {
      const profiledata = this.props.customerprofiledata.profile
        .paymentProfiles[0];
      this.setState({
        dataFilled: true,
        firstName: profiledata.billTo.firstName,
        lastName: profiledata.billTo.lastName,
        email: this.props.customerprofiledata.profile.email,
        city: profiledata.billTo.city,
        stateIs: profiledata.billTo.state,
        zip: profiledata.billTo.zip,
        address: profiledata.billTo.address,
        mobile: profiledata.billTo.phoneNumber,
        country: profiledata.billTo.country,
        cardNumber: profiledata.payment.creditCard.cardNumber,
      });
    }
  }
  handleSubscriptionUpdate = () => {
    const {
      firstName,
      lastName,
      email,
      city,
      stateIs,
      zip,
      address,
      mobile,
      country,
      cardNumber,
      expiryMonth,
      expiryYear,
    } = this.state;

    const billingdata = {
      // doctor_uid: uid,
      customer_payment_profile_id: this.props.subscriptiondata
        .customerPaymentProfileId,
      doctor_uid: this.props.subscriptiondata.DoctorUid,
      customer_profile_id: this.props.subscriptiondata.customerProfileId,
      first_name: firstName,
      last_name: lastName,
      email: email,
      city: city,
      state: stateIs,
      zip: zip,
      country: address,
      phone_number: mobile,
      address: country,
      card_nuber: cardNumber,
      card_expiray_date: `${expiryMonth}${expiryYear}`,
    };

    PostAPI(`api/subscription/update_customer_payment_profile`, billingdata)
      .then((res) => {
        toast.success(<ToastMessage message={res.data.message} />, {
          position: toast.POSITION.TOP_CENTER,
        });
        this.setState({ update: false });
      })
      .catch((err) => {
        if (err.response) {
          toast.error(<ToastMessage message={err.response.data.error} />, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });
  };

  handleBillingUpdate = (e) => {
    e.preventDefault();
    if (this.validationBilling() && this.validationPayment()) {
      this.handleSubscriptionUpdate();
    }
  };

  handleUpdateMode = (update) => {
    this.setState({ update });
  };

  componentDidMount() {
    const uid = localStorage.getItem("uid");
    this.props.getTransactionDetails(uid);
    this.props.getTrailSubscriptionData(uid);
  }
  render() {
    const Subsfunc = {
      handleOnChange: this.handleOnChange,
      handleBilling: this.handleBillingUpdate,
      handleTabChange: this.handleTabChange,
      handleUpdateMode: this.handleUpdateMode,
    };

    const { activeTab, update } = this.state;
    const {
      subscriptiondata,
      transactiondata,
      handleActiveTab,
      trailsubscriptiondata,
    } = this.props;
    return (
      <React.Fragment>
        {/* {console.log(trailsubscriptiondata, "trailsubscriptiondata")} */}
        <div
          className={`tab-pane ${this.props.activeTab === 0 ? `active` : ``}`}
          id="profile"
          role="tabpanel"
          aria-labelledby="profile-tab"
        >
          <div className="row">
            <div className="col-sm-12">
              {trailsubscriptiondata?.status && (
                <React.Fragment>
                  <h4>Free Subscription Details</h4>
                  <div className="container">
                    <div className="row">
                      <div>
                        <div class="table-responsive">
                          <table class="table">
                            <tbody>
                              <tr>
                                <th>Plan:</th>
                                <td class="text-right">
                                Smartappointment free trail plan
                                </td>
                              </tr>
                              <tr>
                                <th>Trail Start Date:</th>
                                <td class="text-right">
                                  <Moment format="MM-DD-YYYY">
                                    {trailsubscriptiondata.trail_start_date}
                                  </Moment>
                                </td>
                              </tr>
                              <tr>
                                <th>Trail End Date:</th>
                                <td class="text-right">
                                  <Moment format="MM-DD-YYYY">
                                    {trailsubscriptiondata.trail_end_date}
                                  </Moment>
                                </td>
                              </tr>
                              <tr>
                                <th>Trial Status:</th>
                                <td class="text-right">ACTIVE</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )}
              {subscriptiondata ? (
                <React.Fragment>
                  <h4>Paid Subscription Details</h4>
                  <div className="container">
                    <div className="row">
                      <div>
                        <ActiveSubscription {...this.state} {...this.props} />

                        {subscriptiondata.status === 1 ? (
                          <React.Fragment>
                            <CancelSubscription />
                            <button
                              className="btn btn-primary "
                              // onClick={() => this.handleUpdateMode(true)}
                              onClick={() => handleActiveTab(2)}
                            >
                              Update Payment Profile
                            </button>
                          </React.Fragment>
                        ) : (
                          <Link className="btn btn-primary " to="/subscription">
                            Take Subscription
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <div>
                  <h4>Paid Subscription Details</h4>
                  <div className="container feature bordered">
                    <div className="row">
                      <div className="not-subscribed" style={{lineHeight:'100px'}}>Not Subscribed.</div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* update tab */}
        <div
          className={`tab-pane ${this.props.activeTab === 2 ? `active` : ``}`}
          id="profile"
          role="tabpanel"
          aria-labelledby="profile-tab"
        >
          <div className="row">
            <div className="col-sm-12">
              {subscriptiondata ? (
                <React.Fragment>
                  <div className="container">
                    <div className="row">
                      <div className="plan-detail">
                        <BillingAddress
                          {...this.state}
                          {...this.props}
                          handleFunc={Subsfunc}
                        />
                        <Payment
                          {...this.state}
                          {...this.props}
                          handleFunc={Subsfunc}
                        />
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <div className="not-subscribed">Not Subscribed.</div>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    subscriptiondata: state.subscriptionData,
    transactiondata: state.transactionData,
    customerprofiledata: state.customerProfileData,
    trailsubscriptiondata: state.trailsubscription,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getTransactionDetails: (data) =>
      dispatch({ type: GET_TRANSACTION_REQUEST, data }),
    getTrailSubscriptionData: (data) =>
      dispatch({ type: GET_TRAIL_SUBSCRIPTION_REQUEST, data }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionUpdate);
