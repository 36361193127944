import React, { Component } from 'react'
import { Baseurl } from "../../utils/Baseurl";
import axios from 'axios';
import { toast } from 'react-toastify';
import { ToastMessage } from '../../component/ToastMessage';

export default class Messaging extends Component {
     constructor(props) {
      super(props);    
      this.state = {
        value: '',
        border: "2px solid #ddd",
        background: "#eee",
        color:"#000",
        loading: false
       };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    if(event.target.value==""){
    this.setState({value: event.target.value,
      border: "2px solid #ddd",
        background: "#eee",color:"#000",loading: false});
    }else{
      this.setState({value: event.target.value,
      border: "2px solid #47b475",
        background: "#47b475",color:"#fff",loading: false});
    }
  }

  handleSubmit(event) { 
     let id = localStorage.getItem('uid');   
     let data = {"message": this.state.value,"uid":id}
    event.preventDefault();
     this.setState({ loading: true });
     axios({
            method:'Post',
            url:Baseurl+`api/fb/post_content`,
            headers:{'content-type':'application/json'},
            data: data,
        }).then((res)=>{
             this.setState({value: '',
              border: "2px solid #ddd",
              background: "#eee",
              color:"#000",
              loading: false});
            //  console.log("res----->",res);
            //  console.log("resid----->",res.data.data.id);
            if(res.data.data.id){             
              toast.success(<ToastMessage message="Successfully posted your message to Facebook." />, { position: toast.POSITION.TOP_CENTER });
            }else{
              toast.error('Something went wrong!', { position: toast.POSITION.TOP_CENTER });
            }
        }).catch((error)=>{
            this.setState({value: '',
              border: "2px solid #ddd",
              background: "#eee",
              color:"#000",
              loading: false});
            toast.error('Something went wrong!', { position: toast.POSITION.TOP_CENTER });
            // console.log("error--->",error);
        })
  }

  render() {

    const mystyle = {
        border: "1px #ddd solid",
        width: "100%",
        height: "120px",
        font: "9pt Consolas",        
    }
     const mystyle2 = {
        border: this.state.border,
        background: this.state.background,
        color:this.state.color,  
        borderRadius: 12, 
        fontWeight: "bold",
        fontSize: "16px",
        textShadow: "0px -1px 0px #000000",

    }
    const text= {
      fontSize: "12px",
    }
    const textwrapper = {
      width:"100%",
    }

    return (
      <form onSubmit={this.handleSubmit}>
       <h4>Post Message</h4>
       <span style={text}>Your message will be posted to the Smart Appointment Facebook page - https://www.facebook.com/Smartappt/ </span>       
          
          <div style={textwrapper}><textarea style={mystyle} value={this.state.value} onChange={this.handleChange} cols={150} rows={10} /></div>
        
        <input type="submit" style={mystyle2} disabled={!this.state.value} value={this.state.loading ? "Sending..." : "Submit"} />
      </form>
    );
  }

   
}
