import { call, put } from "redux-saga/effects";
import axios from "axios";
import { DOC_PAST_APPOINTMENT_SUCCESS_REQUEST, DOC_PAST_APPOINTMENT_FAILURE_REQUEST } from '../../actions/Action';
import { Baseurl } from '../../utils/Baseurl';

export function docPastAppointments(url) {
    let headers = {
        'Content-Type': 'application/json',
        'Pragma': 'no-cache'
    };
    return axios({
        method: "get",
        url: Baseurl + url,
        headers
    });
}


export function* docPastAppointmentSaga(action) {
    try {
        const response = yield call(docPastAppointments, `api/appointment/doc_past_appointments?uid=${action.data.id}&limit=${action.data.limit}&page=${action.data.page}`);
        // console.log("in-saga-doclist---->",response.data);
        const docPastAppoints = response.data;

        // dispatch a success action to the store with the new data
        yield put({ type: DOC_PAST_APPOINTMENT_SUCCESS_REQUEST, docPastAppoints });
    } catch (error) {
        // dispatch a failure action to the store with the error
        yield put({ type: DOC_PAST_APPOINTMENT_FAILURE_REQUEST, error });
    }
}




