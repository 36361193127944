import React, { Component } from 'react';
import Modal from 'react-modal';
import { handleTimeFormat } from "../utils/DateMethod";
const cross = {
    fontSize: 20,
    padding: '0 7px',
    cursor: 'pointer',
    marginLeft: '20px',
    height:'10px'
};
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};
export default class ScheduleTimeButton extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isButtonDisabled: true,
            toggle: false,
            ismodal: false,
            modalData: '',
            offer_telemedicine111: this.props.value.offer_telemedicine
            
        };
    }

    closeModal = () => {
        this.setState({ ismodal: false, isLoading: false });
    }

    handleOpenModal = (data,data2) => {
        if(data2===1){
        this.setState({ ismodal: true, modalData: data });
        }
    }
    render() {
        const { value, date,telemedicine } = this.props;
        let text = '#d3d3d3';
        let color = '#47b475';
        if (value.hasOwnProperty('offer_telemedicine') && value.offer_telemedicine == 1) {
            text = '#008b8b';
            color = '#fff';
        }
        if (value.hasOwnProperty('offer_telemedicine') && value.offer_telemedicine == 0) {
            text = '#47b475';
            color = '#fff';
        }
       
        return (
            
            <React.Fragment>
                <Modal
                    isOpen={this.state.ismodal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Modal"
                >    <div style={{ textAlign: 'left' }}>
                        <div className="time_container">
                            <div style={{paddingLeft: 0, paddingRight: 5, alignItems: 'center', justifyContent: 'center' }}>
                                <label style={{ marginRight: "20px" }} htmlFor="from"><p><strong>Provide Offer at time slot {this.state.modalData}</strong></p></label>
                                <div className="custom-control custom-radio">
                                    <input type="radio" id="customCheck1" className="form-check-input" name="offer_telemedicine111" onChange={e => { this.setState({ offer_telemedicine111: e.target.value }); }} checked={this.state.offer_telemedicine111 == 1}  value="1" />
                                    <label className="custom-control-label" htmlFor="customCheck1" > &nbsp;&nbsp;Telemedicine</label>
                                </div>
                                <div className="custom-control custom-radio">
                                    <input type="radio" className="form-check-input" name="offer_telemedicine111" id="customCheck2" checked={this.state.offer_telemedicine111 == 0} onChange={e => { this.setState({ offer_telemedicine111: e.target.value }); }} value="0" />
                                    <label className="custom-control-label" htmlFor="customCheck2">&nbsp;&nbsp; Office visit</label>
                                </div>
                                <div className="custom-control custom-radio">
                                    <input type="radio" className="form-check-input" name="offer_telemedicine111" id="customCheck3" checked={this.state.offer_telemedicine111 == 2} onChange={e => { this.setState({ offer_telemedicine111: e.target.value }); }} value="2" />
                                    <label className="custom-control-label" htmlFor="customCheck3">&nbsp;&nbsp; Both</label>
                                </div>
                            </div>
                        </div>
                        <div style={{ textAlign: 'center' }}>
                        <button className="btn" style={{ marginTop: 24, marginBottom: 0 }} onClick={() => {this.props.handleUpdateTele(value.time,this.state.offer_telemedicine111,date);this.closeModal();}}>SAVE</button>
                        </div>
                    </div>

                </Modal>
                <button 
                className={value.client ? "btn btn-sm btn-rounded btn-filled mb0" : "btn btn-sm btn-rounded mb0"}  
                style={{ lineHeight: 0, background: text, color: color }}>
                {value.client != null ?
                <span> <strong> {handleTimeFormat(this.props.value.time)} </strong> </span>
                : <span role="button" onClick={() => this.handleOpenModal(handleTimeFormat(this.props.value.time),telemedicine)}> <strong> {handleTimeFormat(this.props.value.time)} </strong> </span>}
                    {value.client != null ? '' : <button className="border-none" aria-hidden='true' style={cross} onClick={() => this.props.handleRemove(value, date)}>×</button>}


                </button>

            </React.Fragment>
        );
    }
}
