import React, { Component } from "react";
import { GetAPI } from "../../utils/Api-interface"

export default class BillingAddress extends Component {

  state = {
    StateList: []
  }

  handleStateList = () => {
    GetAPI(`api/subscription/state_list`).then((res) => {
      const response = res.data.data
      if (response) {
        this.setState({ StateList: response })
      }
    })
  }

  componentDidMount() {
    this.handleStateList()
  }

  render() {
    const { firstName, lastName, email, mobile, country, stateIs, city, handleFunc, address, zip, cityList, errorBillingIs } = this.props;
    const { StateList } = this.state;

    return (
      <React.Fragment>
        <div className="account-information">
          {/* <div className="text-center">
            <h4 className="order-title">Order Information</h4>
          </div>
          <div className="table-responsive">
            <table className=" table-hover w-100">
              <thead>
                <tr>
                  <th>Product</th>
                  <th>#</th>
                  <th className="text-center">Price</th>
                  <th className="text-center">Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="">Baked Rodopa Sheep Feta</td>
                  <td className="" style={{ textAlign: "center" }}>
                    {" "}
                            2{" "}
                  </td>
                  <td className=" text-center">$13</td>
                  <td className=" text-center">$26</td>
                </tr>
                <tr>
                  <td className="">Lebanese Cabbage Salad</td>
                  <td className="" style={{ textAlign: "center" }}>
                    {" "}
                            1{" "}
                  </td>
                  <td className=" text-center">$8</td>
                  <td className=" text-center">$8</td>
                </tr>
                <tr>
                  <td className="">Lebanese Cabbage Salad</td>
                  <td className="" style={{ textAlign: "center" }}>
                    {" "}
                            3{" "}
                  </td>
                  <td className=" text-center">$16</td>
                  <td className=" text-center">$48</td>
                </tr>
                <tr>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td className="text-center">
                    <p>
                      <strong>Subtotal:&nbsp;</strong>
                    </p>
                    <p>
                      <strong>Tax:&nbsp;</strong>
                    </p>
                  </td>
                  <td className="text-center">
                    <p>
                      <strong>$6.94</strong>
                    </p>
                    <p>
                      <strong>$6.94</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td className="text-center">
                    <h4>
                      <strong>Total:&nbsp;</strong>
                    </h4>
                  </td>
                  <td className="text-center text-danger">
                    <h4>
                      <strong>$31.53</strong>
                    </h4>
                  </td>
                </tr>
              </tbody>
            </table>
          </div> */}

          <form className="">
            <h4>Personal Details</h4>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <input
                    type="text"
                    className={`form-control ${errorBillingIs.firstName ? `form-input-error` : ''}`}
                    placeholder="First Name"
                    name="firstName"
                    value={firstName}
                    onChange={handleFunc.handleOnChange}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <input
                    type="text"
                    className={`form-control ${errorBillingIs.lastName ? `form-input-error` : ''}`}
                    placeholder="Last Name"
                    name="lastName"
                    value={lastName}
                    onChange={handleFunc.handleOnChange}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <input
                    type="email"
                    className={`form-control ${errorBillingIs.email ? `form-input-error` : ''}`}
                    placeholder="Email"
                    name="email"
                    value={email}
                    onChange={handleFunc.handleOnChange}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <input
                    type="number"
                    className={`form-control ${errorBillingIs.mobile ? `form-input-error` : ''}`}
                    placeholder="Mobile No"
                    name="mobile"
                    value={mobile}
                    onChange={handleFunc.handleOnChange}
                  />
                </div>
              </div>
            </div>
          </form>
        
            <h4>Billing Address</h4>
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <input
                    type="text"
                    className={`form-control ${errorBillingIs.address ? `form-input-error` : ''}`}
                    placeholder="Address"
                    name="address"
                    value={address}
                    onChange={handleFunc.handleOnChange}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <input
                    type="text"
                    className={`form-control ${errorBillingIs.country ? `form-input-error` : ''}`}
                    placeholder="United States"
                    name="country"
                    value={country}
                    onChange={handleFunc.handleOnChange}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <select
                    className={`form-control ${errorBillingIs.stateIs ? `form-input-error` : ''}`}
                    id="exampleFormControlSelect2"
                    name="stateIs"
                    value={stateIs}
                    onChange={handleFunc.handleOnChange}
                  >
                    <option value="">Select state</option>
                    {StateList.length > 0 && StateList.map((obj, index) => (
                      <option value={obj.abbreviation} key={index}>{obj.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <select
                    className={`form-control ${errorBillingIs.city ? `form-input-error` : ''}`}
                    id="exampleFormControlSelect3"
                    placeholder="City"
                    name="city"
                    value={city}
                    onChange={handleFunc.handleOnChange}
                    disabled={cityList.length ? false : true}
                  >
                    <option value="">City</option>
                    {cityList.length > 0 && cityList.map((obj, index) => (
                      <option value={obj.city} key={index}>{obj.city}</option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-sm-6">
                <div className="form-group">
                  <input
                    type="text"
                    className={`form-control ${errorBillingIs.zip ? `form-input-error` : ''}`}
                    placeholder="Zip"
                    name="zip"
                    value={zip}
                    onChange={handleFunc.handleOnChange}
                  />
                </div>
              </div>
              <div className="col-sm-12">
              <button className="btn btn-primary" onClick={(e)=>{ handleFunc.handleTabChange(0)}}>
                  Prev
                        </button>
                <button className="btn btn-primary" onClick={handleFunc.handleBilling}>
                  Next
                        </button>
              </div>
            </div>
        
        </div>

        <style>
          {
            `.error{
                      color: 1px solid red;
                    }
                    `
          }
        </style>
      </React.Fragment>
    )
  }
}