import React from 'react';
import ReactPlayer from "react-player";
import "../../styles/traningVideo.css";

function TrainingVideo() {
  return (
  	<React.Fragment>
  	<div className="container">
    <div className="row">
        <div className="col-sm-12 col-md-12">
            <section className="video-grid">
            <div className="video-box">
                    <h4 className="text-capitalize mb-4">Traning Video <span>1</span></h4>                  
                    <iframe className="v-video" frameborder="0" allowfullscreen="1" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" title="YouTube video player" width="100%" height="100%" src="https://www.youtube.com/embed/zv8v5rzvzqU?autoplay=0&amp;mute=0&amp;controls=1&amp;origin=https://doctor.smartappointment.com&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;iv_load_policy=3&amp;modestbranding=1&amp;enablejsapi=1&amp;widgetid=1" id="widget2"></iframe>
                </div>
                <div className="video-box">
                    <h4 className="text-capitalize mb-4">Traning Video <span>2</span></h4>
                    <iframe className="v-video" frameborder="0" allowfullscreen="1" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" title="YouTube video player" width="100%" height="100%" src="https://www.youtube.com/embed/ntMIASzriZ4?autoplay=0&amp;mute=0&amp;controls=1&amp;origin=https://doctor.smartappointment.com&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;iv_load_policy=3&amp;modestbranding=1&amp;enablejsapi=1&amp;widgetid=3" id="widget4"></iframe>
                </div>

               
            </section>
        </div>
    </div>
   </div>

    </React.Fragment>
  );
}
 
export default TrainingVideo;