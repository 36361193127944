import React, { Component, PureComponent } from "react";
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  Tooltip,
  Legend,
} from "recharts";

const COLORS = [
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "#e1c699",
  "#666666",
];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
  value,
}) => {
  // const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  // const x = cx + radius * Math.cos(-midAngle * RADIAN);
  // const y = cy + radius * Math.sin(-midAngle * RADIAN);
  const RADIAN = Math.PI / 180;
  // eslint-disable-next-line
  const radius = 25 + innerRadius + (outerRadius - innerRadius);
  // eslint-disable-next-line
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  // eslint-disable-next-line
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  return (
    <text
      x={x}
      y={y}
      fill="#666666"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {value}
    </text>
  );
};

export default class Example extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidUpdate(prevProps){
    // console.log(this.props)
    if(
      prevProps.yourTotalAppointData !== this.props.yourTotalAppointData ||
      prevProps.totalConsultReferYou !== this.props.totalConsultReferYou ||
      prevProps.consultSentYouOther !== this.props.consultSentYouOther){
        return true
      }else{
        return
      }
  }
  render() {
    const {
      yourTotalAppointData,
      totalConsultReferYou,
      consultSentYouOther,
    } = this.props;
    const data = [
      { name: "Your appointments", value: yourTotalAppointData },
      { name: "Your Inbound Referrals", value: totalConsultReferYou },
      { name: "Your Outbound Referrals", value: consultSentYouOther },
    ];
    return (
      <div style={{position:'relative'}}>
        <ResponsiveContainer
          width="100%"
          height={350}
          className="cu-recharts-1"
        >
          <PieChart width={400} height={400}>
            <Pie
              data={data}
              cx="50%"
              cy="60%"
              label={renderCustomizedLabel}
              outerRadius={150}
              fill="#8884d8"
              dataKey="value"
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip />
            <Legend width={190} />
          </PieChart>
        </ResponsiveContainer>
        {!yourTotalAppointData &&
          !totalConsultReferYou &&
          !consultSentYouOther && <div className="no-data">No statistics found.</div>}
      </div>
    );
  }
}
