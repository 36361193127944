import React, { Component } from 'react';
import PatientName from './PatientName';
import StarRatingComponent from 'react-star-rating-component';
import ShowMoreText from 'react-show-more-text';



export default class DoctorReviewMobileView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            toggle: false
        }
    }

    handleToggle = () => {
        this.setState({ toggle: !this.state.toggle });
    }


    render() {

        let date = new Date(this.props.data.createdAt);
        let yy = date.getFullYear();
        let mm = String(date.getMonth() + 1).padStart(2, "0");
        let dd = date.getDate();
        let created_at = `${yy}/${mm}/${dd}`;

        return (
            <React.Fragment>
                <li className={this.state.toggle ? "active" : ""} >
                    <div onClick={this.handleToggle} className="title doc_review_title_box">
                        <PatientName patientId={this.props.data.patient_id} tag="doc_review" />
                        <div className="text-right" style={{ height: 'auto', width: '50%', fontWeight: 'bold' }}>
                            {created_at}
                            <span style={{ paddingLeft: 5 }}>
                                <img src={require('../assets/logo/down-arrow.png')}
                                    style={{
                                        height: 20, width: 20,
                                        WebkitTransform: this.state.toggle ? 'rotate(180deg)' : '',
                                        MozTransformStyle: this.state.toggle ? 'rotate(180deg)' : '',
                                        msTransform: this.state.toggle ? 'rotate(180deg)' : '',
                                        OTransform: this.state.toggle ? 'rotate(180deg)' : '',
                                        transform: this.state.toggle ? 'rotate(180deg)' : ''
                                    }}
                                />
                            </span>
                        </div>
                    </div>

                    <div className="content doc_review_mobile_content">
                        <table style={{ width: '100%' }}>
                            <tbody>
                                <tr>
                                    <th>Date of review</th>
                                    <td>{created_at}</td>
                                </tr>
                                <tr>
                                    <th>Patient Name</th>
                                    <PatientName patientId={this.props.data.patient_id} tag="td" />
                                </tr>
                                <tr>
                                    <th>Doctor Name</th>
                                   <td> {this.props.data.doctorName}</td>
                                </tr>
                                <tr>
                                    <th>Rating</th>
                                    <td>
                                        <StarRatingComponent
                                            name="userRating1"
                                            starCount={5}
                                            value={this.props.data.rating}
                                            starColor={'#ec8a19'}
                                            emptyStarColor={'lightgray'}

                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Review</th>
                                    <td>
                                        <ShowMoreText
                                            lines={1}
                                            more='Show more'
                                            less='Show less'
                                            anchorClass=''
                                            expanded={false}
                                        >
                                            {this.props.data.dec}
                                        </ShowMoreText>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </li>
            </React.Fragment>
        )
    }
}
