import React from 'react';
import '../styles/ProfileNotificationBar.css';
import { Link } from 'react-router-dom';

export const ProfileNotificationBar = (props) => {

    return (
        <React.Fragment>
            <div className="profile_notification_bar" style={{ display: !props.profileStatus || props.profileStatus === "0" ? 'block' : 'none' }}>
                <p>Complete your profile for a better experience. <Link to="/profile">View Profile.</Link></p>
            </div>
        </React.Fragment>
    )
}
