import React from "react";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux"; 
import { GetAPI } from "../../../../utils/Api-interface";
import {convertDayToDate} from "../../../../utils/DateMethod"
import * as moment from "moment"
class DailyFirstAppointment extends React.Component {
  state = {
    page: 1,
    limit: 15,
    dailyfirstappointmentdata:[],
    loading:false
  };
  getDailyFirstAppointmentdata= async(id,page,limit)=>{
    this.setState({loading:true})
    const response = await GetAPI(`api/consult_report/doctor_daily_first_app?doctor_uid=${id}&page=${page}&limit=${limit}`)
   if(response.status === 200){
    this.setState({dailyfirstappointmentdata:response.data, loading:false})
   }else{
     this.setState({loading:false})
   }
  
  }
  componentDidMount() {
    const uid = localStorage.getItem("uid");
    const { page, limit } = this.state;
   this.getDailyFirstAppointmentdata(uid,page,limit)
  }
  handlePageClick = (pages) =>{
    const uid = localStorage.getItem("uid");
    const { limit } = this.state;
    this.getDailyFirstAppointmentdata(uid, pages.selected + 1,limit)
    
  }
  render() {
    const { dailyfirstappointmentdata, loading } = this.state;
    // console.log(dailyfirstappointmentdata,'responsed')
    return (
      <div className="consults-sent">
        {!loading ? <div class="col-12 mt-3">
          <div className="doc_appointment-resp_table">
            <table className="table table-striped table-bordered" id="myTable">
            <thead
                style={{
                  background: "#70ad47",
                  color: "#FFF",
                }}
              >
                <tr>
                  <th scope="col">
                    <span
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        whiteSpace: "nowrap",
                      }}
                    >
                     MM/DD/YYYY
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        whiteSpace: "nowrap",
                      }}
                    >
                    Daily First Appointment
                    </span>
                  </th>
               
                </tr>
              </thead>
              <tbody>
                {/* {console.log(dailyfirstappointmentdata,'dailyfirstappointmentdata')} */}
                {dailyfirstappointmentdata?.data?.length ? ( 
                  dailyfirstappointmentdata?.data?.map((el) => {
                   const date = convertDayToDate(parseInt(el._id.daily),parseInt(el._id.year))
                    return (
                      <tr>
                        <td nowrap="nowrap">
                          {moment(new Date(date)).format("MM/DD/YYYY")}                        
                        </td>
                        <td nowrap="nowrap">{el.count}</td>
                        
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td className="text-center" colSpan={7}>
                      No data found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        :
        <div
        style={{
          height: "auto",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          alt="placeholder"
          src="https://luxysource.com/pub/static/frontend/Magento/luxysource/en_US/custom/img/preloader.gif"
          style={{ height: 80, width: 80 }}
        />
      </div>}

        {dailyfirstappointmentdata?.totalPages ? (
          <ReactPaginate
            previousLabel={"previous"}
            nextLabel={"next"}
            pageCount={dailyfirstappointmentdata.totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={this.handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
   // dailyfirstappointmentdata: state.dailyfirstappointmentdata,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
   // getAppointments: (data) =>
     // dispatch({ type: GET_MONTHLY_APPOINTMENTS_REQUEST, data }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DailyFirstAppointment);
