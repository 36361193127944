import React, { Component } from 'react';
import { handleTimeFormat } from '../utils/DateMethod';

export default class ConsultScheduleMobileView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            toggle: false,
            weekday: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            month: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
        }
    }

    handleToggle = () => {
        this.setState({ toggle: !this.state.toggle });
    }

    render() {

        let date = new Date();
        let currentTimes = String(date.getHours()) + String(date.getMinutes());

        let y = date.getFullYear();
        let m = String(date.getMonth() + 1).padStart(2, 0);
        let da = String(date.getDate()).padStart(2, "0");

        let currentDate = `${y}${m}${da}`;


        const year = this.props.data.date.slice(0, 4);
        const month = this.props.data.date.slice(4, 6);
        const day = this.props.data.date.slice(6, 8);

        const fullDate = new Date(`${year}-${month}-${day}`);
        let sday = this.state.weekday[fullDate.getUTCDay()];
        let fullMonth = this.state.month[fullDate.getUTCMonth()];


        return (
            <React.Fragment>
                <li className={this.state.toggle ? "active" : ""}>
                    <div onClick={this.handleToggle} className="title consult_schedule_mobile_view_title">
                        <div className="text-left" style={{ height: 'auto', width: '45%', fontWeight: 'bold' }}>{sday}</div>
                        <div className="text-right" style={{ height: 'auto', width: '50%', fontWeight: 'bold' }}>
                            {`${fullMonth}   ${day}, ${year}`}
                            <span style={{ paddingLeft: 1 }}>
                                <img alt='logo' src={require('../assets/logo/down-arrow.png')}
                                    style={{
                                        height: 20, width: 20,
                                        WebkitTransform: this.state.toggle ? 'rotate(180deg)' : '',
                                        MozTransformStyle: this.state.toggle ? 'rotate(180deg)' : '',
                                        msTransform: this.state.toggle ? 'rotate(180deg)' : '',
                                        OTransform: this.state.toggle ? 'rotate(180deg)' : '',
                                        transform: this.state.toggle ? 'rotate(180deg)' : ''
                                    }}
                                />
                            </span>
                        </div>
                    </div>
                    <div className="content schedule_mobile_view_content">
                        <div className="cunsult_schedule_btn_box_new">
                            {
                               
                                this.props.data.availability.map((value, i) => {

                                    if(value.offer_telemedicine==1){
                                     var background='#008b8b';
                                     var color='#FFFFFF';
                                    }
                                    if(value.offer_telemedicine==2){
                                      var background='#D3D3D3';
                                      var color='#47b475';
                                    }
                                     if(value.offer_telemedicine==0){
                                      var background='#47b475';
                                      var color='#FFFFFF';
                                    }
                                    return <button key={i} style={{background: background,color:color,}} className="btn btn-sm btn-rounded mb0" disabled={this.props.isClickTime || (currentDate === this.props.data.date ? parseInt(value.time) < parseInt(currentTimes) : false)} onClick={() => this.props.handleSetClientInScheduleTime(this.props.data, i)}>{handleTimeFormat(value.time)}</button>
                                })
                            }

                        </div>
                        <div className="wraper-box-inf w-100">
                                        <ul className="info-caln">
                                            <li>
                                                <div className="wrap-flx">
                                                    <div className="sqr-block bg-cl-one"></div> <span>Offer Telemedicine</span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="wrap-flx">
                                                    <div className="sqr-block bg-cl-two"></div> <span>Offer Non Telemedicine</span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="wrap-flx">
                                                    <div className="sqr-block bg-cl-three"></div> <span>Offer Telemedicine & Non Telemedicine Both</span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                    </div>
                </li>
            </React.Fragment>
        )
    }
}

