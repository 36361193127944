let initialState = {
  is_user: {},
  isAuth: false,
  doctorDetails: {},
  docReviews: [],
  isLoading: false,
  docTodaysAppintment: {},
  docUpcomingAppointment: {},
  docPastAppointment: {},
  searchDocResult: [],
  docList: [],
  zipResult: [],
  patientReviewData: [],
  patient_details: {},
  subscriptionData: null,
  transactionData: null,
  customerProfileData: null,
  consultsent: null,
  consultreceived: null,
  monthlyappointments: null,
  loginuserdetails: null,
  trailsubscription:null
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    /*------------------------------------doc-today-appointments to store--------------------------*/

    case "DOC_TODAY_APPOINTMENT_REQUEST": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "DOC_TODAY_APPOINTMENT_SUCCESS_REQUEST": {
      return {
        ...state,
        docTodaysAppintment: action.docTodayAppoints,
        isLoading: false,
      };
    }
    case "DOC_TODAY_APPOINTMENT_FAILURE_REQUEST": {
      return {
        ...state,
        docTodaysAppintment: action.error,
        isLoading: false,
      };
    }

    /*------------------------------------doc-upcoming-appointments to store--------------------------*/

    case "DOC_UPCOMING_APPOINTMENT_REQUEST": {
      return {
        ...state,
        isLoading: false,
      };
    }
    case "DOC_UPCOMING_APPOINTMENT_SUCCESS_REQUEST": {
      return {
        ...state,
        docUpcomingAppointment: action.docUpcomingAppoints,
        isLoading: false,
      };
    }
    case "DOC_UPCOMING_APPOINTMENT_FAILURE_REQUEST": {
      return {
        ...state,
        docUpcomingAppointment: action.error,
        isLoading: false,
      };
    }

    /*------------------------------------doc-past-appointments to store--------------------------*/

    case "DOC_PAST_APPOINTMENT_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "DOC_PAST_APPOINTMENT_SUCCESS_REQUEST": {
      return {
        ...state,
        docPastAppointment: action.docPastAppoints,
        isLoading: false,
      };
    }
    case "DOC_PAST_APPOINTMENT_FAILURE_REQUEST": {
      return {
        ...state,
        docPastAppointment: action.error,
        isLoading: false,
      };
    }

    /*------------------------------------save-doc-details-state-to store--------------------------*/
    case "GET_DOC_DETAILS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "GET_DOC_DETAILS_SUCCESS_REQUEST": {
      return {
        ...state,
        doctorDetails: action.docDetails,
        isLoading: false,
      };
    }
    case "GET_DOC_DETAILS_FAILURE_REQUEST": {
      return {
        ...state,
        doctorDetails: action.error,
        isLoading: false,
      };
    }

    /*------------------------------------save-doc-reviews-state-to store--------------------------*/

    case "GET_DOC_REVIEWS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "GET_DOC_REVIEWS_SUCCESS_REQUEST": {
      return {
        ...state,
        docReviews: action.docreview,
        isLoading: false,
      };
    }
    case "GET_DOC_REVIEWS_FAILURE_REQUEST": {
      return {
        ...state,
        docReviews: action.error,
        isLoading: false,
      };
    }

    /*---------------------------------------------doc-list----------------------------------------------*/

    case "GET_DOCTOR_LIST_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "GET_DOCTOR_LIST_SUCCESS_REQUEST": {
      return {
        ...state,
        docList: action.docList,
        isLoading: false,
      };
    }
    case "GET_DOCTOR_LIST_FAILURE_REQUEST": {
      return {
        ...state,
        docList: action.error,
        isLoading: false,
      };
    }

    /*---------------------------------------------save-doc-search-result-state----------------------------------------------*/

    case "SEARCH_DOC_SPECIALITY_REQUEST": {
      return {
        ...state,
      };
    }
    case "SEARCH_DOC_SPECIALITY_SUCCESS_REQUEST": {
      return {
        ...state,
        searchDocResult: action.docSearchResult,
      };
    }
    case "SEARCH_DOC_SPECIALITY_FAILURE_REQUEST": {
      return {
        ...state,
        searchDocResult: action.error,
      };
    }

    /*---------------------------------------------save-zip-search-result-state----------------------------------------------*/

    case "SEARCH_ZIP_REQUEST": {
      return {
        ...state,
      };
    }
    case "SEARCH_ZIP_SUCCESS_REQUEST": {
      return {
        ...state,
        zipResult: action.zipResult,
      };
    }
    case "SEARCH_ZIP_FAILURE_REQUEST": {
      return {
        ...state,
        zipResult: action.error,
      };
    }

    /*----------------------------------------------set-doc-review-data----------------------------------*/

    case "GET_PATINET_REVIEW_REQUEST": {
      return {
        ...state,
      };
    }
    case "GET_PATINET_REVIEW_SUCCESS_REQUEST": {
      return {
        ...state,
        patientReviewData: action.patient_reviewData,
      };
    }
    case "GET_PATINET_REVIEW_FAILURE_REQUEST": {
      return {
        ...state,
        patientReviewData: action.error,
      };
    }

    /*---------------------------------------------save-patient-details----------------------------------------------*/

    case "GETPATIENT_DETAILS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "GETPATIENT_DETAILS_SUCCESS_REQUEST": {
      return {
        ...state,
        patient_details: action.patientDetails,
        isLoading: false,
      };
    }
    case "GETPATIENT_DETAILS_FAILURE_REQUEST": {
      return {
        ...state,
        patient_details: action.error,
        isLoading: false,
      };
    }

    /*---------------------------------------------get-subscription-details----------------------------------------------*/

    case "GET_SUBSCRIPTION_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "GET_SUBSCRIPTION_SUCCESS_REQUEST": {
      return {
        ...state,
        subscriptionData: action.subscriptionData[0],
        isLoading: false,
      };
    }
    case "GET_SUBSCRIPTION_FAILURE_REQUEST": {
      return {
        ...state,
        subscriptionData: {},
        isLoading: false,
      };
    }

    /*---------------------------------------------get-transaction-details----------------------------------------------*/

    case "GET_TRANSACTION_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "GET_TRANSACTION_SUCCESS_REQUEST": {
      return {
        ...state,
        transactionData: action.transactionData,
        isLoading: false,
      };
    }
    case "GET_TRANSACTION_FAILURE_REQUEST": {
      return {
        ...state,
        transactionData: {},
        isLoading: false,
      };
    }
    /*---------------------------------------------get-customer-profile-details----------------------------------------------*/

    case "GET_CUSTOMER_PROFILE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "GET_CUSTOMER_PROFILE_SUCCESS_REQUEST": {
      return {
        ...state,
        customerProfileData: action.customerprofiledetails,
        isLoading: false,
      };
    }
    case "GET_CUSTOMER_PROFILE_FAILURE_REQUEST": {
      return {
        ...state,
        customerProfileData: {},
        isLoading: false,
      };
    }
    /*---------------------------------------------get-consult-sent-details----------------------------------------------*/

    case "GET_CONSULT_SENT_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "GET_CONSULT_SENT_SUCCESS_REQUEST": {
      return {
        ...state,
        consultsent: action.consultsent,
        isLoading: false,
      };
    }
    case "GET_CONSULT_SENT_FAILURE_REQUEST": {
      return {
        ...state,
        consultsent: null,
        isLoading: false,
      };
    }
    /*---------------------------------------------get-consult-received-details----------------------------------------------*/

    case "GET_CONSULT_RECEIVED_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "GET_CONSULT_RECEIVED_SUCCESS_REQUEST": {
      return {
        ...state,
        consultreceived: action.consultreceived,
        isLoading: false,
      };
    }
    case "GET_CONSULT_RECEIVED_FAILURE_REQUEST": {
      return {
        ...state,
        consultreceived: null,
        isLoading: false,
      };
    }

    /*---------------------------------------------get-monthly-appointments-details----------------------------------------------*/

    case "GET_MONTHLY_APPOINTMENTS_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "GET_MONTHLY_APPOINTMENTS_SUCCESS_REQUEST": {
      return {
        ...state,
        monthlyappointments: action.monthlyappointments,
        isLoading: false,
      };
    }
    case "GET_MONTHLY_APPOINTMENTS_FAILURE_REQUEST": {
      return {
        ...state,
        monthlyappointments: null,
        isLoading: false,
      };
    }

    /*---------------------------------------------get-login-user-details----------------------------------------------*/

    case "GET_LOGIN_USER_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "GET_LOGIN_USER_SUCCESS_REQUEST": {
      return {
        ...state,
        loginuserdetails: action.loginuserdetails,
        isLoading: false,
      };
    }
    case "GET_LOGIN_USER_FAILURE_REQUEST": {
      return {
        ...state,
        loginuserdetails: null,
        isLoading: false,
      };
    }

        /*---------------------------------------------get-login-user-details----------------------------------------------*/

        case "GET_TRAIL_SUBSCRIPTION_REQUEST": {
          return {
            ...state,
            isLoading: true,
          };
        }
        case "GET_TRAIL_SUBSCRIPTION_SUCCESS_REQUEST": {
          return {
            ...state,
            trailsubscription: action.trailsubscription,
            isLoading: false,
          };
        }
        case "GET_TRAIL_SUBSCRIPTION_FAILURE_REQUEST": {
          return {
            ...state,
            trailsubscription: null,
            isLoading: false,
          };
        }

    default:
      return state;
  }

  
};
