import React, { Component } from "react";
import ReportingTableRow from "../../../../component/ReportingTableRow";
import DatePicker from "react-date-picker";
import ReactPaginate from "react-paginate";
import { Baseurl } from "../../../../utils/Baseurl";
import MobileReportView from "../../../../component/MobileReportView";
import axios from "axios";



export default class InsurenceVerifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      from: new Date(new Date().toDateString()),
      to: new Date(new Date().toDateString()),
      start: "",
      end: "",
      insurance_status: "",
      doctor_id: "",
      reportResult: [],
      limit: 25,
      pageCount: 0,
      page: 1,
      loading: false,
      verifiedPatients: "",
      unVerifiedPatients: "",
      totalVerificaitonFee: "",
      pataintCount: "",
      totalAppDownload: "",
      totalappointData: "",
      totalconsultData: "",
      yourTotalAppointData: "",
      totalConsultReferYou: "",
      consultSentYouOther: [],
      specialitydata: [],
      subdrdata:[],
      groupdr:"",
      groupRole:7,
    };
  }

  componentDidMount() {
    this.handleGetDoctorDetails();
  }

  handleGetDoctorDetails = () => {
    let userId = localStorage.getItem("uid");
    this.setState({ loading: true });
    axios({
      method: "get",
      url: Baseurl + `api/doc/getDetails?userId=${userId}`,
      headers: { "content-type": "application/json" },
    })
      .then((res) => {
        // console.log("res----->",res);
        this.setState({ doctor_id: res.data.user._id,groupdr: res.data.user._id,groupRole:res.data.user.groupRole}, () => {
          this.handleGetReport(
            this.state.limit,
            this.state.page,
            res.data.user._id,
            this.state.start,
            this.state.end,
            this.state.insurance_status
          );
          // console.log(res,'resres')
          let data = {"refid":res.data.user.drLinkToGroup,grpuid:userId} 
           axios({
           method: "post",
           url: Baseurl + `api/doc/get_group_doctors`,
           headers: { "content-type": "application/json" },
           data,
           }).then((drdata) => {                           
            this.setState({ subdrdata: drdata.data.data });            
           })
          .catch((error) => {
            this.setState({ loading: false });
            console.log("error--->", error);
          });


        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.log("error--->", error);
      });
  };

  fromDateOnChange = (from) => {
    const fdate = ("0" + new Date(from).getDate()).slice(-2);
    const fmont = ("0" + (new Date(from).getMonth() + 1)).slice(-2);
    const fyears = new Date(from).getFullYear();
    const FromDate = `${fyears}-${fmont}-${fdate}`;

    this.setState({ from: from, to: from, start: FromDate, end: FromDate });
  };

  toDateOnchange = (toDate) => {
    const tdate = ("0" + new Date(toDate).getDate()).slice(-2);
    const tmont = ("0" + (new Date(toDate).getMonth() + 1)).slice(-2);
    const tyears = new Date(toDate).getFullYear();
    const ToDate = `${tyears}-${tmont}-${tdate}`;

    this.setState({ to: toDate, end: ToDate });
  };

  /*-----------------------------------------get-report----------------------------------------------*/

  handleGetReport = (limit, page, doc_id, from, to, status) => {
    this.setState({ loading: true });

    axios({
      method: "get",
      url:
        Baseurl +
        `api/report/get_report?insurance_status=${status}&doctor_id=${doc_id}&from_date=${from}&to_date=${to}&limit=${limit}&page=${page}`,
      headers: { "content-type": "application/json" },
    })
      .then((res) => {
        // console.log("ger-report-response----->",res.data);

        this.setState({
          reportResult: res.data.data,
          pageCount: res.data.upcomint_count / 25,
          loading: false,
          verifiedPatients: res.data.verfied,
          unVerifiedPatients: res.data.unVerfied,
          totalVerificaitonFee: res.data.totalCost,
          pataintCount: res.data.pataintCount,
          totalAppDownload: res.data.totalAppDownload,
          totalappointData: res.data.totalappointData,
          totalconsultData: res.data.totalconsultData,
          yourTotalAppointData: res.data.yourTotalAppointData,
          totalConsultReferYou: res.data.totalConsultReferYou,
          consultSentYouOther: res.data.consultSentYouOther,
          specialitydata: res.data.specialitydata,
        });
      })
      .catch((error) => {
        console.log("get-report-error-------->", error);
        this.setState({ loading: false });
      });
  };

  /*-----------------------------------------handleFilter .btn----------------------------------------------*/

  handleFilter = () => {
    this.handleGetReport(
      this.state.limit,
      this.state.page,
      this.state.doctor_id,
      this.state.start,
      this.state.end,
      this.state.insurance_status
    );
  };

  handlePageClick = (data) => {
    let selected = data.selected;
    this.setState({ page: selected + 1 }, () => {
      this.handleGetReport(
        this.state.limit,
        selected + 1,
        this.state.doctor_id,
        this.state.start,
        this.state.end,
        this.state.insurance_status
      );
    });
  };

  render() {

    const coins = Object.keys(this.state.specialitydata).map((key) => (
      <span key={`badge-${key}`}>
        <span className="badge badge-dark">
          {key} : {this.state.specialitydata[key]}{" "}
        </span>
        <span>&nbsp;&nbsp;</span>
      </span>
    ));

    return (
      <React.Fragment>
        <div className="main-container">
          {/* <p className="h3">Smart Appointment Statistics</p>
          <table className="table ">
            <tbody>
              <tr>
                <td width="15%">
                  <strong>Total Patients - </strong>
                </td>
                <td width="15%">{this.state.pataintCount}</td>
                <td width="30%">
                  <strong>Your Total appointments - </strong>
                </td>
                <td width="40%">{this.state.yourTotalAppointData}</td>
              </tr>
              <tr>
                <td>
                  <strong>Total App Downloads - </strong>
                </td>
                <td>{this.state.totalAppDownload}</td>
                <td>
                  <strong>Total Inbound Referrals- </strong>
                </td>
                <td>{this.state.totalConsultReferYou}</td>
              </tr>
              <tr>
                <td>
                  <strong>Total Consults - </strong>
                </td>
                <td>{this.state.totalconsultData}</td>
                <td>
                  <strong>
                    Total Outbound Referrals -{" "}
                  </strong>
                </td>
                <td>{this.state.consultSentYouOther}</td>
              </tr>
              <tr>
                <td>
                  <strong>Total Appointments - </strong>
                </td>
                <td>{this.state.totalappointData}</td>

                <td colSpan="2"> {coins}</td>
              </tr>
            </tbody>
          </table> */}
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <h4>Reporting</h4>
              <hr />
              <div className="filter_box">
                <div
                  className="col-lg-2 col-sm-12"
                  style={{ paddingLeft: 0, paddingBottom: 24 }}
                >
                  <label style={{ marginRight: "30px" }} htmlFor="from">
                    <strong>From</strong>
                  </label>
                  <DatePicker
                    className="react-date-picker width-100"
                    onChange={(e) => this.fromDateOnChange(e)}
                    value={this.state.start ? this.state.from : ""}
                    clearIcon={null}
                    format="MM-dd-y"
                    dayPlaceholder="dd"
                    monthPlaceholder="mm"
                    yearPlaceholder="yyyy"
                    // minDate={new Date()}
                  />
                </div>

                <div
                  className="col-lg-2 col-sm-12"
                  style={{ paddingLeft: 0, paddingBottom: 24 }}
                >
                  <label style={{ marginRight: 50 }} htmlFor="to">
                    <strong>To</strong>
                  </label>
                  <DatePicker
                    className="react-date-picker width-100"
                    onChange={(e) => this.toDateOnchange(e)}
                    value={this.state.end ? this.state.to : ""}
                    clearIcon={null}
                    format="MM-dd-y"
                    dayPlaceholder="dd"
                    monthPlaceholder="mm"
                    yearPlaceholder="yyyy"
                    minDate={this.state.from}
                  />
                </div>

                <div
                  className="col-lg-2 col-sm-12"
                  style={{ paddingLeft: 0, paddingBottom: 24 }}
                >
                  <label style={{ marginRight: "30px+" }} htmlFor="status">
                    <strong>Status</strong>
                  </label>
                  <select
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      this.setState({ insurance_status: e.target.value })
                    }
                  >
                    <option value="">All</option>
                    <option value="Active">Verified Patients</option>
                    <option value="none">Unverified Patients</option>
                  </select>
                </div>
               
               {this.state.groupRole===6 ?
                 <div
                  className="col-lg-2 col-sm-12"
                  style={{ paddingLeft: 0, paddingBottom: 24 }}
                >
                  <label style={{ marginRight: "30px+" }} htmlFor="status">
                    <strong>Select Doctor</strong>
                  </label>
                  <select
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      this.setState({ doctor_id: e.target.value })
                    }
                  >
                  <option value={this.state.groupdr}>All</option>  
                 {this.state.subdrdata.map((dr, key) => {
                   return (
                    <option value={dr._id}>{dr.first_name} {dr.last_name}</option>
                     );       
                 })}
                  </select>
                </div>: ''}

                <div
                  className="col-lg-3 col-sm-12"
                  style={{ paddingLeft: 0, paddingBottom: 24 }}
                >
                  <label
                    style={{ marginRight: "30px+" }}
                    htmlFor="filter"
                  ></label>
                  <br />
                  <button className="btn" onClick={this.handleFilter}>
                    {this.state.loading && (
                      <span
                        className="reporting_spinner-border text-light mr8"
                        role="status"
                      />
                    )}
                    Filter
                  </button>
                </div>
              </div>
            </div>

            {/* ------------------------------status-------------------------------------------- */}

            <div className="col-md-12 col-sm-12 reporting_status">
              <p>In This Period</p>
              <div
                className="col-md-4 col-sm-4 col-xs-12"
                style={{ padding: 0, marginBottom: 20 }}
              >
                <h5>
                  Verified Patients -{" "}
                  <span className="bold">{this.state.verifiedPatients}</span>
                </h5>
              </div>
              <div
                className="col-md-4 col-sm-4 col-xs-12"
                style={{ padding: 0, marginBottom: 20 }}
              >
                <h5>
                  Unverified Patients -{" "}
                  <span className="bold">{this.state.unVerifiedPatients}</span>
                </h5>
              </div>
              <div
                className="col-md-4 col-sm-4 col-xs-12"
                style={{ padding: 0, marginBottom: 20 }}
              >
                <h5>
                  Total Verification Fees -{" "}
                  <span className="bold">
                    ${this.state.totalVerificaitonFee}
                  </span>
                </h5>
              </div>
            </div>

            <div className="col-md-12 col-sm-12" style={{ marginBottom: 15 }}>
              {this.state.loading ? (
                <div
                  style={{
                    height: "auto",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    alt="placeholder"
                    src="https://luxysource.com/pub/static/frontend/Magento/luxysource/en_US/custom/img/preloader.gif"
                    style={{ height: 80, width: 80 }}
                  />
                </div>
              ) : this.state.reportResult.length === 0 ? (
                <h4 className="text-center bold">No Result</h4>
              ) : (
                <React.Fragment>
                  {/*---------------------reporting-for-mobile-view------------------------*/}

                  <ul className="accordion accordion-1 insurance_report_forMobile">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "nowrap",
                        width: "100%",
                        height: "auto",
                        padding: 10,
                      }}
                    >
                      <div
                        className="text-left"
                        style={{
                          height: "auto",
                          width: "33.3%",
                          fontWeight: "bold",
                        }}
                      >
                        Date
                      </div>
                      <div
                        className="text-center"
                        style={{
                          height: "auto",
                          width: "33.3%",
                          fontWeight: "bold",
                        }}
                      >
                        Patient Name
                      </div>
                      <div
                        className="text-right"
                        style={{
                          height: "auto",
                          width: "25%",
                          fontWeight: "bold",
                        }}
                      >
                        Insurance Status
                      </div>
                    </div>

                    {this.state.reportResult.map((value, key) => {
                      return <MobileReportView key={key} data={value} />;
                    })}
                  </ul>

                  {/*---------------------reporting-table-for-web-view------------------------*/}

                  <table className="table table-striped reporting_resp_table">
                    <thead
                      className="thead-dark"
                      style={{
                        background: "#70ad47",
                        color: "#FFF",
                      }}
                    >
                      <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Patient Name</th>
                         <th scope="col">Provider Name</th>
                        <th scope="col"> Insurance</th>
                        <th scope="col"> Status</th>
                        <th scope="col">Membership ID</th>
                        <th scope="col"> Verification Fees</th>
                      </tr>
                    </thead>

                    <tbody>
                      {this.state.reportResult.map((value, key) => {
                        return <ReportingTableRow key={key} data={value} />;
                      })}
                    </tbody>
                  </table>
                </React.Fragment>
              )}
            </div>

            {this.state.reportResult.length === 0 ? null : (
              <React.Fragment>
                <div
                  className="pagination_container col-md-12"
                  style={{ textAlign: "center", marginBottom: 24 }}
                >
                  <ReactPaginate
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    pageCount={this.state.pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
