/*----------------------------------------------Get-Doctors-Details-action----------------------------------*/

export const GET_DOC_DETAILS_REQUEST = "GET_DOC_DETAILS_REQUEST";
export const GET_DOC_DETAILS_SUCCESS_REQUEST = "GET_DOC_DETAILS_SUCCESS_REQUEST";
export const GET_DOC_DETAILS_FAILURE_REQUEST = "GET_DOC_DETAILS_FAILURE_REQUEST";

/*----------------------------------------------Get-Doctors-revies given by user-action----------------------------------*/

export const GET_DOC_REVIEWS_REQUEST = "GET_DOC_REVIEWS_REQUEST";
export const GET_DOC_REVIEWS_SUCCESS_REQUEST = "GET_DOC_REVIEWS_SUCCESS_REQUEST";
export const GET_DOC_REVIEWS_FAILURE_REQUEST = "GET_DOC_REVIEWS_FAILURE_REQUEST";




/*----------------------------------------------Get-Doctors-today-appointments----------------------------------*/

export const DOC_TODAY_APPOINTMENT_REQUEST = "DOC_TODAY_APPOINTMENT_REQUEST";
export const DOC_TODAY_APPOINTMENT_SUCCESS_REQUEST = "DOC_TODAY_APPOINTMENT_SUCCESS_REQUEST";
export const DOC_TODAY_APPOINTMENT_FAILURE_REQUEST = "DOC_TODAY_APPOINTMENT_FAILURE_REQUEST";



/*------------------------------------------Get-Doctors-upcoming-appointments----------------------------------*/

export const DOC_UPCOMING_APPOINTMENT_REQUEST = "DOC_UPCOMING_APPOINTMENT_REQUEST";
export const DOC_UPCOMING_APPOINTMENT_SUCCESS_REQUEST = "DOC_UPCOMING_APPOINTMENT_SUCCESS_REQUEST";
export const DOC_UPCOMING_APPOINTMENT_FAILURE_REQUEST = "DOC_UPCOMING_APPOINTMENT_FAILURE_REQUEST";


/*----------------------------------------Get-Doctors-past-appointments-----------------------------------------*/

export const DOC_PAST_APPOINTMENT_REQUEST = "DOC_PAST_APPOINTMENT_REQUEST";
export const DOC_PAST_APPOINTMENT_SUCCESS_REQUEST = "DOC_PAST_APPOINTMENT_SUCCESS_REQUEST";
export const DOC_PAST_APPOINTMENT_FAILURE_REQUEST = "DOC_PAST_APPOINTMENT_FAILURE_REQUEST";





/*----------------------------------------------get-doc-list----------------------------------*/

export const GET_DOCTOR_LIST_REQUEST = "GET_DOCTOR_LIST_REQUEST";
export const GET_DOCTOR_LIST_SUCCESS_REQUEST = "GET_DOCTOR_LIST_SUCCESS_REQUEST";
export const GET_DOCTOR_LIST_FAILURE_REQUEST = "GET_DOCTOR_LIST_FAILURE_REQUEST";

/*----------------------------------------------search-doctors-and-speciality-actions----------------------------------*/

export const SEARCH_DOC_SPECIALITY_REQUEST = "SEARCH_DOC_SPECIALITY_REQUEST";
export const SEARCH_DOC_SPECIALITY_SUCCESS_REQUEST = "SEARCH_DOC_SPECIALITY_SUCCESS_REQUEST";
export const SEARCH_DOC_SPECIALITY_FAILURE_REQUEST = "SEARCH_DOC_SPECIALITY_FAILURE_REQUEST";

/*----------------------------------------------search-zip--actions----------------------------------*/

export const SEARCH_ZIP_REQUEST = "SEARCH_ZIP_REQUEST";
export const SEARCH_ZIP_SUCCESS_REQUEST = "SEARCH_ZIP_SUCCESS_REQUEST";
export const SEARCH_ZIP_FAILURE_REQUEST = "SEARCH_ZIP_FAILURE_REQUEST";

/*----------------------------------------------get-patients-review-data----------------------------------*/

export const GET_PATINET_REVIEW_REQUEST = "GET_PATINET_REVIEW_REQUEST";
export const GET_PATINET_REVIEW_SUCCESS_REQUEST = "GET_PATINET_REVIEW_SUCCESS_REQUEST";
export const GET_PATINET_REVIEW_FAILURE_REQUEST = "GET_PATINET_REVIEW_FAILURE_REQUEST";


/*----------------------------------------------get-user-details----------------------------------*/

export const GETPATIENT_DETAILS_REQUEST = "GETPATIENT_DETAILS_REQUEST";
export const GETPATIENT_DETAILS_SUCCESS_REQUEST = "GETPATIENT_DETAILS_SUCCESS_REQUEST";
export const GETPATIENT_DETAILS_FAILURE_REQUEST = "GETPATIENT_DETAILS_FAILURE_REQUEST";

/*----------------------------------------------get-subscription-details----------------------------------*/

export const GET_SUBSCRIPTION_REQUEST = "GET_SUBSCRIPTION_REQUEST";
export const GET_SUBSCRIPTION_SUCCESS_REQUEST = "GET_SUBSCRIPTION_SUCCESS_REQUEST";
export const GET_SUBSCRIPTION_FAILURE_REQUEST = "GET_SUBSCRIPTION_FAILURE_REQUEST";

/*----------------------------------------------get-transaction-details----------------------------------*/

export const GET_TRANSACTION_REQUEST = "GET_TRANSACTION_REQUEST";
export const GET_TRANSACTION_SUCCESS_REQUEST = "GET_TRANSACTION_SUCCESS_REQUEST";
export const GET_TRANSACTION_FAILURE_REQUEST = "GET_TRANSACTION_FAILURE_REQUEST";
/*----------------------------------------------get-transaction-details----------------------------------*/

export const GET_CUSTOMER_PROFILE_REQUEST = "GET_CUSTOMER_PROFILE_REQUEST";
export const GET_CUSTOMER_PROFILE_SUCCESS_REQUEST = "GET_CUSTOMER_PROFILE_SUCCESS_REQUEST";
export const GET_CUSTOMER_PROFILE_FAILURE_REQUEST = "GET_CUSTOMER_PROFILE_FAILURE_REQUEST";

/*----------------------------------------------get-consult-sent-details----------------------------------*/

export const GET_CONSULT_SENT_REQUEST = "GET_CONSULT_SENT_REQUEST";
export const GET_CONSULT_SENT_SUCCESS_REQUEST = "GET_CONSULT_SENT_SUCCESS_REQUEST";
export const GET_CONSULT_SENT_FAILURE_REQUEST = "GET_CONSULT_SENT_FAILURE_REQUEST";

/*----------------------------------------------get-consult-received-details----------------------------------*/

export const GET_CONSULT_RECEIVED_REQUEST = "GET_CONSULT_RECEIVED_REQUEST";
export const GET_CONSULT_RECEIVED_SUCCESS_REQUEST = "GET_CONSULT_RECEIVED_SUCCESS_REQUEST";
export const GET_CONSULT_RECEIVED_FAILURE_REQUEST = "GET_CONSULT_RECEIVED_FAILURE_REQUEST";

/*----------------------------------------------get-consult-received-details----------------------------------*/

export const GET_MONTHLY_APPOINTMENTS_REQUEST = "GET_MONTHLY_APPOINTMENTS_REQUEST";
export const GET_MONTHLY_APPOINTMENTS_SUCCESS_REQUEST = "GET_MONTHLY_APPOINTMENTS_SUCCESS_REQUEST";
export const GET_MONTHLY_APPOINTMENTS_FAILURE_REQUEST = "GET_MONTHLY_APPOINTMENTS_FAILURE_REQUEST";

/*----------------------------------------------get-login-user-details----------------------------------*/

export const GET_LOGIN_USER_REQUEST = "GET_LOGIN_USER_REQUEST";
export const GET_LOGIN_USER_SUCCESS_REQUEST = "GET_LOGIN_USER_SUCCESS_REQUEST";
export const GET_LOGIN_USER_FAILURE_REQUEST = "GET_LOGIN_USER_FAILURE_REQUEST";

/*----------------------------------------------get-trail-subscription-details----------------------------------*/

export const GET_TRAIL_SUBSCRIPTION_REQUEST = "GET_TRAIL_SUBSCRIPTION_REQUEST";
export const GET_TRAIL_SUBSCRIPTION_SUCCESS_REQUEST = "GET_TRAIL_SUBSCRIPTION_SUCCESS_REQUEST";
export const GET_TRAIL_SUBSCRIPTION_FAILURE_REQUEST = "GET_TRAIL_SUBSCRIPTION_FAILURE_REQUEST";