import React from "react";
import Reporting from "./panels/reporting";
import AdminReporting from "./panels/admin-reporting";
import ProviderDetails from "./panels/admin-reporting/provider-details";
import ConsultsReceived from "./panels/consults-received";
import ConsultsReceivedGroup from "./panels/admin-reporting/consults-received-group";
import ConsultsSent from "./panels/consults-sent"; 
import ConsultsSentGroup from "./panels/admin-reporting/consults-sent-group"
import MonthlyCancelledAppoinments from "./panels/monthly-cancelled-appoinments";
import MonthlyCancelledAppoinmentsGroup from "./panels/admin-reporting/monthly-cancelled-appoinments-group"
import ConsultCancelledIncommingGroup from "./panels/admin-reporting/consults-cancelled-incomming-group"
import MonthlyVolume from "./panels/monthly-volume";
import MonthlyNoShow from "./panels/monthly-noshow";
import DailyFirstAppointment from "./panels/daily-first-appointment";
import DailyFirstAppointmentGroup from "./panels/admin-reporting/daily-first-appointment-group";
import MonthlyVolumeGroup from "./panels/admin-reporting/monthly-volume-group"
import InsurenceVerifications from "./panels/insurence-verifications";
import { connect } from "react-redux";
import axios from "axios"
import { Baseurl } from "../../../utils/Baseurl";
import ConsultsCancelledIncomming from "./panels/consults-cancelled-incomming";
import ConsultsCancelledOutgoing from "./panels/consults-cancelled-outgoing";
import ConsultCancelledOutgoingGroup from "./panels/admin-reporting/consults-cancelled-outgoing-group"
import MonthlyNoshowGroup from "./panels/admin-reporting/monthly-noshow-group";
class ReportingWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
      subdrdata:null
    };
  }
  handleTabChange = (activeTab) => {
    this.setState({ activeTab });
  };
  handleGetDoctorDetails = () => {
    let userId = localStorage.getItem("uid");
    axios({
      method: "get",
      url: Baseurl + `api/doc/getDetails?userId=${userId}`,
      headers: { "content-type": "application/json" },
    })
      .then((res) => {
        let data = { refid: res.data.user.drLinkToGroup, grpuid: userId };
            axios({
              method: "post",
              url: Baseurl + `api/doc/get_group_doctors`,
              headers: { "content-type": "application/json" },
              data,
            })
              .then((drdata) => {
                  const subdrdata = drdata.data.data;
                  console.log('ffffffffffffff',subdrdata)
                this.setState({ subdrdata });
              })
              .catch((error) => {
                console.log("error--->", error);
              });
      })
      .catch((error) => {
        console.log("error--->", error);
      });
  };
  componentDidMount(){
    this.handleGetDoctorDetails()
  }
  render() {
    const { activeTab } = this.state;
    const tabs = [
      {
        name: "Overview",
        component: <Reporting isGroupDoctor={false}/>,
      },
      {
        name: "Monthly Volume",
        component: <MonthlyVolume isGroupDoctor={false}/>,
      },
      {
        name: "Monthly No Show",
        component: <MonthlyNoShow isGroupDoctor={false}/>,
      },
      {
        name: "Daily First Appointment",
        component: <DailyFirstAppointment isGroupDoctor={false}/>,
      },
      {
        name: "Consults Sent",
        component: <ConsultsSent isGroupDoctor={false}/>,
      },
      {
        name: "Consults Received",
        component: <ConsultsReceived isGroupDoctor={false}/>,
      },
      // {
      //   name: "Monthly Cancelled Appoinments",
      //   component: <MonthlyCancelledAppoinments isGroupDoctor={false}/>,
      // },
      // {
      //   name: "Cancelled Incomming",
      //   component: <ConsultsCancelledIncomming isGroupDoctor={false}/>,
      // },
      // {
      //   name: "Cancelled Outgoing",
      //   component: <ConsultsCancelledOutgoing isGroupDoctor={false}/>,
      // },   
      {
        name: "Insurance Verifications",
        component: <InsurenceVerifications isGroupDoctor={false}/>,
      },
    ];
    const tabsforadmin = [
      {
        name: "Overview",
        component: <AdminReporting/>,
      },
      {
        name: "Monthly Volume",
        component: <MonthlyVolumeGroup isGroupDoctor={true} subdrdata={this.state.subdrdata}/>,
      },
      {
        name: "Monthly No Show",
        component: <MonthlyNoshowGroup isGroupDoctor={true} subdrdata={this.state.subdrdata}/>,
      },
      {
        name: "Daily First Appointment",
        component: <DailyFirstAppointmentGroup isGroupDoctor={false} subdrdata={this.state.subdrdata}/>,
      },
      {
        name: "Consults Sent",
        component: <ConsultsSentGroup isGroupDoctor={true} subdrdata={this.state.subdrdata}/>,
      },
      {
        name: "Consults Received",
        component: <ConsultsReceivedGroup isGroupDoctor={true} subdrdata={this.state.subdrdata}/>,
      },    
      // {
      //   name: "Monthly Cancelled Appoinments",
      //   component: <MonthlyCancelledAppoinmentsGroup isGroupDoctor={true} subdrdata={this.state.subdrdata}/>,
      // },
      // {
      //   name: "Cancelled Incomming",
      //   component: <ConsultCancelledIncommingGroup isGroupDoctor={true} subdrdata={this.state.subdrdata}/>,
      // },
      // {
      //   name: "Cancelled Outgoing",
      //   component: <ConsultCancelledOutgoingGroup isGroupDoctor={true} subdrdata={this.state.subdrdata}/>,
      // },
      {
        name: "Insurance Verifications",
        component: <InsurenceVerifications />,
      },
      {
        name: "Provider Chart",
        component: <ProviderDetails />,
      },
    ];

    let tabstoshow = this.props.doctordetails.groupRole === 6 && !localStorage.getItem("Gruid") ? tabsforadmin : tabs;
    return (
      <div className="reporting-wrapper">        
        <div className="row">
          <div className="col-md-12">
            <ul className="nav nav-tabs nav-overflow header-tabs">
              {tabstoshow && tabstoshow.map((el, i) => {
                return (
                  <li className={`nav-item ${activeTab === i ? `active` : ``}`}>
                    <a
                      className="nav-link"
                      onClick={() => this.handleTabChange(i)}
                    >
                      {el.name}
                    </a>
                  </li>
                );
              })}
            </ul>
            <div className="tab-content">
              {tabstoshow && tabstoshow.map((el, i) => {
                return (
                  <React.Fragment>
                    {activeTab === i ?  <div
                    className={`tab-pane ${activeTab === i ? `active` : ``}`}
                  >
                    <div className="row">
                      <div className="col-sm-12">{el.component}</div>
                    </div>
                  </div> : null}
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    doctordetails: state.doctorDetails 
  };
};
// const mapDispatchToProps = (dispatch) => {
//   return {
//     getTransactionDetails: (data) =>
//       dispatch({ type: GET_TRANSACTION_REQUEST, data }),
//     getTrailSubscriptionData: (data) =>
//       dispatch({ type: GET_TRAIL_SUBSCRIPTION_REQUEST, data }),
//   };
// };
export default connect(mapStateToProps, null)(ReportingWrapper);
