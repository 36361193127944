import { call, put } from "redux-saga/effects";
import axios from "axios";
import {
  GET_CUSTOMER_PROFILE_SUCCESS_REQUEST,
  GET_DOCTOR_LIST_FAILURE_REQUEST,
} from "../../actions/Action";
import { Baseurl } from "../../utils/Baseurl";

export function getCustomerProfileRequest(url) {
  let headers = {
    "Content-Type": "application/json",
    Pragma: "no-cache",
  };
  return axios({
    method: "get",
    url: Baseurl + url,
    headers,
  });
}

export function* getCustomerProfileDetails(action) {
  try {
    const response = yield call(
      getCustomerProfileRequest,
      `api/subscription/get_customer_profile?customerProfileId=${action.data}`
    );
    const customerprofiledetails = response.data.data;

    // dispatch a success action to the store with the new data
    yield put({ type: GET_CUSTOMER_PROFILE_SUCCESS_REQUEST, customerprofiledetails });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_DOCTOR_LIST_FAILURE_REQUEST, error });
  }
}
