import React, { Component } from "react";

export default class ReportingTableRow extends Component {
  render() {
    let createDate = new Date(this.props.data.created_at);
    let mm = String(createDate.getMonth() + 1).padStart(2, "0");
    let dd = String(createDate.getDate()).padStart(2, "0");
    let yy = String(createDate.getFullYear());

    let createdFullDate = `${mm}/${dd}/${yy}`;

    let user_name = this.props.data.user
      ? `${this.props.data.user.first_name} ${this.props.data.user.last_name}`
      : "";

   let doctor_name = this.props.data.doctor
      ? `${this.props.data.doctor.first_name} ${this.props.data.doctor.last_name}`
      : "";

    let medicaiddata =
      this.props.data.isMedicaidInsurance == 1 ? "(Medicaid)" : "";

    return (
      <React.Fragment>
        <tr>
          <th scope="row">{createdFullDate}</th>
          <td>{user_name}</td>
           <td>{doctor_name}</td>
          <td>
            {this.props.data.payerName} {medicaiddata}
          </td>
          <td>
            {this.props.data.insurance_status === "none"
              ? "Un-Verified"
              : "Verified"}
          </td>
          <td>{this.props.data.MembershipId}</td>
          <td>${this.props.data.verification_cost}</td>
        </tr>
      </React.Fragment>
    );
  }
}
