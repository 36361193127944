import { call, put } from "redux-saga/effects";
import axios from "axios";
import {
  GET_CONSULT_RECEIVED_SUCCESS_REQUEST,
  GET_CONSULT_RECEIVED_FAILURE_REQUEST,
} from "../../actions/Action";
import { Baseurl } from "../../utils/Baseurl";

export function getConsultReceivedRequest(url) {
  let headers = {
    "Content-Type": "application/json",
    Pragma: "no-cache",
  };
  return axios({
    method: "get",
    url: Baseurl + url,
    headers,
  });
}

export function* getConsultReceivedDetails(action) {
  try {
    const response = yield call(
      getConsultReceivedRequest,
      `api/consult_report/doctor_consult_received?doctor_uid=${action.data.doctor_uid}&filetr_doc_id=${action.data.provider}&page=${action.data.page}&limit=${action.data.limit}&date_from=${action.data.from}&date_to=${action.data.to}`
    );
    const consultreceived = response.data;

    // dispatch a success action to the store with the new data
    yield put({ type: GET_CONSULT_RECEIVED_SUCCESS_REQUEST, consultreceived });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_CONSULT_RECEIVED_FAILURE_REQUEST, error });
  }
}
