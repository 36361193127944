import React, { Component } from 'react';
// import PatientName from "../component/PatientName";
import { handleTimeFormat } from "../utils/DateMethod";
import axios from 'axios';
import { Baseurl } from '../utils/Baseurl';
import queryString from "query-string";
import { formatPhoneNumber } from 'react-phone-number-input'
import Modal from 'react-modal';

const customStyles = {
    content: {
        top: '48%',
        left: '50%',
        right: 'auto',
        bottom: '-30%',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    }
};

Modal.setAppElement('#root')
Modal.defaultStyles.overlay.backgroundColor = 'rgba(111, 111, 111, 0.75)';

export default class DocAppointmentRow extends Component {
    state = {
        action: 'none',
        patientName: '',
        patientEmail: '',
        patientMobile: '',
    };

    /*---------------------------------------------handle-Action-Onchange--------------------------------------*/

    handleActionOnChange = (value) => {
        const { action } = this.state;

        if (action === 'Confirm') {
            this.props.handlePatientConfirmButton(value);
        }
        else if (action === 'Re-Schedule') {
            this.props.setScheduleData(value);
        }
        else if (action === 'Cancel') {
            this.props.handlePatientCancelButton(value);
        }
    };


    UNSAFE_componentWillReceiveProps(props) {
        // console.log('props +++++', props)
        if (props.value) {
            this.handleGetPatientDetails(props.value);
        }

    }

    handleGetPatientDetails = (propdata) => {

        this.setState({ patientName: `${propdata.patient_name}`, patientEmail: propdata.patient_email, patientMobile: propdata.patient_mobile });
        /*axios({
            method: 'get',
            url: Baseurl + `api/user/getuserdetails?userId=${id}`,
            headers: { 'content-type': 'application/json' }
        }).then((res) => {
          
            this.setState({ patientName: `${res.data.user.first_name} ${res.data.user.last_name}`, patientEmail: res.data.user.email, patientMobile: res.data.user.mobile });
        }).catch((error) => {
            console.log("in-error---->", error);
        })*/
    }



    handleUpcomingCancelButton = (data) => {

        data.patientEmail = this.state.patientEmail;
        data.patientName = this.state.patientName;
        data.patientMobile = this.state.patientMobile;
        const modalFor = 'Cancel';
        this.props.setModalState(modalFor, data);
    }

    handleUpcomingConsultsButton = (data) => {
        this.props.history.push(`/searchdoc?name=&practice_name=&insurance_provider=&zip=&patient=${data.patient_id}&m=${data.patient_Medicaid}`);
    }
    handleNoShowButton = (data) => {
        // console.log('no show', data)
        //  let id = localStorage.getItem('uid');
        // this.props.handleGetDocDetails(id);
        const modalFor = "NoShow"
        this.props.setModalState(modalFor, data);


    }

    render() {

        const { value, location, upcoming } = this.props;
        let param = queryString.parse(location.search);

        // const [id, ,] = value.statusBy.split('&');
        const year = value.date.slice(0, 4);
        const month = value.date.slice(4, 6);
        const day = value.date.slice(6, 8);
        const newDate = `${month}/${day}/${year}`;
        let locationName = this.props ? this.props.docPractice.filter(data => data ? data._id === value.practice_id : '') : [];
        let pmobile = formatPhoneNumber(value.patient_mobile);
        let dmobile = formatPhoneNumber(value.doctor_mobile);
        const groupRole = this.props.grouprole;
        //let medicaid = this.props.medicaidValue;

        //console.log('propssssss',this.props);
        return (
            <React.Fragment>
                <tr style={{ background: value.date === param.date && value.time === param.time ? '#e0e0e0' : '' }}>
                    <td nowrap="nowrap">
                        <h5 style={{ border: '1px dotted #47b475', padding: '5px', backgroundColor: '#ECEAE0' }}>Appointment Date :<br />{newDate} {handleTimeFormat(value.time)}</h5>
                        <p style={{ border: '1px dotted #47b475', padding: '5px', marginTop: '7px' }}>Date Created :<br />{new Date(value.createdAt).toLocaleString()}</p>
                    </td>

                    <td nowrap="nowrap">

                        <h5>{value.newPatient === 1 ? <img alt='new' src={require('../assets/img/new1.png')} rounded={true} /> : ''} {value.patient_name}</h5>
                        {pmobile === '' ? '' : <span>{pmobile} |</span>} <span style={{ color: value.status === 'Confirmed' ? 'green' : 'tomato' }}>{value.status}</span>
                        <br />
                        <span><a href={`mailto:${value.patient_email}`}>{value.patient_email}</a>
                        </span>
                    </td>

                    <td>
                        <h5>{value.payment_mode} {value.patient_Medicaid == 1 && value.payment_mode !== 'cash' ? '(Medicaid)' : ''}</h5>
                        {value.payment_mode === 'cash' ? null : <p>{value.patient_memberId} | {value.patient_dob}</p>}
                    </td>

                    <td>
                        <h5>{value.doctor_name}</h5>
                        {dmobile == '' ? '' : <span>{dmobile} </span>}
                        {
                            locationName.map(value => {
                                return <React.Fragment>
                                    <h5>{value.practice_name}</h5>
                                    <p>{value.practice_location}, {value.zip}</p>
                                </React.Fragment>
                            })
                        }
                    </td>


                    <td>
                        <h5 className="text-center">{value.consult_from}</h5>
                    </td>


                    <td nowrap="nowrap">
                        {groupRole === 7 || groupRole === 8 ?
                            <button className="btn btn-teal btn-rounded btn-sm m-0" onClick={() => this.handleUpcomingConsultsButton(value)} style={{ opacity: value.status === 'Cancelled' ? '0.5' : '1', background: '#47b475', color: '#fff', marginBottom: 0, paddingLeft: 3, paddingRight: 3 }} disabled={value.status === 'Cancelled'} >Consults</button> : ''}
                        {groupRole === 7 || groupRole === 8 ?
                            <button className="btn btn-teal btn-rounded btn-sm m-0" onClick={this.props.handleGetInsuranceDetails} style={{ background: '#47b475', color: '#fff', marginBottom: 0, paddingLeft: 3, paddingRight: 3 }} >Deductible</button> : ''}
                        {
                            upcoming ?
                                <button
                                    className="btn btn-teal btn-rounded btn-sm m-0"
                                    disabled={value.status === 'Cancelled'}
                                    onClick={() => this.handleUpcomingCancelButton(value)}
                                    style={{ opacity: value.status === 'Cancelled' ? '0.5' : '1', background: '#47b475', marginBottom: 0, height: 25, padding: 5, lineHeight: 0, color: '#fff' }}
                                >Cancel</button> : 
                                <>
                                <br></br><br></br>
                                 <button className="btn btn-teal btn-rounded btn-sm m-0"
                                    disabled={value.status === 'Confirmed' ? false : true}
                                    onClick={() => this.handleNoShowButton(value)}
                                    style={{ opacity: value.status === 'Confirmed' ? 1 : 0.5, background: '#47b475', marginBottom: 0, color: '#fff', paddingLeft: 3, paddingRight: 3 }}
                                >No Show</button>
                                </>
                                }

                    </td>


                </tr>


                {/* <div className="doc_appointment-resp_table_row" style={{ background: value.date === param.date && value.time === param.time ? '#e0e0e0' : '' }}>
                    <div className="doc_appointment-table_body_cell">
                    {new Date(value.createdAt).toLocaleString()}</div>
                    <div className="doc_appointment-table_body_cell">
                        {value.patient_name}<br />
                        {value.patient_email}<br />
                        {value.patient_mobile}
                    </div>
                    <div className="doc_appointment-table_body_cell">{newDate}</div>

                    <div className="doc_appointment-table_body_cell">{handleTimeFormat(value.time)}</div>
                    <div className="doc_appointment-table_body_cell" style={{ color: value.status === 'Confirmed' ? 'green' : 'tomato' }}>{value.status}</div>
                    {
                        upcoming &&
                        <div className="doc_appointment-table_body_cell">
                            <button
                                disabled={value.status === 'Cancelled'}
                                onClick={() => this.handleUpcomingCancelButton(value)}
                                style={{ opacity: value.status === 'Cancelled' ? '0.5' : '1', background: '#47b475', marginBottom: 0, height: 25, padding: 5, lineHeight: 0, color: '#fff' }}
                            >Cancel</button>
                        </div>
                    }
                    <div className="doc_appointment-table_body_cell" style={{ textTransform: 'capitalize' }}>
                        {
                            locationName.map(value => {
                                return `${value.practice_name},  ${value.zip}`
                            })
                        }<br />
                        {value.payment_mode}
                    </div>


                </div> */}
            </React.Fragment>
        )
    }
}
