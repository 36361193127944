export const primarySpeciality = [
    "Acupuncturist",
    "Aesthetic medicine",
    "Electrophysiologist",
    "Allergist",
    "Anesthesiologist",
    "Audiologist",
    "Bariatric Physician",
    "Cardiac Electrophysiologist",
    "Cardiologist",
    "Cardiothoracic Surgeon",
    "Chiropractor",
    "Colorectal Surgeon",
    "Dentist",
    "Dermatologist",
    "Dietitian",
    "Ear, Nose &amp; Throat Doctor",
    "Emergency Medicine Physician",
    "Endocrinologist",
    "Endodontist",
    "Family Physician",
    "Gastroenterologist",
    "Geneticist",
    "Geriatrician",
    "Hand Surgeon",
    "Hematologist",
    "Immunologist",
    "Infectious Disease Specialist",
    "Integrative Health Medicine Specialist",
    "Internist",
    "Medical Ethicist",
    "Microbiologist",
    "Midwife",
    "Mental health specialist",
    "Naturopathic Doctor",
    "Nephrologist",
    "Neurologist",
    "Neuropsychiatrist",
    "Neurosurgeon",
    "Nurse Practitioner",
    "Nutritionist",
    "OB-GYN",
    "Occupational Medicine Specialist",
    "Oncologist",
    "Ophthalmologist",
    "Optometrist",
    "Oral Surgeon",
    "Orthodontist",
    "Orthopedic Surgeon",
    "Pain Management Specialist",
    "Pathologist",
    "Pediatric Dentist",
    "Pediatrician",
    "Periodontist",
    "Physiatrist",
    "Physical Therapist",
    "Physician Assistant",
    "Plastic Surgeon",
    "Podiatrist",
    "Preventive Medicine Specialist",
    "Primary Care Doctor",
    "Prosthodontist",
    "Psychiatrist",
    "Psychologist",
    "Psychosomatic Medicine Specialist",
    "Psychotherapist",
    "Pulmonologist",
    "Radiation Oncologist",
    "Radiologist",
    "Reproductive Endocrinologist",
    "Rheumatologist",
    "Sleep Medicine Specialist",
    "Sports Medicine Specialist",
    "Surgeon",
    "Surgical Oncologist",
    "Travel Medicine Specialist",
    "Urgent Care Specialist",
    "Urological Surgeon",
    "Urologist",
    "Vascular Surgeon"
]


export const speciality = [
    "Acupuncturist",
    "Aesthetic medicine",
    "Allergist (Immunologist)",
    "Audiologist",
    "Cardiologist (Heart Doctor)",
    "Cardiothoracic Surgeon",
    "Chiropractor",
    "Colorectal Surgeon",
    "Dentist",
    "Dermatologist",
    "Dietitian / Nutritionist",
    "Ear, Nose & Throat Doctor (ENT / Otolaryngologist)",
    "Endocrinologist (incl Diabetes Specialists)",
    "Eye Doctor",
    "Gastroenterologist",
    "Geriatrician",
    "Hearing Specialist",
    "Hematologist (Blood Specialist)",
    "Infectious Disease Specialist",
    "Infertility Specialist",
    "Midwife",
    "Mental health specialist",
    "Naturopathic Doctor",
    "Nephrologist (Kidney Specialist)",
    "Neurologist (incl Headache Specialists)",
    "Neurosurgeon",
    "OB-GYN (Obstetrician-Gynecologist)",
    "Oncologist",
    "Ophthalmologist",
    "Optometrist",
    "Oral Surgeon",
    "Orthodontist",
    "Orthopedic Surgeon (Orthopedist)",
    "Pain Management Specialist",
    "Pediatric Dentist",
    "Pediatrician",
    "Physiatrist (Physical Medicine)",
    "Physical Therapist",
    "Plastic Surgeon",
    "Podiatrist (Foot and Ankle Specialist)",
    "Primary Care Doctor (PCP)",
    "Prosthodontist",
    "Psychiatrist",
    "Psychologist",
    "Pulmonologist (Lung Doctor)",
    "Radiologist",
    "Rheumatologist",
    "Sleep Medicine Specialist",
    "Sports Medicine Specialist",
    "Surgeon",
    "Therapist / Counselor",
    "Urgent Care Specialist",
    "Urological Surgeon",
    "Urologist",
    "Vascular Surgeon"
];
