
//-------------------------------api-url-for development------------------------------*/

/*export const Baseurl = 'http://192.168.43.10:3001/';
export const docSiteUrl = 'http://192.168.43.10:3000';
export const patientSiteUrl = 'http://192.168.43.10:5000';
export const BaseUrlPatent = 'http://192.168.43.10:5003' */
//------------------------------------for-production-----------------------------------*/

export const Baseurl = "https://api.smartappointment.com/";
export const docSiteUrl = "https://doctor.smartappointment.com";
export const patientSiteUrl = "https://www.smartappointment.com";  
export const BaseUrlPatent = "https://www.smartappointment.com"; 
 
//------------------------------------for-staging-----------------------------------*/

//export const Baseurl = "http://localhost:3001/";
//export const docSiteUrl = "http://localhost:3000";
//export const patientSiteUrl = "http://3.86.149.230:5000";  

/* export const Baseurl = 'http://54.144.198.151:3001/';
export const docSiteUrl = 'http://54.144.198.151:3000';
export const patientSiteUrl = 'http://54.144.198.151:5000';  
export const BaseUrlPatent = 'http://54.144.198.151:5003' 
 */
