import React from "react";
import { confirmAlert } from "react-confirm-alert";
import { cancelSubscription } from "../../../../services/cancel-subscription";
import { connect } from "react-redux";
const CancelSubscription = ({ subscriptiondata }) => {
  const { DoctorUid, subscriptionId } = subscriptiondata;

  const handleCancelSubscription = (subscription_id, doctor_uid) => {
    cancelSubscription({ subscription_id, doctor_uid });
  };
  
  const confirmCancelSubscription = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui cancel-confirm-subscription">
            <h1>Are you sure?</h1>
            <p>You want to Cancel Subscription?</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={() => {
                handleCancelSubscription(subscriptionId, DoctorUid);
                onClose();
              }}
            >
              Yes
            </button>
          </div>
        );
      },
    });
  };

  return (
    <React.Fragment>
      <button
        className="btn btn-primary text-black"
        onClick={confirmCancelSubscription}
      >
        Cancel Subscription
      </button>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    subscriptiondata: state.subscriptionData,
  };
};
export default connect(mapStateToProps, null)(CancelSubscription);
