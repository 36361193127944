import React, { Component } from 'react';
import axios from 'axios';
import { Baseurl } from '../../utils/Baseurl';
import queryString from 'query-string';

import { ConfirmAppointmentMailToDoctor } from '../../component/ConfirmAppointmentMailToDoctor';
import { ConfirmAppointmentMailToPatient } from '../../component/ConfirmAppointmentMailToPatient';
import { CancelAppointmentMailToDoctor } from '../../component/CancelAppointmentMailToDoctor';
import { CancelAppointmentMailToPatient } from '../../component/CancelAppointmentMailToPatient';

export default class AppointmentConfirmation extends Component {


    constructor(props) {
        super(props);
        this.state = {
            messageStatus: '',
            message: '',
        }
    }

    /*---------------------------------------------------handle-componentwillMount---------------------------------------------------*/

    UNSAFE_componentWillMount() {
        let param = queryString.parse(this.props.location.search);

        if (this.props.isAuth) {
            // let param = queryString.parse(this.props.location.search);
            if (param.status === "0") {
                this.handleUpdateAppointmentStatus();
            }
            else if (param.status === "1") {
                this.props.history.push(`/?date=${param.date}&time=${param.time}&id=${param.id}&status=${param.status}`);
            }
            else if (param.status === "2") {
                this.handleDocCancelButton();
            }
            else {
                this.setState({ message: 'somthing went wrong . invalid url !' });
                setTimeout(() => {
                    this.props.history.push('/');
                }, 1000);
            }
        }
        else {
            this.props.history.push({ pathname: '/access/login', state: { routename: 'confirm', date: param.date, time: param.time, id: param.id, patientId: param.patientId, status: param.status } });
        }
    }


    /*------------------------------------------------handle doctor confirm btn----------------------------------------------------*/

    handleUpdateAppointmentStatus = () => {
        let docId = localStorage.getItem('uid');
        let param = queryString.parse(this.props.location.search);

        if (param.id === docId) {
            axios({
                method: 'get',
                url: Baseurl + `api/doc/getDetails?userId=${docId}`,
                headers: { 'content-type': 'application/json' }
            }).then((doctor) => {
                // console.log("doctor-details-------->", doctor.data);
                let practiceName;
                let practice = doctor.data.user.practice.filter((k, i) => i === 0 ? k : null);
                practice.map((value) => {
                    practiceName = `${value.practice_name}, ${value.practice_location}, ${value.zip}`;
                    return null;
                })
                axios({

                    method: 'get',
                    url: Baseurl + `api/user/getuserdetails?userId=${param.patientId}`,
                    headers: { 'content-type': 'application/json' },

                }).then(res => {
                    this.setState({ message: 'Loading..!' });
                    let data = {
                        emailto_patient: res.data.user.email,
                        patient_subject: 'Your appointment has been confirmed !',
                        patient_message: ConfirmAppointmentMailToPatient(practiceName, param.date, param.time, `${doctor.data.user.first_name} ${doctor.data.user.last_name}`),


                        emailto_doc: doctor.data.user.email,
                        emailto_grpdoc: doctor.data.user.groupDrEmail,
                        doc_subject: 'Your appointment has been confirmed !',
                        doc_message: ConfirmAppointmentMailToDoctor(practiceName, param.date, param.time, `${res.data.user.first_name} ${res.data.user.last_name}`)
                    }

                    axios({
                        method: 'post',
                        url: Baseurl + `api/appointment/doc_confirm_appointment?appointDate=${param.date}&appointTime=${param.time}&status_by=${param.id} `,
                        headers: { 'content-type': 'application/json' },
                        data: data
                    }).then((res) => {
                        // console.log("update-appointment confirmation status--------->", res);
                        this.setState({ message: 'Congratulations your appointment has confirmed !' })
                        setTimeout(() => {
                            this.props.history.push('/');
                        }, 1000);

                    }).catch((error) => {
                        console.log("update-appointment confirmation status error--------->", error);
                    })

                }).catch(error => {
                    console.log("getuser email details--->", error);
                })


            }).catch((error) => {
                console.log("doctor-details-error------->", error);
            })

        } else {
            this.setState({ message: 'invalid url !' });
            setTimeout(() => {
                this.props.history.push('/');
            }, 1000);
        }

    }

    /*------------------------------------------------------handle-cancel-button-------------------------------------*/

    handleDocCancelButton = () => {
        let param = queryString.parse(this.props.location.search);
        let docId = localStorage.getItem('uid');
        let availarr = [];

        if (param.id === docId) {
            axios({
                method: 'get',
                url: Baseurl + `api/schedule/get_schedules?uid=${docId}`,
                headers: { 'content-type': 'application/json' },
            }).then((response) => {
                // console.log("get-schedule-response------------->", response.data.data);
                response.data.data.map(data => {
                    if (data.date === param.date) {
                        const par = JSON.parse(data.availability);
                        par.map(v => {
                            if (v.time === param.time) {
                                v.client = null;
                            }
                            return null;
                        });
                        availarr.push(JSON.stringify(par));
                    }
                    return null;
                });
                /*----------after set availibility--------------*/

                axios({
                    method: 'get',
                    url: Baseurl + `api/doc/getDetails?userId=${docId}`,
                    headers: { 'content-type': 'application/json' }
                }).then((doctor) => {
                    // console.log("doctor-details-------->", doctor.data);
                    let practiceName;
                    let practice = doctor.data.user.practice.filter((k, i) => i === 0 ? k : null);
                    practice.map((value) => {
                        practiceName = `${value.practice_name}, ${value.practice_location}, ${value.zip}`;
                        return null;
                    })

                    axios({
                        method: 'get',
                        url: Baseurl + `api/user/getuserdetails?userId=${param.patientId}`,
                        headers: { 'content-type': 'application/json' },
                    }).then(res => {
                        this.setState({ message: 'Loading..!' });

                        let data = {
                            availability: availarr,
                            emailto_patient: res.data.user.email,
                            patient_subject: 'Your appointment has been Cancelled !',
                            patient_message: CancelAppointmentMailToPatient(practiceName, param.date, param.time, `${doctor.data.user.first_name} ${doctor.data.user.last_name}`),


                            emailto_doc: doctor.data.user.email,
                            emailto_grpdoc: doctor.data.user.groupDrEmail,
                            doc_subject: 'Your appointment has been Cancelled !',
                            doc_message: CancelAppointmentMailToDoctor(practiceName, param.date, param.time, `${res.data.user.first_name} ${res.data.user.last_name}`)
                        }

                        axios({
                            method: 'post',
                            url: Baseurl + `api/appointment/doc_cancel_appointment?appointDate=${param.date}&appointTime=${param.time}&status_by=${param.id}`,
                            headers: { 'content-type': 'application/json' },
                            data: data
                        }).then((res) => {
                            // console.log("update-appointment confirmation status--------->", res);
                            this.setState({ message: 'Your appointment has been cancelled !' })
                            setTimeout(() => {
                                this.props.history.push('/');
                            }, 1000);

                        }).catch((error) => {
                            console.log("update-appointment cancelled status error--------->", error);
                        })

                    }).catch(error => {
                        console.log("getdoc details error--->", error);
                    })

                }).catch((error) => {
                    console.log("doctor-details-error------->", error);
                })

            }).catch((error) => {
                console.log("get-schedule-error------------->", error);
            })
        }
        else {
            this.setState({ message: 'invalid url !' });
            setTimeout(() => {
                this.props.history.push('/');
            }, 1000);
        }

    }




    render() {
        return (
            <React.Fragment>
                <div style={{ height: window.innerHeight, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <h5>{this.state.message}</h5>
                </div>
            </React.Fragment>
        )
    }
}
