import React, { Component } from "react";
import { Link } from "react-router-dom";
import { GET_DOC_DETAILS_REQUEST } from "../../actions/Action";
import { connect } from "react-redux";
import DocHubReviewBox from "../../component/DocHubReviewBox";
import StarRatingComponent from "react-star-rating-component";
import queryString from "query-string";

class DoctorsProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      doc_name: "",
      // primary_speciality: '',
      location: "",
      about: "",
      board_certification: "None",
      speciality: [],
      practice: [],
      hospital_affiliations: [],
      education_training: [],
      awards_publications: [],
      insurance_provider: [],
      language: [],
      gender: "",
      npi_number: "",
      profile: "",
      uId: "",
      reviewLength: "",
      reviewAllRatings: "",
      docLocation: [],
      telemedicine: 0,
    };
  }

  UNSAFE_componentWillMount() {
    let values = queryString.parse(this.props.location.search);
    this.props.handlegetDoctorProfile(values.doc);
  }

  UNSAFE_componentWillReceiveProps(props) {
    // console.log("props---->", props.docProfile);
    if (props.docProfile) {
      this.setState({
        doc_name: `${props.docProfile.first_name} ${props.docProfile.last_name}`,
        // primary_speciality: props.docProfile.primary_speciality,
        location: props.docProfile.practice_location,
        about: props.docProfile.about_me,
        speciality: props.docProfile.speciality
          ? props.docProfile.speciality
          : [],
        practice:
          props.docProfile.practice && props.docProfile.practice.length > 0
            ? [props.docProfile.practice[0]]
            : [],
        docLocation:
          props.docProfile.practice && props.docProfile.practice.length > 0
            ? props.docProfile.practice
            : [],
        insurance_provider:
          props.docProfile.insurance_providers &&
          props.docProfile.insurance_providers.length > 0
            ? props.docProfile.insurance_providers
            : [],
        hospital_affiliations: props.docProfile.hospital_affiliations,
        education_training: props.docProfile.education_training,
        awards_publications: props.docProfile.awards_publications,
        language: props.docProfile.languages,
        gender: props.docProfile.gender,
        npi_number: props.docProfile.npi_number,
        uId: props.docProfile.uid,
        profile: props.docProfile.profile_image,
        board_certification: props.docProfile.board_certification,
        telemedicine: props.docProfile.telemedicine,
      });
    }
  }

  renderLocation = () => {
    let l = this.state.practice.filter((k, i) => (i === 0 ? k : null));
    return l.map((value) => {
      return `${value.practice_name}, ${value.practice_location}, ${value.zip}`;
    });
  };

  /*------------------------------------handle-all-ratings----------------------------------------*/

  handleAllRatings = (data) => {
    let rating = 0;
    data.map((value) => {
      rating += value.rating;
      return null;
    });

    this.setState({
      reviewLength: data.length,
      reviewAllRatings: rating / data.length,
    });
  };

  render() {
   
    let values = queryString.parse(this.props.location.search);
    console.log(values, "values");
    return (
      <React.Fragment>
        <div className="main-container">
          <section>
            <div className="container">
              <div className="row">
                <div className="col-md-8 col-sm-10">
                  <div className="feature feature-2 mb-xs-24">
                    <img
                      alt="go_back"
                      title="go back"
                      src={require("../../assets/logo/arrow_left.png")}
                      style={{ height: 25, width: 23, cursor: "pointer" }}
                      onClick={() => this.props.history.goBack()}
                    />
                    <div
                      className="text-center"
                      style={{
                        height: 150,
                        width: 150,
                        backgroundImage: `url('${
                          this.state.profile
                            ? this.state.profile
                            : "https://cdn1.vectorstock.com/i/thumb-large/75/85/default-placeholder-doctor-half-length-portrait-vector-20847585.jpg"
                        }')`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        borderRadius: "50%",
                        margin: "0 auto",
                      }}
                    >
                    
                    </div>

                    <div className="text-center">
                      <div className="">
                        <h3 className="bold mb0 mt16">{this.state.doc_name}</h3>
                        <h5 className="bold mb0">
                          {this.state.speciality.map((value, index) => {
                            return `${value} , `;
                          })}
                        </h5>
                        <h6 className="bold mb16 uppercase">
                          {this.renderLocation()}
                        </h6>
                      </div>
                    </div>
                    <div className="text-left mt24">
                      <div className="">
                        <h4 style={{ fontWeight: "bold" }}>
                          About {this.state.doc_name}
                        </h4>
                        <p className="lead">{this.state.about}</p>
                        <h5 style={{ fontWeight: "bold" }}>
                          Board Certifications{" "}
                        </h5>
                        <p className="lead">{this.state.board_certification}</p>

                        {this.state.speciality?.length && (
                          <div>
                            <h5 style={{ fontWeight: "bold" }}>Specialties</h5>
                            <ul>
                              {this.state.speciality?.map((val, key) => {
                                return <li key={key}>{val}</li>;
                              })}
                            </ul>
                          </div>
                        )}

                        {this.state.practice?.length && (
                          <div>
                            <h5 style={{ fontWeight: "bold" }}>
                              Practice Locations
                            </h5>
                            <ul>
                              {this.state.docLocation?.map((val, key) => {
                                return (
                                  <li key={key}>{`${val.practice_name}, ${
                                    val.city !== "undefined" ? val.city : ""
                                  } ${val.practice_location}, ${val.zip}`}</li>
                                );
                              })}
                            </ul>
                          </div>
                        )}

                        {this.state.hospital_affiliations?.length && (
                          <div>
                            <h5 style={{ fontWeight: "bold" }}>
                              Hospital Affiliations
                            </h5>
                            <ul>
                              {this.state.hospital_affiliations?.map(
                                (val, key) => {
                                  return <li key={key}>{val}</li>;
                                }
                              )}
                            </ul>
                          </div>
                        )}

                        {this.state.education_training?.length && (
                          <div>
                            <h5 style={{ fontWeight: "bold" }}>
                              Education and Training
                            </h5>
                            <ul>
                              {this.state.education_training?.map((val, key) => {
                                return <li key={key}>{val}</li>;
                              })}
                            </ul>
                          </div>
                        )}

                        {this.state.awards_publications?.length && (
                          <div>
                            <h5 style={{ fontWeight: "bold" }}>
                              Awards and Publications
                            </h5>
                            <ul>
                              {this.state.awards_publications?.map(
                                (val, key) => {
                                  return <li key={key}>{val}</li>;
                                }
                              )}
                            </ul>
                          </div>
                        )}

                        {this.state.language?.length && (
                          <div>
                            <h5 style={{ fontWeight: "bold" }}>
                              Languages Spoken
                            </h5>
                            <ul>
                              {this.state.language?.map((val, key) => {
                                return <li key={key}>{val}</li>;
                              })}
                            </ul>
                          </div>
                        )}

                        <h5 style={{ fontWeight: "bold" }}>
                          Provider's Gender
                        </h5>
                        <ul>
                          <li>{this.state.gender}</li>
                        </ul>
                        {this.state.insurance_provider.length !== 0 && (
                          <div>
                            <h5 style={{ fontWeight: "bold" }}>
                              In-network insurances
                            </h5>
                            <ul>
                              {this.state.insurance_provider.map((val, key) => {
                                return <li key={key}>{val.provider_name}</li>;
                              })}
                            </ul>
                          </div>
                        )}

                        <h5 style={{ fontWeight: "bold" }}>
                          Offer Telemedicine
                        </h5>
                        <ul>
                          <li>{this.state.telemedicine == 1 ? "YES" : "NO"}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="feature mb-xs-24 mb32 bordered bg-secondary">
                    <div className="">
                      <div className="">
                        <h5 className="bold mb0">{this.state.doc_name}</h5>
                        <span>
                          {this.state.speciality.map((value, index) => {
                            return `${value} , `;
                          })}
                          <br />
                          {this.renderLocation()}

                          <br />
                          <div style={{ display: "flex" }}>
                            <StarRatingComponent
                              name="rate1"
                              starCount={5}
                              value={Number(this.state.reviewAllRatings)}
                              starColor={"#ec8a19"}
                              emptyStarColor={"lightgray"}
                            />
                            <span>({this.state.reviewLength})</span>
                          </div>
                        
                        </span>
                        <Link
                          className="mb0 btn btn-lg btn-filled uppercase"
                          to={`/schedule?doc=${values.doc}&patient=${values.patient}`}
                        >
                          Schedule Appointment
                        </Link>
                      </div>
                    </div>
                  </div>
                  <DocHubReviewBox
                    docId={values.doc}
                    patient={values.patient}
                    handleAllRating={this.handleAllRatings}
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    docProfile: state.doctorDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handlegetDoctorProfile: (data) =>
      dispatch({ type: GET_DOC_DETAILS_REQUEST, data }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DoctorsProfile);
