import React, { Component } from 'react';
import firebaseApp from '../../utils/Fire';
import { validateEmail, validateName } from '../../utils/FormValidater';
import { Link } from 'react-router-dom';
import { Baseurl, BaseUrlPatent } from '../../utils/Baseurl';
import axios from 'axios';
import { toast } from 'react-toastify';
import { primarySpeciality } from '../../constant/speciality';
import { ToastMessage } from '../../component/ToastMessage';

import '../../styles/Common.css';


export default class Signup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            first_name: '', firstError: false,
            last_name: '', lastError: false,
            phone: '', phoneError: false,
            email: '', emailError: false,
            password: '', passwordError: false,
            primary_speciality: '', specialityError: false,
            zip: '', zipError: false,
            tooLong: false,
            groupRole:7,
            errors: {},
            isLoading: false,
            managementGroup:'',
            managementGroupData:[],
            TermsStatus : false
        }
    }



    /*------------------------------------------------------------handle-input-onchange-------------------------------------------------*/

    handleOnChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });      

        e.target.name === 'first_name' && this.setState({ firstError: false });
        e.target.name === 'last_name' && this.setState({ lastError: false });
        e.target.name === 'phone' && this.setState({ phoneError: false });
        e.target.name === 'password' && this.setState({ passwordError: false, tooLong: false });
        e.target.name === 'email' && this.setState({ emailError: false });
        e.target.name === 'primary_speciality' && this.setState({ specialityError: false });
        e.target.name === 'zip' && this.setState({ zipError: false });

        if(this.state.groupRole==6){
            this.setState({primary_speciality:'None'});
        }

    }

    handleTermCondition = () =>{
        const{ TermsStatus } = this.state;
        this.setState({
            TermsStatus : !TermsStatus
        })
    }

    componentDidMount(){      
        axios({
        method:'get',
        url:Baseurl+`group/all_groups`,
        headers:{'content-type':'application/json'}
       }).then((res)=>{
           console.log('resdata',res.data.data);
           if(res.data !== undefined && res.data.data !== undefined && res.data.data.length > 0){
             this.setState({managementGroupData: res.data.data})
           }
          
       }).catch((err)=>{
           console.log("doc error---->",err);
       })

    }


    /*------------------------------------------------------------handle-validateForm-------------------------------------------------*/

    validateForm() {
        const { first_name, last_name, password, email, phone, zip, primary_speciality,groupRole } = this.state;
        let errors = {};
       
        !validateName(first_name) && this.setState({ firstError: true });
        !validateName(last_name) && this.setState({ lastError: true });
        !validateEmail(email) && this.setState({ emailError: true });
        password.length < 8 && this.setState({ passwordError: true });
        password.length > 36 && this.setState({ tooLong: true });
        phone.length < 1 && this.setState({ phoneError: true });
        if(groupRole==7){
        primary_speciality.length < 1 && this.setState({ specialityError: true });
        }
        zip.length < 1 && this.setState({ zipError: true });

         

        return validateName(first_name) && validateName(last_name) && validateEmail(email) > 0 && password.length > 7 && password.length < 37 && phone.length > 0 && zip.length > 0 && primary_speciality.length > 0;
    };


    /*------------------------------------------------------------handle-signup-with-firebase------------------------------------------*/

    handleSubmit = (e) => {
        e.preventDefault();
        let errors = {};
        let isValid = true;
        const { email, password,phone,zip } = this.state;
        this.setState({ isLoading: true });

        if (phone) {          
            var pattern = new RegExp(/^[0-9\b]+$/);
            if (!pattern.test(phone)) {
              isValid = false; 
              errors["phone"] = "Please enter only number."; 

              toast.error('Please enter only number.', { position: toast.POSITION.TOP_CENTER });
                      
              this.setState({ phoneError: true,isLoading: false });             
              return false;
            }else if(phone.length != 10){
              isValid = false;  
              errors["phone"] = "Please enter valid phone number."; 
              toast.error('Please enter valid phone number.', { position: toast.POSITION.TOP_CENTER });       
              this.setState({ phoneError: true,isLoading: false });             
              return false;
            }
        }

        if (zip) {          
            var pattern2 = new RegExp(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
            if (!pattern2.test(zip)) {
              isValid = false;  
              toast.error('Please enter valid zip code.', { position: toast.POSITION.TOP_CENTER });                      
              this.setState({ zipError: true,isLoading: false });             
              return false;
            }else if(zip.length != 5){
              isValid = false; 
              toast.error('Please enter valid zip code.', { position: toast.POSITION.TOP_CENTER });       
              this.setState({ zipError: true,isLoading: false });             
              return false;
            }
        }

        if (this.validateForm()) {
            
            firebaseApp.auth().createUserWithEmailAndPassword(email, password)
                .then((user) => {
                    console.log("signup-with-firebase response---------->", user.user.uid);
                    this.props.signingUp(true);
                    this.handleSignupApi(user.user.uid);

                })
                .catch((error) => {
                    console.log("Signup-with-firebase-error--------->", error);
                    this.setState({ isLoading: false,emailError: true });
                    if (error.code === 'auth/email-already-in-use') {
                        toast.error('This email already exists. Please try to sign up with another email.', { position: toast.POSITION.TOP_CENTER });
                    }
                    else
                    {
                        toast.error(error.message, { position: toast.POSITION.TOP_CENTER });
                    }
                });

        } else {
            console.log("in-validation-error");
            this.setState({ isLoading: false });
        }
    }

    /*------------------------------------------------------------handle-signupapi-realdatabase-------------------------------------------*/
    generateDoctorSlug = () =>{
        axios({
            method: 'get',
            url: BaseUrlPatent + '/run_shell_command',
            headers: {
                "Content-Type": "application/json",
            }
        }).then((response) => {
            console.log("generate-slug-res----->", response);
   
        }).catch((error) => {
            console.log("generate-slug-err----->", error.response);
        })
    }

    updateSingleDoctorSlug=(uid)=>{ 
        axios({
            method: 'get',
            url: Baseurl + `api/doc/update_single_doctor_slug?drUid=${uid}`,
            headers: {
                "Content-Type": "application/json",
            },
        }).then((response) => {
            console.log("updateSingleDoctorSlug-response----->", response);
            this.generateDoctorSlug()
           
        }).catch((error) => {
            console.log("updateSingleDoctorSlug-error----->", error.response);
         
        }) 
    }

    handleSignupApi = (userId) => {
        const { email, first_name, last_name, phone, zip, primary_speciality,groupRole,managementGroup } = this.state;
         
          let referdata = '';
         
         if(localStorage.getItem('reffer_key')){
           referdata = localStorage.getItem('reffer_key');
         }

        let data = {
            uid: userId,
            email: email,
            first_name: first_name,
            last_name: last_name,
            phone: phone,
            zip: zip,
            primary_speciality: primary_speciality,
            groupRole:groupRole,
            ref_number:referdata !== undefined ? referdata : '',
            managment_group_id:managementGroup,
        }

        axios({
            method: 'post',
            url: Baseurl + 'api/doc/register',
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        }).then((response) => {
            console.log("signup-response----->", response);
            if(response){
              localStorage.removeItem("reffer_key");
            } 
            setTimeout(()=>{
                this.updateSingleDoctorSlug(userId)
                this.sendEmailVerification();
            },5000)
         
        }).catch((error) => {
            console.log("signup-error----->", error);
            toast.error('Something went wrong!', { position: toast.POSITION.TOP_CENTER });
            this.setState({ isLoading: false });
        })

    }


    /*------------------------------------------------------------handle-sendVerification-email----------------------------------------*/

    sendEmailVerification = () => {
        firebaseApp.auth().currentUser.sendEmailVerification()
            .then((res) => {
                firebaseApp.auth().signOut();
                toast.success(<ToastMessage message="Verification email was sent to your email address." />, { position: toast.POSITION.TOP_CENTER });
                this.setState({ isLoading: false });
                this.props.signingUp(false);
                setTimeout(() => {
                   
                    this.props.history.push('/access/login');
                }, 1000);
            }).catch((error) => {
                console.log("something wrong during send verification email !", error);
                toast.error(error.message, { position: toast.POSITION.TOP_CENTER });
                this.setState({ isLoading: false });
            })
    }




    render() {
        console.log('this.state',this.state.TermsStatus);
        return (
            <React.Fragment>               
                <h3 className="bold mb0">Create an account</h3>
                <p className="">Already have one? <Link to="/access/login">Log in.</Link></p>
                <form className="text-left inpt-place" onSubmit={this.state.TermsStatus ? this.handleSubmit : ()=>{}}>
                    <div className="form-group">
                    <div className="input-with-label text-left sl-arrow">
                        <span>Select Group Admin/Doctor</span>
                        <select
                            className="col-md-12 form-control"
                            name="groupRole" style={{width:'100%'}} onChange={(e) => this.handleOnChange(e)}>
                            <option value="7">Doctor</option>
                            <option value="6">Group Admin</option>  
                            <option value="8">Aesthetician</option>
                        </select>
                    </div>
                    <div className="input-with-label text-left sl-arrow">
                  <span>Select Organisation</span>
                  <select
                    className="col-md-12 form-control"
                    name="managementGroup" style={{width:'100%'}} onChange={(e) => this.handleOnChange(e)}>
                        <option>Select</option>
                  {
                                this.state.managementGroupData.map((mgmtgrp, index) => (
                                <option value={mgmtgrp._id} key={index} >{mgmtgrp.name}</option>
                                ))
                   }                
                  </select>
                </div>                  
                  </div>
                    <input type="text" name="email" className="form-control" placeholder="Please enter your email address." onChange={(e) => this.handleOnChange(e)} style={{ border: this.state.emailError ? '1px solid #c64444' : '' }} />
                    <input type="password" name="password" className="form-control" placeholder="Enter your password" onChange={(e) => this.handleOnChange(e)} style={{ border: this.state.passwordError ? '1px solid #c64444' : '' }} />
                    <div className="input-with-label text-left">
                        <span>Your name</span>
                        <input className="col-md-6 form-control" type="text" name="first_name" placeholder="First name" onChange={(e) => this.handleOnChange(e)} style={{ border: this.state.firstError ? '1px solid #c64444' : '' }} />
                        <input className="col-md-6 form-control" type="text" name="last_name" placeholder="Last name" onChange={(e) => this.handleOnChange(e)} style={{ border: this.state.lastError ? '1px solid #c64444' : '' }} />
                    </div>
                  
                    <div className="input-group">
                     <span className="input-group-addon">+1</span>
                    <input type="text" className="m_number form-control" ref="namePhone" name="phone" placeholder="Office Phone"
                        maxLength={10}
                        onKeyPress={(e) => (e.charCode >= 48 && e.charCode <= 57) ? true : e.preventDefault()}
                        value={this.state.phone}
                        onChange={(e) => this.handleOnChange(e)}
                    />                    
                    </div>                  
                    <br/>
                  
                   
     
                    {this.state.groupRole==7 || this.state.groupRole==8 ?
                    <div style={{marginTop:'20px'}}>
                        <input type='text' list='primary_speciality' onChange={(e) => this.handleOnChange(e)} name="primary_speciality" placeholder='Primary speciality'
                            style={{ border: this.state.specialityError ? '1px solid #c64444' : '' }} />
                        <datalist id="primary_speciality">
                            {
                                primarySpeciality.map((name, index) => (
                                    <option value={name} key={index} />
                                ))
                            }
                        </datalist>
                    </div> :<input type="hidden" name="primary_speciality" value="None"/>
                    }
                    <input type="text" name="zip" placeholder="Zipcode" onChange={(e) => this.handleOnChange(e)} style={{ border: this.state.zipError ? '1px solid #c64444' : '',marginTop:'12px' }} />
                    <div className="d-flex mb-5"style={{marginBottom:'20px'}}>
                        <input type="checkbox" style={{position:'relative', top:'3px'}} checked={this.state.TermsStatus } onChange={this.handleTermCondition}/>
                        <span className="ml-2" style={{marginLeft : "10px"}}>By signing up, you agree to our <Link to="/TermsCondition" target="_blank">Terms Of Use</Link> </span>
                    </div>                    
                    <button type='submit' disabled={(!this.state.TermsStatus) || this.state.isLoading} className={((!this.state.TermsStatus) || this.state.isLoading) ? "disabled" : ""} onClick={this.state.TermsStatus ?  this.handleSubmit : ()=>{}}>
                        {this.state.isLoading && <span className="spinner-border text-light mr8" role="status" />}
                        SIGN UP
                    </button>
                </form>
                <style>
                    {
                        `.disabled {
                            opacity: 0.5;
                        }
                        `
                    }
                </style>
                
            </React.Fragment>
        )
    }
}
