import { takeLatest } from "redux-saga/effects";
import { getDocDetailsSaga } from "./workersagas/GetDocDetailsSagas";
import { getDocReviewsSaga } from "./workersagas/GetDocReviewSaga";
import { docListSaga } from "./workersagas/GetDocListSaga";
import { searchDocSaga } from "./workersagas/SearchDocSaga";
import { searchZipSaga } from "./workersagas/SearchZipSaga";
import { patentReviewsSaga } from "./workersagas/GetPatientReview";
import { patientDetailsSaga } from "./workersagas/GetPatientDetails";
import { docTodayAppointmentSaga } from "./workersagas/DocTodayAppointmentList";
import { docUpcomingAppointmentSaga } from "./workersagas/DocUpcomingAppointmentList";
import { docPastAppointmentSaga } from "./workersagas/DocPastAppointmentList";
import { getSubscriptionDetails } from "./workersagas/GetSubscriptionDetails";
import { getTransactionDetails } from "./workersagas/GetTransactionDetails";
import { getCustomerProfileDetails } from "./workersagas/GetCustomerProfileDetails";
import { getConsultSentDetails } from "./workersagas/getConsultSentDetails";
import { getConsultReceivedDetails } from "./workersagas/getConsultReceivedDetails";
import { getMonthlyAppointments } from "./workersagas/getMonthlyAppointments"; 
import { getLoginUserRequest } from "./workersagas/getLoginUserRequest";
import { getTrailSubscriptionRequest } from "./workersagas/getTrailSubscriptionRequest";
import {
  GET_SUBSCRIPTION_REQUEST,
  GET_DOC_DETAILS_REQUEST,
  GET_DOC_REVIEWS_REQUEST,
  DOC_TODAY_APPOINTMENT_REQUEST,
  DOC_UPCOMING_APPOINTMENT_REQUEST,
  DOC_PAST_APPOINTMENT_REQUEST,
  GETPATIENT_DETAILS_REQUEST,
  GET_DOCTOR_LIST_REQUEST,
  SEARCH_DOC_SPECIALITY_REQUEST,
  SEARCH_ZIP_REQUEST,
  GET_PATINET_REVIEW_REQUEST,
  GET_TRANSACTION_REQUEST,
  GET_CUSTOMER_PROFILE_REQUEST,
  GET_CONSULT_SENT_REQUEST,
  GET_CONSULT_RECEIVED_REQUEST,
  GET_MONTHLY_APPOINTMENTS_REQUEST,
  GET_LOGIN_USER_REQUEST,
  GET_TRAIL_SUBSCRIPTION_REQUEST
} from "../actions/Action";


export function* watcherSaga() {
  yield takeLatest(GET_DOC_DETAILS_REQUEST, getDocDetailsSaga);
  yield takeLatest(GET_DOC_REVIEWS_REQUEST, getDocReviewsSaga);
  yield takeLatest(DOC_TODAY_APPOINTMENT_REQUEST, docTodayAppointmentSaga);
  yield takeLatest(GET_DOCTOR_LIST_REQUEST, docListSaga);
  yield takeLatest(SEARCH_DOC_SPECIALITY_REQUEST, searchDocSaga);
  yield takeLatest(SEARCH_ZIP_REQUEST, searchZipSaga);
  yield takeLatest(GET_PATINET_REVIEW_REQUEST, patentReviewsSaga);
  yield takeLatest(GETPATIENT_DETAILS_REQUEST, patientDetailsSaga);
  yield takeLatest(
    DOC_UPCOMING_APPOINTMENT_REQUEST,
    docUpcomingAppointmentSaga
  );
  yield takeLatest(DOC_PAST_APPOINTMENT_REQUEST, docPastAppointmentSaga);
  yield takeLatest(GET_SUBSCRIPTION_REQUEST, getSubscriptionDetails);
  yield takeLatest(GET_TRANSACTION_REQUEST, getTransactionDetails);
  yield takeLatest(GET_CUSTOMER_PROFILE_REQUEST, getCustomerProfileDetails);
  yield takeLatest(GET_CONSULT_SENT_REQUEST, getConsultSentDetails);
  yield takeLatest(GET_CONSULT_RECEIVED_REQUEST, getConsultReceivedDetails);
  yield takeLatest(GET_MONTHLY_APPOINTMENTS_REQUEST, getMonthlyAppointments);
  yield takeLatest(GET_LOGIN_USER_REQUEST, getLoginUserRequest);
  yield takeLatest(GET_TRAIL_SUBSCRIPTION_REQUEST, getTrailSubscriptionRequest);
}
