import { call, put } from "redux-saga/effects";
import axios from "axios";
import { DOC_TODAY_APPOINTMENT_SUCCESS_REQUEST, DOC_TODAY_APPOINTMENT_FAILURE_REQUEST } from '../../actions/Action';
import { Baseurl } from '../../utils/Baseurl';

export function docTodayAppointments(url) {
    let headers = {
        'Content-Type': 'application/json',
        'Pragma': 'no-cache'
    };
    return axios({
        method: "get",
        url: Baseurl + url,
        headers
    });
}


export function* docTodayAppointmentSaga(action) {
    try {
        const response = yield call(docTodayAppointments, `api/appointment/doc_todays_appointments?uid=${action.data.id}&limit=${action.data.limit}&page=${action.data.page}`);
        // console.log("in-saga-doclist---->", response.data);
        const docTodayAppoints = response.data;

        // dispatch a success action to the store with the new data
        yield put({ type: DOC_TODAY_APPOINTMENT_SUCCESS_REQUEST, docTodayAppoints });
    } catch (error) {
        // dispatch a failure action to the store with the error
        yield put({ type: DOC_TODAY_APPOINTMENT_FAILURE_REQUEST, error });
    }
}




