import React from "react"
import Imgone from "../../../assets/img/bg_im.png";
import Moment from "react-moment";
import Avatar from 'react-avatar';
import { getNameLetter } from "../../../utils/helperFunctions";
import emoji from 'react-easy-emoji'
const RecivedChatMessages=({data, download, isOnline})=>{
    const {message,sentTime,sender,containsFile, files,isDeleted} = data;

    return(
      <div className="box d-flex w_80">
      <div className="box_left mr-3">
        <div className={`chat_user_icon ${isOnline ? 'online' : 'offline'}`}>
        <Avatar name={getNameLetter(sender?.first_name, sender?.last_name)} size="40"  round="20px" maxInitials={2}/>
          {/* <img src={Imgone} alt="img_s" /> */}
        </div>
      </div>
      <div className="box_right w_100">
        <div className="chat_box">
          {/* <div className="chat_head flex">
            <p> {sender.first_name} </p>
            <div className="ml_auto flex flex_center"> </div>
          </div> */}
          <div className="chat_text left_text">
            <div className="inner_chat_info bg_pre arrow_left">
              <div className="option_info flex end_item flex_center">
              
                {/* <div className="dropdown show">
                <a
                  className="btn cs_drop"
                  href="#"
                  role="button"
                  id="dropdownMenuLink"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div />
                  <div />
                  <div />
                </a>
                <div
                  className="dropdown-menu cs_drop_title"
                  aria-labelledby="dropdownMenuLink"
                >
                  <a className="dropdown-item" href="#">
                    Reply
                  </a>
                  <a className="dropdown-item" href="#">
                    Edit
                  </a>
                  <a className="dropdown-item" href="#">
                    Delete
                  </a>
                </div>
              </div> */}
              </div>
              <div>
                <p className="title_text">   
                {isDeleted 
                ?
                <i style={{fontWeight:600}}>Message deleted</i>
                :
                containsFile 
                  ? 
                  <React.Fragment>
                  <i style={{display:'block',textAlign:"left",fontWeight:600}}><i className="fas fa-paperclip" style={{verticalAlign:"middle", color:"#000",visibility:"hidden"}}/> File attachment</i> 
                 { files.map(el=>{
                 return <i style={{display:'block',textAlign:"left"}}><a style={{cursor:"pointer"}} onClick={()=>download(el.url, el.name)}><i className="fas fa-download" style={{verticalAlign:"middle", color:"#000"}} title={`Download ${el.name}`}/> {el.name}</a></i> 
                  })}
                  </React.Fragment>
                  :
                  <React.Fragment>
                  {emoji(message)}
                  </React.Fragment>} 
                   </p>
              </div>
              <h4 className="time mr_10 order_2 "> <Moment format="hh:mm a">{sentTime}</Moment></h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
}
export default RecivedChatMessages;