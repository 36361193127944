import React,{ Component } from "react";
import { GetAPI } from "../../../utils/Api-interface"

export default class BillingAddress extends Component{

    state={
        StateList : []
    }
    
    handleStateList = () =>{
        GetAPI(`api/subscription/state_list`).then((res)=>{
            const response = res.data.data
            if(response){
              this.setState({ StateList : response })
            }
        })
    }

    componentDidMount(){
      this.handleStateList()
    }

    render(){
        const{ firstName,lastName, email, mobile, address, stateIs, city, handleFunc, country, zip, cityList, errorBillingIs } = this.props;
        const{ StateList } = this.state;
      
        return(
            <React.Fragment>
                <div className="account-information">
                  <form className="">
                    <h4>Personal Information</h4>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className={`form-control ${errorBillingIs.firstName ? `form-input-error` : ''}`}
                            placeholder="First Name"
                            name="firstName"
                            value={firstName}
                            onChange={handleFunc.handleOnChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className={`form-control ${errorBillingIs.lastName ? `form-input-error` : ''}`}
                            placeholder="Last Name"
                            name="lastName"
                            value={lastName}
                            onChange={handleFunc.handleOnChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <input
                            type="email"
                            className={`form-control ${errorBillingIs.email ? `form-input-error` : ''}`}
                            placeholder="Email"
                            name="email"
                            value={email}
                            onChange={handleFunc.handleOnChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <input
                            type="number"
                            className={`form-control ${errorBillingIs.mobile ? `form-input-error` : ''}`}
                            placeholder="Mobile No" 
                            name="mobile"
                            value={mobile}
                            onChange={handleFunc.handleOnChange}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                  <form>
                    <h4>Billing Address</h4>
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className={`form-control ${errorBillingIs.address ? `form-input-error` : ''}`}
                            placeholder="Address"
                            name="address"
                            value={address}
                            onChange={handleFunc.handleOnChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className={`form-control ${errorBillingIs.country ? `form-input-error` : ''}`}
                            placeholder="country"
                            name="country"
                            value={country}
                            disabled={true}
                            onChange={handleFunc.handleOnChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <select
                            className={`form-control ${errorBillingIs.stateIs ? `form-input-error` : ''}`}
                            id="exampleFormControlSelect2"
                            name="stateIs"
                            value={stateIs}
                            onChange={handleFunc.handleOnChange}                            
                          >
                            <option>State</option>
                            {StateList.length > 0 && StateList.map((obj,index)=>(
                              <option value={obj.abbreviation} key={index}>{obj.name}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <select
                            className={`form-control ${errorBillingIs.city ? `form-input-error` : ''}`}
                            id="exampleFormControlSelect3"
                            placeholder="City"
                            name="city"
                            value={city}
                            onChange={handleFunc.handleOnChange}
                            disabled={cityList.length ? false : true}
                          >
                            {cityList.length > 0 ? cityList.map((obj,index)=>(
                              <option value={obj.city} key={index}>{obj.city}</option>
                            )) : <option value={city}>{city}</option>}
                          </select>
                        </div>
                      </div>
                  
                      <div className="col-sm-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className={`form-control ${errorBillingIs.zip ? `form-input-error` : ''}`}
                            placeholder="Zip"
                            name="zip"
                            value={zip}
                            onChange={handleFunc.handleOnChange}
                          />
                        </div>
                      </div>
                      {/* <div className="col-12">
                        <button className="btn btn-primary" onClick={handleFunc.handleBilling}>
                          Update
                        </button>
                      </div> */}
                    </div>
                  </form>
                </div>
  
            </React.Fragment>
        )
    }
}