import { call, put } from "redux-saga/effects";
import { GET_DOC_REVIEWS_SUCCESS_REQUEST, GET_DOC_REVIEWS_FAILURE_REQUEST } from '../../actions/Action';
import { Baseurl } from '../../utils/Baseurl';
import axios from "axios";

export function handleGetDocReviews(url) {
    let headers = {
        'Accept': "application/json",
        'Content-Type': 'application/json',
        'Pragma': 'no-cache'
    };
    return axios({
        method: "get",
        url: Baseurl + url,
        headers
    });
}


export function* getDocReviewsSaga(action) {
    try {

        const response = yield call(handleGetDocReviews, `api/review/get_review?parameter=${action.data}-doc_id`);
        // console.log("doc-reviews---->", response.data.data);
        const docreview = response.data.data;

        // dispatch a success action to the store with the new data
        yield put({ type: GET_DOC_REVIEWS_SUCCESS_REQUEST, docreview });
    } catch (error) {
        // dispatch a failure action to the store with the error
        yield put({ type: GET_DOC_REVIEWS_FAILURE_REQUEST, error });
    }
}

