import React from "react";
import Moment from "react-moment";
import Logo from "../../assets/img/smartappt-logo.svg";
const TransactionDetails = ({ transactiondata }) => {
  return (
    <React.Fragment>   
      <div className="table-responsive">
        <table className="table">
          <tbody>
            <tr>
              <th>Plan:</th>
              <td className="text-right">
                SmartAppointment Monthly Subscription
              </td>
            </tr>
            <tr>
              <th>Subscription Id:</th>
              <td className="text-right">{transactiondata.subscriptionId}</td>
            </tr>
            {transactiondata?.transId && <tr>
            <th>Transaction Id:</th>
            <td className="text-right">{transactiondata?.transId}</td>
          </tr>}
            <tr>
              <th>Payment method:</th>
              <td className="text-right">Card</td>
            </tr>
            <tr>
              <th>Card Number:</th>
              <td className="text-right">{transactiondata.payment_method.split(" ")[1]}</td>
            </tr>
            <tr>
              <th>Amount:</th>
              <td className="text-right">${transactiondata.amount}</td>
            </tr>
            <tr>
              <th>Transaction Date:</th>
              <td className="text-right">
                <Moment format="MM-DD-YYYY">
                  {transactiondata.created_at}
                </Moment>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};

export default TransactionDetails;

export const PrintableTransactionDetails = ({
  transactiondata,
  customerprofiledata,
}) => {
  const address = customerprofiledata?.profile.paymentProfiles[0].billTo;
  return (
    <div className="row print-pdf">
      <div className="col-md-12">
        <table
        className="header-printable"
          style={{
            width: "100%",
          }}
        >
          <tbody>
            <tr>
              <td align="left" width="40%">
                <div className="col-md-12">
                  <address>
                  <br />
                  <strong>
                      DATE:&nbsp;&nbsp;
                      <Moment format="MM-DD-YYYY">
                        {transactiondata.created_at}
                      </Moment>
                    </strong>
                  {/* <strong>
                      INVOICE NO. : &nbsp;&nbsp;{transactiondata.subscriptionId}
                    </strong> */}
                    
                    <br />                   
                    <br />
                    <br />
                    MedShed LLC
                    <br />
                    3 Holly Lane Setauket New York,
                    <br />
                    New York NY 11733
                    <br />
                    United States <br />
                    <abbr title="Phone">P:</abbr> (631) 237-1668
                    <br />
                    apilip@smartappt.com
                  </address>
                </div>
              </td>
              <td
                style={{
                  verticalAlign: "top",
                  marginBottom: "10px!important",
                  textAlign:"right"
                }}
                width="20%"
              >
             
                <br />
                <br />
                <br />
                <strong style={{ position: "relative", top:'-4px' }}>
                  {" "}
                  INVOICE TO:
                </strong>
              </td>
              <td />
              <td align="left" width="40%" style={{ verticalAlign: "top" }}>
                <div className="col-md-12">
                  <address>
                    <img src={Logo} />
                    <br />
                    <br />
                    <strong>
                      INVOICE NO. : &nbsp;&nbsp;{transactiondata.invoiceNumber}
                    </strong>
                    <br />
                    <strong />
                  </address>
                  <address>
                    <address style={{marginBottom:0}}>
                      {" "}
                      {address?.address}
                      <br />
                      {address?.city}
                      <br />
                      {address?.state}
                      <br />
                      {address?.zip}
                      <br />
                      P : {address?.phoneNumber}
                      {/* <br /> */}
                    </address>

                    <strong>
                      {address?.firstName} {address?.lastName}
                    </strong>
                    <br />
                    {customerprofiledata?.profile.email}
                  </address>
                </div>
              </td>
              <td />
            </tr>
          </tbody>
        </table>
        <br />
        <center>
          <table className="table">
            <thead>
              <tr style={{borderTop:'1px solid'}}>
                <th className="border-right-0" style={{ color: "green", fontSize: "15px"}}>
                  Subscription Id
                </th>
                <th className="border-right-0" style={{ color: "green", fontSize: "15px" }}>Plan</th>
                <th className="border-right-0" style={{ color: "green", fontSize: "15px" }}>
                  Transaction type
                </th>
                <th className="border-right-0" style={{ color: "green", fontSize: "15px" }}>
                  Transaction Id
                </th>
                <th className="border-right-0" style={{ color: "green", fontSize: "15px" }}>
                  Payment Method
                </th>
                <th
                  style={{
                    color: "green",
                    fontSize: "15px",
                    textAlign: "right",
                  }}
                >
                  Amount
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="billingTd border-right-0 border-bottom-0">{transactiondata.subscriptionId}</td>
                <td className="billingTd border-right-0 border-bottom-0" >
                  SmartAppointment Monthly Subscription
                </td>
                <td className="billingTd border-right-0 border-bottom-0">
                  {transactiondata.transaction_type}
                </td>
                <td className="billingTd border-right-0 border-bottom-0">{transactiondata.transId}</td>
                <td className="billingTd border-right-0 border-bottom-0">
                  Card
                  {/* {transactiondata.payment_method.split(" ")[0]} */}
                  </td>
                <td className="billingTd border-bottom-0" style={{ textAlign: "right" }}>
                  ${transactiondata.amount}
                </td>
              </tr>

              <tr>
                <td
                  className="billingTd border-right-0 border-bottom-0"
                  colSpan={5}
                  style={{ textAlign: "right" }}
                >
                  Subtotal
                </td>
                <td className="billingTd border-bottom-0" style={{ textAlign: "right" }}>
                  ${transactiondata.amount}
                </td>
              </tr>
              <tr>
                <td
                  className="billingTd border-right-0"
                  colSpan={5}
                  style={{ textAlign: "right" }}
                >
                  Total
                </td>
                <td style={{ textAlign: "right" }}>${transactiondata.amount}</td>
              </tr>
            </tbody>
          </table>
        </center>
      </div>
    </div>
  );
};
