export const HandleScheduleDate = (date) => {
    let weekday = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    let smonth = date.toLocaleString('en-GB', { month: 'short' });
    let sday = weekday[date.getDay()]
    let sdate = date.getDate();

    return { date: `${sday}, ${smonth} ${sdate}`, timezone: date };
}


export const handleDateFormat = (date) => {
    const year = date.slice(0, 4);
    const month = date.slice(4, 6);
    const day = date.slice(6, 8);
    const newDate = `${month}/${day}/${year}`;
    return newDate;
}
export const handleDateFormatMonthYear = (date) => {
if(date){
    const year = date.slice(0, 4);
    const month = date.slice(4, 6);
    // const day = date.slice(6, 8);
    const newDate = `${month}/${year}`;
    return newDate;
}
}

export const handleCorrectDateFormat = (date) => {
    const year = date.slice(0, 4);
    const month = date.slice(4, 6);
    const day = date.slice(6, 8);
    const newDate = `${year}-${month}-${day}`;
    return newDate;
}


export const handleTimeFormat = (time) => {
    const hour = time.slice(0, 2);
    const min = time.slice(2, 4);
    const finalHours = time.slice(0, 2) > 12 ? time.slice(0, 2) - 12 : time.slice(0, 2);
    const ts = hour > 11 ? 'PM' : 'AM';
    const complete_time = `${String(finalHours).padStart(2, '0')}:${min} ${ts}`;
    return complete_time;

}

export const convertDayToDate = (dayNum, year) => {

    var date = new Date();
    if (year) {
      date.setFullYear(year);
    }
    date.setMonth(0);
    date.setDate(0);
    var timeOfFirst = date.getTime(); 
    var dayMilli = 1000 * 60 * 60 * 24;
    var dayNumMilli = dayNum * dayMilli;
    date.setTime(timeOfFirst + dayNumMilli);
    return date;
      }