import React from "react"

const FooterLeftPanel=({handleSideBar, sidebarhide})=>{
    return(
        <div className="footer_left">
        <div className="arrow_section">
          <i
            onClick={() => handleSideBar(!sidebarhide)}
            className={`fas fa-chevron-${sidebarhide ? `right` : `left`}`}
            id="click_event"
            role="button"
          />
        </div>
      </div>
    )
}
export default FooterLeftPanel