import React, { Component } from 'react';
import { connect } from 'react-redux';
import { GET_DOC_DETAILS_REQUEST } from '../actions/Action';
// import axios from 'axios';
// import { Baseurl } from '../utils/Baseurl';
// import { AllownextUpcomingAppointmentMail } from '../component/AllownextUpcomingAppointmentMail';
// import { handleDateFormat, handleTimeFormat } from '../utils/DateMethod';

class CurrentAppointmentNotificationBar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            date: '',
            time: '',
            practice: [],
            doc_name: '',
            patientEmail: '',
            patient_id: '',
            patientMobile: '',
            hasRunning: '2',
        }
    }



    UNSAFE_componentWillMount() {
        let id = localStorage.getItem('uid');
        this.props.handleGetDocDetails(id);
    }

    UNSAFE_componentWillReceiveProps(props) {

        if (props.data) {
            this.setState({
                date: props.data.date,
                time: props.data.time,
                hasRunning: props.data.is_running,
                patient_id: props.data.patient_id,
                doc_name: `${props.docDetails.first_name} ${props.docDetails.last_name}`,
                practice: props.docDetails.practice && props.docDetails.practice.length > 0 ? props.docDetails.practice[0] : [],
            })
            // this.handleGetPatientDetails(props.data.patient_id);
        }
    }


    // /*----------------------------------------handle get patient email and details --------------------------------------*/

    // handleGetPatientDetails = (id) => {
    //     axios({
    //         method: 'get',
    //         url: Baseurl + `api/user/getuserdetails?userId=${id}`,
    //         headers: { 'content-type': 'application/json' }
    //     }).then((res) => {
    //         // console.log("patient-details------>", res);
    //         this.setState({ patientEmail: res.data.user.email });
    //     }).catch((error) => {
    //         console.log("in-error---->", error);
    //     })
    // }




    // /*-------------------------------------------------------handleOkbutton-----------------------------------------------------*/

    // handleIllBeReady = () => {

    //     var date = new Date();
    //     date.setSeconds(0);
    //     date.setMilliseconds(0);
    //     var currentTime = date.getTime();

    //     let id = localStorage.getItem('uid');
    //     let practiceName;
    //     let practice = this.state.practice.filter((k, i) => i === 0 ? k : null);
    //     practice.map((value) => {
    //         practiceName = `${value.practice_name}, ${value.practice_location}, ${value.zip}`;
    //         return null;
    //     });


    //     let data = {
    //         is_running: `0&${currentTime}`,

    //         mobile: this.state.patientMobile,
    //         mobile_message: `Your Appointment is on time with Dr. ${this.state.doc_name} in ${practiceName} 
    //         on ${handleDateFormat(this.state.date)} at ${handleTimeFormat(this.state.time)}. Be ready in 10 minutes.`,


    //         emailto_patient: this.state.patientEmail,
    //         patient_subject: 'Your Appoinment is on time',
    //         patient_message: AllownextUpcomingAppointmentMail(practiceName, this.state.date, this.state.time, this.state.doc_name),
    //     };
    //     axios({
    //         method: 'post',
    //         url: Baseurl + `api/appointment/allow_nextupcoming_appointment?date=${this.state.date}&time=${this.state.time}&patient_id=${this.state.patient_id}`,
    //         headers: { 'content-type': 'application/json' },
    //         data
    //     }).then((res) => {
    //         console.log("ok next appointment response------>", res);
    //         this.props.handleGetAppointment(id);
    //     }).catch((error) => {
    //         console.log("ok next appointment error------>", error);
    //     })

    // }





    render() {
        const [hasRunning, time] = this.state.hasRunning ? this.state.hasRunning.split('&') : '';
        var currentTime = Date.now();
        var expiryTime = parseInt(time) + 600000;
        const expired = expiryTime < currentTime;


        return (
            <React.Fragment>
                <div id="appointment_notification_row" style={{ display: (hasRunning === '0' || hasRunning === '1') && !expired ? 'table-row' : 'none', transition: '1s ease-in-out', background: (hasRunning === '0' || hasRunning === '1') && !expired ? '#36985f' : '#f8f8f8' }}>
                    <div className="doc_appointment-table_body_cell"></div>
                    <div className="doc_appointment-table_body_cell"></div>
                    <div className="doc_appointment-table_body_cell"></div>
                    <div className="doc_appointment-table_body_cell" style={{textTransform:'none'}}>
                        {
                            hasRunning === '0' ?
                                <p>Patient notified that the appointment is on-time.</p>
                                : hasRunning === '1' ?
                                    <p>Patient notified about delay.</p>
                                    :
                                    null
                        }
                    </div>
                    <div className="doc_appointment-table_body_cell"></div>
                    <div className="doc_appointment-table_body_cell"></div>
                    <div className="doc_appointment-table_body_cell"></div>

                </div>
            </React.Fragment>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        docDetails: state.doctorDetails,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        handleGetDocDetails: data => dispatch({ type: GET_DOC_DETAILS_REQUEST, data }),
        // handleGetAppointment: data => dispatch({ type: DOC_TODAY_APPOINTMENT_REQUEST, data })
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(CurrentAppointmentNotificationBar);