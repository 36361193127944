import React, { Component } from 'react';
import Modal from 'react-modal';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { Baseurl } from '../utils/Baseurl';
import { handleTimeFormat, handleCorrectDateFormat } from '../utils/DateMethod';
import { ToastMessage } from './ToastMessage';
import { toast } from 'react-toastify';
import queryString from 'query-string';
import { ConsultConfirmAppointmentMail } from './ConsultConfirmAppointmentMail';
import { ConsultConfirmAppointmentMailToPatient } from './ConsultConfirmAppointmentMailToPatient';


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    }
};

Modal.setAppElement('#root')
Modal.defaultStyles.overlay.backgroundColor = 'rgba(111, 111, 111, 0.75)';


class ConfirmAppointmentPopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen_modal: false,
            apointData: {},
            weekday:['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            numricDate:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31],
            disable_confirmation_email:0,
            SelectedTelemedicine:0,
            offer_telemedicine: '',
            openConfirm:0,
        }
    }



    UNSAFE_componentWillReceiveProps(props) {
        this.setState({
            isOpen_modal: props.hasModel,
            apointData: props.modalData,
            disable_confirmation_email:props.disable_confirmation_email,
        })
    }


    closeModal = () => {
        this.setState({ isOpen_modal: false, openConfirm : 0, offer_telemedicine : '' });
        this.props.handleSetReminder()
    }


    /*--------------------------------handleaddInsurance----------------------------*/

    handleAddInsurance = () => {
        this.props.history.push({ pathname: '/settings/insurance', state: { routename: 'schedule', doctor_id: this.props.match.params.id } });
    }



    /*--------------------------------handleaddInsurance----------------------------*/

    handleOk = () => {
        let values = queryString.parse(this.props.location.search);
        this.props.handleLoader(true);
        let clientuid = values.patient;
        this.state.apointData.appoint.availability[this.state.apointData.index].client = clientuid;
        this.props.setAppointData();

        let data = {
            appointDate: this.state.apointData.appoint.date,
            id: this.state.apointData.appoint.uid,
            availability: this.state.apointData.appoint.availability,
            time: this.state.apointData.appoint.availability[this.state.apointData.index].time,           
            practice_id: this.state.apointData.practice_id
        }


        axios({
            method: 'post',
            url: Baseurl + 'api/schedule/put_client',
            headers: { 'content-type': 'application/json' },
            data: data,

        }).then((res) => { 
            this.setState({ isOpen_modal: false });
              this.handleSaveConsultDetails();

        }).catch((error) => { 
            this.props.handleLoader(false);

            if (error.response.data.message === 'slotTaken') {
                toast.error(<ToastMessage message="Sorry, this slot is already booked." />, { position: toast.POSITION.TOP_CENTER });
                this.props.handleGetAppointmentData();
            }
            else {
                toast.error(<ToastMessage message={error.response.data.message} />, { position: toast.POSITION.TOP_CENTER });
                this.props.handleGetAppointmentData();
            }
        })
    }


/*--------------------------------------handleSaveConsultDetails--------------------------*/


    handleSaveConsultDetails = () => {

        let selfUid = localStorage.getItem('uid');
        let values = queryString.parse(this.props.location.search);
        let drSpeciality ="None";

        if(this.state.apointData.docSpeciality.length ==1){
            drSpeciality =this.state.apointData.docSpeciality[0];
        }
        if(this.state.apointData.docSpeciality.length  > 1){

            const filter1 = this.state.apointData.docSpeciality.filter(word => word !='Primary Care Doctor');
            const filter2 = filter1.filter(word => word !='Internist');
            
            if(filter2.length > 0){
              drSpeciality =filter2[0];
            }
        }



        let speciality = values.speciality ? values.speciality:this.state.apointData.docSpeciality.length ? drSpeciality:"None"

        let data = {
            patient_id: values.patient,
            patient_name: this.state.apointData.patientName,
            doc_from_id: selfUid,
            doc_to_id: this.state.apointData.appoint.uid,
            reff_speciality:speciality 
        }  

        axios({
            method:'post',
            url: Baseurl + 'api/dr_consult/save',
            data:data
        }).then((res)=>{ 
            this.handlePatientSaveAppointments(res.data.data._id);
        }).catch((error)=>{ 
            this.props.handleLoader(false);
        })
    }


    /*-------------------------------------------------handlePatientSaveAppointments----------------------------------------*/


        handlePatientSaveAppointments = (consultId) => {
            let selfUid = localStorage.getItem('uid');
            let appdate = new Date(handleCorrectDateFormat(this.state.apointData.appoint.date));
            //let day = appdate.toLocaleString('en-US', { weekday: 'short' });
            let day = this.state.weekday[appdate.getUTCDay()];
            let month = this.state.months[appdate.getMonth()];
            let date = this.state.numricDate[appdate.getDate()];
            //let date = appdate.getDate();
            //let date = appdate.toLocaleString('en-US', {timeZone: 'America/New_York', day: 'numeric'});
            let year = appdate.getFullYear();
            let fulappointDate = `${day} ${date} ${month} ${year}`;
            


            let values = queryString.parse(this.props.location.search);

            let practiceName = this.state.apointData.practice_arr[0];

            let appintTime = handleTimeFormat(this.state.apointData.appoint.availability[this.state.apointData.index].time);

            const yearnew = this.state.apointData.appoint.date.slice(0, 4);
            const monthnew = this.state.apointData.appoint.date.slice(4, 6);
            const daynew = this.state.apointData.appoint.date.slice(6, 8);
            const newDate = `${monthnew}/${daynew}/${yearnew}`;
            

            var textdata ="";
             if(this.state.apointData.appoint.availability[this.state.apointData.index].offer_telemedicine==1){
               textdata ="Telemedicine";
             }

            //  if(this.state.apointData.appoint.availability[this.state.apointData.index].offer_telemedicine==2){
            //     if(this.state.SelectedTelemedicine===1){
            //         textdata ="Telemedicine";
            //     }
                
            //  }

             let isMedicaid = this.props.isMedicaidInsurance;
             let disable_confirmation_email= this.state.disable_confirmation_email;
             var telemedicine = null;

             if(this.state.apointData.appoint.availability[this.state.apointData.index].offer_telemedicine==2){
                if(this.state.SelectedTelemedicine==="1"){
                     telemedicine = 1;
                     textdata ="Telemedicine";
                 }else{
                     telemedicine = 0  ; 
                 }
             }else{
                telemedicine = this.state.apointData.appoint.availability[this.state.apointData.index].offer_telemedicine; 
             }
             const consult_from_name = this.props.loginuserdetails.user
            let data = {

                patient_id: values.patient,
                doc_id: this.state.apointData.appoint.uid,
                date: this.state.apointData.appoint.date,
                time: this.state.apointData.appoint.availability[this.state.apointData.index].time,
                practice_id: this.state.apointData.practice_id,
                payment_mode: this.state.apointData.insuranceName,
                ConsultId: consultId,
                // consult_from: this.state.apointData.self_name, 
                consult_from : consult_from_name.first_name + ' ' + consult_from_name.last_name,
                consult_from_uid:selfUid,
                telemedicine: telemedicine,
                

               
                
                consultConfirmMessage:`Your consultation with Dr. ${this.state.apointData.docName} is confirmed for ${appintTime}, ${newDate} at ${practiceName}. This request was made by ${this.state.apointData.self_name}. https://smartappointment.com/view-appointments`,
                patientMobile:this.state.apointData.patientMobile,



                emailto_patient: this.state.apointData.patientEmail,
                patient_subject: 'Your '+textdata+' consultation appointment has been confirmed.',
                patient_message: ConsultConfirmAppointmentMailToPatient(this.state.apointData.self_name, this.state.apointData.docName, newDate, appintTime, practiceName, this.state.apointData.insuranceName,textdata,isMedicaid),


                emailto_doc: this.state.apointData.docEmail,
                emailto_grpdoc: this.state.apointData.grpDocEmail,
                doc_subject: 'Your '+textdata+' consultation appointment has been confirmed.',
                doc_message: ConsultConfirmAppointmentMail(this.state.apointData.self_name, this.state.apointData.patientName, newDate, appintTime, practiceName, this.state.apointData.insuranceName, this.state.apointData.patientEmail, this.state.apointData.patientMobile,textdata,isMedicaid,disable_confirmation_email)
            }



            axios({
                method: 'post',
                url: Baseurl + 'api/appointment/confirmpatientappointment',
                headers: { 'content-type': 'application/json' },
                data: data,
            }).then((response) => { 
                this.props.handleLoader(false);
                this.props.history.push('/');
            }).catch((error) => { 
                this.props.handleLoader(false);
            })
        }









    renderStatusZeroView = () => {
        return (<div>
            <h5 className="text-center" style={{ fontSize: 14 }}>As you do not have insurance, you have to opt for Cash pay.</h5>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <button onClick={this.handleOk} className="btn btn-md btn-filled btn-rounded" style={{ lineHeight: 0 }}>Ok</button>
                <button onClick={this.closeModal} className="btn btn-md btn-filled btn-rounded" style={{ lineHeight: 0 }}>Cancel</button>
            </div>
        </div>)
    }


    renderStatusOneView = () => {
        return (
            <div>
                <h5 className="text-center" style={{ fontSize: 14 }}>Patient did not fill insurance. you can't continue.</h5>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <button onClick={this.closeModal} className="btn btn-md btn-filled btn-rounded" style={{ lineHeight: 0 }}>OK</button>
                </div>
            </div>
        )
    }


    renderStatusThreeView = () => {
        return (
            <div>
                <h5 className="text-center" style={{ fontSize: 14 }}>There seems to be some problem with Patient's Insurance or it has expired. Please re-check Patient's Insurance settings.</h5>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <button onClick={this.closeModal} className="btn btn-md btn-filled btn-rounded" style={{ lineHeight: 0 }}>Ok</button>
                </div>
            </div>
        )
    }



    renderForthView = () => {
        return (
            <div>
                <h5 className="text-center" style={{ fontSize: 14 }}>This doctor does not support Patient's Insurance. You have to opt for Cash pay.</h5>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <button onClick={this.handleOk} className="btn btn-md btn-filled btn-rounded" style={{ lineHeight: 0 }}>Ok</button>
                    <button onClick={this.closeModal} className="btn btn-md btn-filled btn-rounded" style={{ lineHeight: 0 }}>Cancel</button>
                </div>
            </div>
        )
    }

    handleSelectTeleMedicine = () =>{
      this.setState({openConfirm:1});      
      this.setState({SelectedTelemedicine:this.state.offer_telemedicine},()=>{ 
      })
      return (<React.Fragment><Modal
                    isOpen={this.state.isOpen_modal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Modal"
                >
              <div>
                <h5 className="text-center" style={{ fontSize: 14 }}>Are you sure you want to make an appointment for this time?</h5>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <button onClick={this.handleOk} className="btn btn-md btn-filled btn-rounded" style={{ lineHeight: 0 }}>Ok</button>
                    <button onClick={this.closeModal} className="btn btn-md btn-filled btn-rounded" style={{ lineHeight: 0 }}>Cancel</button>
                </div>
             </div>
      </Modal></React.Fragment>)

    }

    handleSelectTele = () => {
        this.setState({openConfirm:1});      
        this.setState({SelectedTelemedicine:this.state.offer_telemedicine})
        let modalStatus = this.state.apointData.modalStatus;
        if(modalStatus === "2"){
            this.handleSelectTeleMedicine();
        }else if(modalStatus === "4"){
            this.renderForthView()
        }
    }


    handleTeleMedicine = () => {   
       return (<div style={{ textAlign: 'left' }}>
       <div className="time_container">
           <div style={{paddingLeft: 0, paddingRight: 5, alignItems: 'center', justifyContent: 'center' }}>
               <label style={{ marginRight: "20px" }} htmlFor="from"><p><strong>Please Select Provider Offer</strong></p></label>
               <div className="custom-control custom-radio">
                   <input type="radio" id="customCheck1" className="form-check-input" name="offer_telemedicine"   value="1" onChange={e => { this.setState({ offer_telemedicine: e.target.value }); }} />
                   <label className="custom-control-label" htmlFor="customCheck1" > &nbsp;&nbsp;Telemedicine</label>
               </div>
               <div className="custom-control custom-radio">
                   <input type="radio" className="form-check-input" name="offer_telemedicine" onChange={e => { this.setState({ offer_telemedicine: e.target.value }); }} id="customCheck2" value="0" />
                   <label className="custom-control-label" htmlFor="customCheck2">&nbsp;&nbsp; Office visit</label>
               </div>
             
           </div>
       </div>
       <div style={{ textAlign: 'center' }}>
       <button onClick={this.handleSelectTele} className="btn btn-md btn-filled btn-rounded" disabled= {!(this.state.offer_telemedicine.length) ? "disabled" : ''} style={{ lineHeight: 0 }}>SAVE</button>
       <button onClick={this.handleSelectTele} className="btn btn-md btn-filled btn-rounded" disabled={!(this.state.offer_telemedicine.length) ? "disabled" : ''} style={{ lineHeight: 0 }}>Cancel</button>
       </div>
   </div>)
    }



    handleHasInsuranceModal = () => {    
        return (           
            <div>
                <h5 className="text-center" style={{ fontSize: 14 }}>Are you sure you want to make an appointment for this time?</h5>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <button onClick={this.handleOk} className="btn btn-md btn-filled btn-rounded" style={{ lineHeight: 0 }}>Ok</button>
                    <button onClick={this.closeModal} className="btn btn-md btn-filled btn-rounded" style={{ lineHeight: 0 }}>Cancel</button>
                </div>
             </div>
           
        )
    }



    render() {
        let modalStatus = this.state.apointData.modalStatus;
        let teleStatus = this.state.apointData.teleStatus; 
       
        return (
            <React.Fragment>
                <Modal
                    isOpen={this.state.isOpen_modal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Modal"
                >
                    {modalStatus === '0' && this.renderStatusZeroView()}
                    {modalStatus === '1' && this.renderStatusOneView()}
                    {teleStatus === '2' && this.state.openConfirm===0 ? this.handleTeleMedicine() : modalStatus=== "2" ? this.handleHasInsuranceModal() :modalStatus === '4' ? this.renderForthView() : ()=>{}}   
                                 
                    {modalStatus === '3' && this.renderStatusThreeView()}
                    {/* {modalStatus === '4' && this.renderForthView()} */}
                </Modal>
            </React.Fragment>
        )
    }
}

export default withRouter(ConfirmAppointmentPopup);
