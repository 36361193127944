import React from "react";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";
import { GET_MONTHLY_APPOINTMENTS_REQUEST } from "../../../../actions/Action";
import { handleDateFormatMonthYear } from "../../../../utils/DateMethod";
class MonthlyVolume extends React.Component {
  state = {
    page: 1,
    limit: 10,
  };
  componentDidMount() {
    const uid = localStorage.getItem("uid");
    const { page, limit } = this.state;
    this.props.getAppointments({ uid: uid, page: page, limit: limit });
  }
  handlePageClick = (pages) =>{
    const uid = localStorage.getItem("uid");
    const { limit } = this.state;
    this.props.getAppointments({ uid: uid, page: pages.selected + 1, limit: limit });
  }
  render() {
    const { monthlyappointments, loading } = this.props;
    return (
      <div className="consults-sent">
       {!loading ? <div class="col-12 mt-3">
          <div className="doc_appointment-resp_table">
            <table className="table table-striped table-bordered" id="myTable">
            <thead
                style={{
                  background: "#70ad47",
                  color: "#FFF",
                }}
              >
                <tr>
                  <th scope="col">
                    <span
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        whiteSpace: "nowrap",
                      }}
                    >
                      MONTH YEAR
                    </span>
                  </th>
                  <th scope="col" className="br-0">
                    <span
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        whiteSpace: "nowrap",
                      }}
                    >
                      NEW PATIENTS
                    </span>
                  </th>
                  <th scope="col"  className="bl-0">
                    <span
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        whiteSpace: "nowrap",
                        textTransform:"uppercase"
                      }}
                    >
                      Appx. revenue generated
                    </span>
                  </th>
                  <th scope="col"  className="br-0">
                    <span
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Established
                    </span>
                  </th>
                  <th scope="col"  className="bl-0">
                    <span
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        whiteSpace: "nowrap",
                        textTransform:"uppercase"
                      }}
                    >
                      Appx. revenue generated
                    </span>
                  </th>
                  <th scope="col"  className="br-0">
                    <span
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        whiteSpace: "nowrap",
                      }}
                    >
                      TOTAL PATIENTS
                    </span>
                  </th>
                  <th scope="col"  className="bl-0">
                    <span
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        whiteSpace: "nowrap",
                        textTransform:"uppercase"
                      }}
                    >
                      Total revenue generated
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* {console.log(monthlyappointments,'monthlyappointments')} */}
                {monthlyappointments?.data.length ? ( 
                  monthlyappointments?.data.map((el) => {
                    const new_patient_revinue = el.newPatientCount * 100;
                    const est_patient_revinue = (el.count - el.newPatientCount) * 75;
                    const total_patient_revinue = new_patient_revinue + est_patient_revinue;
                    return (
                      <tr>
                        <td nowrap="nowrap">
                          {handleDateFormatMonthYear(el._id)}
                        </td>
                        <td nowrap="nowrap"  className="br-0">{el.newPatientCount}</td>
                        <td nowrap="nowrap"  className="bl-0">${new_patient_revinue}</td>
                        <td nowrap="nowrap"  className="br-0">{el.count - el.newPatientCount}</td>
                        <td nowrap="nowrap"  className="bl-0">${est_patient_revinue} </td>
                        <td nowrap="nowrap"  className="br-0">{el.count}</td>
                        <td nowrap="nowrap"  className="bl-0">${total_patient_revinue}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td className="text-center" colSpan={7}>
                      No data found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        :
        <div
        style={{
          height: "auto",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          alt="placeholder"
          src="https://luxysource.com/pub/static/frontend/Magento/luxysource/en_US/custom/img/preloader.gif"
          style={{ height: 80, width: 80 }}
        />
      </div>}

        {monthlyappointments?.totalPages ? (
          <ReactPaginate
            previousLabel={"previous"}
            nextLabel={"next"}
            pageCount={monthlyappointments.totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={this.handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    monthlyappointments: state.monthlyappointments,
    loading:state.isLoading
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAppointments: (data) =>
      dispatch({ type: GET_MONTHLY_APPOINTMENTS_REQUEST, data }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MonthlyVolume);
