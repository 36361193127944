import React from "react";
import Moment from "react-moment";
const ActiveSubscription = (props) => {
  const { subscriptionId, subscription_start_date, status } = props.subscriptiondata;
  const subscription_end_date = props.subscriptiondata?.subscription_end_date;
  const tdata = props?.transactiondata?.filter(
    (el) => el.subscriptionId === subscriptionId
  )[0];
  const getStatus=(status)=> {
    const avilableStatus = [
      "active",
      "canceled",
      "expired",
      "suspended",
      "terminated",
    ];
    return avilableStatus[status - 1].toUpperCase();
  }
  return (
    <div className="table-responsive">
      <table className="table">
        <tbody>
          <tr>
            <th>Plan:</th>
            <td className="text-right">
              SmartAppointment Monthly Subscription
            </td>
          </tr>
          <tr>
            <th>Subscription Id:</th>
            <td className="text-right">{subscriptionId}</td>
          </tr>
          {/* <tr>
              <th>Payment method:</th>
              <td className="text-right">Card</td>
            </tr> */}
            {/* <tr>
              <th>Card Number:</th>
              <td className="text-right">{tdata?.payment_method.split(" ")[1]}</td>
            </tr> */}
            {/* {tdata?.transId && <tr>
            <th>Transaction Id:</th>
            <td className="text-right">{tdata?.transId}</td>
          </tr>} */}
          <tr>
            <th>Transaction type:</th>
            <td className="text-right">{tdata?.transaction_type || "Purchase"}</td>
          </tr>
          <tr>
            <th>Amount:</th>
            <td className="text-right">${tdata?.amount || 300}</td>
          </tr>
          <tr>
            <th>Subscription Start Date:</th>
            <td className="text-right">
              <Moment format="MM-DD-YYYY">{subscription_start_date}</Moment>
            </td>
          </tr>
         {subscription_end_date && <tr>
            <th>Subscription End Date:</th>
            <td className="text-right">
              <Moment format="MM-DD-YYYY">{subscription_end_date}</Moment>
            </td>
          </tr>}
          {status && <tr>
            <th>Subscription Status:</th>
            <td className="text-right">
              {status == 1 && props.trailsubscriptiondata?.status ? "Will activate after trail period" : getStatus(status)}
            </td>
          </tr>}
        </tbody>
      </table>
    </div>
  );
};

export default ActiveSubscription;
