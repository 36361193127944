import React from "react";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux"; 
import { GetAPI } from "../../../../../utils/Api-interface";
import { handleDateFormatMonthYear } from "../../../../../utils/DateMethod";
class MonthlyNoShowGroup extends React.Component {
  state = {
    page: 1,
    limit: 15,
    provider: null,
    loading: false,
    noshowdata: []
  };
 

  getNoShowData = async (id, page, limit) => {
    this.setState({ loading: true })
    const response = await GetAPI(`api/consult_report/dr_monthly_no_show?doctor_uid=${id}&page=${page}&limit=${limit}`)
    if (response.status === 200) {
      this.setState({ noshowdata: response.data, loading: false })
    } else {
      this.setState({ loading: false })
    }

  }
  handlePageClick = (pages) => {
 
    const { limit, provider } = this.state;
    this.getNoShowData(provider, pages.selected + 1, limit);
  }
  handleProviderChange = (event) => {
    const provider = event.target.value;
    const { page, limit } = this.state;
    this.setState({ provider }, () => { this.getNoShowData(provider, page, limit); });
  };
  render() {
    const {subdrdata} = this.props;
    const { page, limit, provider, noshowdata, loading } = this.state;
    return (
      <div className="consults-sent">
        <div className="col-sm-12">
          <select
            style={{ width: "100%" }}
            value={provider}
            onChange={this.handleProviderChange}
          >
            <option value="">Select Provider for No Show Count</option>
            {subdrdata?.map((el, i) => {
              return (
                <option value={el.uid}>
                  {el.first_name} {el.last_name}
                </option>
              );
            })}
          </select>

        </div>
        <div class="col-12 mt-3">
          <div className="doc_appointment-resp_table">
            <table className="table table-striped table-bordered" id="myTable">
              <thead
                style={{
                  background: "#70ad47",
                  color: "#FFF",
                }}
              >
                <tr>
                  <th scope="col">
                    <span
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        whiteSpace: "nowrap",
                        textTransform: "uppercase"
                      }}
                    >
                      MONTH YEAR
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        whiteSpace: "nowrap",
                        textTransform: "uppercase"
                      }}
                    >
                      No Show Count
                    </span>
                  </th>



                </tr>
              </thead>
              <tbody>
                {loading ?
                  <tr><td colSpan={3}>
                    <div
                      style={{
                        height: "auto",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        alt="placeholder"
                        src="https://luxysource.com/pub/static/frontend/Magento/luxysource/en_US/custom/img/preloader.gif"
                        style={{ height: 80, width: 80 }}
                      />
                    </div>
                  </td></tr> :
                  <React.Fragment>
                    {noshowdata?.data?.length ? (
                      noshowdata?.data?.map((el) => {

                        return (
                          <tr>
                            <td nowrap="nowrap">
                              {handleDateFormatMonthYear(el._id)}
                            </td>
                            <td nowrap="nowrap">{el.noShowCount}</td>

                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td className="text-center" colSpan={7}>
                          No data found.
                    </td>
                      </tr>
                    )}
                  </React.Fragment>}

              </tbody>
            </table>
          </div>
        </div>

        {noshowdata?.totalPages ? (
          <ReactPaginate
            previousLabel={"previous"}
            nextLabel={"next"}
            pageCount={noshowdata.totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={this.handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    //  noshowdata: state.noshowdata,
    // loading:state.isLoading
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    // getAppointments: (data) =>
    //  dispatch({ type: GET_MONTHLY_APPOINTMENTS_REQUEST, data }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MonthlyNoShowGroup);
