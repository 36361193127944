import React from "react";
import DatePicker from "react-date-picker";
import Moment from "react-moment";
import * as moment from "moment";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";
import { GET_CONSULT_SENT_REQUEST } from "../../../../../actions/Action";
class ConsultsSentGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      from: new Date(new Date().toDateString()),
      to: new Date(new Date().toDateString()),
      provider: "",
      limit: 10,
      page: 1,
    };
  }
  updateData = () => {
    const { from, to, provider, limit, page } = this.state;
    // const doctor_uid = localStorage.getItem("uid");
    this.props.getConsultSent({
      doctor_uid:provider,
      from: moment(from).format("MM-DD-YYYY"),
      to: moment(to).format("MM-DD-YYYY"),
      limit,
      provider:"",
      page,
    });
  };
  fromDateOnChange = (from) => {
    this.setState({ from, page: 1 }, () => {
      this.updateData();
    });
  };
  toDateOnChange = (to) => {
    this.setState({ to, page: 1 }, () => {
      this.updateData();
    });
  };
  // componentDidMount() {
  //   this.updateData();
  // }

  handlePageChange = (pageIndex) => {
    this.setState({ page: pageIndex.selected + 1 }, () => {
      this.updateData();
    });
  };
  handleProviderChange = (e) => {
    const provider = e.target.value;
    this.setState({ provider, page: 1 }, () => {
      this.updateData();
    });
  };
  getSelectedProvider = (id) => {
    if (
      this.props.consultsent &&
      this.props.consultsent.doctorData.length &&
      id
    ) {
      const selectedprovider = this.props.consultsent.doctorData.filter(
        (el) => el.uid === id
      )[0];
      return `${selectedprovider.first_name} ${selectedprovider.last_name}`;
    } else {
      return "All Providers";
    }
  };
  render() {
    const { from, to, provider, limit } = this.state;
    const { consultsent,subdrdata, loading } = this.props;
    return (
      <div className="consults-sent">
        <div className="row">
          <div className="col-lg-8 col-sm-12">
            <select
              style={{ width: "100%" }}
              value={provider}
              onChange={this.handleProviderChange}
            >
              <option value="">Select Provider for Consults Sent</option>
              {subdrdata?.map((el, i) => {
                return (
                  <option value={el.uid}>
                    {el.first_name} {el.last_name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col-lg-2 col-sm-12">
            <DatePicker
              className="react-date-picker width-100"
              onChange={this.fromDateOnChange}
              value={from}
              clearIcon={null}
              format="MM-dd-y"
              dayPlaceholder="dd"
              monthPlaceholder="mm"
              yearPlaceholder="yyyy"
            />
          </div>

          <div className="col-lg-2 col-sm-12">
            <DatePicker
              className="react-date-picker width-100"
              onChange={this.toDateOnChange}
              value={to}
              clearIcon={null}
              format="MM-dd-y"
              dayPlaceholder="dd"
              monthPlaceholder="mm"
              yearPlaceholder="yyyy"
              minDate={from}
            />
          </div>
        </div>
        <div class="col-12 mt-3">
          <div className="doc_appointment-resp_table">
            <table className="table table-striped table-bordered" id="myTable">
              <thead
                style={{
                  background: "#70ad47",
                  color: "#FFF",
                }}
              >
                <tr>
                  <th scope="col">
                    <span
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        whiteSpace: "nowrap",
                      }}
                    >
                      PROVIDER NAME
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        whiteSpace: "nowrap",
                      }}
                    >
                      SPECIALITY
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        whiteSpace: "nowrap",
                      }}
                    >
                      CONSULTS SENT
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {loading ? 
                  <tr><td colSpan={3}>
                  <div
                    style={{
                      height: "auto",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      alt="placeholder"
                      src="https://luxysource.com/pub/static/frontend/Magento/luxysource/en_US/custom/img/preloader.gif"
                      style={{ height: 80, width: 80 }}
                    />
                  </div> 
                  </td></tr> :
                  
                  <React.Fragment>
                {consultsent?.data.length ? (
                  consultsent.data.map((el, i) => {
                    console.log(consultsent,'consultsent')
                    return (
                      <tr key={`consult_sent_${i}`}>
                        <td nowrap="nowrap">{el.docName}</td>
                        <td nowrap="nowrap">{el.reff_speciality}</td>
                        <td>{el.consult_count}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td className="text-center" colSpan={3}>
                      No data found.
                    </td>
                  </tr>
                )}
                </React.Fragment>}
              </tbody>
            </table>
          </div>
          {/* <p class="text-color">
            Total Consults Sent to {this.getSelectedProvider(provider)} in this
            period <Moment format="MM-DD-YYYY">{from}</Moment> to{" "}
            <Moment format="MM-DD-YYYY">{to}</Moment>
          </p> */}
        </div>

        {consultsent && consultsent.totalPages !== 0 && (
          <ReactPaginate
            previousLabel={"previous"}
            nextLabel={"next"}
            pageCount={consultsent?.totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={this.handlePageChange}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    consultsent: state.consultsent,
    loading: state.isLoading
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getConsultSent: (data) =>
      dispatch({ type: GET_CONSULT_SENT_REQUEST, data }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ConsultsSentGroup);
