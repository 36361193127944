import { call, put } from "redux-saga/effects";
import axios from "axios";
import {
  GET_MONTHLY_APPOINTMENTS_SUCCESS_REQUEST,
  GET_MONTHLY_APPOINTMENTS_FAILURE_REQUEST,
} from "../../actions/Action";
import { Baseurl } from "../../utils/Baseurl";

export function getMonthlyAppointmentsRequest(url) {
  let headers = {
    "Content-Type": "application/json",
    Pragma: "no-cache",
  };
  return axios({
    method: "get",
    url: Baseurl + url,
    headers,
  });
}

export function* getMonthlyAppointments(action) {
  try {
    const response = yield call(
      getMonthlyAppointmentsRequest,
      `api/consult_report/doctor_monthly_volume?doctor_uid=${action.data.uid}&page=${action.data.page}&limit=${action.data.limit}`
    );
    const monthlyappointments = response.data;

    // dispatch a success action to the store with the new data
    yield put({ type: GET_MONTHLY_APPOINTMENTS_SUCCESS_REQUEST, monthlyappointments });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: GET_MONTHLY_APPOINTMENTS_FAILURE_REQUEST, error });
  }
}
