import React from "react";
import ReactPaginate from "react-paginate";
import axios from "axios";
import { Baseurl } from "../../../../utils/Baseurl";
import { handleDateFormatMonthYear } from "../../../../utils/DateMethod";
class ConsultCancelledIncomming extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      consultCancelledIncommingData: null,
      page: 1,
      limit: 10, 
    }; 
  }
  getCancelledAppointments = (id, page, limit) => {
    axios
      .get(
        `${Baseurl}api/consult_report/consultCancelledIncomming?doctor_uid=${id}&page=${page}&limit=${limit}`
      )
      .then((res) => {
        if (res.status === 200) {
          this.setState({ consultCancelledIncommingData: res.data });
        }
        console.log(res, "res");
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
  componentDidMount() {
    const id = localStorage.getItem("uid");
    const { page, limit } = this.state;
    this.getCancelledAppointments(id, page, limit);
  }
  handlePageClick=(pages)=>{
    const id = localStorage.getItem("uid");
    const { limit } = this.state;
    this.setState({page:pages.selected + 1},()=>{
      this.getCancelledAppointments(id, pages.selected + 1, limit);
    })
  }
  render() {
    const { consultCancelledIncommingData } = this.state;
    console.log(consultCancelledIncommingData, "consultCancelledIncommingData");
    return (
      <div className="consults-sent">
        <div class="col-12 mt-3">
          <div className="doc_appointment-resp_table">
            <table className="table table-striped table-bordered" id="myTable">
              <thead
                style={{
                  background: "#70ad47",
                  color: "#FFF",
                }}
              >
                <tr>
                  <th scope="col">
                    <span
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        whiteSpace: "nowrap",
                      }}
                    >
                      MONTH YEAR
                    </span>
                  </th>
                  <th scope="col" className="text-left">
                    <span
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        whiteSpace: "nowrap",
                      }}
                    >
                      CANCELLED INCOMMING APPOINTMENTS COUNT
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {consultCancelledIncommingData?.data.length ? (
                  consultCancelledIncommingData?.data.map((el) => {
                    return (
                      <tr key={el._id}>
                        <td nowrap="nowrap">{handleDateFormatMonthYear(el._id)}</td>
                        <td nowrap="nowrap"className="text-left">{el.cancelledCount}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td className="text-center" colSpan={2}>
                      No data found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        {consultCancelledIncommingData?.totalPages ?
        <ReactPaginate
          previousLabel={"previous"}
          nextLabel={"next"}
          pageCount={consultCancelledIncommingData.totalPages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={this.handlePageClick}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        /> : ""}
      </div>
    );
  }
}

export default ConsultCancelledIncomming;
