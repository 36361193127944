import React,{ Component } from 'react';
import { GetAPI } from "../../utils/Api-interface";

export default class SubscriptionAccountInfo extends Component{

  state={
    planData : []
  }

  handleSubscriptionPlan = () =>{
    GetAPI(`api/subscription/get_plan`).then((res)=>{
      const response = res.data.data;
      if(response){
        this.setState({ planData : response })
      }
    }).catch((err)=>{
      console.log("errr", err)
    })
  }

   componentDidMount(){
    this.handleSubscriptionPlan();
   }

    render(){
        const{ planData } = this.state;
        const{ handleFunc } = this.props;
         return(
            <React.Fragment> 
                <div className="plan">
                  <div className="plan-wrapper">
                    <div className="add-img">
                      <img
                        src={require("../../assets/img/banner_img.png")}
                        className="img-fluid"
                      />
                    </div>

                    {/* {
                      planData.map((obj,index)=>{
                        return <React.Fragment>

                        </React.Fragment>
                      })
                    } */}
                    <div className="card mb-5 mb-lg-0">
                      <div className="card-body">
                        <h5 className="card-title text-muted  text-center">
                       {planData[0]?.plan_name}
                        </h5>
                        <h6 className="card-price text-center">
                          ${planData[0]?.amount}
                          {/* <span className="period">/{planData[0]?.type}</span> */}
                        </h6>
                        <hr />
                        <ul className="fa-ul">
                          <li>
                            <span className="fa-li">
                              <i className="fa fa-check" />
                            </span>
                            Single User
                          </li>
                          <li>
                            <span className="fa-li">
                              <i className="fa fa-check" />
                            </span>
                            View Patient Appointment
                          </li>
                          <li>
                            <span className="fa-li">
                              <i className="fa fa-check" />
                            </span>
                            View Reports
                          </li>
                          <li>
                            <span className="fa-li">
                              <i className="fa fa-check" />
                            </span>
                            Create Schedule for Appointment
                          </li>
                        </ul>
                        <button 
                          className="btn  btn-primary"
                          id="sus"
                          role="tabpanel"
                          aria-labelledby="profile-tab"
                          onClick={handleFunc.handleSubscription}
                        >
                          Start Subscription
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
            </React.Fragment>
        )
    }
}