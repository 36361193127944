import React, { Component } from 'react';
import { DOC_TODAY_APPOINTMENT_REQUEST, DOC_UPCOMING_APPOINTMENT_REQUEST, DOC_PAST_APPOINTMENT_REQUEST, GET_DOC_DETAILS_REQUEST } from '../../actions/Action';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import queryString from 'query-string';
import axios from 'axios';
import { Baseurl, patientSiteUrl, docSiteUrl } from '../../utils/Baseurl';
import { handleTimeFormat, handleDateFormat, handleCorrectDateFormat } from '../../utils/DateMethod';
import RecentOntimeDelayActions from '../../component/RecentOntimeDelayActions';
import { formatPhoneNumber } from 'react-phone-number-input';
import CurrentAppointmentNotificationBar from '../../component/CurrentAppointmentNotificationBar';
import DocAppointmentRow from '../../component/DocAppointmentRow';
import RecentAppointmentMobileView from '../../component/RecentAppointmentMobileView';
import UpcomingPastAppointmentMobileView from '../../component/UpcomingPastAppointmentMobileView';
import { ConfirmAppointmentMailToDoctor } from '../../component/ConfirmAppointmentMailToDoctor';
import { ConfirmAppointmentMailToPatient } from '../../component/ConfirmAppointmentMailToPatient';
import { CancelAppointmentMailToDoctor } from '../../component/CancelAppointmentMailToDoctor';
import { CancelAppointmentMailToPatient } from '../../component/CancelAppointmentMailToPatient';
import { ReScheduleAppointmentMailToDoctor } from '../../component/ReScheduleAppointmentMailToDoctor';
import { ReScheduleAppointmentMailToPatient } from '../../component/ReScheduleAppointmentMailToPatient';
import { HandlePatientReviewEmail } from '../../component/HandlePatientReviewEmail';
import { AllownextUpcomingAppointmentMail } from '../../component/AllownextUpcomingAppointmentMail';
import { DelaynextUpcomingAppointmentMail } from '../../component/DelaynextUpcomingAppointmentMail';
import ReactPaginate from 'react-paginate';
import Modal from 'react-modal';
import '../../styles/Dashboard.css';
import socketIOClient from "socket.io-client";
import WebNotification from 'react-web-notifications';



const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    }
};

const customStylesIns = {
    content: {
        top: '48%',
        left: '50%',
        right: 'auto',
        bottom: '-30%',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width:'60%'
    }
};


Modal.defaultStyles.overlay.backgroundColor = 'rgba(111, 111, 111, 0.75)';
Modal.defaultStyles.overlay.zIndex = 100;



class Dashboard extends Component {
    interval = null;

    constructor(props) {
        super(props);
        this.state = {
            docAppointments: [],
            recentAppointment: [],
            upcomingAppointment: [],
            action: 'Select',
            doc_name: '',
            patientEmail: '',
            docPracticeLocation: [],
            practice: [],
            reScheduleCurrnedate: '',
            reScheduleCurrnetime: '',
            reScheduleNewdate: '',
            reScheduleNewtime: '',
            patientId: '',
            re_schedulePopup: false,
            scheduleData: [],
            newTimeArr: [],
            docEmail: '',
            groupDrEmail: '',
            rescheduleStatusBy: '',
            isLoading: false,
            ispopup: false,
            modalData: {},
            medicaid: '',
            check: 0,

            today_limit: 10,
            today_pageCount: 0,

            upcoming_limit: 10,
            upcoming_pageCount: 0,

            past_limit: 10,
            past_pageCount: 0,
            subdrdata: [],
            groupdr: "",
            groupRole: 7,
            doctor_id: localStorage.getItem('uid'),
            todayPage: 1,
            upcomingPage: 1,
            pastPage: 1,
            weekday: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            numricDate: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
            response: false,
            endpoint: Baseurl,
            isPatientLoading: false,
            isOpenModal: false,
            insuranceData: [],
            payerName: '',
            InsStatus: '',
            InsPatientId: ''
        }
    }


    /*----------------------------------------------handle-componentWillMount--------------------------------------*/

    UNSAFE_componentWillMount() {
        window.scrollTo(0, 0);
        let id = localStorage.getItem('uid');
        this.props.handleGetDocDetails(id);
        this.handleGetScheduleData();
        this.props.handleTodayAppointmentData({ id: this.state.doctor_id, limit: this.state.today_limit, page: this.state.todayPage });
        this.props.handleUpcomingAppointmentData({ id: this.state.doctor_id, limit: this.state.upcoming_limit, page: this.state.upcomingPage });
        this.props.handlePastAppointmentData({ id: this.state.doctor_id, limit: this.state.past_limit, page: this.state.pastPage });

    }
    componentDidMount() {
        let id = localStorage.getItem('uid');
        this.setState({ doctor_id: id })
        this.handleGetDoctorDetails();
        //console.log('hjhhjjhj'); 
        /* this.interval = setInterval(() => {
             this.props.handleTodayAppointmentData({ id: this.state.doctor_id, limit: this.state.today_limit, page: this.state.todayPage });
             this.props.handleUpcomingAppointmentData({ id: this.state.doctor_id, limit: this.state.upcoming_limit, page: this.state.upcomingPage });
             // this.props.handlePastAppointmentData({ id: id, limit: this.state.past_limit, page: this.state.pastPage });
         }, 20000)*/

        const { endpoint } = this.state;
        const socket = socketIOClient(endpoint);
        socket.on("FromAPI", data => {

            if (data.dr_uid === id || data.group_dr_uid === id) {
                this.props.handleTodayAppointmentData({ id: this.state.doctor_id, limit: this.state.today_limit, page: this.state.todayPage });
                this.props.handleUpcomingAppointmentData({ id: this.state.doctor_id, limit: this.state.upcoming_limit, page: this.state.upcomingPage });
                this.setState({ response: false });
            }



        });
    }


    handleFilter(e) {
        this.props.handleTodayAppointmentData({ id: e.target.value, limit: this.state.today_limit, page: this.state.todayPage });
        this.props.handleUpcomingAppointmentData({ id: e.target.value, limit: this.state.upcoming_limit, page: this.state.upcomingPage });
        this.props.handlePastAppointmentData({ id: e.target.value, limit: this.state.past_limit, page: this.state.pastPage });
    }

    handleGetDoctorDetails = () => {
        let userId = localStorage.getItem("uid");
        this.setState({ loading: true });
        axios({
            method: "get",
            url: Baseurl + `api/doc/getDetails?userId=${userId}`,
            headers: { "content-type": "application/json" },
        }).then((res) => {

            this.setState({ doctor_id: res.data.user.uid, groupdr: res.data.user._id, groupRole: res.data.user.groupRole });
            let data = { "refid": res.data.user.drLinkToGroup, grpuid: userId }
            localStorage.setItem("doctorStatus", res.data.user.status)
            axios({
                method: "post",
                url: Baseurl + `api/doc/get_group_doctors`,
                headers: { "content-type": "application/json" },
                data,
            }).then((drdata) => {
                this.setState({ subdrdata: drdata.data.data });
            })
                .catch((error) => {
                    this.setState({ loading: false });
                    console.log("error--->", error);
                });
        })
            .catch((error) => {
                this.setState({ loading: false });
                console.log("error--->", error);
            });
    };



    componentWillUnmount() {
        clearInterval(this.interval);
    }


    /*-----------------------------handle filter and sort current date appintment-----------------------------*/

    handleFilterAndSortCurrentDateAppointment = (data) => {
        const currentAppointmentSort = data.sort((a, b) => {
            var keyA = parseInt(a.time),
                keyB = parseInt(b.time);
            if (keyB > keyA) return -1;
            if (keyA < keyB) return 1;
            return 0;
        });

        return currentAppointmentSort;
    }






    /*-------------------------------------handle get schedule time and date--------------------*/

    handleGetScheduleData = () => {
        let id = localStorage.getItem('uid');
        axios({
            method: 'get',
            url: Baseurl + `api/schedule/get_schedules?uid=${id}`,
            headers: { 'content-type': 'application/json' },
        }).then((response) => {
            // console.log("get-appointments-response------------->", response.data);
            this.setState({ scheduleData: response.data.data });
        }).catch((error) => {
            console.log("get-appointments-error------------->", error);
        })
    };


    /*----------------------------------------------handle-componentWillReceiveProps--------------------------------------*/

    UNSAFE_componentWillReceiveProps(props) {
        // console.log("doc_today_appintment-props--->", props.doc_today_appintment);
        // console.log("doc_upcoming_appointment-props--->", props.doc_upcoming_appointment.data);
        // console.log("doc_past_appointment-props--->", props.doc_past_appointment);

        //console.log('props.doc_today_appintment',props.doc_today_appintment.data);

        if (props.doc_today_appintment) {

            this.setState({
                recentAppointment: props.doc_today_appintment.data && props.doc_today_appintment.data.length > 0 ? this.handleFilterAndSortCurrentDateAppointment(props.doc_today_appintment.data) : [],
                upcomingAppointment: props.doc_upcoming_appointment.data && props.doc_upcoming_appointment.data.length > 0 ? props.doc_upcoming_appointment.data : [],
                docAppointments: props.doc_past_appointment.data && props.doc_past_appointment.data.length > 0 ? props.doc_past_appointment.data : [],
                today_pageCount: props.doc_today_appintment.todayCount / 10,
                upcoming_pageCount: props.doc_upcoming_appointment.upcomint_count / 10,
                past_pageCount: props.doc_past_appointment.pastCount / 10,
                doc_name: `${props.docDetails.first_name} ${props.docDetails.last_name}`,
                docEmail: props.docDetails.email,
                groupDrEmail: props.docDetails.groupDrEmail,
                practice: props.docDetails.practice && props.docDetails.practice.length > 0 ? [props.docDetails.practice[0]] : [],
                docPracticeLocation: props.docDetails.practice && props.docDetails.practice.length > 0 ? props.docDetails.practice : []
            })
        }
    }

    closeModal = () => {
        this.setState({ isOpenModal: false });
    }

    closeModal = () => {
        this.setState({ InsPatientId: false });
    }

    handleGetInsuranceDetails = (date, value) => {
        const { docDetails } = this.props;
        this.setState({ isOpenModal: true, isPatientLoading: true }, () => {
            axios({
                method: "get",
                url: Baseurl + `api/user/getuserdetails?userId=${value.patient_id}`,
                headers: { "content-type": "application/json" },
            }).then((res) => {
                let response = res.data
                // console.log('Insurance res', response)
                let first = value.doctor_name.split(' ')[0]
                let last = value.doctor_name.split(' ')[1]
                let today = date.split('-')[1] + '/' + date.split('-')[2] + '/' + date.split('-')[0]
                // console.log('data props', value.doctor_name.split(' ')[0], value.doctor_name.split(' ')[1])
                let data = { "d_firstname": first, "d_lastname": last, "npi": docDetails.npi_number, "payerCode": response.user.payerCode, "dob": response.user.dob, "memberID": response.user.insurance_id, "p_firstname": response.user.first_name, "p_lastname": response.user.last_name, "doS_StartDate": today, "doS_EndDate": today, "user_obj_id": response.user._id, "doctor_obj_id": docDetails._id }
                // console.log('data props', data)
                axios({
                    method: "post",
                    url: Baseurl + `api/user/GetInsuranceDetails  `,
                    headers: { "content-type": "application/json" },
                    data,
                }).then((resp) => {
                    const responseData = resp.data.data?.healthBenefitPlanCoverageServiceType?.serviceTypeSections
                    // console.log('insuranceData ===', responseData)
                    this.setState({ insuranceData: responseData.length > 0 ? responseData : [], isPatientLoading: false, payerName: resp.data.data.payerName, InsStatus: resp.data.data.status });
                })
                    .catch((error) => {
                        this.setState({ isOpenModal: false, isPatientLoading: false });
                        console.log("error--->", error);
                    });
            })
                .catch((error) => {
                    this.setState({ isOpenModal: false, isPatientLoading: false });
                    console.log("error--->", error);
                });
        });

    };

    /*----------------------------------------------handle confrim option --------------------------------------*/

    handleUpdateAppointmentStatus = (value) => {
        let docId = localStorage.getItem('uid');
        axios({
            method: 'get',
            url: Baseurl + `api/user/getuserdetails?userId=${value.patient_id}`,
            headers: { 'content-type': 'application/json' },
        }).then(res => {
            //console.log("patient-details 1------>", res.isMedicaid);
            let practiceName;
            let practice = this.state.practice.filter((k, i) => i === 0 ? k : null);
            practice.map((value) => {
                practiceName = `${value.practice_name}, ${value.practice_location}, ${value.zip}`;
                return null;
            })


            if (res.isMedicaid == 1) {
                this.setState({ medicaid: " (Medicaid)" });
            }

            let data = {
                emailto_patient: res.data.user.email,
                patient_subject: 'Your appointment has been confirmed !',
                patient_message: ConfirmAppointmentMailToPatient(practiceName, value.date, value.time, this.state.doc_name),

                emailto_doc: this.state.docEmail,
                emailto_grpdoc: this.state.groupDrEmail,
                doc_subject: 'Your appointment has been confirmed !',
                doc_message: ConfirmAppointmentMailToDoctor(practiceName, value.date, value.time, `${res.data.user.first_name} ${res.data.user.last_name}`)

            }

            axios({
                method: 'post',
                url: Baseurl + `api/appointment/doc_confirm_appointment?appointDate=${value.date}&appointTime=${value.time}&status_by=${docId}`,
                headers: { 'content-type': 'application/json' },
                data: data
            }).then((res) => {
                toast.success('Appointment Confirmed !', { position: toast.POSITION.TOP_CENTER });
                // console.log(res);
                this.props.handleTodayAppointmentData({ id: docId, limit: this.state.today_limit, page: this.state.todayPage });
                this.props.handleUpcomingAppointmentData({ id: docId, limit: this.state.upcoming_limit, page: this.state.upcomingPage });

            }).catch((error) => {
                console.log("update-appointment confirmation status error--------->", error);
            })



        }).catch(error => {
            console.log("getuser email error--->", error);
        })

    }


    /*----------------------------------------------handle re-schedule option--------------------------------------*/

    handledocRescheduleButton = () => {
        let docId = localStorage.getItem('uid');

        /*------------------------new date client-------------*/
        let availarr = [];
        let previousAvailability = [];

        this.state.scheduleData.map(value => {
            if (value.date === this.state.reScheduleNewdate) {
                const par = JSON.parse(value.availability);
                par.map(v => {
                    if (v.time === this.state.reScheduleNewtime) {
                        v.client = this.state.patientId;
                    }
                    return null;
                });
                availarr.push(JSON.stringify(par));
            }
            return null;
        });
        /*------------------------previous date client-------------*/

        let [, date, time] = this.state.rescheduleStatusBy.split('&');

        this.state.scheduleData.map(value => {
            if (value.date === date) {
                const prevPar = JSON.parse(value.availability);
                prevPar.map(v => {
                    if (v.time === time) {
                        v.client = null;
                    }
                    return null;
                });
                previousAvailability.push(JSON.stringify(prevPar));
            }
            return null;
        });



        axios({
            method: 'get',
            url: Baseurl + `api/user/getuserdetails?userId=${this.state.patientId}`,
            headers: { 'content-type': 'application/json' },
        }).then(res => {
            //console.log("patient-details 2------>", res.isMedicaid);
            let practiceName;
            let practice = this.state.practice.filter((k, i) => i === 0 ? k : null);
            practice.map((value) => {
                practiceName = `${value.practice_name}, ${value.practice_location}, ${value.zip}`;
                return null;
            })

            if (res.isMedicaid == 1) {
                this.setState({ medicaid: " (Medicaid)" });
            }

            let data = {
                patient_id: this.state.patientId,
                new_date: this.state.reScheduleNewdate,
                new_time: this.state.reScheduleNewtime,
                availability: availarr,
                previousAvailability: previousAvailability,
                reScheduleStatusBy: this.state.rescheduleStatusBy,

                emailto_patient: res.data.user.email,
                patient_subject: 'Your appointment has been Re-Scheduled !',
                patient_message: ReScheduleAppointmentMailToPatient(
                    practiceName,
                    this.state.reScheduleCurrnedate,
                    this.state.reScheduleCurrnetime,
                    this.state.reScheduleNewdate,
                    this.state.reScheduleNewtime,
                    this.state.doc_name,
                    docId,
                    this.state.patientId
                ),


                emailto_doc: this.state.docEmail,
                emailto_grpdoc: this.state.groupDrEmail,
                doc_subject: 'Your appointment has been Re-Scheduled !',
                doc_message: ReScheduleAppointmentMailToDoctor(
                    practiceName,
                    this.state.reScheduleCurrnedate,
                    this.state.reScheduleCurrnetime,
                    this.state.reScheduleNewdate,
                    this.state.reScheduleNewtime,
                    `${res.data.user.first_name} ${res.data.user.last_name}`
                )
            };

            axios({
                method: 'post',
                url: Baseurl + `api/appointment/doc_reSchedule_appointment?appointDate=${this.state.reScheduleCurrnedate}&appointTime=${this.state.reScheduleCurrnetime}&status_by=${docId}`,
                headers: { "content-type": "application/json" },
                data: data
            }).then((res) => {
                toast.success('Appointment Re-Scheduled !', { position: toast.POSITION.TOP_CENTER });
                // console.log(res);
                this.setState({ re_schedulePopup: false });
                this.props.handleTodayAppointmentData({ id: docId, limit: this.state.today_limit, page: this.state.todayPage });

            }).catch((error) => {
                console.log("Appointment Re-Schedule error---->", error);
                this.setState({ re_schedulePopup: false });
            })

        }).catch((error) => {
            console.log("getuser email error--->", error);
        })

    }


    /*--------------------------------------------------------handle cancel option------------------------------------------*/

    handleCancelButton = (value) => {


        let docId = localStorage.getItem('uid');

        let availarr = [];
        this.state.scheduleData.map(data => {
            if (data.date === value.date) {
                const par = JSON.parse(data.availability);
                par.map(v => {
                    if (v.time === value.time) {
                        v.client = null;
                    }
                    return null;
                });
                availarr.push(JSON.stringify(par));
            }
            return null;
        });

        axios({
            method: 'get',
            url: Baseurl + `api/user/getuserdetails?userId=${value.patient_id}`,
            headers: { 'content-type': 'application/json' },
        }).then((res) => {
            //console.log("patient-details 3------>", res.isMedicaid);
            let practiceName;
            let practice = this.state.practice.filter((k, i) => i === 0 ? k : null);
            practice.map((value) => {
                practiceName = `${value.practice_name}, ${value.practice_location}, ${value.zip}`;
                return null;
            })

            if (res.isMedicaid == 1) {
                this.setState({ medicaid: " (Medicaid)" });
            }

            let data = {

                availability: availarr,

                emailto_patient: res.data.user.email,
                patient_subject: 'Your appointment has been Cancelled !',
                patient_message: CancelAppointmentMailToPatient(practiceName, value.date, value.time, this.state.doc_name),



                emailto_doc: this.state.docEmail,
                emailto_grpdoc: this.state.groupDrEmail,
                doc_subject: 'Your appointment has been Cancelled !',
                doc_message: CancelAppointmentMailToDoctor(practiceName, value.date, value.time, `${res.data.user.first_name} ${res.data.user.last_name}`)
            };

            let confirm = window.confirm(`Are you sure? You want to cancel this Appointment time with ${res.data.user.first_name} ${res.data.user.last_name}? `);
            if (confirm) {
                axios({
                    method: 'post',
                    url: Baseurl + `api/appointment/doc_cancel_appointment?appointDate=${value.date}&appointTime=${value.time}&status_by=${docId}`,
                    headers: { "content-type": "application/json" },
                    data: data,
                }).then((res) => {
                    toast.success('Appointment Cancelled !', { position: toast.POSITION.TOP_CENTER });
                    // console.log(res);
                    this.props.handleTodayAppointmentData({ id: docId, limit: this.state.today_limit, page: this.state.todayPage });
                    this.props.handleUpcomingAppointmentData({ id: docId, limit: this.state.upcoming_limit, page: this.state.upcomingPage });

                }).catch((error) => {
                    console.log("cancel appointment-error----->", error);
                })
            } else {
                return false;
            }

        }).catch((error) => {
            console.log("getuser email error--->", error);
        })

    }


    /*--------------------------------------setting data of doctor to re-schedule---------------------------------*/

    setScheduleData = (value) => {
        this.setState({ rescheduleStatusBy: value.statusBy, re_schedulePopup: true, reScheduleCurrnedate: value.date, reScheduleCurrnetime: value.time, patientId: value.patient_id });
    };




    /*------------------------------------------------------render re-schedule-newTime-------------------------------------------*/

    newDateOnchange = (e) => {

        this.state.scheduleData.map(value => {
            if (value.date === e.target.value) {
                this.setState({ reScheduleNewdate: e.target.value, newTimeArr: JSON.parse(value.availability) });
            } else {
                return null;
            }
            return null;
        });
    }

    /*------------------------------------------------------handle backdrop-------------------------------------------*/

    handleBackdrop = () => {
        this.setState({ re_schedulePopup: false });
    }


    /*------------------------------------------------handleChangeISrunning-status--------------------------------------------*/

    handleChangeIsRunning = (value) => {
        let id = localStorage.getItem('uid');
        const [, runningTime] = value.is_running.split('&');
        let time = Number(runningTime) + 2400000;
        var currentDate = new Date();
        currentDate.setSeconds(0);
        currentDate.setMilliseconds(0);
        var currentTime = currentDate.getTime();

        if (this.state.check === 0 && time <= currentTime) {
            let data = {
                is_running: '3',
            }
            this.setState({ check: 1 })
            axios({
                method: 'post',
                url: Baseurl + `api/appointment/update_completeAppointment?date=${value.date}&time=${value.time}&patient_id=${value.patient_id}`,
                headers: { 'content-type': 'application/json' },
                data
            }).then((res) => {
                // console.log("check------->", this.state.check);
                // console.log("update_complete_appointment-response------->", res);

                this.props.handleTodayAppointmentData({ id: id, limit: this.state.today_limit, page: this.state.todayPage });
                this.setState({ check: 1 })
                //this.handleSendMailAfterAppointmentFinished(value);
            }).catch((error) => {
                console.log("update_complete_appointment-error--------->", error);
            })
        }
    }

    /*---------------------------------------handleSendMailAfterAppointmentFinished--------------------------------*/


    handleSendMailAfterAppointmentFinished = (app) => {

        axios({
            method: 'get',
            url: Baseurl + `api/user/getuserdetails?userId=${app.patient_id}`,
            headers: { 'content-type': 'application/json' }
        }).then((res) => {
            // console.log("patient-details------>", res.data.user.email);
            //console.log("patient-details 4------>", res.isMedicaid);
            if (res.isMedicaid == 1) {
                this.setState({ medicaid: " (Medicaid)" });
            }
            let data = {
                mobile: res.data.user.mobile,
                mobile_message: `Hope you liked appointment with Dr. ${this.state.doc_name}. We wish you a quick recovery. Review your doctor by clicking on the following link ${patientSiteUrl}/searchdoc_profile/${app.doc_id}`,

                emailto_patient: res.data.user.email,
                patient_subject: `Dr. ${this.state.doc_name} needs your feedback.`,
                patient_message: HandlePatientReviewEmail(this.state.doc_name, app.doc_id),
            }

            axios({
                method: 'post',
                url: Baseurl + 'api/appointment/handlePatientReviewEmail',
                headers: { 'content-type': 'application/json' },
                data
            }).then((res) => {
                // console.log("patient-review-email-response------>", res);
            }).catch(error => {
                console.log("patient-review-email-error------>", error);
            })

        }).catch((error) => {
            console.log("in-error---->", error);
        })
    }


    handleConsultsButton = (data) => {
        this.props.history.push(`/searchdoc?name=&practice_name=&insurance_provider=&zip=&patient=${data.patient_id}&m=${data.patient_Medicaid}`);
    }

    closeModal = () => {
        this.setState({ ispopup: false, modalFor: '', modalData: {} });
    }


    setModalState = (modalFor, data) => {

        this.setState({ ispopup: true, modalFor, modalData: data });
    }




    handleModalOk = () => {
        if (this.state.modalFor === 'Ready') {
            this.handleIllBeReady()
        } else if (this.state.modalFor === 'Delay') {
            this.handleDelay()
        } if (this.state.modalFor === 'NoShow') {
            if (this.state.modalData.status = "Confirmed") {
                this.setState({ isLoading: true })
                const id = localStorage.getItem('uid');
                axios({
                    method: 'post',
                    url: Baseurl + `api/appointment/doc_no_show_appointment?appointment_id=${this.state.modalData._id}&status_by=${this.state.modalData.statusBy}`,
                    headers: { 'content-type': 'application/json' },
                    // data
                }).then((res) => {
                    // console.log("ok next appointment response------>", res);
                    this.props.handleTodayAppointmentData({ id: id, limit: this.state.today_limit, page: this.state.todayPage });
                    this.props.handlePastAppointmentData({ id: this.state.doctor_id, limit: this.state.past_limit, page: this.state.pastPage });
                    this.setState({ isLoading: false, ispopup: false, modalFor: '', modalData: {} });
                }).catch((error) => {
                    this.setState({ isLoading: false, ispopup: false, modalFor: '', modalData: {} });
                    // console.log("ok next appointment error------>", error);
                })
            }
        } else {
            this.handleCancelAppointment()
        }
        // this.state.modalFor === 'Ready' ?
        //     this.handleIllBeReady() :
        //     this.state.modalFor === 'Delay' ?
        //         this.handleDelay() :
        //         this.handleCancelAppointment()
    }


    /*----------------------------------------------handleIllBeReady----------------------------------------*/

    handleIllBeReady = () => {

        this.setState({ isLoading: true });
        //let id = localStorage.getItem('uid');




        let locationdata = this.state.docPracticeLocation.length > 0 ? this.state.docPracticeLocation : this.state.modalData.doctor_location;
        //console.log('locationdata',locationdata);

        let id = this.state.modalData.doc_id;

        var date = new Date();
        date.setSeconds(0);
        date.setMilliseconds(0);
        var currentTime = date.getTime();

        let practiceName;
        let practice = locationdata.filter(k => k._id === this.state.modalData.practice_id);
        practice.map((value) => {
            practiceName = `${value.practice_name}, ${value.practice_location}, ${value.zip}`;
            return null;
        });

        let year1 = this.state.modalData.date.slice(0, 4);
        let month1 = this.state.modalData.date.slice(4, 6);
        let day1 = this.state.modalData.date.slice(6, 8);
        let newDate1 = `${month1}/${day1}/${year1}`;

        let data = {
            is_running: `0&${currentTime}`,
            practice_id: this.state.modalData.practice_id,
            mobile: this.state.modalData.patientMobile,
            mobile_message: `Your Appointment is on time with Dr. ${this.state.modalData.doctor_name} in ${practiceName} 
                on ${newDate1} at ${handleTimeFormat(this.state.modalData.time)}. Be ready in 10 minutes.`,


            emailto_patient: this.state.modalData.patientEmail,
            patient_subject: 'Your Appoinment is on time',
            patient_message: AllownextUpcomingAppointmentMail(practiceName, this.state.modalData.date, this.state.modalData.time, this.state.modalData.doctor_name),
        };



        axios({
            method: 'post',
            url: Baseurl + `api/appointment/allow_nextupcoming_appointment?date=${this.state.modalData.date}&time=${this.state.modalData.time}&patient_id=${this.state.modalData.patient_id}`,
            headers: { 'content-type': 'application/json' },
            data
        }).then((res) => {
            // console.log("ok next appointment response------>", res);
            this.setState({ isLoading: false, ispopup: false, modalFor: '', modalData: {} });
            this.props.handleTodayAppointmentData({ id: id, limit: this.state.today_limit, page: this.state.todayPage });
        }).catch((error) => {
            // console.log("ok next appointment error------>", error);
            this.setState({ isLoading: false, ispopup: false, modalFor: '', modalData: {} });
        })
    }


    /*----------------------------------------------handleDelay----------------------------------------*/

    handleDelay = () => {
        this.setState({ isLoading: true });
        //let id = localStorage.getItem('uid');

        //console.log('modeldatadelay',this.state.modalData);
        //return false;

        let locationdata = this.state.docPracticeLocation.length > 0 ? this.state.docPracticeLocation : this.state.modalData.doctor_location;
        //console.log('locationdata',locationdata);

        let id = this.state.modalData.doc_id;

        let practiceName;
        let practice = locationdata.filter(k => k._id === this.state.modalData.practice_id);
        practice.map((value) => {
            practiceName = `${value.practice_name}, ${value.practice_location}, ${value.zip}`;
            return null;
        });




        let data = {
            is_running: '1',
            practice_id: this.state.modalData.practice_id,

            mobile: this.state.modalData.patientMobile,
            mobile_message: `Dr. ${this.state.modalData.doctor_name} is running late in ${practiceName}  
            on ${handleDateFormat(this.state.modalData.date)} at ${handleTimeFormat(this.state.modalData.time)}.`,


            emailto_patient: this.state.modalData.patientEmail,
            patient_subject: 'Your Appoinment is delayed',
            patient_message: DelaynextUpcomingAppointmentMail(practiceName, this.state.modalData.date, this.state.modalData.time, this.state.modalData.doctor_name),
        };

        axios({
            method: 'post',
            url: Baseurl + `api/appointment/delay_nextupcoming_appointment?date=${this.state.modalData.date}&time=${this.state.modalData.time}&patient_id=${this.state.modalData.patient_id}`,
            headers: { 'content-type': 'application/json' },
            data
        }).then((res) => {
            // console.log("ok next appointment response------>", res);
            this.setState({ isLoading: false, ispopup: false, modalFor: '', modalData: {} });
            this.props.handleTodayAppointmentData({ id: id, limit: this.state.today_limit, page: this.state.todayPage });
        }).catch((error) => {
            // console.log("ok next appointment error------>", error);
            this.setState({ isLoading: false, ispopup: false, modalFor: '', modalData: {} });
        })
    }


    /*------------------------------------handle-cancel-appointment-by-popup-------------------------------------*/

    handleCancelAppointment = () => {
        this.setState({ isLoading: true });
        //let docId = localStorage.getItem('uid');
        // console.log('modeldata',this.state.modalData.doctor_name);

        //console.log('this.state.docPracticeLocation',this.state.docPracticeLocation);

        let locationdata = this.state.docPracticeLocation.length > 0 ? this.state.docPracticeLocation : this.state.modalData.doctor_location;
        //console.log('locationdata',locationdata);

        let docId = this.state.modalData.doc_id;
        const location = this.state.modalData.doctor_location.filter(el => el._id === this.state.modalData.practice_id)[0];
        let practiceName = `${location.practice_name}, ${location.practice_location}, ${location.zip}`;
        // let practice = locationdata.filter(k => k._id === this.state.modalData.practice_id);
        // practice.map((value) => {
        //     practiceName = `${value.practice_name}, ${value.practice_location}, ${value.zip}`;
        //     return null;
        // });

        // console.log(practiceName,'practiceName', location)

        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const numricDate = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];


        let appdate = new Date(handleCorrectDateFormat(this.state.modalData.date));

        let app_date = this.state.numricDate[appdate.getDate()];

        let app_month = months[appdate.getMonth()];
        //let app_date = numricDate[appdate.getDate()];
        var app_date2 = this.state.modalData.date.slice(-2);
        let app_year = appdate.getFullYear();
        let messagefullDate = `${app_date2} ${app_month} ,${app_year}`;

        const year = this.state.modalData.date.slice(0, 4);
        const month = this.state.modalData.date.slice(4, 6);
        const day = this.state.modalData.date.slice(6, 8);
        const newDate = `${month}/${day}/${year}`;

        let groupmail = this.state.groupDrEmail ? this.state.groupDrEmail : this.state.docEmail;


        let data = {
            practice_id: this.state.modalData.practice_id,
            createdAt: this.state.modalData.createdAt,
            emailto_grpdoc: groupmail,
            docCancelappotinetMessage: ` Your appointment with Dr. ${this.state.modalData.doctor_name} is cancelled. The appointment was on ${newDate} at ${handleTimeFormat(this.state.modalData.time)}. `,
            patientMobile: this.state.modalData.patientMobile,



            emailto_patient: this.state.modalData.patientEmail,
            patient_subject: 'Your appointment has been Cancelled !',
            patient_message: CancelAppointmentMailToPatient(practiceName, this.state.modalData.date, this.state.modalData.time, this.state.modalData.doctor_name),

            emailto_doc: this.state.modalData.doctor_email,
            //emailto_grpdoc:this.state.docEmail,

            doc_subject: 'Your appointment has been Cancelled !',
            doc_message: CancelAppointmentMailToDoctor(practiceName, this.state.modalData.date, this.state.modalData.time, this.state.modalData.patientName)
        };


        axios({
            method: 'post',
            url: Baseurl + `api/appointment/doc_cancel_appointment?appointDate=${this.state.modalData.date}&appointTime=${this.state.modalData.time}&status_by=${docId}`,
            headers: { "content-type": "application/json" },
            data: data,
        }).then((res) => {
            this.setState({ isLoading: false, ispopup: false, modalFor: '', modalData: {} });
            toast.success('Appointment Cancelled !', { position: toast.POSITION.TOP_CENTER });
            this.props.handleTodayAppointmentData({ id: docId, limit: this.state.today_limit, page: this.state.todayPage });
            this.props.handleUpcomingAppointmentData({ id: docId, limit: this.state.upcoming_limit, page: this.state.upcomingPage });
        }).catch((error) => {
            // console.log("cancel appointment-error----->", error);
            this.setState({ isLoading: false, ispopup: false, modalFor: '', modalData: {} });
        })

    }



    renderCurrentAppointmentLocation = (data) => {

        let locationdata = this.state.docPracticeLocation.length > 0 ? this.state.docPracticeLocation : data.doctor_location;
        let filterLocation = locationdata.filter(value => value._id === data.practice_id);
        return filterLocation.map(value => {
            // // return `${value.practice_name}, ${value.zip}`
            // console.log(value );
            return <React.Fragment>
                <h5>{value.practice_name}</h5>
                <p>{value.practice_location}, {value.zip}</p>
            </React.Fragment>
        })
    }


    renderLocationForMobile = (value) => {
        let locationName = value.doctor_location ? value.doctor_location.filter(data => data ? data._id === value.practice_id : '') : [];
        // let locationdata = this.state.docPracticeLocation.length > 0 ? this.state.docPracticeLocation:data.doctor_location;
        //let filterLocation = locationdata.filter(value => value._id === data.practice_id);
        // console.log(data.practice_id,'p location',locationdata)
        return locationName.map(el => {
            return `${el.practice_name}, ${el.zip}`
        })
    }


    /*-----------------------------------handleUpcomingPageClick---------------------------------*/


    handleTodayPageClick = (data) => {
        let id = localStorage.getItem('uid');
        let selected = data.selected;
        this.setState({ todayPage: selected + 1 }, () => {
            this.props.handleTodayAppointmentData({ id: id, limit: this.state.today_limit, page: selected + 1 });
        });
    }

    /*-----------------------------------handleUpcomingPageClick---------------------------------*/


    handleUpcomingPageClick = (data) => {
        let id = localStorage.getItem('uid');
        let selected = data.selected;
        this.setState({ upcomingPage: selected + 1 }, () => {
            this.props.handleUpcomingAppointmentData({ id: id, limit: this.state.upcoming_limit, page: selected + 1 });
        });
    }

    /*-----------------------------------handlePastPageClick---------------------------------*/
    handlePastPageClick = (data) => {
        let id = localStorage.getItem('uid');
        let selected = data.selected;
        this.setState({ pastPage: selected + 1 }, () => {
            this.props.handlePastAppointmentData({ id: id, limit: this.state.upcoming_limit, page: selected + 1 });
        });
    }



    render() {
        let param = queryString.parse(this.props.location.search);

        let fixedtext = {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            maxWidth: '140px'
        }

        let d = new Date();
        const today = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate();

        return (
            <React.Fragment>

                <Modal
                    isOpen={this.state.isOpenModal}
                    onRequestClose={this.closeModal}
                    style={customStylesIns}
                    contentLabel="Modal"
                >
                    <a
                        href="#!"
                        className="close"
                        onClick={() => this.setState({ isOpenModal: false })}
                    >
                        <span aria-hidden="true">×</span>
                    </a>
                    <div>
                        <h4 style={{fontWeight:'bolder'}}>Deductible details</h4>
                        <hr />
                        {!this.state.isPatientLoading ?
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <p><spam style={{fontWeight:'bolder'}}>Payer Name</spam> : {this.state.payerName.length ? this.state.payerName : ''}</p>
                                {this.state.InsStatus.length ? <p><spam style={{fontWeight:'bolder'}}>Status</spam> : {this.state.InsStatus}</p> : ''}
                            </div>
                            : ''
                        }
                        <div className="review_main_container">
                            {
                                this.state.isPatientLoading ?
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img alt="placeholder" src="https://luxysource.com/pub/static/frontend/Magento/luxysource/en_US/custom/img/preloader.gif" style={{ height: 100, width: 100 }} />
                                    </div>
                                    : this.state.insuranceData.length === 0 ?
                                        <h4 className="text-center" style={{ color: 'rgb(0, 35, 75)', fontWeight: 'bold' }}>No Results</h4>
                                        :
                                        <React.Fragment>

                                            <div className="doc_review_for_web">
                                                <div className="doc_review_resp-table-header">
                                                    <div className="doc_review_table-header-cell">Tittle</div>
                                                    <div className="doc_review_table-header-cell">Value</div>
                                                </div>
                                                {this.state.insuranceData.map((obj, key) => {
                                                    return obj.serviceParameters.map((value, key) => {
                                                        return value.key.includes('Deductible') ?
                                                            <>
                                                                <div className="doc_reivew_resp-table-body">
                                                                    <div className="doc_review_resp-table-row" key={key}>
                                                                        <div className="doc_reivew_table-body-cell">{value.key}</div>
                                                                        <div className="doc_reivew_table-body-cell">{value.value}</div>
                                                                    </div>
                                                                </div>
                                                            </> : ''
                                                    })
                                                })
                                                }
                                            </div>
                                        </React.Fragment>

                            }
                        </div>
                    </div>
                </Modal>
                {this.state.response
                    ?

                    (<WebNotification
                        title="a patient has taken appointment!"
                        icon="path/to/image.jpg"
                        body="a patient has taken appointment"
                        timeout={9000}
                        onClickFn={() => window.open({ docSiteUrl }, '_blank')}
                    />) : null}


                {this.state.groupRole === 6 ? <div className="input-with-label text-left">
                    <span>Filter By Doctor</span>
                    <select
                        className="col-md-12"
                        name="groupRole" onChange={(e) => this.handleFilter(e)}>
                        <option value={this.state.doctor_id}>All</option>
                        {this.state.subdrdata.map((dr, key) => {
                            return (
                                <option value={dr.uid}>{dr.first_name} {dr.last_name}</option>
                            );
                        })}
                    </select>
                </div> : ''}

                <div style={{ border: '0px solid #ccc', marginTop: '55px' }}>

                    <Modal
                        isOpen={this.state.ispopup}
                        onRequestClose={this.closeModal}
                        style={customStyles}
                        contentLabel="Modal"
                    >
                        {
                            this.state.isLoading ?
                                <div style={{ display: 'flex', width: '300px', justifyContent: 'center', alignItems: 'center', paddingTop: 10, paddingBottom: 10 }}>
                                    <img alt="loader" src={require('../../assets/img/smartappt_preloader.gif')} style={{ height: 100, width: 100 }} />
                                </div>
                                :
                                <>
                                    <div>
                                        <h5>
                                            {
                                                this.state.modalFor === 'Ready' ?
                                                    'We will inform the patient to be ready in 10 min.'
                                                    : this.state.modalFor === 'Delay' ?
                                                        'Notify patient about delay?' :
                                                        this.state.modalFor === "NoShow" ?
                                                            'The patient did not show up for this appointment?'
                                                            :
                                                            'Are you sure? You want to cancel this Appointment time with ' + this.state.modalData.patientName
                                            }
                                        </h5>
                                        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center' }}>
                                            <button onClick={this.handleModalOk} className="btn btn-md btn-filled btn-rounded" style={{ lineHeight: 0 }}>{this.state.modalFor === "NoShow" ? "Yes" : "Ok"}</button>
                                            <button onClick={this.closeModal} className="btn btn-md btn-filled btn-rounded" style={{ lineHeight: 0 }}>Cancel</button>
                                        </div>
                                    </div>
                                </>
                        }
                    </Modal>


                    <div className="appointment_table_box" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ height: 'auto', width: '100%' }}>
                            <div><h5 className="text-center" style={{ fontWeight: 'bold', paddingTop: 5, paddingBottom: 5 }}>Today’s Appointments</h5></div>
                            <div className="recent_appointment_container">
                                {
                                    this.state.recentAppointment.length === 0 ?
                                        <h5 style={{ textAlign: 'center', fontSize: 13 }}>No Today's Appointments.</h5>
                                        :


                                        <div>
                                            <ul className="accordion accordion-1 apointTable_mobile_view" style={{ display: 'none' }}>
                                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', width: '100%', height: 'auto', padding: 10, border: '1px solid #47b475' }}>
                                                    <div className="text-left" style={{ height: 'auto', width: '33.3%', fontWeight: 'bold', color: '#47b475', borderRight: '1px solid #47b475' }}>Patient name</div>
                                                    <div className="text-center" style={{ height: 'auto', width: '33.3%', fontWeight: 'bold', color: '#47b475', borderRight: '1px solid #47b475' }}>Date</div>
                                                    <div className="text-right" style={{ height: 'auto', width: '25%', fontWeight: 'bold', color: '#47b475' }}>Time</div>
                                                </div>
                                                {

                                                    this.state.recentAppointment.map((value, key) => {
                                                        return <RecentAppointmentMobileView
                                                            key={key}
                                                            groupRole={this.state.groupRole}
                                                            data={value}
                                                            handleChangeIsRunning={this.handleChangeIsRunning}
                                                            handleConsultsButton={this.handleConsultsButton}
                                                            setModalState={this.setModalState}
                                                            renderRecentAppointmentLocation={this.renderLocationForMobile(value)}
                                                        />
                                                    })
                                                }
                                            </ul>
                                            {/* --------------------for-web---------------------------------------------------- */}
                                            <div className="doc_appointment-resp_table">

                                                <table className="table table-bordered">
                                                    <thead style={{
                                                        background: '#70ad47',
                                                        color: '#FFF', textAlign: "center"
                                                    }}
                                                    >
                                                        <tr>
                                                            <th scope="col" nowrap="nowrap"><span style={{ fontSize: "15px", fontWeight: "bold" }}>Date</span><br /></th>
                                                            <th scope="col"><span style={{ fontSize: "15px", fontWeight: "bold" }}>Patient Name</span> <br />Phone | Status<br />Email Address</th>
                                                            <th scope="col" nowrap="nowrap"><span style={{ fontSize: "15px", fontWeight: "bold" }}>Insurance</span> <br />Member ID | DOB</th>
                                                            <th scope="col"><span style={{ fontSize: "15px", fontWeight: "bold" }}>Provider Name <br /></span>Mobile <br />Practice Location</th>
                                                            <th scope="col" nowrap="nowrap"><span style={{ fontSize: "15px", fontWeight: "bold" }}>Consult From</span><br />&nbsp;</th>
                                                            <th scope="col"><span style={{ fontSize: "15px", fontWeight: "bold" }}>Action</span><br />&nbsp;</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.recentAppointment.map((value, key) => {
                                                                // console.log(value,'value')
                                                                const [is_running,] = value.is_running ? value.is_running.split('&') : '';
                                                                if (is_running === '0') {
                                                                    this.handleChangeIsRunning(value);
                                                                }
                                                                const year = value.date.slice(0, 4);
                                                                const month = value.date.slice(4, 6);
                                                                const day = value.date.slice(6, 8);
                                                                const newDate = `${month}/${day}/${year}`;

                                                                let pmobile = formatPhoneNumber(value.patient_mobile);
                                                                let dmobile = formatPhoneNumber(value.doctor_mobile);
                                                                let locationName = value.doctor_location ? value.doctor_location.filter(data => data ? data._id === value.practice_id : '') : [];
                                                                return <React.Fragment>
                                                                    <tr key={value._id} style={{ background: value.date === param.date && value.time === param.time ? '#e0e0e0' : '' }}>
                                                                        <td nowrap="nowrap" key={value._id}>
                                                                            <h5 style={{ border: '1px dotted #47b475', padding: '5px', backgroundColor: '#ECEAE0' }}>Appointment Date :<br />{newDate} {handleTimeFormat(value.time)}</h5>
                                                                            <p style={{ border: '1px dotted #47b475', padding: '5px', marginTop: '7px' }}>Date Created :<br /> {new Date(value.createdAt).toLocaleString()}</p>
                                                                        </td>
                                                                        <td nowrap="nowrap">
                                                                            <h5>{value.newPatient === 1 ? <img alt='new' src={require('../../assets/img/new1.png')} rounded /> : ''} {value.patient_name}</h5>
                                                                            {pmobile == '' ? '' : <span>{pmobile}|</span>} <span style={{ color: value.status === 'Confirmed' ? 'green' : 'tomato' }}>{value.status}</span>
                                                                            <br />
                                                                            <span style={{fontSize:'smaller'}}><a href={`mailto:${value.patient_email}`}>{value.patient_email}</a>
                                                                            </span>
                                                                        </td>
                                                                        <td nowrap="nowrap">
                                                                            <h5>{value.payment_mode} {value.patient_Medicaid == 1 && value.payment_mode !== 'cash' ? '(Medicaid)' : ''}</h5>
                                                                            {value.payment_mode === 'cash' ? null : <p>{value.patient_memberId} | {value.patient_dob}</p>}
                                                                        </td>
                                                                        <td>
                                                                            <h5>{value.doctor_name}</h5>
                                                                            {dmobile == '' ? '' : <span>{dmobile} </span>}
                                                                            {/* {this.renderCurrentAppointmentLocation(value)} */}
                                                                            {
                                                                                locationName.map(el => {
                                                                                    return <React.Fragment>
                                                                                        <h5>{el.practice_name}</h5>
                                                                                        <p>{el.practice_location}, {el.zip}</p>
                                                                                    </React.Fragment>
                                                                                })
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            <h5 className="text-center">{value.consult_from}</h5>
                                                                        </td>
                                                                        <td nowrap="nowrap">
                                                                            {this.state.groupRole === 7 || this.state.groupRole === 8 ?
                                                                                <button className="btn btn-teal btn-rounded btn-sm m-0" onClick={() => this.handleConsultsButton(value)} style={{ opacity: value.status === 'Cancelled' ? '0.5' : '1', background: '#47b475', color: '#fff', marginBottom: 0, paddingLeft: 3, paddingRight: 3 }} disabled={value.status === 'Cancelled'} >Consults</button> : ''}
                                                                            {this.state.groupRole === 7 || this.state.groupRole === 8 ?
                                                                                <button className="btn btn-teal btn-rounded btn-sm m-0" onClick={() => this.handleGetInsuranceDetails(today, value)} style={{ background: '#47b475', color: '#fff', marginBottom: 0, paddingLeft: 3, paddingRight: 3 }} >Deductible</button> : ''}
                                                                            <RecentOntimeDelayActions data={value} setModalState={this.setModalState} />
                                                                        </td>
                                                                    </tr>
                                                                    <CurrentAppointmentNotificationBar data={value} />
                                                                </React.Fragment>
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                }
                            </div>
                            <hr />
                            {
                                this.state.recentAppointment.length === 0 ?
                                    null
                                    :
                                    <React.Fragment>
                                        <div className="today_appointment_pagination" style={{ textAlign: 'center', marginBottom: 24 }}>
                                            <ReactPaginate
                                                previousLabel={'previous'}
                                                nextLabel={'next'}
                                                pageCount={this.state.today_pageCount}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={5}
                                                onPageChange={this.handleTodayPageClick}
                                                containerClassName={'pagination'}
                                                subContainerClassName={'pages pagination'}
                                                activeClassName={'active'}
                                            />
                                        </div>
                                        <hr style={{ color: '#333', backgroundColor: 'lightgray', height: 2 }} />
                                    </React.Fragment>
                            }


                            {/*---------end of recent-div---start-upcoming appoinment------------*/}

                            <div><h5 className="text-center" style={{ fontWeight: 'bold', paddingTop: 5, paddingBottom: 5 }}>Upcoming Appointments</h5></div>
                            <div className="upcomint_appointment_container">
                                {
                                    this.state.upcomingAppointment.length === 0 ?
                                        <h5 style={{ textAlign: 'center', fontSize: 13 }}>No Upcoming Appointments .</h5>
                                        :
                                        <div>
                                            <ul className="accordion accordion-1 apointTable_mobile_view">
                                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', width: '100%', height: 'auto', padding: 10, border: '1px solid #47b475' }}>
                                                    <div className="text-left" style={{ height: 'auto', width: '33.3%', fontWeight: 'bold', color: '#47b475', borderRight: '1px solid #47b475' }}>Patient name</div>
                                                    <div className="text-center" style={{ height: 'auto', width: '33.3%', fontWeight: 'bold', color: '#47b475', borderRight: '1px solid #47b475' }}>Date</div>
                                                    <div className="text-right" style={{ height: 'auto', width: '25%', fontWeight: 'bold', color: '#47b475' }}>Time</div>
                                                </div>
                                                {
                                                    this.state.upcomingAppointment.map((value, key) => {
                                                        return <UpcomingPastAppointmentMobileView
                                                            key={key}
                                                            data={value} {...this.props}
                                                            grouprole={this.state.groupRole}
                                                            upcoming={true}
                                                            setModalState={this.setModalState}
                                                            docPractice={value.doctor_location}
                                                        />
                                                    })
                                                }
                                            </ul>

                                            {/* --------------------for-web---------------------------------------------------- */}
                                            <div className="doc_appointment-resp_table">
                                                <table className="table table-striped table-bordered">
                                                    <thead style={{
                                                        background: '#70ad47',
                                                        color: '#FFF'
                                                    }}
                                                    >
                                                        <tr>
                                                            <th scope="col"><span style={{ fontSize: "15px", fontWeight: "bold" }}>Date</span><br /></th>
                                                            <th scope="col"><span style={{ fontSize: "15px", fontWeight: "bold" }}>Patient Name</span> <br />Phone | Status<br />Email Address</th>
                                                            <th scope="col"><span style={{ fontSize: "15px", fontWeight: "bold" }}>Insurance</span> <br />Member ID | DOB</th>
                                                            <th scope="col"><span style={{ fontSize: "15px", fontWeight: "bold" }}>Provider Name <br /></span>Mobile <br />Practice Location</th>
                                                            <th scope="col" nowrap="nowrap"><span style={{ fontSize: "15px", fontWeight: "bold" }}>Consult From</span><br />&nbsp;</th>
                                                            <th scope="col"><span style={{ fontSize: "15px", fontWeight: "bold" }}>Action</span><br />&nbsp;</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.upcomingAppointment.map((value, index) => {
                                                                return <DocAppointmentRow
                                                                    key={index}
                                                                    value={value} {...this.props}
                                                                    grouprole={this.state.groupRole}
                                                                    upcoming={true}
                                                                    medicaidValue={this.state.medicaid}
                                                                    setModalState={this.setModalState}
                                                                    docPractice={value.doctor_location}
                                                                    handleGetInsuranceDetails={() => this.handleGetInsuranceDetails(today, value)}
                                                                />
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                }
                            </div>
                            <hr />
                            {
                                this.state.upcomingAppointment.length === 0 ?
                                    null
                                    :
                                    <React.Fragment>
                                        <div className="upcoming_appointment_pagination" style={{ textAlign: 'center', marginBottom: 24 }}>
                                            <ReactPaginate
                                                previousLabel={'previous'}
                                                nextLabel={'next'}
                                                pageCount={this.state.upcoming_pageCount}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={5}
                                                onPageChange={this.handleUpcomingPageClick}
                                                containerClassName={'pagination'}
                                                subContainerClassName={'pages pagination'}
                                                activeClassName={'active'}
                                            />
                                        </div>
                                        <hr style={{ color: '#333', backgroundColor: 'lightgray', height: 2 }} />
                                    </React.Fragment>
                            }


                            {/*---------end of upcoming-appoinemt-start past appointment------------*/}
                            <div><h5 className="text-center" style={{ fontWeight: 'bold', paddingTop: 5, paddingBottom: 5 }}>Past Appointments</h5></div>
                            <div className="past_appointment_container">
                                {
                                    this.state.docAppointments.length === 0 ?
                                        <h5 style={{ textAlign: 'center', fontSize: 13 }}>No Past Appointments .</h5>
                                        :
                                        <div>
                                            <ul className="accordion accordion-1 apointTable_mobile_view">
                                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', width: '100%', height: 'auto', padding: 10, border: '1px solid #47b475' }}>
                                                    <div className="text-left" style={{ height: 'auto', width: '33.3%', fontWeight: 'bold', color: '#47b475', borderRight: '1px solid #47b475' }}>Patient name</div>
                                                    <div className="text-center" style={{ height: 'auto', width: '33.3%', fontWeight: 'bold', color: '#47b475', borderRight: '1px solid #47b475' }}>Date</div>
                                                    <div className="text-right" style={{ height: 'auto', width: '25%', fontWeight: 'bold', color: '#47b475' }}>Time</div>
                                                </div>
                                                {
                                                    this.state.docAppointments.map((value, key) => {
                                                        return <UpcomingPastAppointmentMobileView
                                                            key={key} {...this.props}
                                                            data={value}
                                                            grouprole={this.state.groupRole}
                                                            setScheduleData={this.setScheduleData}
                                                            docPractice={value.doctor_location}
                                                        />
                                                    })
                                                }
                                            </ul>
                                            {/* --------------------for-web---------------------------------------------------- */}
                                            <div className="doc_appointment-resp_table">

                                                <table className="table table-striped table-bordered" id="myTable">
                                                    <thead style={{
                                                        background: '#70ad47',
                                                        color: '#FFF'
                                                    }}
                                                    >
                                                        <tr>
                                                            <th scope="col"><span style={{ fontSize: "15px", fontWeight: "bold" }}>Date</span><br />&nbsp;</th>
                                                            <th scope="col"><span style={{ fontSize: "15px", fontWeight: "bold" }}>Patient Name</span> <br />Phone | Status<br />Email Address</th>
                                                            <th scope="col"><span style={{ fontSize: "15px", fontWeight: "bold" }}>Insurance</span> <br />Member ID | DOB</th>
                                                            <th scope="col"><span style={{ fontSize: "15px", fontWeight: "bold" }}>Provider Name <br /></span>Mobile <br />Practice Location</th>
                                                            <th scope="col" nowrap="nowrap"><span style={{ fontSize: "15px", fontWeight: "bold" }}>Consult From</span><br />&nbsp;</th>
                                                            <th scope="col"><span style={{ fontSize: "15px", fontWeight: "bold" }}>Action</span><br />&nbsp;</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.docAppointments.map((value, index) => {
                                                                return <DocAppointmentRow
                                                                    key={index}
                                                                    value={value} {...this.props}
                                                                    setModalState={this.setModalState}
                                                                    grouprole={this.state.groupRole}
                                                                    setScheduleData={this.setScheduleData}
                                                                    docPractice={value.doctor_location}
                                                                    handleGetInsuranceDetails={() => this.handleGetInsuranceDetails(today, value)}
                                                                />
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                }
                            </div>
                            {
                                this.state.docAppointments.length === 0 ?
                                    null
                                    :
                                    <div className="past_appointment_pagination clear" style={{ textAlign: 'center', marginBottom: 10 }}>
                                        <ReactPaginate
                                            previousLabel={'previous'}
                                            nextLabel={'next'}
                                            pageCount={this.state.past_pageCount}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            onPageChange={this.handlePastPageClick}
                                            containerClassName={'pagination'}
                                            subContainerClassName={'pages pagination'}
                                            activeClassName={'active'}
                                        />
                                    </div>
                            }
                        </div>
                    </div>
                    <div onClick={this.handleBackdrop} className="backdrop" style={{ display: this.state.re_schedulePopup ? 'block' : 'none' }}></div>
                </div>
            </React.Fragment >
        )
    }
}

const mapStateToProps = (state) => {
    return {
        doc_today_appintment: state.docTodaysAppintment,
        doc_upcoming_appointment: state.docUpcomingAppointment,
        doc_past_appointment: state.docPastAppointment,
        docDetails: state.doctorDetails,
        isLoading: state.isLoading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        handleTodayAppointmentData: data => dispatch({ type: DOC_TODAY_APPOINTMENT_REQUEST, data }),
        handleUpcomingAppointmentData: data => dispatch({ type: DOC_UPCOMING_APPOINTMENT_REQUEST, data }),
        handlePastAppointmentData: data => dispatch({ type: DOC_PAST_APPOINTMENT_REQUEST, data }),
        handleGetDocDetails: data => dispatch({ type: GET_DOC_DETAILS_REQUEST, data }),
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
