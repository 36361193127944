import React from "react";
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  ResponsiveContainer,
  Cell,
  Tooltip,
} from "recharts";
import { scaleOrdinal } from "d3-scale";
import { schemeCategory10 } from "d3-scale-chromatic";

const colors = scaleOrdinal(schemeCategory10).range();
export default class ReferralSpecialities extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const data = Object.keys(this.props.specialitydata).map((key) => {
      return {
        name: key,
        value: this.props.specialitydata[key],
      };
    });
    return (
      <ResponsiveContainer width="100%" height={350} className="cu-recharts-2">
        <BarChart
          width={670}
          height={350}
          data={data}
          margin={{
            top: 10,
            right: 5,
            left: -35,
            bottom: 5,
          }} 
        >
          <CartesianGrid strokeDasharray="4 4" />
          <XAxis dataKey="name" interval={0} height={80} tick={<TiltedAxisTick />}/>
          <Tooltip/>
          <YAxis />
          <Bar label={true} dataKey="value" maxBarSize={30}>
            {data &&
              data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={colors[index % 20]} />
              ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    );
  }
}


const TiltedAxisTick = (props) => {

  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`} >
      <text 
        x={0} 
        y={0} 
        dy={16} 
        textAnchor="end" 
        fill="#666" 
        transform="rotate(-45)">
          {payload.value.length > 6 ? `${payload.value.slice(0,7)}...` : payload.value}
      </text>
    </g>
  );

};
