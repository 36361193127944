import React from "react";
import { Baseurl } from "../../../../../utils/Baseurl";
import OldVsNewPatient from "../reporting/old-vs-new-patent-graph";
import Statistics from "../reporting/statistics";
import ReferralSpecialities from "../reporting/referrals-specialities";
import axios from "axios";
class ProviderDetails extends React.Component {
  state = {
    provider: "",
    doctor_id: null,
    groupdr: null,
    groupRole: null,
    subdrdata: null,
    reportResult:null,
    pageCount:null,
    verifiedPatients:null,
    unVerifiedPatients:null,
    totalVerificaitonFee:null,
    pataintCount:null,
    totalAppDownload:null,
    totalappointData:null,
    totalconsultData:null,
    yourTotalAppointData:null,
    totalConsultReferYou:null,
    consultSentYouOther:null,
    specialitydata:null,
    provider_id:null,
    loading:false
  };
  handleGetReport = (limit, page, doc_id, from, to, status) => { 
    this.setState({loading:true})
    axios({
      method: "get",
      url:
        Baseurl +
        `api/report/get_report?insurance_status=${status}&doctor_id=${doc_id}&from_date=${from}&to_date=${to}&limit=${limit}&page=${page}`,
      headers: { "content-type": "application/json" },
    })
      .then((res) => {
        // console.log("ger-report-response----->",res.data);

        this.setState({
          reportResult: res.data.data,
          pageCount: res.data.upcomint_count / 25, 
          verifiedPatients: res.data.verfied,
          unVerifiedPatients: res.data.unVerfied,
          totalVerificaitonFee: res.data.totalCost,
          pataintCount: res.data.pataintCount,
          totalAppDownload: res.data.totalAppDownload,
          totalappointData: res.data.totalappointData,
          totalconsultData: res.data.totalconsultData,
          yourTotalAppointData: res.data.yourTotalAppointData,
          totalConsultReferYou: res.data.totalConsultReferYou,
          consultSentYouOther: res.data.consultSentYouOther,
          specialitydata: res.data.specialitydata,
          loading:false
        });
      })
      .catch((error) => {
        console.log("get-report-error-------->", error); 
        this.setState({loading:false})
      });
  };
  handleGetDoctorDetails = () => {
    let userId = localStorage.getItem("uid");
    this.setState({loading:true})
    axios({
      method: "get",
      url: Baseurl + `api/doc/getDetails?userId=${userId}`,
      headers: { "content-type": "application/json" },
    })
      .then((res) => {
        // console.log("res----->",res);
        this.setState(
          {
            doctor_id: res.data.user._id,
            groupdr: res.data.user._id,
            groupRole: res.data.user.groupRole,
            loading:false
          },
          () => {
            this.setState({loading:true})
            let data = { refid: res.data.user.drLinkToGroup, grpuid: userId };
            axios({
              method: "post",
              url: Baseurl + `api/doc/get_group_doctors`,
              headers: { "content-type": "application/json" },
              data,
            })
              .then((drdata) => {
                  const subdrdata = drdata.data.data;
                this.setState({ subdrdata, loading:false });
              })
              .catch((error) => {
                console.log("error--->", error);
                this.setState({loading:false})
              });
          }
        );
      })
      .catch((error) => {
        console.log("error--->", error);
        this.setState({loading:false})
      });
  };

  componentDidMount() {
    this.handleGetDoctorDetails();
    this.handleGetReport(45, 1, this.state.provider_id, '', '', '') 
  }
  handleProviderChange = (event) => {
    const provider = event.target.value;
    const provider_id = this.state.subdrdata.filter(el=>el.uid === provider)[0]._id
    this.setState({ provider },()=>{ this.handleGetReport(45, 1, provider_id, '', '', '') });
  };
  render() {
    const { provider, yourTotalAppointData, subdrdata, totalConsultReferYou,consultSentYouOther, loading } = this.state;
    return (
      <div className="provider-details">
        <div className="row">
          <div className="col-sm-12">
           {subdrdata && <select
              style={{ width: "100%" }}
              value={provider}
              onChange={this.handleProviderChange}
            >
              <option value="">Select Provider</option>
              {subdrdata?.map((el, i) => {
                return (
                  <option value={el.uid}>
                    {el.first_name} {el.last_name}
                  </option>
                );
              })}
            </select> }
        
          </div>
          {loading ? 
           <div
           style={{
             height: "auto",
             width: "100%",
             display: "flex",
             justifyContent: "center",
             alignItems: "center",
           }}
         >
           <img
             alt="placeholder"
             src="https://luxysource.com/pub/static/frontend/Magento/luxysource/en_US/custom/img/preloader.gif"
             style={{ height: 80, width: 80 }}
           />
         </div> : 
         <React.Fragment>
           {provider !=="" &&
         <React.Fragment>
         <div className=" col-md-12">
            <p className="h3">Old Patient Vs New Patient Graph</p>
              <OldVsNewPatient doctorid={provider}/>
            </div>
            <div className=" col-md-6">
            <p className="h3">Statistics</p>
              <Statistics
              yourTotalAppointData={yourTotalAppointData}
              totalConsultReferYou={totalConsultReferYou}
              consultSentYouOther={consultSentYouOther}
              
              />
              <br/>
              <br/>
              <br/>
           
            </div>
           
            <div className=" col-md-6">
            <p className="h3">Referrals to Specialities</p>
              <ReferralSpecialities specialitydata={this.state.specialitydata}/>
            </div>
            </React.Fragment>}
         </React.Fragment>
         }
         
        </div>
      </div>
    );
  }
}
export default ProviderDetails;
