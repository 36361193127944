import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Baseurl } from '../utils/Baseurl';
import axios from 'axios';
//import {withRouter} from 'react-router-dom';
import '../styles/DoctorCard.css';

export default class DoctorCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            reviewLength: '',
            reviewAllRatings: '',
            latastReview: {},
            hasInsurance_match: false,
        }
    }

    componentDidMount() {
        this.handleGetDocReview(this.props.id);
        this.handleGetInsuranceDetails();
    }


    handleGetInsuranceDetails = () => {

        axios({
            method: 'get',
            url: Baseurl + `api/user/getuserdetails?userId=${this.props.patientId}`,
            headers: { 'content-type': 'application/json' }
        }).then((res) => {
            // console.log("get-insurance-response---->", res);

            let doc_insurance = this.props.insurance_providers && this.props.insurance_providers.length > 0 ? this.props.insurance_providers : [];
            let filterdocinsurance = doc_insurance.filter(v => v.code === res.data.user.payerCode)
            if (filterdocinsurance.length !== 0) {
                this.setState({ hasInsurance_match: true });
            }
            else {
                this.setState({ hasInsurance_match: false });
            }

        }).catch((error) => {
            console.log("get-user-details-error", error);
        })

    }







    handleGetDocReview = (id) => {
        axios({
            method: 'get',
            url: Baseurl + `api/review/get_review?parameter=${id}-doc_id`,
            headers: { 'Content-Type': 'application/json', },
        }).then((res) => {
            // console.log("doc-reviews-response------>", res);
            this.handleAllRatingandLeatestReview(res.data.data);
        }).catch((error) => {
            console.log("doc-reviews-error------>", error);
        })
    }

    UNSAFE_componentWillReceiveProps(props) {
        if (props.id) {
            this.handleGetDocReview(props.id);
        }

    }



    handleAllRatingandLeatestReview = (data) => {
        let latastCreated = {
            createdAt: 0,
        }

        let rating = 0;
        data.map((value) => {
            rating += value.rating;
            if (value.createdAt > latastCreated.createdAt) {
                latastCreated = value;
            }
            else {
                return null;
            }
            return null;
        })

        this.setState({ reviewLength: data.length, reviewAllRatings: rating / data.length, latastReview: latastCreated });
    }


    executeOnClick(isExpanded) {
        // console.log(isExpanded);
    }



    render() {
        const location = this.props.location && this.props.location.length > 0 ? this.props.location : [];
        return (
            <React.Fragment>
                <div className="col-md-6 col-sm-6">
                    <div className="feature mb-xs-24 mb32 bordered" style={{ overflow: 'hidden'}}>
                        <div className="left cardLeftContent"
                            style={{
                                width: 80, height: 80, display: 'inline-block', float: 'left',
                                backgroundImage: `url('${this.props.doc_image ? this.props.doc_image : 'https://cdn1.vectorstock.com/i/thumb-large/75/85/default-placeholder-doctor-half-length-portrait-vector-20847585.jpg'}')`,
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                                borderRadius: '50%'
                            }}>
                            {/* <img alt="Pic" src={this.props.doc_image ? this.props.doc_image : 'https://cdn1.vectorstock.com/i/thumb-large/75/85/default-placeholder-doctor-half-length-portrait-vector-20847585.jpg'} /> */}
                        </div>
                        <div className="right cardrightContent" style={{ width: '80%', display: 'inline-block', float: 'left' }}>
                            <div className="ml16">
                                <h5 className="bold mb0">{this.props.doc_name} <span style={{ fontSize: 12, fontWeight: 'bold', color: '#47b475' }} className="pull-right">{this.state.hasInsurance_match ? 'Insurance matched' : ''}</span></h5>
                                <span className="pull-right bold">{ this.props.distance }</span>
                                <span className="blackColor">{this.props.primary_speciality} <br />
                                    {
                                        location.map(value => {
                                            return ` ${value.practice_name}, ${value.city !=="undefined" ? value.city : ""} ${value.practice_location} , ${value.zip} `
                                        })
                                    }

                                </span>

                                <div className="card_action_box" style={{ paddingTop: 20, paddingBottom: 15 }}>
                                    <Link className="btn btn-md btn-filled" to={`/schedule?doc=${this.props.id}&patient=${this.props.patientId}&speciality=${this.props.selectSpeciality}`} >Schedule Appointment</Link>
                                    <Link className="mb0 btn btn-md btn-filled" to={`/doctor_profile?doc=${this.props.id}&patient=${this.props.patientId}`}>View profile</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

