import React from "react";
import firebaseApp from "../utils/Fire";
import { withRouter, Link, NavLink } from "react-router-dom";
import $ from "jquery";
import {
  GET_DOC_DETAILS_REQUEST,
  GET_LOGIN_USER_REQUEST,
} from "../actions/Action";
import { connect } from "react-redux";
import { ProfileNotificationBar } from "./ProfileNotificationBar";
import "../styles/Navbar.css";
import axios from "axios";
import { Baseurl, docSiteUrl } from "../utils/Baseurl";
import doctorPlaceholder from "../assets/img/doctor-placeholder.jpg";
import socketIOClient from "socket.io-client";
import { PostAPI } from "../utils/Api-interface";
class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isAuth: false,
      profileStatus: "",
      isMenu: false,
      groupRole: 7,
      fname: "",
      lname: "",
      status: 1,
      profile_image: "",
      groupDrUid: "",
      matches: window.matchMedia("(min-width: 768px)").matches,
      messageCount:0,
      eligibleForChatSystem:false,
      doctorChatUser: null,  
    };
  }



  startChatExecution = async () => {
    const docUid = localStorage.getItem("uid");
    const { eligibleForChatSystem } = this.state;

    if (docUid && !eligibleForChatSystem) {
      const res = await PostAPI(
        `chat_user/chat_user_deatil`, { drUid: docUid, userType: 2 }
      );
      if (res.status === 200) {
        const apiRes = res.data.data;
        if (!apiRes) {
          const resAddInChatUser = await PostAPI(
            `chat_user/add_in_chat_user`, { drUid: docUid }
          );

          if (resAddInChatUser.status === 200) {
            this.setState({ doctorChatUser: resAddInChatUser.data.data, eligibleForChatSystem: true }, () => {
              this.startChatExecution()
            });
          }

        } else {
          this.setState({ doctorChatUser: apiRes, eligibleForChatSystem: true }, () => {
            this.startChatExecution()
          });

        }

      }
    }else if(eligibleForChatSystem){
      const {doctorChatUser} = this.state;
      const socket = socketIOClient(Baseurl, { query: { conversationId:null, userId: doctorChatUser._id } });
      socket.on("refreshConverstationUserList", () => {
        socket.emit("converstationUserList", doctorChatUser._id)
      })
      socket.on("converstationUserList", (chatWithData) => {
        // console.log(chatWithData,'chatWithDatachatWithData')
        if(chatWithData && chatWithData.length){ 
          let count = 0;
        chatWithData.forEach(e => {
          if(e.unreadMsgCount){
            count = count + 1
          }
        });
        this.setState({messageCount:count},()=>{
          count = 0
        })}
      });
    }
  }
  componentDidMount() {
    this.startChatExecution()
    const handler = (e) => this.setState({ matches: e.matches });

    firebaseApp.auth().onAuthStateChanged((user) => {
      let userId = localStorage.getItem("uid");
      if (user && userId) {
        // console.log("pathname", this.props.location.pathname);
        // this.props.handleGetDocDetails(user.uid);
        if (this.props.location.pathname !== "/schedule" && this.props.location.pathname !== "/doctor_profile" && this.props.location.pathname !== "/profile" && this.props.location.pathname !== "/calendar") {
          this.props.handleGetDocDetails(user.uid);
        }
        this.props.getLoginUserDetails(userId);
        axios({
          method: "get",
          url: Baseurl + `api/doc/get_details_selected?userId=${userId}`,
          headers: { "content-type": "application/json", pragma: "no-cache" },
        })
          .then((doctor) => {
            // console.log(doctor, "doctor");
            this.setState({
              isAuth: true,
              groupRole: doctor.data.user.groupRole,
              fname: doctor.data.user.first_name,
              lname: doctor.data.user.last_name,
              profile_image: doctor.data.user.profile_image,
              groupDrUid: doctor.data.user.groupDrUid,
              profileStatus: doctor.data.user.profile_status,
              status: doctor.data.user.status,
            });
          })
          .catch((error) => {
            console.log(
              "doctor-details-error------->"
              // error.response,
              // this.state.isAuth
            );
          });
      } else {
        this.setState({ isAuth: false });
      }
    });

    window.matchMedia("(min-width: 768px)").addListener(handler);
  }

  componentDidUpdate(prevProps, prevState){

    if(prevState?.isAuth !== this.state.isAuth){
      this.startChatExecution()
    }
  }
  /*UNSAFE_componentWillReceiveProps(props) {
        // console.log('props---->', props);
        if (props.doctorDetails) {
            this.setState({
                // user_name: `${props.userDetails.first_name} ${props.userDetails.last_name}`,
                profileStatus: props.doctorDetails.profile_status,
                isLoading: false
            });
        }
    }*/

  handlelogout = (e) => {
    e.preventDefault();
    firebaseApp.auth().signOut();
    localStorage.removeItem("uid");
    localStorage.removeItem("Gruid");
    this.props.history.push("/");
  };

  switchTogroupAccount = () => {
    let userId = localStorage.getItem("uid");
    let GroupUserId = localStorage.getItem("Gruid");
    localStorage.removeItem("uid");
    localStorage.setItem("uid", GroupUserId);
    localStorage.removeItem("Gruid");
    window.location.href = "/";
  };

  render() {
    $(function () {
      $(".dropdown-toggle1").click(function () {
        $(".dropdown-menu1").slideToggle("slow");
      });
    });
    const user = this.props?.loginuserdetails?.user;
    const allUserDetails =  this.props?.doctorDetails
    const {messageCount} = this.state;
    return (
      <React.Fragment>
        <nav
          className="navbar-kwp-header navbar-dark"
          style={{ background: "#47b475" }}
        >
          <div
            className="container nav-bar nav-open custom-css-header-desktop"
            style={{ overflow: "visible" }}
          >
            <div className="navbar-header">
              <button
                type="button"
                className="navbar-toggle collapsed"
                data-toggle="collapse"
                data-target="#Navbar"
              >
                <span className="sr-only">Navigation ein- / ausblenden</span>
                <span
                  style={{
                    display: "block",
                    width: "22px",
                    height: "2px",
                    backgroundColor: "#cccccc",
                    borderRadius: "1px",
                    marginTop: "3px",
                  }}
                ></span>
                <span
                  style={{
                    display: "block",
                    width: "22px",
                    height: "2px",
                    backgroundColor: "#cccccc",
                    borderRadius: "1px",
                    marginTop: "4px",
                  }}
                ></span>
                <span
                  style={{
                    display: "block",
                    width: "22px",
                    height: "2px",
                    backgroundColor: "#cccccc",
                    borderRadius: "1px",
                    marginTop: "4px",
                  }}
                ></span>
              </button>
              <Link to="/">
                <img
                  className="logo logo-light"
                  alt="Foundry"
                  src={require("../assets/img/smartappt-logo.svg")}
                />
                <img
                  className="logo logo-dark"
                  style={{ marginLeft: "5px" }}
                  alt="Foundry"
                  src={require("../assets/img/smartappt-logo.svg")}
                />
              </Link>
            </div>

            <div className="module-group right">
              <div id="Navbar" className="navbar-collapse collapse ">
                {this.props.isAuth ? (
                  <ul
                    className="nav navbar-nav navbar-right"
                    style={{
                      marginTop:
                        (!this.state.profileStatus ||
                          this.state.profileStatus === "0") &
                        !this.state.matches
                          ? "40px"
                          : "0px",
                    }}
                  >
                    <li>
                      <NavLink
                        className="navbarlianchor"
                        to="/"
                        activeClassName="active"
                        exact
                      >
                        Dashboard
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className="navbarlianchor"
                        to="/rating_&_reviews"
                        activeClassName="active"
                        exact
                      >
                        Rating & Reviews
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className="navbarlianchor"
                        to="/reporting"
                        activeClassName="active"
                        exact
                      >
                        Reporting
                      </NavLink>
                    </li>
                    {/* {this.state.groupRole !== 6 ? (
                      <li>
                        <NavLink
                          className="navbarlianchor"
                          to="/message"
                          activeClassName="active"
                          exact
                        >
                          Messaging
                        </NavLink>
                      </li>
                    ) : (
                      ""
                    )} */}
                    {this.state.groupRole !== 6 ? (
                      <li>
                        <NavLink
                          className="navbarlianchor"
                          to="/calendar"
                          activeClassName="active"
                          exact
                        >
                          Calendar
                        </NavLink>
                      </li>
                    ) : (
                      ""
                    )}
                
                    {user && user.groupRole !== 6 && this.state.status && allUserDetails.isEnableSubscrption === 1 ? (
                      <React.Fragment>
                        {!this.props.subscriptiondata ? (
                          <React.Fragment>
                       {this.props.subscriptiondata !== null && <li>
                            <NavLink
                              to="/subscription"
                              className="navbarlianchor"
                              activeClassName="active"
                              exact
                            >
                              Subscription
                            </NavLink>
                          </li>}
                          </React.Fragment>
                        ) : (
                          <li>
                            <a
                              className="nav-link navbarlianchor"
                              href="/my-subscription"
                              id="navbarDropdownMenuLink"
                              role="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <span
                                style={{
                                  marginRight: 5,
                                  marginLeft: 5,
                                  fontWeight: "bold",
                                  letterSpacing: 1,
                                  fontSize: 11,
                                  color: "inherit",
                                }}
                              >
                                My Subscription
                              </span>

                              <span
                                className="caret"
                                style={{ color: "inherit" }}
                              ></span>
                            </a>

                            <ul
                              className="dropdown-menu ml-auto"
                              style={{ background: "#f1f0f0" }}
                            >
                              <li style={{ height: "40px" }}>
                                <Link to="/my-subscription">
                                  My Subscription
                                </Link>
                              </li>
                              <li style={{ height: "40px" }}>
                                <Link to="/my-transaction">My Transaction</Link>
                              </li>
                            </ul>
                          </li>
                        )}
                      </React.Fragment>
                    ):""}

                    {this.state.groupRole === 6 ? (
                      <li>
                        <NavLink
                          className="navbarlianchor"
                          to="/mange_doctor"
                          activeClassName="active"
                          exact
                        >
                          Manage Providers
                        </NavLink>
                      </li>
                    ) : (
                      ""
                    )}

                  {this.state.groupRole !== 6 ? (
                    <li>
                    <NavLink
                      className="navbarlianchor"
                      to="/chat"
                      activeClassName="active"
                      exact
                    >
                    Messages {messageCount ? <span className="badge" style={{backgroundColor:"#ff0000"}}>{messageCount}</span>:null}
                    </NavLink>
                  </li>
                  ) : (
                    ""
                  )}
                     
                    <li>
                      <a
                        className="dropdown-toggle nav-link navbarlianchor"
                        href="/personal-details"
                        id="navbarDropdownMenuLink"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img
                          src={
                            this.state.profile_image
                              ? this.state.profile_image
                              : doctorPlaceholder
                          }
                          style={{
                            borderRadius: "50%",
                            height: "35px",
                            width: "35px",
                            objectFit: "cover",
                          }}
                          alt="avatar image"
                        />

                        <span
                          style={{
                            marginRight: 5,
                            marginLeft: 5,
                            fontWeight: "bold",
                            opacity: "0.5",
                            letterSpacing: 1,
                            fontSize: 11,
                            textTransform: "uppercase",
                            color: "#000",
                          }}
                        >{`${this.state.fname}`}</span>

                        <span
                          className="caret"
                          style={{ color: "green" }}
                        ></span>
                      </a>

                      <ul
                        className="dropdown-menu ml-auto"
                        style={{ background: "#f1f0f0" }}
                      >
                        {this.state.groupRole !== 6 ? (
                          <>
                          <li style={{ height: "40px" }}>
                            <Link to="/personal-details">Personal Details</Link>
                          </li>
                            <li style={{ height: "40px" }}>
                            <Link to="/staff-members">Staff Members</Link>
                          </li>
                          </>
                        ) : (
                          ""
                        )}
                        {this.state.groupRole !== 6 ? (
                          <li style={{ height: "40px" }}>
                            <Link to="/profile">Profile</Link>
                          </li>
                        ) : (
                          ""
                        )}
                        <li style={{ height: "40px" }}>
                          <Link to="/password">Password</Link>
                        </li>
                        {/* <li style={{ height: '40px' }}><Link to="subscriptiondetail">Subscription</Link></li> */}
                        {/* <li style={{ height: "40px" }}>
                          <Link to="transition">My Transaction</Link>
                        </li> */}

                        <li style={{ height: "40px" }}>
                          <Link to="" onClick={this.handlelogout}>
                            Log out
                          </Link>
                        </li>
                      </ul>
                    </li>

                    {this.state.groupRole === 7 &&
                    localStorage.getItem("Gruid") &&
                    this.state.groupDrUid === localStorage.getItem("Gruid") ? (
                      <li>
                        <Link
                          className="navbarlianchor"
                          to=""
                          onClick={this.switchTogroupAccount}
                        >
                          Switch To Group Account
                        </Link>
                      </li>
                    ) : (
                      ""
                    )}
                  </ul>
                ) : (
                  <div>
                    <span>
                      <a
                        className="navbarlianchor"
                        href="https://smartappointment.com"
                      >
                        For Patients
                      </a>
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </nav>
        {this.state.status === 0 && (
          <div
            className="alert alert-danger text-center"
            role="alert"
            style={{
              backgroundColor: "#f8d7da",
              borderColor: "#f8d7da",
              color: "#721c24",
            }}
          >
            Your profile is under review. Once verified, we will approve your
            profile and it will be visible on the website. We may contact you
            during the verification process.
          </div>
        )}

        {/* <button onClick={()=>this.setState({status:false})}>change</button> */}
        {!this.state.isLoading && this.props.isAuth && (
          <ProfileNotificationBar profileStatus={this.state.profileStatus} />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    doctorDetails: state.doctorDetails,
    subscriptiondata: state.subscriptionData,
    loginuserdetails: state.loginuserdetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLoginUserDetails: (data) =>
      dispatch({ type: GET_LOGIN_USER_REQUEST, data }),
    handleGetDocDetails: (data) =>
      dispatch({ type: GET_DOC_DETAILS_REQUEST, data }),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navbar));
