import React, { Component } from 'react';
// import PatientName from './PatientName';
import { handleTimeFormat } from '../utils/DateMethod';
import RecentOntimeDelayActions from './RecentOntimeDelayActions';

export default class RecentAppointmentMobileView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            toggle: false
        }
    }

    handleToggle = () => {
        this.setState({ toggle: !this.state.toggle });
    }


    render() {
        const [is_running, time] = this.props.data.is_running ? this.props.data.is_running.split('&') : '';

        var currentTime = Date.now();
        var expiryTime = parseInt(time) + 600000;
        const expired = expiryTime < currentTime;


        if (is_running === '0') {
            this.props.handleChangeIsRunning(this.props.data);
        }

        const year = this.props.data.date.slice(0, 4);
        const month = this.props.data.date.slice(4, 6);
        const day = this.props.data.date.slice(6, 8);
        const newDate = `${month}/${day}/${year}`;
        const groupRole = this.props.groupRole;

        return (
            <React.Fragment>
                <li className={this.state.toggle ? "active" : ""}>
                    <div onClick={this.handleToggle} className="title recentAppointment_mobileview_title_box">
                        <div className="text-left" style={{ height: 'auto', width: '33.3%', fontWeight: 'bold' }}>{this.props.data.patient_name}</div>
                        <div className="text-center" style={{ height: 'auto', width: '33.3%', fontWeight: 'bold' }}>{newDate}</div>
                        <div className="text-right" style={{ height: 'auto', width: '33.3%', fontWeight: 'bold' }}>
                            {handleTimeFormat(this.props.data.time)}
                            <span style={{ paddingLeft: 12 }}>
                                <img alt='logo' src={require('../assets/logo/down-arrow.png')}
                                    style={{
                                        height: 20, width: 20,
                                        WebkitTransform: this.state.toggle ? 'rotate(180deg)' : '',
                                        MozTransformStyle: this.state.toggle ? 'rotate(180deg)' : '',
                                        msTransform: this.state.toggle ? 'rotate(180deg)' : '',
                                        OTransform: this.state.toggle ? 'rotate(180deg)' : '',
                                        transform: this.state.toggle ? 'rotate(180deg)' : ''
                                    }}
                                />
                            </span>
                        </div>
                    </div>

                    <div className="content recentAppointmentmobile_content_table">
                        <table style={{ width: '100%', marginBottom: 5 }}>
                            <tbody>
                                <tr>
                                    <th>Date Created</th>
                                    <td>{new Date(this.props.data.createdAt).toLocaleString()}</td>
                                </tr>
                                <tr>
                                <th>Patient Details</th>
                                    <td>
                                        <p style={{ marginBottom: 0, padding: 0 }}>{this.props.data.newPatient===1 ? <img alt='new' src={require('../assets/img/new1.png')} rounded={true} /> : '' } {this.props.data.patient_name}</p><br />
                                        <p style={{ marginBottom: 0, padding: 0 }}>{this.props.data.patient_email}</p><br />
                                        <p style={{ marginBottom: 0, padding: 0 }}>{this.props.data.patient_mobile}</p><br />
                                        <p style={{ marginBottom: 0, padding: 0, textTransform: 'capitalize' }}>{this.props.data.payment_mode}</p>
                                    </td>
                                </tr>

                                <tr>
                                    <th>Doctor Details</th>
                                    <td>
                                        <p style={{ marginBottom: 0, padding: 0 }}>{this.props.data.doctor_name}</p><br />
                                        <p style={{ marginBottom: 0, padding: 0 }}>{this.props.data.doctor_email}</p><br />
                                        <p style={{ marginBottom: 0, padding: 0 }}>{this.props.data.doctor_mobile}</p><br />
                                      
                                    </td>
                                </tr>

                                <tr>
                                    <th>Date</th>
                                    <td>{newDate}</td>
                                </tr>
                                <tr>
                                    <th>Time</th>
                                    <td>{handleTimeFormat(this.props.data.time)}</td>
                                </tr>
                                <tr>
                                    <th>Status</th>
                                    <td style={{ color: this.props.data.status === 'Confirmed' ? 'green' : 'tomato' }}>{this.props.data.status}</td>
                                </tr>
                                <tr>
                                    <th style={{ paddingBottom: 50 }}>Action</th>
                                    <td style={{ padding: 5, textAlign: 'right' }} className="cu-389">
                                       {groupRole===7 || groupRole===8 ?
                                        <button className="btn btn-teal btn-rounded btn-sm m-0" onClick={() => this.props.handleConsultsButton(this.props.data)} style={{ opacity: this.props.data.status === 'Cancelled' ? '0.5' : '1', background: '#47b475', padding: 5, marginBottom: 0, color: '#fff' }} disabled={this.props.data.status === 'Cancelled'}>Consults</button>:''}
                                        <RecentOntimeDelayActions data={this.props.data} setModalState={this.props.setModalState} />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Practice Location</th>
                                    <td>{this.props.renderRecentAppointmentLocation}</td>
                                </tr>
                                <tr>
                                    <th>Consult From </th>
                                    <td>{this.props.data.consult_from ==='undefined'? '--' : <span>{this.props.data.consult_from}</span> }
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div
                            style={{
                                height: 'auto', width: '100%',
                                background: '#47b475',
                                textAlign: 'center',
                                display: (is_running === '0' || is_running === '1') && !expired ? 'block' : 'none',
                                color: '#fff'
                            }}>
                            {
                                is_running === '0' ?
                                    <p>Patient notified that the appointment is on-time.</p>
                                    : is_running === '1' ?
                                        <p>Patient notified about delay.</p>
                                        :
                                        null
                            }
                        </div>
                    </div>
                </li>
            </React.Fragment>
        )
    }
}
