import React from "react";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";
import { GET_MONTHLY_APPOINTMENTS_REQUEST } from "../../../../actions/Action";
import { GetAPI } from "../../../../utils/Api-interface";
import { handleDateFormatMonthYear } from "../../../../utils/DateMethod";
class MonthlyNoShow extends React.Component {
  state = {
    page: 1,
    limit: 15,
    noshowdata:[],
    loading:false
  };
  getNoShowData= async(id,page,limit)=>{
    this.setState({loading:true})
    const response = await GetAPI(`api/consult_report/dr_monthly_no_show?doctor_uid=${id}&page=${page}&limit=${limit}`)
   if(response.status === 200){
    this.setState({noshowdata:response.data, loading:false})
   }else{
     this.setState({loading:false})
   }
  
  }
  componentDidMount() {
    const uid = localStorage.getItem("uid");
    const { page, limit } = this.state;
   this.getNoShowData(uid,page,limit)
  }
  handlePageClick = (pages) =>{
    const uid = localStorage.getItem("uid");
    const { limit } = this.state;
    this.getNoShowData(uid, pages.selected + 1,limit)
    
  }
  render() {
    const { noshowdata, loading } = this.state;
    // console.log(noshowdata,'responsed')
    return (
      <div className="consults-sent">
       {!loading ? <div class="col-12 mt-3">
          <div className="doc_appointment-resp_table">
            <table className="table table-striped table-bordered" id="myTable">
            <thead
                style={{
                  background: "#70ad47",
                  color: "#FFF",
                }}
              >
                <tr>
                  <th scope="col">
                    <span
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        whiteSpace: "nowrap",
                      }}
                    >
                      MONTH YEAR
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        whiteSpace: "nowrap",
                      }}
                    >
                     No Show Count
                    </span>
                  </th>
               
                </tr>
              </thead>
              <tbody>
                {/* {console.log(noshowdata,'noshowdata')} */}
                {noshowdata?.data?.length ? ( 
                  noshowdata?.data?.map((el) => {
                   
                    return (
                      <tr>
                        <td nowrap="nowrap">
                          {handleDateFormatMonthYear(el._id)}
                        </td>
                        <td nowrap="nowrap">{el.noShowCount}</td>
                        
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td className="text-center" colSpan={7}>
                      No data found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        :
        <div
        style={{
          height: "auto",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          alt="placeholder"
          src="https://luxysource.com/pub/static/frontend/Magento/luxysource/en_US/custom/img/preloader.gif"
          style={{ height: 80, width: 80 }}
        />
      </div>}

        {noshowdata?.totalPages ? (
          <ReactPaginate
            previousLabel={"previous"}
            nextLabel={"next"}
            pageCount={noshowdata.totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={this.handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
   // noshowdata: state.noshowdata,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
   // getAppointments: (data) =>
     // dispatch({ type: GET_MONTHLY_APPOINTMENTS_REQUEST, data }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MonthlyNoShow);
