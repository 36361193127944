import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import ErrorHandling from "../component/ErrorHandling";
import NotFound from "../component/NotFound";
import Navbar from "../component/Navbar";

import PersonalDetails from "../container/appContainer/PersonalDetails";
import Profile from "../container/appContainer/Profile";
import Password from "../container/appContainer/Password";
import Dashboard from "../container/appContainer/Dashboard";
import RatingAndReview from "../container/appContainer/RatingAndReview";
import AppointmentSchedule from "../container/appContainer/AppointmentSchedule";
import AppointmentConfirmation from "../container/appContainer/AppointmentConfirmation";
import ReportingWrapper from "../container/appContainer/reporting/index";
import Messaging from "../container/appContainer/Messaging";
import ManageDoctor from "../container/appContainer/ManageDoctor";
import AddDoctor from "../container/appContainer/AddDoctor";

import SearchDoctor from "../container/ConsultsContainer/SearchDoctor";
import DoctorsProfile from "../container/ConsultsContainer/DoctorsProfile";
import ScheduleConsults from "../container/ConsultsContainer/ScheduleConsults";
import Interest from "../container/Interested/Interest";
import TrainingVideo from "../container/appContainer/TrainingVideo";
import Subscription from "../component/Subscription/Subscription";
import Subscriptiondetail from "../component/Subscriptiondetail";
import Transition from "../component/Transition";
import TrialPeriod from "../component/Subscription/TrialPeriod";
import "../styles/DashboardWrapper.css";
import ChatPanel from "../container/appContainer/chat-panel";
import ManageStaffMember from "../container/appContainer/ManageStaffMember";
export default class DashboardWrapper extends Component {
  state = {
    userIs: "",
  };

  componentDidMount() {
    this.setState({ userIs: this.props.userData });
  }

  render() {
    const routepath = this.props.location.pathname.split("/")[1];
    // const currentStatusIs = localStorage.getItem("doctorStatus");
    return (
      <React.Fragment>    
        <Navbar isAuth={this.props.isAuth} user={this.props.user} />
        {this.props?.userData?.groupRole === 6
          ? ""
          : this.props?.userData.isEnableSubscrption === 1 && <TrialPeriod />}
        {routepath === "searchdoc" ||
        routepath === "doctor_profile" ||
        routepath === "schedule" ||
        routepath === "interest" ||
        routepath === "traning_video" ? (
          <ErrorHandling>
            <Switch>
              <Route
                path={`${this.props.match.path}searchdoc`}
                component={SearchDoctor}
              />
              <Route
                path={`${this.props.match.path}doctor_profile`}
                component={DoctorsProfile}
              />
              <Route
                path={`${this.props.match.path}schedule`}
                component={ScheduleConsults}
              />
              <Route
                path={`${this.props.match.path}traning_video`}
                component={TrainingVideo}
              />
              <Route
                path={`${this.props.match.path}interest`}
                render={(props) => (
                  <Interest {...props} isAuth={this.props.isAuth} />
                )}
              />
            </Switch>
          </ErrorHandling>
        ) : (
          <ErrorHandling>
            <Switch>
              <Route
                path={`${this.props.match.path}chat/:reciverId/:conversationId`}
                render={(props) => <ChatPanel {...props} {...this.state} />}
              />
              <Route
                path={`${this.props.match.path}chat`}
                exact
                render={(props) => <ChatPanel {...props} {...this.state} />}
              />
              <div className="main-container">
                <section
                  className="doc_dashboard_section"
                  style={{ overflow: "visible" }}
                >
                  <div
                    className="row"
                    style={{ marginLeft: 0, marginRight: 0 }}
                  >
                    <div className="col-lg-12  mb-xs-24 feature bordered boxradius bg-white">
                      <div className="post-snippet">
                        <Route
                          exact
                          path={`${this.props.match.path}`}
                          component={Dashboard}
                        />
                        {/* <Route exact path={`${this.props.match.path}subscription`} component={Subscription} /> */}

                        <Route
                          path={`${this.props.match.path}subscription`}
                          render={(props) => (
                            <Subscription {...props} {...this.state} />
                          )}
                        />
                        <Route
                          exact
                          path={`${this.props.match.path}subscriptiondetail`}
                          component={Subscriptiondetail}
                        />
                        <Route
                          exact
                          path={`${this.props.match.path}my-subscription`}
                          component={Transition}
                        />
                        <Route
                          exact
                          path={`${this.props.match.path}my-transaction`}
                          render={() => <Transition />}
                        />

                        {/* <Route path="/subscription"> <Subscription /> </Route> */}
                        <Route
                          path={`${this.props.match.path}traning-video`}
                          component={TrainingVideo}
                        />
                        <Route
                          path={`${this.props.match.path}personal-details`}
                          component={PersonalDetails}
                        />
                         <Route
                          path={`${this.props.match.path}staff-members`}
                          component={ManageStaffMember}
                        />
                        <Route
                          path={`${this.props.match.path}profile`}
                          component={Profile}
                        />
                        <Route
                          path={`${this.props.match.path}password`}
                          component={Password}
                        />
                        <Route
                          path={`${this.props.match.path}rating_&_reviews`}
                          component={RatingAndReview}
                        />
                        <Route
                          path={`${this.props.match.path}reporting`}
                          component={ReportingWrapper}
                        />
                        {/* <Route
                          path={`${this.props.match.path}message`}
                          component={Messaging}
                        /> */}
                        <Route
                          path={`${this.props.match.path}mange_doctor`}
                          component={ManageDoctor}
                        />
                        <Route
                          path={`${this.props.match.path}add_doctor`}
                          component={AddDoctor}
                        />
                        <Route
                          path={`${this.props.match.path}calendar`}
                          component={AppointmentSchedule}
                        />
                        <Route
                          path={`${this.props.match.path}confirm`}
                          render={(props) => (
                            <AppointmentConfirmation
                              {...props}
                              isAuth={this.props.isAuth}
                            />
                          )}
                        />
                        {/* <Route render={() => <NotFound />} /> */}
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </Switch>
          </ErrorHandling>
        )}
      </React.Fragment>
    );
  }
}
