import React, { useState } from "react";
import Doc from "../pdfExporter/DocService";
const PdfContainer = (props) => {
  const createPdf = (html) => Doc.createPdf(html, props.subscriptionId);
  const bodyRef = React.createRef();
  const generatePdf = () => {
    createPdf(bodyRef.current)
    setTimeout(()=>{
      setShow(false)
    },1000)
  };
  const [show, setShow] = useState(false);
  return (
    <div className="pdf-container">
      {show && (
        <div
          className="pdf-body printable"
          ref={bodyRef}
          style={{
            // position:'absolute', zIndex:-1,
            width: "500px",
          }}
        >
          {props.children}
        </div>
      )}
      <div className="pdf-toolbar">
        {show ? (
          <React.Fragment>
          <button className="btn  btn-primary text-black" onClick={generatePdf}>
            Download Invoice
          </button>
           <button className="btn  btn-primary text-black" onClick={()=>setShow(false)}>
           Close Preview
         </button>
         </React.Fragment>
        ) : (
          <button className="btn  btn-primary text-black" onClick={()=>setShow(true)}>
            Generate Invoice
          </button>
        )}
      </div>
    </div>
  );
};
export default PdfContainer;
