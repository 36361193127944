import React, { Component } from "react";
import Imgone from "../../../assets/img/bg_im.png"; 
import { getNameLetter } from "../../../utils/helperFunctions";
import Avatar from "react-avatar";
import Moment from "react-moment";
class ChatSectionHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
     
    };
  }





  render() {
    const {activeUser,socket} =this.props;
    const first_name = activeUser?.user.first_name;
    const last_name = activeUser?.user.last_name;
    const online = activeUser?.user.online;
    const logs = activeUser?.user.logs;
    return (
      <React.Fragment>

        <div className="top_left_section flex w-100" style={{alignItems:'center'}}>
          <div className={`chat_user_icon mr_10 ${online ? 'online' : 'offline'}`}>
          <Avatar name={getNameLetter(first_name, last_name)} size="44"  round="22px" maxInitials={2}/>
            {/* <img src={Imgone} alt="img_s" /> */}
          </div>

          <div className="show_ur u_info">
            <h3>{first_name}{' '}{last_name}  </h3>
            {online ? <p style={{marginTop:'-10px', textTransform:'unset'}}> <span className="bg_onn" style={{width:'10px', height:'10px'}} /> online</p>
            : <p style={{marginTop:'-10px',  textTransform:'unset'}}>Last seen <Moment fromNow>{logs?.last_login_date}</Moment></p>}
          </div>

        </div>
        <div className="top_right_section ml_auto">
          <div className="dropdown show ">
            <a className="btn cs_drop top_cs_drop mb-0" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <div />
              <div />
              <div />
            </a>
            <div className="dropdown-menu cs_drop_title" aria-labelledby="dropdownMenuLink">
              <a className="dropdown-item" data-toggle="modal" data-target="#confirmation"  onClick={()=>socket.emit("clearChat")}>Delete Conversation</a>
            </div>
          </div>
        </div> 
      </React.Fragment>

    );
  }
}


export default ChatSectionHeader
