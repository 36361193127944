import React, { Component } from "react";
import SubscriptionAccountInfo from "./SubscriptionAccountInfo";
import BillingAddress from "./BillingAddress";
import Payment from "./Payment";
import { GetAPI, PostAPI } from "../../utils/Api-interface";
import { ToastMessage } from "../ToastMessage";
import { toast } from "react-toastify";
import { Link, withRouter } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
// import {} from "../../styles/Subscription.css";
import {
  validateEmail,
  validateName,
  findDebitCardType,
  tenDigitMobileNumber,
} from "../../utils/FormValidater";
import { connect } from "react-redux";
import { GET_SUBSCRIPTION_REQUEST } from "../../actions/Action";
class Subscription extends Component {
  state = {
    isLoading: false,
    userDataUpdated: false,
    activeTab: 0,
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    address: "",
    stateIs: "",
    city: "",
    street: "",
    country: "United States",
    zip: "",
    expiryMonth: "",
    expiryYear: "",
    cardNumber: "",
    cvv: "",
    cityList: [],
    errorBillingIs: {
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      address: "",
      stateIs: "",
      city: "",
      street: "",
      zip: "",
    },
    errorPaymentIs: {
      cardNumber: "",
      expiryMonth: "",
      expiryYear: "",
      cvv: "",
    },
  };
  messageSuccess = () => {
    confirmAlert({
      closeOnEscape: false,
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui cancel-confirm-subscription">
            <h4 style={{ color: "#ffffff" }}>
              You have successfully subscribed our subscription plan.
            </h4>

            <p>
              Your subscription is successfully activated, for transaction
              details.
            </p>
            <button
              onClick={() => {
                onClose();
                this.props.history.push("/my-subscription");
              }}
            >
              See Subscription
            </button>
          </div>
        );
      },
    });
  };
  handleCityList = (value) => {
    GetAPI(`api/subscription/city_list?state_id=${value}`).then((res) => {
      const response = res.data.data;
      if (response) {
        this.setState({
          cityList: response,
          city: response.length && response[0].city,
        });
      }
    });
  };

  handleOnChange = (e) => {
    const { name, value } = e.target;
    if (name === "mobile") {
      if (value.length <= 10) {
        this.setState({ [name]: value });
      }
    } else if (name === "cardNumber") {
      if (value.length <= 16) {
        this.setState({ [name]: value });
      }
    } else if (name === "cvv") {
      if (value.length <= 3) {
        this.setState({ [name]: value });
      }
    } else {
      this.setState({ [name]: value }, () => {
        if (name === "stateIs") {
          this.handleCityList(value);
        }
      });
    }

    if (this.state.errorBillingIs.hasOwnProperty(name)) {
      this.setState({
        errorBillingIs: { ...this.state.errorBillingIs, [name]: "" },
      });
    } else if (this.state.errorPaymentIs.hasOwnProperty(name)) {
      this.setState({
        errorPaymentIs: { ...this.state.errorPaymentIs, [name]: "" },
      });
    }
  };

  handleSubscription = () => {
    this.setState({ activeTab: 1 });
  };
  handleTabChange = (activeTab) => {
    this.setState({ activeTab });
  };
  validationBilling() {
    const {
      errorBillingIs,
      firstName,
      lastName,
      email,
      mobile,
      address,
      stateIs,
      city,
      street,
      zip,
    } = this.state;
    errorBillingIs.firstName = !validateName(firstName)
      ? "Please enter valid Firstname"
      : "";
    errorBillingIs.lastName = !validateName(lastName)
      ? "Please enter valid Lastname"
      : "";
    errorBillingIs.email = !validateEmail(email)
      ? "Please enter valid email"
      : "";
    errorBillingIs.mobile =
      mobile.length !== 10 ? "Please enter valid mobile no" : "";
    errorBillingIs.address =
      address.length < 3
        ? "Billing address should have min 3 characters"
        : address.length < 61 ? "" : "Address is too long please enter valid details";
    errorBillingIs.stateIs = !stateIs ? "Please select State" : "";
    errorBillingIs.city = !city ? "Please select your City name" : "";
    // errorBillingIs.street = !street ? 'Please select your City name' : '';
    errorBillingIs.zip = zip.length !== 5 ? "Please enter valid zip code" : "";
    if (
      !errorBillingIs.firstName &&
      !errorBillingIs.lastName &&
      !errorBillingIs.email &&
      !errorBillingIs.mobile &&
      !errorBillingIs.address &&
      !errorBillingIs.stateIs &&
      !errorBillingIs.city &&
      !errorBillingIs.zip
    ) {
      return true;
    } else {
      this.setState({ errorBillingIs }, () => {
        const fields = Object.keys(errorBillingIs);
        const msg = fields
          .map((el) => {
            return { fieldname: el, value: errorBillingIs[el] };
          })
          .filter((el) => el.value !== "");
        toast.error(<ToastMessage message={msg[0].value} />, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
    }
  }

  validationPayment() {
    const {
      errorPaymentIs,
      cardNumber,
      expiryMonth,
      expiryYear,
      cvv,
    } = this.state;
    errorPaymentIs.cardNumber = !findDebitCardType(cardNumber)
      ? "Please enter valid Card number"
      : "";
    errorPaymentIs.expiryMonth = !expiryMonth
      ? "Please select expiry month"
      : "";
    errorPaymentIs.expiryYear = !expiryYear ? "Please select expiry year" : "";
    errorPaymentIs.cvv = cvv.length !== 3 ? "Please enter valid cvv no" : "";

    if (
      !errorPaymentIs.cardNumber &&
      !errorPaymentIs.expiryMonth &&
      !errorPaymentIs.expiryYear &&
      !errorPaymentIs.cvv
    ) {
      return true;
    } else {
      this.setState({ errorPaymentIs }, () => {
        const fields = Object.keys(errorPaymentIs);
        const msg = fields
          .map((el) => {
            return { fieldname: el, value: errorPaymentIs[el] };
          })
          .filter((el) => el.value !== "");
        toast.error(<ToastMessage message={msg[0].value} />, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
    }
  }

  handleSubscriptionApi = (subsData) => {
    this.setState({ isLoading: true });

    PostAPI(`api/subscription/create_subscription`, subsData)
      .then((res) => {
        // toast.success(<ToastMessage message={res.data.message} />, {
        //   position: toast.POSITION.TOP_CENTER,
        // });
        this.props.getSubscriptionDetails(subsData.doctor_uid);
        this.setState({ isLoading: false });
        this.messageSuccess();
      })
      .catch((err) => {
        if (err.response) {
          toast.error(<ToastMessage message={err.response.data.error} />, {
            position: toast.POSITION.TOP_CENTER,
          });
          this.setState({ isLoading: false });
        }
      });
  };

  handleSubmit = () => {
    const uid = localStorage.getItem("uid");
    const {
      firstName,
      lastName,
      email,
      city,
      stateIs,
      zip,
      address,
      mobile,
      country,
      cardNumber,
      expiryMonth,
      expiryYear,
    } = this.state;

    const subsData = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      city: city,
      state: stateIs,
      zip: zip,
      country: country,
      phone_number: mobile,
      address: address,
      card_nuber: cardNumber,
      card_expiray_date: `${expiryMonth}${expiryYear}`,
      doctor_uid: uid,
      doctor_obj_id: this.props.userIs._id,
    };

    if (this.validationBilling() && this.validationPayment()) {
      this.handleSubscriptionApi(subsData);
    }
  };

  handleBilling = (e) => {
    e.preventDefault();
    if (this.validationBilling()) {
      this.setState({ activeTab: 2 });
    }
  };
  componentDidUpdate(prevProps, prevState, snapshot) {
    // console.log(prevProps, "state in mount");
    if (this.props.loginuserdetails && !this.state.userDataUpdated) {
      const pdata = this.props.loginuserdetails.user;
      this.setState({
        userDataUpdated: true,
        firstName: pdata.first_name,
        lastName: pdata.last_name,
        email: pdata.email,
        mobile: tenDigitMobileNumber(pdata.mobile),
      });
    }
  }
  render() {
    const Subsfunc = {
      handleSubscription: this.handleSubscription,
      handleOnChange: this.handleOnChange,
      handleBilling: this.handleBilling,
      handleSubmit: this.handleSubmit,
      handleTabChange: this.handleTabChange,
    };

    const { activeTab, lastName, isLoading, firstName } = this.state;

    return (
      <React.Fragment>
        {/* {console.log(activeTab, "state in render activeTab")} */}
        {this.props.subscriptiondata?.status !== 1 && (
          <div className="container animate-tab">
            <div className="row">
              <ul className="subscription ">
                <li
                  className={`d-flex justify-space active ${
                    activeTab === 0 ? `current-active` : ``
                  }`}
                >
                  <a>
                    <div className="circle-dot">
                      <span className="edit-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-edit-2"
                        >
                          <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
                        </svg>
                      </span>
                    </div>
                    Plan Information
                  </a>
                  <div className="h-line">
                    <div className="horizontal-line"></div>
                  </div>
                </li>
                <li
                  className={`d-flex justify-space ${
                    activeTab > 0 ? "active" : ""
                  } ${activeTab === 1 ? `current-active` : ``}`}
                >
                  <a>
                    <div className="circle-dot">
                      <span className="edit-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-edit-2"
                        >
                          <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
                        </svg>
                      </span>
                    </div>
                    Billing Address
                  </a>
                  <div className="h-line">
                    <div className="horizontal-line"></div>
                  </div>
                </li>
                <li
                  className={`${activeTab > 2 ? "active" : ""}  ${
                    activeTab === 2 ? `current-active` : ``
                  }`}
                >
                  <a>
                    <div className="circle-dot">
                      <span className="edit-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-edit-2"
                        >
                          <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
                        </svg>
                      </span>
                    </div>
                    Payment
                  </a>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                {/*****------------------- Account Information------------------------ */}
                <div
                  className={`tab-pane ${
                    activeTab > 0 ? "active-filled" : ""
                  } ${activeTab === 0 ? "active" : ""}`}
                >
                  <div className="tab-pane-inner">
                    <SubscriptionAccountInfo
                      {...this.state}
                      {...this.props}
                      handleFunc={Subsfunc}
                    />
                  </div>
                </div>
                {/*****------------------- Account Information------------------------ */}

                {/*****------------------- Billing Address------------------------ */}
                <div
                  className={`tab-pane plan-detail ${
                    activeTab > 1 ? "active-filled" : ""
                  } ${activeTab === 1 ? "active" : ""}`}
                >
                  <div className="tab-pane-inner">
                    <BillingAddress
                      {...this.state}
                      {...this.props}
                      handleFunc={Subsfunc}
                    />
                  </div>
                </div>
                {/*****-------------------subscription Billing Address------------------------ */}

                {/*****-------------------Payment------------------------ */}
                <div
                  className={`tab-pane plan-detail ${
                    activeTab > 2 ? "active-filled" : ""
                  } ${activeTab === 2 ? "active" : ""}`}
                >
                  <div className="tab-pane-inner">
                    <Payment
                      {...this.state}
                      {...this.props}
                      handleFunc={Subsfunc}
                    />
                  </div>
                </div>
                {/*****------------------- Payment------------------------ */}
              </div>
            </div>
          </div>
        )}
        {this.props.subscriptiondata?.status === 1 &&   this.messageSuccess() }
        {isLoading && (
          <div className="section-screen loader">
            <span className="bubble" role="status">
              <img
                style={{ width: "100%" }}
                src={require("../../assets/img/bubble-loader.svg")}
              />
            </span>
          </div>
        )}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    subscriptiondata: state.subscriptionData,
    loginuserdetails: state.loginuserdetails,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getSubscriptionDetails: (data) =>
      dispatch({ type: GET_SUBSCRIPTION_REQUEST, data }),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Subscription)
);
