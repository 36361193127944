export const validateEmail = (email) => {
  let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validateName = (name) => {
  return /^[A-z ]+$/.test(name) && name.length > 2;
};
export const findDebitCardType = (cardNumber) => {
  const regexPattern = {
    MASTERCARD: /^5[1-5][0-9]{1,}|^2[2-7][0-9]{1,}$/,
    VISA: /^4[0-9]{2,}$/,
    AMERICAN_EXPRESS: /^3[47][0-9]{5,}$/,
    DISCOVER: /^6(?:011|5[0-9]{2})[0-9]{3,}$/,
    DINERS_CLUB: /^3(?:0[0-5]|[68][0-9])[0-9]{4,}$/,
    JCB: /^(?:2131|1800|35[0-9]{3})[0-9]{3,}$/,
  };
  for (const card in regexPattern) {
    if (
      cardNumber.replace(/[^\d]/g, "").match(regexPattern[card]) &&
      cardNumber.length === 16
    )
      return card;
  }
  return "";
};

export const tenDigitMobileNumber = (number) => {
  if (number.startsWith("+91")) {
    return number.split("+91")[1];
  } else if (number.startsWith("+1")) {
    return number.split("+1")[1];
  } else {
    return number;
  }
};
