import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ErrorHandling from "../component/ErrorHandling";
import NotFound from "../component/NotFound";
import firebaseApp from "../utils/Fire";
import TermsCondition from "../container/authContainer/TermsCondition";

/*--------------------user-unauthorized-screen--------------*/

import AuthWrapper from "../childroutes/AuthWrapper";
import VerificationScreen from "../container/authContainer/VerificationScreen";

/*--------------------user-unauthorized-screen--------------*/
import DashboardWrapper from "../childroutes/DashboardWrapper";
import AppointmentConfirmation from "../container/appContainer/AppointmentConfirmation";
import Interest from "../container/Interested/Interest";
import { toast } from "react-toastify";
import axios from "axios";
import { Baseurl } from "../utils/Baseurl";
// import ConsultWrapper from '../childroutes/ConsultWrapper';

export default class RootRouter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuth: false,
      isAuthLoading: true,
      signingUp: false,
      userData: "",
    };
  }

  handleUserId = (userId) => {
    axios({
      method: "get",
      url: Baseurl + `api/doc/getDetails?userId=${userId}`,
      headers: { "content-type": "application/json" },
    })
      .then((response) => {
        // console.log("alldata", response);
        if (response) {
          this.setState({
            isAuth: true,
            isAuthLoading: false,
            userData: response.data.user,
          });
        }
      })
      .catch((err) => {
        this.setState({ isAuth: false, isAuthLoading: false });
        localStorage.removeItem("uid");
      });
  };

  UNSAFE_componentWillMount() {
    // this.setState({ isAuthLoading: true });
    firebaseApp.auth().onAuthStateChanged((user) => {
      if (user && !this.state.signingUp) {
        let userId = localStorage.getItem("uid");
     if(userId){
      this.handleUserId(userId);
     }else{
      this.setState({
        isAuth: false,
        isAuthLoading: false,
      });
     }
        // if (user.emailVerified && userId) {
         
        //   // alert("dd")
        //   this.handleUserId(userId);
        // } else {
        //   let userId = localStorage.getItem("uid");
        //   if (user.uid === userId) {
        //     firebaseApp.auth().signOut();
        //     // this.props.history.push('/access/login');
        //     toast.warn("Please verify your email.", {
        //       position: toast.POSITION.TOP_CENTER,
        //     });
        //     this.setState({ isAuth: false, isAuthLoading: false });
        //   } else {
        //     this.setState({  isAuthLoading: false });
        //   }
        // }
      } else {
        this.setState({ isAuth: false, isAuthLoading: false });
      }
    });
  }

  handleAuthState = (state) => {
    this.setState({ isAuth: state });
  };

  signingUp = (state) => {
    this.setState({ signingUp: state });
  };

  render() {
    return this.state.isAuthLoading ? (
      <h1>Loading...</h1>
    ) : (
      <Router>
        <React.Fragment>
          <ErrorHandling>
            {this.state.isAuth ? (
              <React.Fragment>
                <Switch>
                  <Route
                    path="/"
                    render={(props) => (
                      <DashboardWrapper
                        {...props}
                        isAuth={this.state.isAuth}
                        {...this.state}
                      />
                    )}
                  />
                  {/* <Route path="/consults" component={ConsultWrapper} /> */}
                  <Route render={() => <NotFound />} />
                </Switch>
              </React.Fragment>
            ) : (
              <Switch>
                <Route exact path="/" component={WithoutAuth} />
                <Route
                  path="/access"
                  render={(props) => (
                    <AuthWrapper
                      {...props}
                      handleIsAuth={this.handleAuthState}
                      signingUp={this.signingUp}
                      isAuth={this.state.isAuth}
                    />
                  )}
                />
                <Route
                  path="/confirm"
                  render={(props) => (
                    <AppointmentConfirmation
                      {...props}
                      isAuth={this.state.isAuth}
                    />
                  )}
                />
                <Route
                  path="/interest"
                  render={(props) => (
                    <Interest {...props} isAuth={this.state.isAuth} />
                  )}
                />
                <Route
                  exact
                  path={`/TermsCondition`}
                  render={(props) => (
                    <TermsCondition {...props} isAuth={this.state.isAuth} />
                  )}
                />
                <Route
                  path="/verification"
                  render={(props) => <VerificationScreen {...props} />}
                />
                <Route render={() => <NotFound />} />
              </Switch>
            )}
          </ErrorHandling>
        </React.Fragment>
      </Router>
    );
  }
}

class WithoutAuth extends Component {
  componentDidMount() {
    let params = this.props.location.search;
    console.log("this.props.location", this.props.location.search);
    var res = params.split("=");
    if (res.length > 0) {
      let reffer_key = res[1];
      localStorage.setItem("reffer_key", reffer_key);
    }
    this.props.history.push("/access/login");
  }

  render() {
    return null;
  }
}
