import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { validateEmail } from '../../utils/FormValidater';
import firebaseApp from '../../utils/Fire';
import { toast } from 'react-toastify';
import { ToastMessage } from '../../component/ToastMessage';
import '../../styles/Common.css';


export default class RecoverAccount extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '', emailError: false,
            isLoading: false
        }
    }

    handleOnChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        e.target.name === 'email' && this.setState({ emailError: false });

    }

    validateForm() {
        const { email } = this.state;
        !validateEmail(email) && this.setState({ emailError: true });

        return validateEmail(email) > 0;
    };


    /*------------------------------------------------------------handle-user-resetpassword-------------------------------------------------*/


    handleResetPassword = (e) => {
        e.preventDefault();
        const { email } = this.state;
        this.setState({ isLoading: true });

        if (this.validateForm()) {
            firebaseApp.auth().sendPasswordResetEmail(email)
                .then((res) => {
                    // console.log("Password reset email sent, check your inbox.", res);
                    toast.success(<ToastMessage message="Password reset request sent to your email." />, { position: toast.POSITION.TOP_CENTER });
                    this.setState({ isLoading: false });
                    this.props.history.push('/');
                }).catch((error) => {
                    console.log("something wrong Password reset email sent.", error);
                    toast.error(error.message, { position: toast.POSITION.TOP_CENTER });
                    this.setState({ isLoading: false });
                })
        } else {
            console.log("in-validation-error");
            this.setState({ isLoading: false });
        }
    }


    render() {
        return (
            <React.Fragment>
                <h3 className="bold mb0">Recover your account</h3>
                <p className="">Did you remember your password? <Link to="/access/login">Log in.</Link></p>
                <form className="text-left" onSubmit={this.handleLogin}>
                    <input type="text" name="email" placeholder="Email Address" onChange={(e) => this.handleOnChange(e)} style={{ border: this.state.emailError ? '1px solid #c64444' : '' }} />

                    <button type='submit' disabled={this.state.isLoading} onClick={this.handleResetPassword}>
                        {this.state.isLoading && <span className="spinner-border text-light mr8" role="status" />}
                        RECOVER ACCOUNT
                    </button>
                </form>
            </React.Fragment>
        )
    }
}
