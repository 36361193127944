import React from "react";
import { PostAPI } from "../utils/Api-interface";
import { toast } from "react-toastify";
import { ToastMessage } from "../component/ToastMessage";
import store from "../store/Store"
import { GET_SUBSCRIPTION_REQUEST } from "../actions/Action";
export async function cancelSubscription(data) {
  await PostAPI(`api/subscription/cancel_subscription`, data)
    .then((success) => {
      console.log(success, "success");
        store.dispatch({type:GET_SUBSCRIPTION_REQUEST,data:data.doctor_uid})
        toast.success(<ToastMessage message={"Subscription cancelled successfully."} />, {
            position: toast.POSITION.TOP_CENTER,
          });
    
    })
    .catch((error) => {
      toast.error(<ToastMessage message={"Something went wrong."} />, {
        position: toast.POSITION.TOP_CENTER,
      });

      console.log(error, "error");
    });
}
