import React, { Component } from 'react'
import firebaseApp from '../../utils/Fire';
import { toast } from 'react-toastify';
import { ToastMessage } from '../../component/ToastMessage';
import UpdatePasswordErrorToast from '../../component/UpdatePasswordErrorToast';
import '../../styles/Common.css';
const firebase = require('firebase/auth');

export default class Password extends Component {

    constructor(props) {
        super(props);
        this.state = {
            new_password: '',
            old_password:'',
            newPasswordError: false,
            confirm_password: '',
            oldPasswordError:false,
            confirmPasswordError: false,
            isLoading: false,
        }
    }

    UNSAFE_componentWillMount() {
        window.scrollTo(0, 0);
    }


    handleOnChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
        e.target.name === 'old_password' && this.setState({ oldPasswordError: false });
        e.target.name === 'new_password' && this.setState({ newPasswordError: false, tooLong: false });
        e.target.name === 'confirm_password' && this.setState({ confirmPasswordError: false });

    }




    validateForm() {
        const {old_password, new_password, confirm_password } = this.state;
        if(old_password===""){
           this.setState({ oldPasswordError: true });
          toast.error('Please enter Old password', { position: toast.POSITION.TOP_CENTER });   
        }
        old_password.length < 1 && this.setState({ old_password: true });
        new_password.length < 8 && this.setState({ newPasswordError: true });
        new_password.length > 36 && this.setState({ tooLong: true });

        confirm_password.length < 8 && this.setState({ confirmPasswordError: true });
        confirm_password.length > 36 && this.setState({ tooLong: true });

        new_password !== confirm_password && this.setState({ confirmPasswordError: true, newPasswordError: true },()=>{
            toast.error('Your password didn’t match', { position: toast.POSITION.TOP_CENTER });
        });


        return new_password.length > 7 && new_password.length < 37 && new_password === confirm_password;
    };

  
    reauthenticate = (currentPassword) => {
        var fbuser = firebase.auth().currentUser;
        var cred = firebase.auth.EmailAuthProvider.credential(
            fbuser.email, currentPassword);
           // console.log('cred',cred);
            //return false;
        return fbuser.reauthenticateWithCredential(cred);
    }  
    
    
   


    handleChangePassword = (e) => {
        e.preventDefault();
        this.setState({ isLoading: true });
        const { old_password,confirm_password } = this.state;
        if (this.validateForm()) {
            this.reauthenticate(old_password).then(() => {
                var user = firebaseApp.auth().currentUser;
                user.updatePassword(confirm_password).then((res) => {
                    toast.success(< ToastMessage message="Your password sucessfully updated." />, { position: toast.POSITION.TOP_CENTER });
                    this.setState({ new_password: '', confirm_password: '',old_password:'', isLoading: false });
                }).catch((error) => {                
                    if(error.message){
                    toast.error(error.message, { position: toast.POSITION.TOP_CENTER });
                    }
                    this.setState({ isLoading: false });
                   
                    if(error.code==="auth/requires-recent-login")
                    {
                        toast.error(<UpdatePasswordErrorToast  message="For your account security purposes you need to validate your existing account. Please  sign in with original sign in information." />, { position: toast.POSITION.TOP_CENTER });
                    }
                    else
                    {
                        toast.error(error.message, { position: toast.POSITION.TOP_CENTER });
                    }
                    
                });
            }).catch((error) => {
                this.setState({ isLoading: false,oldPasswordError: false  });             
                toast.error('The old password is invalid or the user does not have a password', { position: toast.POSITION.TOP_CENTER });              
              
           });



        } else {
            
            this.setState({ isLoading: false })
        }
    }




    render() {
        return (
            <React.Fragment>
                <div className="main-container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <form onSubmit={this.handleChangePassword}>
                                <h4>Password</h4>
                                <hr />
                                <div className="input-with-label text-left">
                                    <span style={{textTransform:'none'}}>Enter old password</span>
                                    <input value={this.state.old_password} className="col-md-12" type="password" name="old_password" placeholder="Old password" onChange={(e) => this.handleOnChange(e)} style={{ border: this.state.oldPasswordError ? '1px solid #c64444' : '' }} />
                                </div>
                                <div className="input-with-label text-left">
                                    <span style={{textTransform:'none'}}>Enter new password</span>
                                    <input value={this.state.new_password} className="col-md-12" type="password" name="new_password" placeholder="New password" onChange={(e) => this.handleOnChange(e)} style={{ border: this.state.newPasswordError ? '1px solid #c64444' : '' }} />
                                </div>
                                <div className="input-with-label text-left">
                                    <span style={{textTransform:'none'}}>Confirm password</span>
                                    <input value={this.state.confirm_password} className="col-md-12" type="password" name="confirm_password" placeholder="Confirm password" onChange={(e) => this.handleOnChange(e)} style={{ border: this.state.confirmPasswordError ? '1px solid #c64444' : '' }} />
                                </div>
                                <hr />
                                <div style={{ display: '', alignItems: 'center' }}>
                                    <button type='submit' disabled={this.state.isLoading} onClick={this.handleChangePassword} style={{ width: 100, margin: 5 }}>
                                        {this.state.isLoading && <span className="spinner-border text-light mr8" role="status" />}
                                        SAVE
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}