import { call, put } from "redux-saga/effects";
import axios from "axios";
import { GET_TRANSACTION_SUCCESS_REQUEST, GET_TRANSACTION_FAILURE_REQUEST } from '../../actions/Action';
import { Baseurl } from '../../utils/Baseurl';

export function getTransactionRequest(url) {
    let headers = {
        'Content-Type': 'application/json',
        'Pragma': 'no-cache'
    };
    return axios({
        method: "get",
        url: Baseurl + url,
        headers
    });
}


export function* getTransactionDetails(action) {
    try {
        const response = yield call(getTransactionRequest, `api/subscription/get_transaction?doctor_uid=${action.data}`);
 
        const transactionData = response.data.data;
    
        // dispatch a success action to the store with the new data
        yield put({ type: GET_TRANSACTION_SUCCESS_REQUEST, transactionData });
    } catch (error) {
        // dispatch a failure action to the store with the error
        yield put({ type: GET_TRANSACTION_FAILURE_REQUEST, error });
      
    }
}






