		import React, { Component } from "react";
		import firebaseApp from '../../utils/Fire';
		import { validateEmail, validateName } from '../../utils/FormValidater';		
		import { Baseurl, BaseUrlPatent } from "../../utils/Baseurl";
		import { toast } from 'react-toastify';
		import { primarySpeciality } from '../../constant/speciality';
		//import { ToastMessage } from '../../component/ToastMessage';
		import "../../styles/ManageDoctor.css";
		import axios from "axios";

		export default class AddDoctor extends Component{
			constructor(props){ 	
				super(props);
				this.state = {
					first_name: '', 
					firstError: false,
					last_name: '', 
					lastError: false,
					phone: '', 
					phoneError: false,
					email: '', 
					emailError: false,
					password: '', 
					passwordError: false,
					primary_speciality: '', 
					specialityError: false,
					zip: '', 
					zipError: false,
					tooLong: false,
					isLoading: false,
					grpDrEmail:'',
					grpDrMobile:'',
					managementGroup:'',
                    managementGroupData:[],
				}		

			}

			handleOnChange = (e) => {
				this.setState({ [e.target.name]: e.target.value });
				e.target.name === 'first_name' && this.setState({ firstError: false });
				e.target.name === 'last_name' && this.setState({ lastError: false });
				e.target.name === 'phone' && this.setState({ phoneError: false });
				e.target.name === 'password' && this.setState({ passwordError: false, tooLong: false });
				e.target.name === 'email' && this.setState({ emailError: false });
				e.target.name === 'primary_speciality' && this.setState({ specialityError: false });
				e.target.name === 'zip' && this.setState({ zipError: false });
			}




			validateForm() {
				const { first_name, last_name, password, email, phone, zip, primary_speciality } = this.state;
				!validateName(first_name) && this.setState({ firstError: true });
				!validateName(last_name) && this.setState({ lastError: true });
				!validateEmail(email) && this.setState({ emailError: true });
				password.length < 8 && this.setState({ passwordError: true });
				password.length > 36 && this.setState({ tooLong: true });
				phone.length < 10 && this.setState({ phoneError: true });			
				primary_speciality.length < 1 && this.setState({ specialityError: true });
				zip.length < 1 && this.setState({ zipError: true });
				return validateName(first_name) && validateName(last_name) && validateEmail(email) > 0 && password.length > 7 && password.length < 37 && phone.length > 0 && zip.length > 0 && primary_speciality.length > 0;
			};

			generateDoctorSlug = () =>{
				setTimeout(()=>{
					window.location.href = "/mange_doctor";
				},2000)
				axios({
					method: 'get',
					url: BaseUrlPatent + '/run_shell_command',
					headers: {
						"Content-Type": "application/json",
					}
				}).then((response) => {
					// console.log("generate-slug-res----->", response);
		   
				}).catch((error) => {
					console.log("generate-slug-err----->", error.response);
				})
			}
		
			updateSingleDoctorSlug=(uid)=>{ 
				axios({ 
					method: 'get',
					url: Baseurl + `api/doc/update_single_doctor_slug?drUid=${uid}`,
					headers: {
						"Content-Type": "application/json",
					},
				}).then((response) => {
					// console.log("updateSingleDoctorSlug-response----->", response);
					this.generateDoctorSlug()
				   
				}).catch((error) => {
					console.log("updateSingleDoctorSlug-error----->", error.response);
				 
				}) 
			}
			handleSubmit = (e) => {
				e.preventDefault();		
				let errors = {};
                let isValid = true;		
				const { email, password,phone,zip } = this.state;
				this.setState({ isLoading: true });		

				if (phone) {          
		            var pattern = new RegExp(/^[0-9\b]+$/);
		            if (!pattern.test(phone)) {
		              isValid = false; 
		              errors["phone"] = "Please enter only number."; 

		              toast.error('Please enter only number.', { position: toast.POSITION.TOP_CENTER });
		                      
		              this.setState({ phoneError: true,isLoading: false });             
		              return false;
		            }else if(phone.length != 10){
		              isValid = false;  
		              errors["phone"] = "Please enter valid phone number."; 
		              toast.error('Please enter valid phone number.', { position: toast.POSITION.TOP_CENTER });       
		              this.setState({ phoneError: true,isLoading: false });             
		              return false;
		            }
		        }

		        if (zip) {          
		            var pattern2 = new RegExp(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
		            if (!pattern2.test(zip)) {
		              isValid = false;  
		              toast.error('Please enter valid zip code.', { position: toast.POSITION.TOP_CENTER });                      
		              this.setState({ zipError: true,isLoading: false });             
		              return false;
		            }else if(zip.length != 5){
		              isValid = false; 
		              toast.error('Please enter valid zip code.', { position: toast.POSITION.TOP_CENTER });       
		              this.setState({ zipError: true,isLoading: false });             
		              return false;
		            }
		        }

				if (this.validateForm()) {

					firebaseApp.auth().createUserWithEmailAndPassword(email, password)
					.then((user) => { 				
						this.handleSignupApi(user.user.uid);

					})
					.catch((error) => { 
						this.setState({ isLoading: false,emailError: true });
						if (error.code === 'auth/email-already-in-use') {
							toast.error('This email already exists. Please try to sign up with another email.', { position: toast.POSITION.TOP_CENTER });
						}
						else
						{
							toast.error(error.message, { position: toast.POSITION.TOP_CENTER });
						}
					});

				} else { 
					this.setState({ isLoading: false });
				}
			}


			handleSignupApi = (userId) => {
				const { email, first_name, last_name, phone, zip, primary_speciality,managementGroup } = this.state;
				let sessionUid = localStorage.getItem("uid");
				let data = {
					uid: userId,
					email: email,
					first_name: first_name,
					last_name: last_name,
					phone: phone,
					zip: zip,
					primary_speciality: primary_speciality,
					groupDrUid:sessionUid,
					groupRole:7,
					sessionUid:sessionUid,
					groupDrEmail: this.state.grpDrEmail,
					groupDrMobile: this.state.grpDrMobile,
					managment_group_id:managementGroup,
				}

				axios({
					method: 'post',
					url: Baseurl + 'api/doc/register',
					headers: {
						"Content-Type": "application/json",
					},
					data: data,
				}).then((response) => {
				    setTimeout( () => {
				    	this.updateSingleDoctorSlug(userId)			
				    },3000)	
					 
				
				}).catch((error) => {				
					toast.error('Something went wrong!', { position: toast.POSITION.TOP_CENTER });
					this.setState({ isLoading: false });
				})

			}			


		 componentDidMount() {
		   let userId = localStorage.getItem("uid");
		    this.setState({ loading: true });
		    axios({
		      method: "get",
		      url: Baseurl + `api/doc/getDetails?userId=${userId}`,
		      headers: { "content-type": "application/json" },
		    })
		      .then((res) => { 
		       this.setState({grpDrEmail:res.data.user.email,grpDrMobile:res.data.user.mobile});
		      })
		      .catch((error) => {
		        this.setState({ loading: false }); 
			  });
			  ///////// select magment group ///
			  axios({
				method:'get',
				url:Baseurl+`group/all_groups`,
				headers:{'content-type':'application/json'}
			   }).then((res)=>{ 
				   if(res.data !== undefined && res.data.data !== undefined && res.data.data.length > 0){
					 this.setState({managementGroupData: res.data.data})
				   }
				  
			   }).catch((err)=>{
				   console.log("doc error---->",err);
			   })
		}

		render(){
			return(  <React.Fragment><div className="container">
				<div className="col-md-10 col-md-offset-1 table-title">
				<div className="panel panel-default">
				<div className="panel-heading">
				<h3 className="panel-title"><strong>Add Provider </strong>
				</h3>
				</div>  
				<div className="panel-body">
				<form className="text-left" onSubmit={this.handleSubmit}>
                
				<div className="input-with-label text-left sl-arrow">
                  <span>Select Organisation</span>
                  <select
                    className="col-md-12 form-control"
                    name="managementGroup" style={{width:'100%'}} onChange={(e) => this.handleOnChange(e)}>
                        <option>Select</option>
                  {
                                this.state.managementGroupData.map((mgmtgrp, index) => (
                                <option value={mgmtgrp._id} key={index} >{mgmtgrp.name}</option>
                                ))
                   }                
                  </select>
                </div>

				<div className="form-group">
				<input type="text" name="email" placeholder="Please enter your email address." onChange={(e) => this.handleOnChange(e)} style={{ border: this.state.emailError ? '1px solid #c64444' : '' }} />
				</div>

				<div className="row">
				<div className="col-xs-12 col-sm-4 col-md-4">
				<div className="form-group">
				 <input type="text" name="first_name" placeholder="First name" onChange={(e) => this.handleOnChange(e)} style={{ border: this.state.firstError ? '1px solid #c64444' : '' }} />
				</div>
				</div>
				<div className="col-xs-12 col-sm-4 col-md-4">
				<div className="form-group">
				<input  type="text" name="last_name" placeholder="Last name" onChange={(e) => this.handleOnChange(e)} style={{ border: this.state.lastError ? '1px solid #c64444' : '' }} />
				</div>
				</div>

				</div>

				<div className="form-group">
			    <input type="password" name="password" placeholder="Enter your password" onChange={(e) => this.handleOnChange(e)} style={{ border: this.state.passwordError ? '1px solid #c64444' : '' }} />
				</div>

				  <div className="input-group">
                     <span className="input-group-addon">+1</span>
                    <input type="text" className="m_number form-control" name="phone" placeholder="Office Phone"
                        maxLength={10}
                        onKeyPress={(e) => (e.charCode >= 48 && e.charCode <= 57) ? true : e.preventDefault()}
                        value={this.state.phone}
                        onChange={(e) => this.handleOnChange(e)}
                    />
                    </div>
				<div style={{marginTop:'30px'}}>
				<input type='text' list='primary_speciality' onChange={(e) => this.handleOnChange(e)} name="primary_speciality" placeholder='Primary speciality'
				style={{ border: this.state.specialityError ? '1px solid #c64444' : '' }} />
				<datalist id="primary_speciality">
				{
					primarySpeciality.map((name, index) => (
						<option value={name} key={index} />
						))
				}
				</datalist>
				</div>
				<div className="form-group">
				<input type="text" name="zip" placeholder="Zipcode" onChange={(e) => this.handleOnChange(e)} style={{ border: this.state.zipError ? '1px solid #c64444' : '' }} />
				</div>



				 <button type='submit' disabled={this.state.isLoading} onClick={this.handleSubmit}>
                        {this.state.isLoading && <span className="spinner-border text-light mr8" role="status" />}
                       Add
                    </button>




				</form>
				</div>
				</div>
				</div>
				</div>  </React.Fragment>)
		}






	}