import React, { Component } from 'react'
// import StarRatingComponent from 'react-star-rating-component';
import { withRouter } from 'react-router-dom';
// import PatientName from './PatientName';
import { GET_PATINET_REVIEW_REQUEST } from '../actions/Action';
import { connect } from 'react-redux';


class DocHubReviewBox extends Component {

    constructor(props) {
        super(props);
        this.state = {
            rating: 0,
            decription: '',
            reviewData: [],
            postReviewVisibility: false,
            currentEditPatientId: '',
            emails: [],
            facebookChecked: false,
            emailChecked: false,
            share_facebookvalue: '',
            share_emailValue: '',
            user_name: '',
        }
    }


    componentDidMount() {
        this.props.handlegetPatientsreview({ docId: this.props.docId, handleAllRating: this.props.handleAllRating });
    }


    // /*----------------------------------------------------render-min-reviews------------------------------------------------*/

    // renderReview = () => {
    //     let userId = localStorage.getItem('uid');
    //     let minReviewData = [];
    //     this.state.reviewData.map((value, key) => {
            
    //         if (value.patient_id === userId) {
    //             return null;
    //         }
    //         else {
    //             minReviewData.push(value);
    //         }
    //     });


    //     const handleMin_review = minReviewData.filter((v, i) => i < 2 ? v : null);
    //     return handleMin_review.map((value, key) => {
    //         return <div className="feature mb-xs-24" key={key}>
    //             <div>
    //                 <PatientName patientId={value.patient_id} />
    //                 <StarRatingComponent
    //                     name="rate1"
    //                     starCount={5}
    //                     value={value.rating}
    //                     starColor={'#ec8a19'}
    //                     emptyStarColor={'lightgray'}
    //                 />
    //                 <br />
    //                 {value.dec}
    //             </div>
    //         </div>
    //     })

    // }


    /*-----------------------------------------------handle-componentWillReceiveProps---------------------------------------*/


    UNSAFE_componentWillReceiveProps(props) {
        this.setState({ reviewData: props.patientReviewData })
    }


    render() {
        return (
            <React.Fragment>
                <div>
                    {/* {this.renderReview()} */}
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        patientReviewData: state.patientReviewData,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        handlegetPatientsreview: data => dispatch({ type: GET_PATINET_REVIEW_REQUEST, data }),
    }

}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DocHubReviewBox));