import React, { Component } from "react";
import ScheduleTimeButton from './ScheduleTimeButton';
import Modal from 'react-modal';
import { toast } from "react-toastify";
import { ToastMessage } from "./ToastMessage";
import { handleTimeFormat } from "../utils/DateMethod";
import axios from "axios";
import { Baseurl } from "../utils/Baseurl";

const cross = {
    fontSize: 20,
    padding: '0 7px',
    cursor: 'pointer',
    marginLeft: '20px'
};


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

export default class SchduleList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isButtonDisabled: true,
            toggle: false,
            weekday: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            month: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
            ismodal: false,
            hours: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
            minutes: ["00", "05","10","15","20","25", "30","35","40", "45", "50", "55"],
            from_hours: "08",
            from_min: "00",
            from_ap: "AM",
            to_hours: "07",
            to_min: "00",
            to_ap: "PM",
            offer_telemedicine11: '1',
            modalData: {}
        };
    }
    componentDidMount(){
        this.setState({offer_telemedicine11:this.props.telemedicine})
    }
    handleToggle = () => {
        this.setState({ toggle: !this.state.toggle });
    };


    closeModal = () => {
        this.setState({ ismodal: false, isLoading: false });
    }


    handleOpenModal = (data) => {
        this.setState({ ismodal: true, modalData: data });
    }

    addMinutesInGivenTime=(time,minutestoadd)=>{
        const hh=parseInt(time.slice(0,2))*60*60000;
        const mm=parseInt(time.slice(2,4))*60000;
        const slot=parseInt(minutestoadd)*60000
        const milliseconds = hh+mm+slot;
        const hours = String(Math.floor(milliseconds / 3600000)).padStart(2, "0");
        const minutes = String(Math.floor((milliseconds - hours * 3600000) / 60000)).padStart(2, "0");
        return `${hours}${minutes}`
    }
    isExitSlots=(d, s, endtime)=>{
       const t= d[d.length-1];
       const f= d[0];
        if(t?.slot !== undefined){       
            const from_pre_book_time = parseInt(this.addMinutesInGivenTime(f.time, 0))   
            const pre_book_time = parseInt(this.addMinutesInGivenTime(t.time, t.slot))
            const tobook_time = parseInt(`${s.getHours().toString().padStart(2, "0")}${s.getMinutes().toString().padStart(2, "0")}`);            
            const endtobook_time = parseInt(`${endtime.getHours().toString().padStart(2, "0")}${endtime.getMinutes().toString().padStart(2, "0")}`);            
            const r = pre_book_time <= tobook_time                                                                                                      
            const b = from_pre_book_time >= endtobook_time
            
          if(b){
              return !b
          }else {
              return !r
          }
          
        }else return false
       
    }
    /*-----------------------------------------handleAddTimeSlot--------------------------------------*/
    handleAddTimeSlot = () => {
        let uid = localStorage.getItem('uid');
        const { from_hours, from_min, from_ap, to_hours, to_min, to_ap, offer_telemedicine11 } = this.state;
        let yy = this.state.modalData.date.slice(0, 4);
        let mm = this.state.modalData.date.slice(4, 6);
        let dd = this.state.modalData.date.slice(6, 8);
        let date = `${yy}/${mm}/${dd}`;
        let currentAppointDate = new Date(new Date(date).toDateString())

        var start_time = new Date(`${currentAppointDate.toDateString()} ${from_hours}:${from_min}:00 ${from_ap}`);
        var end_time = new Date(`${currentAppointDate.toDateString()} ${to_hours}:${to_min}:00 ${to_ap}`);

        const timeArr = this.props.handleTimeArr(start_time, end_time, offer_telemedicine11);
      
        //const timeExist = [];

        let isExit = this.state.modalData.appoint.filter(value => timeArr.find(data => value.time === data.time));
      
        // this.state.modalData.value.map(v => {
        //     timeArr.map(t => {

        //         if(parseInt(t.time) >= parseInt(v.start) && parseInt(t.time) < parseInt(v.end)){
        //             timeExist.push(t);
        //         }
        //     })
        // });

        if (`${from_hours}:${from_min}:00 ${from_ap}` === `${to_hours}:${to_min}:00 ${to_ap}` || start_time > end_time) {
            toast.error(<ToastMessage message="Invalid time." />, { position: toast.POSITION.TOP_CENTER });
        } else {           
            if (isExit.length || this.isExitSlots(this.state.modalData.appoint, start_time, end_time)) {
                toast.error(<ToastMessage message="It seems you have selected duplicate time slots." />, { position: toast.POSITION.TOP_CENTER });
            } else{
                let newAppoint = this.state.modalData.appoint.concat(timeArr);
                newAppoint = newAppoint.sort((a, b) => (a.time > b.time) ? 1 : ((b.time > a.time) ? -1 : 0));
                let appointValue = this.state.modalData.value;
                appointValue.push({ start: `${String(start_time.getHours()).padStart(2, "0")}${from_min}`, end: `${String(end_time.getHours()).padStart(2, "0")}${to_min}`, interval: appointValue.length + 1 });

                const newSchedule = [{
                    date: this.state.modalData.date,
                    appoint: newAppoint,
                    value: appointValue,
                    practice_id: this.state.modalData.practice_id
                }];

                this.props.isDateExist(newSchedule).then((response) => {
                    let practiceName, time;
                    let isExist = response.data.data.some(value => {
                        let filterDate = newSchedule.filter(d => d.date === value.date);
                     
                        return value.practice_id !== this.state.modalData.practice_id ? value.calendarValue.some(val =>                         
                            filterDate[0].appoint.some(data => {
                                time = data.time; 
                                practiceName = this.props.docDetails.practice.find(p => p._id === value.practice_id).practice_name;

                                return parseInt(data.time) >= parseInt(val.start) && parseInt(data.time) <= parseInt(val.end)
                            })
                        )
                            : false
                    }); 
                    if (isExist.length) {
                        this.setState({ isSaveLoading: false });
                        toast.error(<ToastMessage message={`You have already booked ${handleTimeFormat(time)} for ${practiceName}.`} />, { position: toast.POSITION.TOP_CENTER });
                    } else {
                        let data = { uid, appointments: newSchedule };

                        this.setState({ isLoading: true });
                        axios({
                            method: 'post',
                            url: Baseurl + 'api/schedule/schedule_appointment',
                            headers: { "content-type": "application/json" },
                            data: data,
                        }).then((response) => {
                            // console.log("Saved-response--->", response);
                            this.props.handleGetAppointmentData();
                            toast.success(<ToastMessage message="Successfully Saved." />, { position: toast.POSITION.TOP_CENTER })
                            this.closeModal()
                        }).catch((error) => {
                            console.log("save-error--------------->", error);
                            this.setState({ isLoading: false });
                        })
                    }
                }).catch((error) => {
                    console.log("error--------------->", error);
                })
            }
        }
    }


    render() {
        const { data } = this.props;

        const year = this.props.data.date.slice(0, 4);
        const month = this.props.data.date.slice(4, 6);
        const day = this.props.data.date.slice(6, 8);

        const newDate = new Date(`${year}-${month}-${day}`);
        let sday = this.state.weekday[newDate.getUTCDay()];
        let fullMonth = this.state.month[newDate.getUTCMonth()];



        return (
            <React.Fragment>

                <Modal
                    isOpen={this.state.ismodal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Modal"
                >
                    <div style={{ textAlign: 'center' }}>
                        <div className="time_container">
                            <div className="col-md-12 col-xs-12" style={{ display: 'flex', paddingLeft: 0, paddingRight: 5, alignItems: 'center', justifyContent: 'center', marginBottom: 20 }}>
                                <label style={{ marginRight: "4px" }} htmlFor="from"><strong>From</strong></label>
                                <select style={{ width: "30%" }} onChange={e => { this.setState({ from_hours: e.target.value }); }} value={this.state.from_hours}>
                                    {
                                        this.state.hours.map((hour, key) => (
                                            <option key={key} value={hour}>{hour}</option>
                                        ))
                                    }
                                </select>
                                <select style={{ width: "30%" }} onChange={e => { this.setState({ from_min: e.target.value }); }} value={this.state.from_min}>
                                    {this.state.minutes.map((min, key) => (
                                        <option key={key} value={min}>{min}</option>
                                    ))}
                                </select>
                                <select style={{ width: "30%" }} value={this.state.from_ap} onChange={e => { this.setState({ from_ap: e.target.value }); }}>
                                    <option value="AM">AM</option>
                                    <option value="PM">PM</option>
                                </select>
                            </div>




                            <div className="col-md-12 col-xs-12" style={{ display: "flex", paddingLeft: 0, paddingRight: 5, alignItems: 'center', justifyContent: 'center' }}>
                                <label style={{ marginRight: "20px" }} htmlFor="from"><strong>To</strong></label>
                                <select style={{ width: "30%" }} onChange={e => { this.setState({ to_hours: e.target.value }); }} value={this.state.to_hours}>
                                    {this.state.hours.map((hour, key) => (
                                        <option key={key} value={hour}>
                                            {hour}
                                        </option>
                                    ))}
                                </select>
                                <select style={{ width: "30%" }} onChange={e => { this.setState({ to_min: e.target.value }); }} value={this.state.to_min}>
                                    {this.state.minutes.map((min, key) => (
                                        <option key={key} value={min}>
                                            {min}
                                        </option>
                                    ))}
                                </select>
                                <select style={{ width: "30%" }} value={this.state.to_ap} onChange={e => { this.setState({ to_ap: e.target.value }); }}>
                                    <option value="AM">AM</option>
                                    <option value="PM">PM</option>
                                </select>
                            </div>
                        </div>

                        {
                            this.props.telemedicine === 1 ?                               
                                <div className="time_container">
                                <div  style={{ display: 'flex', paddingLeft: 0, paddingRight: 5, alignItems: 'center', justifyContent: 'center' }}>
                                <label style={{ marginRight: "20px" }} htmlFor="from"><strong>Offer</strong></label>                           
                                <div className="custom-control custom-radio">
                                    <input type="radio" className="custom-control-input"  checked={this.state.offer_telemedicine11 == "1"} name="offer_telemedicine11"  onChange={e => { this.setState({ offer_telemedicine11: e.target.value }); }} value="1"  />
                                    <label className="custom-control-label" htmlFor="customCheck1" >&nbsp;Telemedicine&nbsp;&nbsp;</label>
                                </div>
                                <div className="custom-control custom-radio">
                                    <input type="radio" className="custom-control-input" checked={this.state.offer_telemedicine11 == "0"} name="offer_telemedicine11"    onChange={e => { this.setState({ offer_telemedicine11: e.target.value }); }} value="0" />
                                    <label className="custom-control-label" htmlFor="customCheck1">&nbsp;Office visit&nbsp;&nbsp;</label>
                                </div>
                                <div className="custom-control custom-radio">
                                    <input type="radio" className="custom-control-input" name="offer_telemedicine11" checked={this.state.offer_telemedicine11 == "2"}   onChange={e => { this.setState({ offer_telemedicine11: e.target.value }); }} value="2" />
                                    <label className="custom-control-label" htmlFor="customCheck1">&nbsp;Both</label>
                                </div>
                                </div>
                                </div>
                                : ''}

                        <button className="btn" style={{ marginTop: 24, marginBottom: 0 }} onClick={this.handleAddTimeSlot}>SAVE</button>
                    </div>
                </Modal>




                <div className="col-lg-12" style={{ paddingLeft: 0 }}>
                    <li className={this.state.toggle ? "active" : ""}>
                        <div onClick={this.handleToggle} className="title schedule_mobile_view_title">
                            <div className="text-left" style={{ height: "auto", width: "50%", fontWeight: "bold" }}>{sday}
                                {
                                    this.state.toggle ?
                                        <span aria-hidden='true' style={cross} style={{ marginLeft: '30px', fontSize: '150%' }} onClick={() => this.props.removeDay(data)}>×</span>
                                        : null
                                }
                            </div>

                            <div className="text-right" style={{ height: "auto", width: "50%", fontWeight: "bold" }}>
                                {`${fullMonth}, ${day} ${year}`}
                                {
                                    this.state.toggle ?
                                        <i className="ti-angle-up" style={{ zIndex: 2, marginLeft: "10px" }}></i>
                                        :
                                        <i className="ti-angle-down" style={{ zIndex: 2, marginLeft: "10px" }}></i>
                                }
                            </div>
                        </div>

                        <div className="content schedule_mobile_view_content">
                            <div className="schedule_times_button">
                                {this.props.data.appoint.map((value, key) => {
                                    return <ScheduleTimeButton date={this.props.data.date} value={value} telemedicine={this.props.telemedicine} key={key}
                                        handleUpdateTele={this.props.handleSetUpdateTele} handleRemove={this.props.handleSetDeleteTimeState} />
                                })}
                                <button className="btn btn-sm btn-rounded btn-filled mb0" disabled={this.state.isLoading} onClick={() => this.handleOpenModal(this.props.data)}>Add Time</button>
                               
                                    <div className="wraper-box-inf w-100">
                                        <ul className="info-caln">
                                            <li>
                                                <div className="wrap-flx">
                                                    <div className="sqr-block bg-cl-one"></div> <span>Telemedicine</span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="wrap-flx">
                                                    <div className="sqr-block bg-cl-two"></div> <span>Office visit</span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="wrap-flx">
                                                    <div className="sqr-block bg-cl-three"></div> <span>Both</span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                            </div>

                        </div>
                    </li>
                </div>
            </React.Fragment>
        );
    }
}
