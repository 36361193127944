import { call, put } from "redux-saga/effects";
import { GET_DOC_DETAILS_SUCCESS_REQUEST, GET_DOC_DETAILS_FAILURE_REQUEST } from '../../actions/Action';
import { Baseurl } from '../../utils/Baseurl';
import axios from "axios";

export function handleGetDocDetails(url) {
    let headers = {
        'Accept': "application/json",
        'Content-Type': 'application/json',
        'Pragma': 'no-cache'
    };
    return axios({
        method: "get",
        url: Baseurl + url,
        headers
    });
}


export function* getDocDetailsSaga(action) {
    try {
       
        const response = yield call(handleGetDocDetails, `api/doc/getDetails?userId=${action.data}`);
        // console.log("event---->", response.data);
        const docDetails = response.data.user; 
        // dispatch a success action to the store with the new data
        yield put({ type: GET_DOC_DETAILS_SUCCESS_REQUEST, docDetails });
    } catch (error) {
        // dispatch a failure action to the store with the error
        yield put({ type: GET_DOC_DETAILS_FAILURE_REQUEST, error });
    }
}

