import React, { Component } from 'react';
import axios from 'axios';
import { Baseurl } from '../utils/Baseurl';
// import { handleTimeFormat, handleDateFormat } from '../utils/DateMethod';
import { GET_DOC_DETAILS_REQUEST } from '../actions/Action';
import { connect } from 'react-redux';
// import { AllownextUpcomingAppointmentMail } from './AllownextUpcomingAppointmentMail';
// import { DelaynextUpcomingAppointmentMail } from './DelaynextUpcomingAppointmentMail';
import { DOC_TODAY_APPOINTMENT_REQUEST } from '../actions/Action';



class RecentOntimeDelayActions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            date: '',
            time: '',
            practice: [],
            doc_name: '',
            patientEmail: '',
            patient_id: '',
            patientMobile: '',
            hasRunning: '2',
        }
    }


    // UNSAFE_componentWillMount() {
    //     let id = localStorage.getItem('uid');
    //     this.props.handleGetDocDetails(id);
    // }


    UNSAFE_componentWillReceiveProps(props) {
        // console.log("props---->", props.data);
        if (props.data) {
            this.setState({
                date: props.data.date,
                time: props.data.time,
                hasRunning: props.data.is_running,
                patient_id: props.data.patient_id,
                // doc_name: `${props.docDetails.first_name} ${props.docDetails.last_name}`,
                // practice: props.docDetails.practice && props.docDetails.practice.length > 0 ? JSON.parse(props.docDetails.practice[0]) : [],
            })
            this.handleGetPatientDetails(props.data);
            //console.log('patient_id',props.data.patient_id);
            //console.log('props.data',props.data);
        }
    }


    handleGetPatientDetails = (propdata) => {
        this.setState({ patientName: `${propdata.patient_name} `, patientEmail: propdata.patient_email, patientMobile: propdata.patient_mobile });
        /*  axios({
              method: 'get',
              url: Baseurl + `api/user/getuserdetails?userId=${id}`,
              headers: { 'content-type': 'application/json' }
          }).then((res) => {
              // console.log("patient-details------>", res);
              this.setState({ patientName: `${res.data.user.first_name} ${res.data.user.last_name}`, patientEmail: res.data.user.email, patientMobile: res.data.user.mobile });
          }).catch((error) => {
              console.log("in-error---->", error);
          })*/
    }



    /*-------------------------------------------------------handleOkbutton-----------------------------------------------------*/

    handleOkbutton = (data) => {
        data.patientEmail = this.state.patientEmail;
        data.patientMobile = this.state.patientMobile;
        const modalFor = 'Ready';

        this.props.setModalState(modalFor, data);

        // let id = localStorage.getItem('uid');

        // var date = new Date();
        // date.setSeconds(0);
        // date.setMilliseconds(0);
        // var currentTime = date.getTime();

        // let practiceName;
        // let practice = this.state.practice.filter((k, i) => i === 0 ? k : null);
        // practice.map((value) => {
        //     practiceName = `${value.practice_name}, ${value.practice_location}, ${value.zip}`;
        //     return null;
        // });

        // let data = {
        //     is_running: `0&${currentTime}`,

        //     mobile: this.state.patientMobile,
        //     mobile_message: `Your Appointment is on time with Dr. ${this.state.doc_name} in ${practiceName} 
        //     on ${handleDateFormat(this.state.date)} at ${handleTimeFormat(this.state.time)}. Be ready in 10 minutes.`,


        //     emailto_patient: this.state.patientEmail,
        //     patient_subject: 'Your Appoinment is on time',
        //     patient_message: AllownextUpcomingAppointmentMail(practiceName, this.state.date, this.state.time, this.state.doc_name),
        // };

        // const hasOk = window.confirm('We will inform the patient to be ready in 10 min');
        // if (hasOk) {
        //     axios({
        //         method: 'post',
        //         url: Baseurl + `api/appointment/allow_nextupcoming_appointment?date=${this.state.date}&time=${this.state.time}&patient_id=${this.state.patient_id}`,
        //         headers: { 'content-type': 'application/json' },
        //         data
        //     }).then((res) => {
        //         console.log("ok next appointment response------>", res);
        //         this.props.handleGetAppointment(id);
        //     }).catch((error) => {
        //         console.log("ok next appointment error------>", error);
        //     })
        // }
        // else {
        //     return false;
        // }

    }


    /*-------------------------------------------------------handleDelayButton-----------------------------------------------------*/

    handleDelayButton = (data) => {

        data.patientEmail = this.state.patientEmail;
        data.patientMobile = this.state.patientMobile;
        const modalFor = 'Delay';

        this.props.setModalState(modalFor, data);


        // let id = localStorage.getItem('uid');

        // let practiceName;
        // let practice = this.state.practice.filter((k, i) => i === 0 ? k : null);
        // practice.map((value) => {
        //     practiceName = practiceName = `${value.practice_name}, ${value.practice_location}, ${value.zip}`;
        //     return null;
        // });


        // let data = {
        //     is_running: '1',

        //     mobile: this.state.patientMobile,
        //     mobile_message: `Dr. ${this.state.doc_name} is running a little late. We will inform you in 10 minutes before the doctor is ready in ${practiceName}  
        //     on ${handleDateFormat(this.state.date)} at ${handleTimeFormat(this.state.time)}.`,


        //     emailto_patient: this.state.patientEmail,
        //     patient_subject: 'Your Appoinment is delayed',
        //     patient_message: DelaynextUpcomingAppointmentMail(practiceName, this.state.date, this.state.time, this.state.doc_name),
        // };

        // const hasDelay = window.confirm('We will inform the patient about the delay.');
        // if (hasDelay) {

        //     axios({
        //         method: 'post',
        //         url: Baseurl + `api/appointment/delay_nextupcoming_appointment?date=${this.state.date}&time=${this.state.time}&patient_id=${this.state.patient_id}`,
        //         headers: { 'content-type': 'application/json' },
        //         data
        //     }).then((res) => {
        //         console.log("ok next appointment response------>", res);
        //         this.props.handleGetAppointment(id);
        //     }).catch((error) => {
        //         console.log("ok next appointment error------>", error);
        //     })
        // }
        // else {
        //     return false;
        // }
    }
    handleNoShowButton = (data) => {
        // console.log('no show', data)
        //  let id = localStorage.getItem('uid');
        // this.props.handleGetDocDetails(id);
        const modalFor = "NoShow"
        this.props.setModalState(modalFor, data);


    }

    handleRecentCancelButton = (data) => {
        data.patientEmail = this.state.patientEmail;
        data.patientName = this.state.patientName;
        data.patientMobile = this.state.patientMobile;

        const modalFor = 'Cancel';

        this.props.setModalState(modalFor, data);
    }




    render() {
        const [hasRunning,] = this.props.data.is_running ? this.props.data.is_running.split('&') : '';

        return (
            <React.Fragment>
                <button className="btn btn-teal btn-rounded btn-sm m-0"
                    style={{ opacity: hasRunning === '3' || this.props.data.status === 'Cancelled' || this.props.data.status === 'NoShow' || hasRunning === '0' ? '0.5' : '1', background: '#47b475', marginBottom: 0, color: '#fff', paddingLeft: 3, paddingRight: 3 }}
                    disabled={hasRunning === '3' || this.props.data.status === 'Cancelled' || this.props.data.status === 'NoShow' || hasRunning === '0'}
                    onClick={() => this.handleRecentCancelButton(this.props.data)}>Cancel</button>
                <br></br><br></br>
                <button className="btn btn-teal btn-rounded btn-sm m-0"
                    disabled={hasRunning === '0' || hasRunning === '3' || this.props.data.status === 'Cancelled' || this.props.data.status === 'NoShow' ? true : false}
                    onClick={() => this.handleOkbutton(this.props.data)}
                    style={{ opacity: hasRunning === '0' || hasRunning === '3' || this.props.data.status === 'Cancelled' || this.props.data.status === 'NoShow' ? 0.5 : 1, background: '#47b475', marginBottom: 0, color: '#fff', paddingLeft: 3, paddingRight: 3 }}
                >I'am Ready</button>

                <button className="btn btn-teal btn-rounded btn-sm m-0"
                    disabled={hasRunning === '1' || hasRunning === '0' || hasRunning === '3' || this.props.data.status === 'Cancelled' || this.props.data.status === 'NoShow' ? true : false}
                    onClick={() => this.handleDelayButton(this.props.data)}
                    style={{ opacity: hasRunning === '1' || hasRunning === '0' || hasRunning === '3' || this.props.data.status === 'Cancelled' || this.props.data.status === 'NoShow' ? 0.5 : 1, background: '#47b475', marginBottom: 0, color: '#fff', paddingLeft: 3, paddingRight: 3 }}
                >Delay</button>

                <button className="btn btn-teal btn-rounded btn-sm m-0"
                    disabled={this.props.data.status === 'Confirmed' ? false : true}
                    onClick={() => this.handleNoShowButton(this.props.data)}
                    style={{ opacity: this.props.data.status === 'Confirmed' ? 1 : 0.5, background: '#47b475', marginBottom: 0, color: '#fff', paddingLeft: 3, paddingRight: 3 }}
                >No Show</button>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        docDetails: state.doctorDetails,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        handleGetDocDetails: data => dispatch({ type: GET_DOC_DETAILS_REQUEST, data }),
        handleGetAppointment: data => dispatch({ type: DOC_TODAY_APPOINTMENT_REQUEST, data })
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(RecentOntimeDelayActions);