import React, { Component } from "react";
import { primarySpeciality } from "../../constant/speciality";
import axios from "axios";
import { Baseurl } from "../../utils/Baseurl";
import { toast } from "react-toastify";
import Modal from "react-modal";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { ToastMessage } from "../../component/ToastMessage";
import doctorPlaceholder from "../../assets/img/doctor-placeholder.jpg";
import { GET_DOC_DETAILS_REQUEST } from "../../actions/Action";
import { connect } from "react-redux";
import "../../styles/Profile.css";
import reverseGeocode from "latlng-to-zip";

const inputFile = {
  position: "absolute",
  border: "1px solid red",
  width: "100%",
  height: "auto",
  borderRadius: "50%",
  left: 0,
  right: 0,
  opacity: 0,
  cursor: "pointer",
};

const cross = {
  fontSize: 24,
  padding: "0 5px",
  cursor: "pointer",
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");
Modal.defaultStyles.overlay.backgroundColor = "rgba(111, 111, 111, 0.75)";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: "",
      imgFile: "",
      speciality: "",
      practice_name: "",
      practice_location: "",
      practice_zip: "",
      Pstate: "",
      Pcity: "",
      about_me: "",
      board_certification: "",
      insuranceProvider: "",
      medicaidInsuranceProvider: "",
      hospital_affiliations: "",
      education_training: "",
      assistance_emails: "",
      professional_memberships: "",
      awards_publications: "",
      language: "",
      gender: "Male",
      npi_number: "",
      profile_loader: false,
      profileAction: false,

      /*-----multi-addition-arr-------*/

      speciality_arr: [],
      practiceArr: [],
      hospital_affiliations_arr: [],
      education_training_arr: [],
      assistance_emails_arr: [],
      board_certification_arr: [],
      professional_memberships_arr: [],
      awards_publications_arr: [],
      insuranceProvider_arr: [],
      medicaidInsuranceProvider_arr: [],
      language_arr: [],
      isPracticeEdit: false,
      practiceId: "",
      isModal: false,
      modal_practiceObj: {},
      isLoading: false,
      payerNameList: [],
      medicaidInsuranceList: [],
      isProfileUpload: false,
      lat: 0,
      long: 0,
      zipCodes: [],
      zipDropdown: false,
      telemedicine: 0,
      disable_confirmation_email: 0,
      groupRole: 7,
      address: "",
      imageKey: Math.random(),
    };
    this.openZipDropDown = this.openZipDropDown.bind(this);
    this.hidezipDropDown = this.hidezipDropDown.bind(this);
  }

  UNSAFE_componentWillMount() {
    window.scrollTo(0, 0);
    let userId = localStorage.getItem("uid");
    this.props.handleGetDocDetails(userId);
  }

  dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  componentDidMount() {
    //let userId = localStorage.getItem("uid");
    //this.props.handleGetDocDetails(userId);
    this.handleGetPayer();
    this.handleGetMedicaidInsuranceProviders();
  }

  /*-------------------------------handle-get-payer---------------------------------------*/

  handleGetPayer = () => {
    axios({
      method: "get",
      url: Baseurl + "api/user/get_payers",
      headers: { "content-type": "application/json" },
    })
      .then((res) => {
        let arr = [];
        res.data.map((value) => {
          if (value.group) {
            value.group.map((data) => {
              arr.push(data);
            });
          } else {
            arr.push(value);
          }
        });
        arr.sort(this.dynamicSort("payerName"));
        this.setState({ payerNameList: arr });
      })
      .catch((error) => {
        console.log("error-------->", error.response);
      });
  };

  /*-----------------------------handle-get_medicaid_insurance_providers-list----------------------------*/

  handleGetMedicaidInsuranceProviders = () => {
    axios({
      method: "get",
      url: Baseurl + "api/doc/get_medicaid_insurance_providers",
      headers: { "content-type": "application/json" },
    })
      .then((res) => {
        let arr = [];
        res.data.map((value) => {
          if (value.group) {
            value.group.map((data) => {
              arr.push(data);
            });
          } else {
            arr.push(value);
          }
        });
        arr.sort(this.dynamicSort("payerName"));
        this.setState({ medicaidInsuranceList: arr });
      })
      .catch((error) => {
        console.log("error-------->", error.response);
      });
  };

  /*--------------------------------------------------handle-inputs-onchange---------------------------------------------------------*/

  handleOnchange = (e) => {
    this.refs.btn.removeAttribute("disabled");
    this.setState({ [e.target.name]: e.target.value });
  };

  /*----------------------------------handle-insuranceProvider-addmore----------------------------------*/

  handleInsuranceProviderAddmore = (e) => {
    e.preventDefault();
    const { insuranceProvider } = this.state;
    let filterPayerName = this.state.payerNameList.filter(
      (value) => value.payerName === insuranceProvider
    );

    if (filterPayerName.length === 0) {
      toast.error("Please pick an insurance carrier from the list.", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      const alreadyAdded = this.state.insuranceProvider_arr.some(
        (value) => value.provider_name === insuranceProvider
      );
      if (alreadyAdded) {
        toast.warn("This insurance already exist in your list.", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        let insurance_provider = this.state.insuranceProvider_arr.concat({
          provider_name: filterPayerName[0].payerName,
          code: filterPayerName[0].payerCode,
        });
        this.setState({
          insuranceProvider_arr: insurance_provider,
          insuranceProvider: "",
        });
      }
    }
  };

  /*--------------------------------------------------handle-remove-InsuranceProvider-items---------------------------------------*/

  handleInsuranceProviderRemoveItem = (e, value) => {
    e.preventDefault();
    let newData = this.state.insuranceProvider_arr.filter(
      (obj) =>
        obj !==
        this.state.insuranceProvider_arr.find((v) => v.code === value.code)
    );
    this.setState({
      insuranceProvider_arr: [...newData],
    });
  };

  /*-------------------------------handle-medicaid-insurance-addMore---------------------------------------*/

  handleMedicaidInsuranceProviderAddmore = (e) => {
    e.preventDefault();
    const { medicaidInsuranceProvider } = this.state;
    let medicaidInsurancePayerName = medicaidInsuranceProvider.split(
      " (Medicaid)"
    )[0];

    let filterPayerName = this.state.medicaidInsuranceList.filter(
      (value) => value.payerName === medicaidInsurancePayerName
    );

    if (filterPayerName.length === 0) {
      toast.error("Please pick an medicaid insurance carrier from the list.", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      const alreadyAdded = this.state.medicaidInsuranceProvider_arr.some(
        (value) => value.provider_name === medicaidInsurancePayerName
      );
      if (alreadyAdded) {
        toast.warn("This medicaid insurance already exist in your list.", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        let medicaidInsurance_provider = this.state.medicaidInsuranceProvider_arr.concat(
          {
            provider_name: filterPayerName[0].payerName,
            code: filterPayerName[0].payerCode,
            policy_type: filterPayerName[0].PolicyType,
          }
        );
        this.setState({
          medicaidInsuranceProvider_arr: medicaidInsurance_provider,
          medicaidInsuranceProvider: "",
        });
      }
    }
  };

  /*-------------------------------handle-medicaid-insurance-remove---------------------------------------*/

  handleMedicaidInsuranceProviderRemoveItem = (e, value) => {
    e.preventDefault();
    let newData = this.state.medicaidInsuranceProvider_arr.filter(
      (obj) =>
        obj !==
        this.state.medicaidInsuranceProvider_arr.find(
          (v) => v.code === value.code
        )
    );
    this.setState({
      medicaidInsuranceProvider_arr: [...newData],
    });
  };

  /*--------------------------------------------------handle-practice-add-more---------------------------------------------------------*/

  handlePracticeAddmore = (e) => {
    e.preventDefault();

    const {
      practice_name,
      address,
      practice_location,
      practice_zip,
      Pstate,
      Pcity,
      lat,
      long,
    } = this.state;
    if (
      practice_name === "" ||
      practice_location === "" ||
      practice_zip === "" ||
      Pstate === "" ||
      Pcity === ""
    ) {
      toast.error("Please provide all information about this practice.", {
        position: toast.POSITION.TOP_CENTER,
      });
      this.refs.btn.setAttribute("disabled", "disabled");
    } else {
      this.refs.btn.removeAttribute("disabled");
      let practice = this.state.practiceArr.concat({
        practice_name: practice_name,
        practice_location: practice_location,
        zip: practice_zip,
        state: Pstate,
        city: Pcity,
        lat: lat,
        long: long,
      });
      this.setState({
        practiceArr: practice,
        practice_name: "",
        address: "",
        practice_location: "",
        practice_zip: "",
        Pstate: "",
        Pcity: "",
      });
    }
  };

  /*--------------------------------------------------handle-remove-practice-items---------------------------------------------------------*/

  handleremovePracticeItems = (e, value) => {
    e.preventDefault();
    this.setState({ isModal: true, modal_practiceObj: value });
  };

  /*--------------------------------------------------handle-set-edit-practice-state---------------------------------------------------------*/

  handleSetPracticeForEdit = (e, data) => {
    e.preventDefault(e);
    // console.log(data);
    this.setState({
      practice_location: data.practice_location,
      practice_name: data.practice_name,
      address: data.practice_name,
      practice_zip: data.zip,
      Pstate: data.state,
      Pcity: data.city,
      practiceId: data._id,
      isPracticeEdit: true,
    });
  };

  /*--------------------------------------------------handle-edit-doctor-practice---------------------------------------------------------*/

  handleEditPractice = (e) => {
    e.preventDefault(e);

    this.state.practiceArr.map((value) => {
      if (value._id === this.state.practiceId) {
        value.practice_name = this.state.practice_name;
        value.practice_location = this.state.practice_location;
        value.zip = this.state.practice_zip;
        value.state = this.state.Pstate;
        value.city = this.state.Pcity;
      }
    });

    this.setState({
      practiceArr: this.state.practiceArr,
      practice_location: "",
      address: "",
      practice_name: "",
      practice_zip: "",
      Pstate: "",
      Pcity: "",
      practiceId: "",
      isPracticeEdit: false,
    });
  };

  /*--------------------------------------------------handle-add-more---------------------------------------------------------*/

  handleAddMore = (e, name) => {
    e.preventDefault();
    // console.log("state[name]", this.state[name]);
    if (this.state[name] === "") {
      // toast.error('Blank feild can not be added.', { position: toast.POSITION.TOP_CENTER });
      // console.log("'Blank feild can not be added.");
    } else {
      if (name === "assistance_emails") {
        let pattern = new RegExp(
          /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
        );

        if (!pattern.test(this.state.assistance_emails)) {
          // console.log("Please enter valid email address.");
          toast.error("Please enter valid email address.", {
            position: toast.POSITION.TOP_CENTER,
          });
          return false;
        }
      }

      if (name === "speciality") {
        let filterSpeciality = primarySpeciality.filter(
          (value) => value === this.state.speciality
        );
        // console.log(filterSpeciality);
        if (filterSpeciality.length === 0) {
          toast.error("Please pick an Speciality from the list.", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          const alreadyHaveSpeciality = this.state.speciality_arr.some(
            (value) => value === this.state.speciality
          );
          alreadyHaveSpeciality
            ? toast.warn("This speciality already exist in your list.", {
                position: toast.POSITION.TOP_CENTER,
              })
            : this.setState({
                speciality_arr: this.state.speciality_arr.concat(
                  this.state.speciality
                ),
                speciality: "",
              });
        }
      } else {
        this.state[`${name}_arr`].push(this.state[name]);
        this.setState({
          [`${name}_arr`]: this.state[`${name}_arr`],
          [`${name}`]: "",
        });
      }
    }
  };

  /*--------------------------------------------------handle-remove-items---------------------------------------------------------*/

  handleRemoveItems = (e, name, value) => {
    e.preventDefault();
    let index = this.state[`${name}_arr`].indexOf(value);
    this.state[`${name}_arr`].splice(index, 1);
    this.setState({ [`${name}_arr`]: this.state[`${name}_arr`] });
  };

  /*--------------------------------------------------input-type-file-onchange---------------------------------------------------------*/

  handleProfileOnchange = (e) => {
    e.preventDefault();

    let file = e.target.files[0];
    let idxDot = file.name.lastIndexOf(".") + 1;
    let extFile = file.name.substr(idxDot, file.name.length).toLowerCase();

    if (
      extFile == "jpg" ||
      extFile == "jpeg" ||
      extFile == "png" ||
      extFile == "svg" ||
      extFile == "gif"
    ) {
      let reader = new FileReader();
      reader.onloadend = () => {
        this.setState({
          imgFile: file,
          profile: reader.result,
          isProfileUpload: true,
        });
      };
      if (file) {
        reader.readAsDataURL(file);
      }
      this.handleProfileUpload(file);
    } else {
      toast.error("Only jpg/jpeg, png, gif and svg files are allowed!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  /*--------------------------------------------------upload-profile-image---------------------------------------------------------*/

  handleProfileUpload = (file) => {
    this.setState({ profile_loader: true });
    let userId = localStorage.getItem("uid");
    const formData = new FormData();
    formData.append("profile", file);
    formData.append("userId", userId);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    axios
      .post(`${Baseurl}api/doc/upload`, formData, config)
      .then((response) => {
        // console.log("The file is successfully uploaded", response);
        // this.setState({ profile_loader: false });
        toast.success("Your profile picture uploaded.", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 4000,
        });
        this.props.handleGetDocDetails(userId);
      })
      .catch((error) => {
        console.log("error----->", error);
        this.setState({ profile_loader: false });
      });
  };

  /*--------------------------------------------------save-profile---------------------------------------------------------*/

  handleSaveDocProfile = (e) => {
    e.preventDefault();

    this.setState({ isLoading: true, isProfileUpload: false });
    let userId = localStorage.getItem("uid");

    let data = {
      speciality: this.state.speciality_arr,
      practice: this.state.practiceArr,
      about_me: this.state.about_me,
      board_certification: this.state.board_certification_arr,
      insurance_providers: this.state.insuranceProvider_arr,
      medicaidInsuranceProvider: this.state.medicaidInsuranceProvider_arr,
      hospital_affiliations: this.state.hospital_affiliations_arr,
      education_training: this.state.education_training_arr,
      assistance_emails: this.state.assistance_emails_arr,
      professional_memberships: this.state.professional_memberships_arr,
      awards_publications: this.state.awards_publications_arr,
      languages: this.state.language_arr,
      gender: this.state.gender,
      telemedicine: this.state.telemedicine,
      disable_confirmation_email: this.state.disable_confirmation_email,
      npi_number: this.state.npi_number,
      profile_status:
        this.state.speciality_arr.length > 0 &&
        this.state.practiceArr.length > 0 &&
        this.state.about_me.length > 0 &&
        this.state.insuranceProvider_arr.length > 0 &&
        this.state.hospital_affiliations_arr.length > 0 &&
        this.state.education_training_arr.length > 0 &&
        this.state.professional_memberships_arr.length > 0 &&
        this.state.awards_publications_arr.length > 0 &&
        this.state.language_arr.length > 0 &&
        this.state.gender.length > 0 &&
        this.state.npi_number.length > 0
          ? "1"
          : "0",
    };

    if (
      this.state.insuranceProvider_arr.length === 0 &&
      this.state.groupRole === 7
    ) {
      toast.error("Please Enter your In-network insurances.", {
        position: toast.POSITION.TOP_CENTER,
      });
      this.setState({ isLoading: false });
    } else if (!this.state.npi_number && this.state.groupRole === 7) {
      toast.error("Please Enter your NPI number.", {
        position: toast.POSITION.TOP_CENTER,
      });
      this.setState({ isLoading: false });
    } else if (
      this.state.npi_number.length < 10 &&
      this.state.groupRole === 7
    ) {
      toast.error("NPI number is invalid.", {
        position: toast.POSITION.TOP_CENTER,
      });
      this.setState({ isLoading: false });
    } else if (this.state.practiceArr.length === 0) {
      toast.error("Please Enter your practice location.", {
        position: toast.POSITION.TOP_CENTER,
      });
      this.setState({ isLoading: false });
    } else {
   
      axios({
        method: "post",
        url: Baseurl + `api/doc/updateProfile?userId=${userId}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      })
        .then((response) => {
          // console.log("doctor-profile-successfully-saved !", response);
          // toast.success('Successfuly Updated !', { position: toast.POSITION.TOP_CENTER });
          toast.success(
            <ToastMessage message="Your profile successfully updated." />,
            { position: toast.POSITION.TOP_CENTER }
          );
          this.setState({ isLoading: false });
          this.props.handleGetDocDetails(userId);
        })
        .catch((error) => {
          // console.log("something wrong during to save profile--------->", error);
          toast.error("Something wrong could not update !", {
            position: toast.POSITION.TOP_CENTER,
          });
          this.setState({ isLoading: false });
        });
    }
  };

  // /*---------------------------------------------------------get-profile---------------------------------------------------------*/

  UNSAFE_componentWillReceiveProps(props) {
    // console.log('ddddd',props)
    if (props.docDetails && props.isLoading === false) {
      if (this.state.isProfileUpload) {
        this.setState({
          profile: props.docDetails.profile_image,
          profile_loader: props.isLoading,
        });
      } else {
        if (
          props.docDetails.groupRole === 6 ||
          props.docDetails.groupRole === 7 ||
          props.docDetails.groupRole === 8
        ) {
          this.setState({
            practiceArr:
              props.docDetails.practice && props.docDetails.practice.length > 0
                ? props.docDetails.practice
                : [],
            speciality_arr:
              props.docDetails.speciality &&
              props.docDetails.speciality.length > 0
                ? props.docDetails.speciality
                : [],
            about_me: props.docDetails.about_me
              ? props.docDetails.about_me
              : "",
            board_certification_arr: props.docDetails.board_certification
              ? props.docDetails.board_certification
              : "",
            insuranceProvider_arr:
              props.docDetails.insurance_providers &&
              props.docDetails.insurance_providers.length > 0
                ? props.docDetails.insurance_providers
                : [],
            medicaidInsuranceProvider_arr:
              props.docDetails.medicaid_insurance_providers &&
              props.docDetails.medicaid_insurance_providers.length > 0
                ? props.docDetails.medicaid_insurance_providers
                : [],
            hospital_affiliations_arr:
              props.docDetails.hospital_affiliations &&
              props.docDetails.hospital_affiliations.length > 0
                ? props.docDetails.hospital_affiliations
                : [],
            education_training_arr:
              props.docDetails.education_training &&
              props.docDetails.education_training.length > 0
                ? props.docDetails.education_training
                : [],
            assistance_emails_arr:
              props.docDetails.assistance_emails &&
              props.docDetails.assistance_emails.length > 0
                ? props.docDetails.assistance_emails
                : [],
            professional_memberships_arr:
              props.docDetails.professional_memberships &&
              props.docDetails.professional_memberships.length > 0
                ? props.docDetails.professional_memberships
                : [],
            awards_publications_arr:
              props.docDetails.awards_publications &&
              props.docDetails.awards_publications.length > 0
                ? props.docDetails.awards_publications
                : [],
            language_arr:
              props.docDetails.languages &&
              props.docDetails.languages.length > 0
                ? props.docDetails.languages
                : [],
            gender: props.docDetails.gender ? props.docDetails.gender : "Male",
            telemedicine: props.docDetails.telemedicine
              ? props.docDetails.telemedicine
              : 0,
            disable_confirmation_email:
              props.docDetails.disable_confirmation_email,
            npi_number: props.docDetails.npi_number
              ? props.docDetails.npi_number
              : "",
            profile: props.docDetails.profile_image,
            profile_loader: props.isLoading,
            groupRole: props.docDetails.groupRole
              ? props.docDetails.groupRole
              : 7,
          });
        }
      }
    }
  }

  closeModal = () => {
    this.setState({ isModal: false });
  };

  handlePracticePopup = () => {
    let newData = this.state.practiceArr.filter(
      (obj) =>
        obj !==
        this.state.practiceArr.find(
          (v) => v.practice_name === this.state.modal_practiceObj.practice_name
        )
    );
    this.setState({
      practiceArr: [...newData],
      isModal: false,
      isPracticeEdit: false,
      practice_location: "",
      address: "",
      practice_name: "",
      practice_zip: "",
      Pstate: "",
      Pcity: "",
    });
  };

  handleZipOnchange = (e) => {
    this.setState({ practice_zip: e.target.value });
    if (e.target.value.length > 4) {
      axios({
        method: "get",
        url: Baseurl + `api/doc/get_zipcodes?zip=${e.target.value}`,
        headers: { "content-type": "application/json" },
      })
        .then((response) => {
          // this.state.zipCodes.push(response.data.data);
          this.setState({
            zipCodes: [response.data.data],
            zipDropdown: true,
          });
        })
        .catch((err) => {
          console.log("error---------->", err);
        });
    } else {
      this.setState({ zipDropdown: false });
    }
  };

  handleZipItemsOnclick = (data) => {
    this.setState({
      Pstate: data.state,
      Pcity: data.city,
      practice_zip: data.zip,
      lat: data.latitude,
      long: data.longitude,
    });
  };

  openZipDropDown = () => {
    this.setState({ zipDropdown: false }, () => {
      document.addEventListener("click", this.hidezipDropDown);
    });
  };

  hidezipDropDown = () => {
    this.setState({ zipDropdown: false }, () => {
      document.removeEventListener("click", this.hidezipDropDown);
    });
  };

  componentWillUnmount = () => {
    document.removeEventListener("click", this.hidezipDropDown);
  };

  /*-----------------------------profile-picture-action---------------------------*/

  handleActionClick = () => {
    this.setState({ profileAction: !this.state.profileAction });
  };

  handleProfileBackdrop = () => {
    this.setState({ profileAction: false });
  };

  handleRemoveProfile = () => {
    this.setState({ profileAction: false, profile_loader: true });
    let userId = localStorage.getItem("uid");
    let fileKey = this.state.profile.split(
      "https://smart-app-uploads.s3.amazonaws.com/"
    );

    axios({
      method: "post",
      url:
        Baseurl +
        `api/doc/remove_profile?profile_url=${fileKey[1]}&user_id=${userId}`,
      headers: { "content-type": "application/json" },
    })
      .then((res) => {
        // console.log("remove-profile-response------->",res);
        this.setState({ profile_loader: true, imageKey: Math.random() });
        this.props.handleGetDocDetails(userId);
        // window.location.reload(false);
      })
      .catch((error) => {
        console.log("remove-profile-error------->", error);
        this.setState({ profile_loader: false });
      });
  };

  handleChange = (address) => {
    this.setState({ address });
    if (address === "") {
      this.setState({
        practice_location: "",
        practice_name: "",
        address: "",
        practice_zip: "",
        Pstate: "",
        Pcity: "",
      });
    }
  };

  getSuggestionHandler = (value, location) => {
    this.refs.btn.setAttribute("disabled", "disabled");
    setTimeout(() => {
      this.setState({ practice_name: value, practice_location: location });
      this.refs.btn.removeAttribute("disabled");
    }, 1000);
  };

  handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        const street_no = results[0].formatted_address.split(",")[0];
        console.log("street_no[0]", street_no);
        this.setState({
          Pstate: results[0].address_components[2].long_name,
          Pcity: `${street_no}, ${results[0].address_components[1].long_name}`,
        });
        return getLatLng(results[0]);
      })
      .then((latLng) =>
        reverseGeocode(
          { latitude: latLng.lat, longitude: latLng.lng },
          "AIzaSyAv-Li1_1Z0NJ1Z8ZQwI5gOemOGVypo0Ms"
        )
      )
      .then((zipcode) => {
        this.setState({ practice_zip: zipcode });
      })
      .catch((error) => console.error("Error", error));
  };

  render() {
    const zipDropdown =
      this.state.zipCodes.length > 0 && this.state.zipDropdown;
    const disable_confirmation_email = this.state.disable_confirmation_email;
    //console.log("practiceArr-------->", this.state);
    const searchOptions = {
      types: ["places"],
    };
    const { imageKey } = this.state;
    return (
      <React.Fragment>
        <Modal
          isOpen={this.state.isModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Modal"
        >
          <h3 className="text-center"> Are you sure?</h3>
          <h5 className="text-center">
            Deleting a practice will also delete all the appointments associated
            with it. This is not reversible.
          </h5>
          <div style={{ textAlign: "center" }}>
            <button
              className="btn btn-sm btn-filled"
              style={{ height: 50 }}
              onClick={this.handlePracticePopup}
            >
              Delete Practice
            </button>
            <button
              className="btn btn-sm btn-filled"
              style={{ height: 50 }}
              onClick={this.closeModal}
            >
              Cancel
            </button>
          </div>
        </Modal>

        <div className="main-container">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <form onSubmit={this.handleSaveDocProfile} autoComplete="off">
                <h4>Profile</h4>
                <hr />
                <div
                  className="col-md-12"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: 10,
                    paddingBottom: 10,
                    position: "relative",
                  }}
                >
                  {this.state.profile_loader ? (
                    <div
                      className="col-lg-3 col-md-3 col-sm-2 col-xs-2 profile_container"
                      style={{
                        backgroundImage: `url('http://volunteerglos.org.uk/images/spinner.gif')`,
                      }}
                    ></div>
                  ) : (
                    <div
                      className="col-lg-3 col-md-3 col-sm-2 col-xs-2 profile_container"
                      style={{
                        backgroundImage: this.state.profile
                          ? `url('${this.state.profile}')`
                          : `url('${doctorPlaceholder}')`,
                      }}
                    >
                      <div
                        className="profile_after_container"
                        onClick={this.handleActionClick}
                      >
                        <img
                          src="https://pngimage.net/wp-content/uploads/2018/06/white-pencil-icon-png.png"
                          style={{ height: 30, width: 30 }}
                        />
                      </div>
                    </div>
                  )}

                  <div
                    className="profile_action_container"
                    style={{
                      display: this.state.profileAction ? "block" : "none",
                    }}
                  >
                    <ul style={{ paddingLeft: "20%" }}>
                      <li>
                        <input
                          type="file"
                          key={imageKey}
                          onClick={() =>
                            this.setState({ profileAction: false })
                          }
                          style={inputFile}
                          name="profile"
                          onChange={(e) => this.handleProfileOnchange(e)}
                          accept="image/*"
                        />
                        Upload Profile
                      </li>
                      {this.state.profile && (
                        <li onClick={this.handleRemoveProfile}>Remove</li>
                      )}
                    </ul>
                  </div>
                </div>

                <div className="input-with-label text-left">
                  <span>Speciality</span>
                  <div id="multiple_box">
                    {this.state.speciality_arr.map((value, key) => {
                      return (
                        <div id="added_items_box" key={key}>
                          {value}{" "}
                          <span
                            aria-hidden="true"
                            style={cross}
                            onClick={(e) =>
                              this.handleRemoveItems(e, "speciality", value)
                            }
                          >
                            ×
                          </span>
                        </div>
                      );
                    })}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <input
                      className="col-md-12"
                      type="text"
                      list="speciality"
                      name="speciality"
                      value={this.state.speciality}
                      onKeyPress={(e) => {
                        if (e.charCode === 13)
                          this.handleAddMore(e, "speciality");
                      }}
                      placeholder="Speciality"
                      onChange={(e) => this.handleOnchange(e)}
                    />
                    <datalist id="speciality">
                      {primarySpeciality.map((name, index) => (
                        <option value={name} key={index} />
                      ))}
                    </datalist>
                    <button
                      onClick={(e) => this.handleAddMore(e, "speciality")}
                      className="btn btn-sm btn-filled"
                      style={{ height: 50 }}
                    >
                      ADD
                    </button>
                  </div>
                </div>

                <div>
                  <div className="input-with-label text-left">
                    <span>Practice</span>
                    <div id="multiple_box">
                      {this.state.practiceArr.map((value, key) => {
                        return (
                          <div id="added_items_box" key={key}>
                            {`${value.practice_name},${value.city}, ${value.practice_location},${value.zip}`}
                            <span
                              aria-hidden="true"
                              style={{
                                cursor: "pointer",
                                paddingTop: 5,
                                paddingLeft: 10,
                              }}
                              onClick={(e) =>
                                this.handleremovePracticeItems(e, value)
                              }
                            >
                              Delete
                            </span>
                            {
                              <span
                                aria-hidden="true"
                                style={{
                                  cursor: "pointer",
                                  paddingTop: 5,
                                  marginLeft: 10,
                                }}
                                onClick={(e) =>
                                  this.handleSetPracticeForEdit(e, value)
                                }
                              >
                                Edit
                              </span>
                            }
                          </div>
                        );
                      })}
                    </div>

                    <div
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <PlacesAutocomplete
                        className="position-relative"
                        value={this.state.address}
                        onChange={this.handleChange}
                        onSelect={this.handleSelect}
                        value={this.state.address}
                        shouldFetchSuggestions={this.state.address.length >= 3}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <React.Fragment>
                            {
                              <input
                                {...getInputProps({
                                  placeholder: "Search practice name",
                                  className: "location-search-input",
                                })}
                              />
                            }
                            <div className="autocomplete-dropdown-container pos_change">
                              {loading && <div>Loading...</div>}
                              {suggestions.map((suggestion) => {
                                console.log("suggestion", suggestion);
                                const className = suggestion.active
                                  ? "suggestion-item--active"
                                  : "suggestion-item";
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                  ? {
                                      backgroundColor: "#fafafa",
                                      cursor: "pointer",
                                      zIndex: "99999",
                                    }
                                  : {
                                      backgroundColor: "#ffffff",
                                      cursor: "pointer",
                                      zIndex: "99999",
                                    };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                  >
                                    <span
                                      onClick={(e) =>
                                        this.getSuggestionHandler(
                                          suggestion.formattedSuggestion
                                            .mainText,
                                          suggestion.formattedSuggestion
                                            .secondaryText
                                        )
                                      }
                                    >
                                      {suggestion.description}
                                    </span>
                                  </div>
                                );
                              })}
                            </div>
                          </React.Fragment>
                        )}
                      </PlacesAutocomplete>
                      {
                        <input
                          style={{ width: "30%" }}
                          className="col-md-6"
                          type="text"
                          readOnly={true}
                          name="practice_name"
                          value={this.state.practice_name}
                          placeholder="Practice name"
                          onKeyPress={(e) => {
                            if (e.charCode === 13)
                              this.state.isPracticeEdit
                                ? this.handleEditPractice(e)
                                : this.handlePracticeAddmore(e);
                          }}
                          onChange={(e) => this.handleOnchange(e)}
                        />
                      }
                      <input
                        className="col-md-6"
                        style={{ width: "30%" }}
                        type="text"
                        name="practice_location"
                        readOnly={true}
                        value={this.state.practice_location}
                        placeholder="Practice location"
                        onKeyPress={(e) => {
                          if (e.charCode === 13)
                            this.state.isPracticeEdit
                              ? this.handleEditPractice(e)
                              : this.handlePracticeAddmore(e);
                        }}
                        onChange={(e) => this.handleOnchange(e)}
                      />
                    </div>
                  </div>

                  <div className="input-with-label text-left">
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        className="col-md-4 col-xs-12"
                        style={{ position: "relative", padding: 0 }}
                      >
                        <input
                          onClick={this.openZipDropDown}
                          maxLength={5}
                          list="zipcode"
                          readOnly={true}
                          type="text"
                          name="practice_zip"
                          value={this.state.practice_zip}
                          onKeyPress={(e) => {
                            if (e.charCode === 13)
                              this.state.isPracticeEdit
                                ? this.handleEditPractice(e)
                                : this.handlePracticeAddmore(e);
                          }}
                          placeholder="Zip"
                          onChange={this.handleZipOnchange}
                          style={{ borderRight: "3px solid #fff" }}
                        />

                        {zipDropdown && (
                          <div
                            className="zip_search_container"
                            style={{
                              height: "auto",
                              display: zipDropdown ? "block" : "none",
                            }}
                          >
                            {this.state.zipCodes.map((value, key) => {
                              return (
                                <div
                                  className="zip_items"
                                  key={key}
                                  onClick={() =>
                                    this.handleZipItemsOnclick(value)
                                  }
                                >
                                  <i
                                    className="ti-location-pin"
                                    style={{
                                      lineHeight: 2,
                                      fontWeight: "bold",
                                      cursor: "pointer",
                                      fontSize: 17,
                                    }}
                                  ></i>
                                  <span>
                                    {value.zip}, {value.state}, {value.city}
                                  </span>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>

                      <div
                        className="col-md-4 col-xs-12"
                        style={{ padding: 0 }}
                      >
                        <input
                          type="text"
                          name="Pstate"
                          placeholder="State"
                          readOnly={true}
                          onKeyPress={(e) => {
                            if (e.charCode === 13)
                              this.state.isPracticeEdit
                                ? this.handleEditPractice(e)
                                : this.handlePracticeAddmore(e);
                          }}
                          onChange={(e) => this.handleOnchange(e)}
                          value={this.state.Pstate}
                          style={{ borderRight: "3px solid #fff" }}
                        />
                      </div>
                      <div
                        className="col-md-4 col-xs-12"
                        style={{ padding: 0 }}
                      >
                        <input
                          type="text"
                          name="Pcity"
                          readOnly={true}
                          placeholder="City/Town"
                          onKeyPress={(e) => {
                            if (e.charCode === 13)
                              this.state.isPracticeEdit
                                ? this.handleEditPractice(e)
                                : this.handlePracticeAddmore(e);
                          }}
                          onChange={(e) => this.handleOnchange(e)}
                          value={this.state.Pcity}
                        />
                      </div>
                    </div>
                    {this.state.isPracticeEdit ? (
                      <button
                        onClick={(e) => this.handleEditPractice(e)}
                        className="btn btn-sm btn-filled"
                        style={{ height: 50 }}
                      >
                        UPDATE
                      </button>
                    ) : (
                      <button
                        ref="btn"
                        onClick={(e) => this.handlePracticeAddmore(e)}
                        className="btn btn-sm btn-filled"
                        style={{ height: 50 }}
                      >
                        ADD
                      </button>
                    )}
                  </div>
                </div>

                <div className="input-with-label text-left">
                  <span>About me</span>
                  <textarea
                    className="col-md-12"
                    name="about_me"
                    value={this.state.about_me}
                    placeholder="About me"
                    onChange={(e) => this.handleOnchange(e)}
                  ></textarea>
                </div>
                <div className="input-with-label text-left">
                  <span>Board certifications</span>
                  <div id="multiple_box">
                    {this.state.board_certification_arr.map((value, key) => {
                      return (
                        <div id="added_items_box" key={key}>
                          {value}
                          <span
                            aria-hidden="true"
                            style={cross}
                            onClick={(e) =>
                              this.handleRemoveItems(
                                e,
                                "board_certification",
                                value
                              )
                            }
                          >
                            ×
                          </span>
                        </div>
                      );
                    })}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="text"
                      style={{
                        background: "#f5f5f5",
                        border: "none",
                        width: "100%",
                        height: "50px",
                        paddingLeft: "20px",
                        fontWeight: "500",
                        marginBottom: "24px",
                        borderRadius: "0",
                      }}
                      className="col-md-12"
                      name="board_certification"
                      value={this.state.board_certification}
                      onKeyPress={(e) => {
                        if (e.charCode === 13)
                          this.handleAddMore(e, "board_certification");
                      }}
                      placeholder="Board certifications"
                      onChange={(e) => this.handleOnchange(e)}
                    />
                    <button
                      onClick={(e) =>
                        this.handleAddMore(e, "board_certification")
                      }
                      className="btn btn-sm btn-filled"
                      style={{ height: 50 }}
                    >
                      ADD
                    </button>
                  </div>
                </div>

                {this.state.groupRole === 7 ? (
                  <React.Fragment>
                    <div className="input-with-label text-left">
                      <span>In-network insurances</span>
                      <div id="multiple_box">
                        {this.state.insuranceProvider_arr.map((value, key) => {
                          return (
                            <div id="added_items_box" key={key}>
                              {value.provider_name}
                              <span
                                aria-hidden="true"
                                style={cross}
                                onClick={(e) =>
                                  this.handleInsuranceProviderRemoveItem(
                                    e,
                                    value
                                  )
                                }
                              >
                                ×
                              </span>
                            </div>
                          );
                        })}
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <input
                          className="col-md-12"
                          type="text"
                          value={this.state.insuranceProvider}
                          list="insuranceProvider"
                          name="insuranceProvider"
                          onKeyPress={(e) => {
                            if (e.charCode === 13)
                              this.handleInsuranceProviderAddmore(e);
                          }}
                          placeholder="In-network insurances"
                          onChange={(e) => this.handleOnchange(e)}
                        />
                        <datalist id="insuranceProvider">
                          {this.state.payerNameList.map((value, key) => (
                            <option value={value.payerName} key={key} />
                          ))}
                        </datalist>
                        <button
                          disabled={this.state.insuranceProvider.length < 1}
                          onClick={(e) =>
                            this.handleInsuranceProviderAddmore(e)
                          }
                          className="btn btn-sm btn-filled"
                          style={{ height: 50 }}
                        >
                          ADD
                        </button>
                      </div>
                    </div>

                    <div className="input-with-label text-left">
                      <span>Supported Medicaid Insurances Providers</span>
                      <div id="multiple_box">
                        {this.state.medicaidInsuranceProvider_arr.map(
                          (value, key) => {
                            return (
                              <div id="added_items_box" key={key}>
                                {value.provider_name}
                                <span
                                  aria-hidden="true"
                                  style={cross}
                                  onClick={(e) =>
                                    this.handleMedicaidInsuranceProviderRemoveItem(
                                      e,
                                      value
                                    )
                                  }
                                >
                                  ×
                                </span>
                              </div>
                            );
                          }
                        )}
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <input
                          className="col-md-12"
                          type="text"
                          list="medicaidList"
                          value={this.state.medicaidInsuranceProvider}
                          name="medicaidInsuranceProvider"
                          onKeyPress={(e) => {
                            if (e.charCode === 13)
                              this.handleMedicaidInsuranceProviderAddmore(e);
                          }}
                          placeholder="Supported Medicaid Insurances"
                          onChange={(e) => this.handleOnchange(e)}
                        />
                        <datalist id="medicaidList">
                          {this.state.medicaidInsuranceList.map(
                            (value, key) => (
                              <option
                                value={`${value.payerName} (${value.PolicyType}) `}
                                key={key}
                              />
                            )
                          )}
                        </datalist>
                        <button
                          disabled={
                            this.state.medicaidInsuranceProvider.length < 1
                          }
                          onClick={(e) =>
                            this.handleMedicaidInsuranceProviderAddmore(e)
                          }
                          className="btn btn-sm btn-filled"
                          style={{ height: 50 }}
                        >
                          ADD
                        </button>
                      </div>
                    </div>
                  </React.Fragment>
                ) : (
                  ""
                )}

                {/* -----------------------------end-of-Supported Medicaid Insurances--------------------------------- */}

                <div className="input-with-label text-left">
                  <span>Hospital Affiliations</span>
                  <div id="multiple_box">
                    {this.state.hospital_affiliations_arr.map((value, key) => {
                      return (
                        <div id="added_items_box" key={key}>
                          {value}
                          <span
                            aria-hidden="true"
                            style={cross}
                            onClick={(e) =>
                              this.handleRemoveItems(
                                e,
                                "hospital_affiliations",
                                value
                              )
                            }
                          >
                            ×
                          </span>
                          {/*<img onClick={(e) => this.handleRemoveItems(e, 'hospital_affiliations', value)} src="http://simpleicon.com/wp-content/uploads/cross.png" style={{ height: 20, width: 20, cursor: 'pointer', marginLeft: 8 }} />*/}
                        </div>
                      );
                    })}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <input
                      className="col-md-12"
                      type="text"
                      name="hospital_affiliations"
                      value={this.state.hospital_affiliations}
                      onKeyPress={(e) => {
                        if (e.charCode === 13)
                          this.handleAddMore(e, "hospital_affiliations");
                      }}
                      placeholder="Hospital Affiliations"
                      onChange={(e) => this.handleOnchange(e)}
                    />
                    <button
                      onClick={(e) =>
                        this.handleAddMore(e, "hospital_affiliations")
                      }
                      className="btn btn-sm btn-filled"
                      style={{ height: 50 }}
                    >
                      ADD
                    </button>
                  </div>
                </div>

                <div className="input-with-label text-left">
                  <span>Education and Training</span>
                  <div id="multiple_box">
                    {this.state.education_training_arr.map((value, key) => {
                      return (
                        <div id="added_items_box" key={key}>
                          {value}
                          <span
                            aria-hidden="true"
                            style={cross}
                            onClick={(e) =>
                              this.handleRemoveItems(
                                e,
                                "education_training",
                                value
                              )
                            }
                          >
                            ×
                          </span>
                          {/*<img onClick={(e) => this.handleRemoveItems(e, 'education_training', value)} src="http://simpleicon.com/wp-content/uploads/cross.png" style={{ height: 20, width: 20, cursor: 'pointer', marginLeft: 8 }} />*/}
                        </div>
                      );
                    })}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="text"
                      className="col-md-12"
                      name="education_training"
                      value={this.state.education_training}
                      onKeyPress={(e) => {
                        if (e.charCode === 13)
                          this.handleAddMore(e, "education_training");
                      }}
                      placeholder="Education and Training"
                      onChange={(e) => this.handleOnchange(e)}
                    />
                    <button
                      onClick={(e) =>
                        this.handleAddMore(e, "education_training")
                      }
                      className="btn btn-sm btn-filled"
                      style={{ height: 50 }}
                    >
                      ADD
                    </button>
                  </div>
                </div>

                <div className="input-with-label text-left">
                  <span>Professional Memberships</span>
                  <div id="multiple_box">
                    {this.state.professional_memberships_arr.map(
                      (value, key) => {
                        return (
                          <div id="added_items_box" key={key}>
                            {value}
                            <span
                              aria-hidden="true"
                              style={cross}
                              onClick={(e) =>
                                this.handleRemoveItems(
                                  e,
                                  "professional_memberships",
                                  value
                                )
                              }
                            >
                              ×
                            </span>
                            {/*<img onClick={(e) => this.handleRemoveItems(e, 'professional_memberships', value)} src="http://simpleicon.com/wp-content/uploads/cross.png" style={{ height: 20, width: 20, cursor: 'pointer', marginLeft: 8 }} />*/}
                          </div>
                        );
                      }
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="text"
                      className="col-md-12"
                      name="professional_memberships"
                      value={this.state.professional_memberships}
                      onKeyPress={(e) => {
                        if (e.charCode === 13)
                          this.handleAddMore(e, "professional_memberships");
                      }}
                      placeholder="Professional Memberships"
                      onChange={(e) => this.handleOnchange(e)}
                    />
                    <button
                      onClick={(e) =>
                        this.handleAddMore(e, "professional_memberships")
                      }
                      className="btn btn-sm btn-filled"
                      style={{ height: 50 }}
                    >
                      ADD
                    </button>
                  </div>
                </div>

                <div className="input-with-label text-left">
                  <span>Awards and publications</span>
                  <div id="multiple_box">
                    {this.state.awards_publications_arr.map((value, key) => {
                      return (
                        <div id="added_items_box" key={key}>
                          {value}
                          <span
                            aria-hidden="true"
                            style={cross}
                            onClick={(e) =>
                              this.handleRemoveItems(
                                e,
                                "awards_publications",
                                value
                              )
                            }
                          >
                            ×
                          </span>
                          {/*<img onClick={(e) => this.handleRemoveItems(e, 'awards_publications', value)} src="http://simpleicon.com/wp-content/uploads/cross.png" style={{ height: 20, width: 20, cursor: 'pointer', marginLeft: 8 }} />*/}
                        </div>
                      );
                    })}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="text"
                      className="col-md-12"
                      name="awards_publications"
                      value={this.state.awards_publications}
                      onKeyPress={(e) => {
                        if (e.charCode === 13)
                          this.handleAddMore(e, "awards_publications");
                      }}
                      placeholder="Awards and publications"
                      onChange={(e) => this.handleOnchange(e)}
                    />
                    <button
                      onClick={(e) =>
                        this.handleAddMore(e, "awards_publications")
                      }
                      className="btn btn-sm btn-filled"
                      style={{ height: 50 }}
                    >
                      ADD
                    </button>
                  </div>
                </div>

                <div className="input-with-label text-left">
                  <span>Languages Spoken</span>
                  <div id="multiple_box">
                    {this.state.language_arr.map((value, key) => {
                      return (
                        <div id="added_items_box" key={key}>
                          {value}
                          <span
                            aria-hidden="true"
                            style={cross}
                            onClick={(e) =>
                              this.handleRemoveItems(e, "language", value)
                            }
                          >
                            ×
                          </span>
                        </div>
                      );
                    })}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "",
                    }}
                  >
                    <input
                      type="text"
                      className="col-md-12"
                      name="language"
                      value={this.state.language}
                      placeholder="Language"
                      onChange={this.handleOnchange}
                    />
                    <button
                      onClick={(e) => this.handleAddMore(e, "language")}
                      className="btn btn-sm btn-filled"
                      style={{ height: 50 }}
                    >
                      ADD
                    </button>
                  </div>
                </div>

                <div className="input-with-label text-left">
                  <span>Gender</span>
                  <select
                    className="col-md-12"
                    name="gender"
                    onChange={(e) => this.handleOnchange(e)}
                    value={this.state.gender}
                  >
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                {this.state.groupRole === 7 ? (
                  <React.Fragment>
                    <div className="input-with-label text-left">
                      <span>NPI Number</span>
                      <input
                        type="text"
                        className="col-md-12"
                        name="npi_number"
                        maxLength={10}
                        value={this.state.npi_number}
                        onKeyPress={(e) =>
                          e.charCode >= 48 && e.charCode <= 57
                            ? true
                            : e.preventDefault()
                        }
                        placeholder="NPI Number"
                        onChange={(e) => this.handleOnchange(e)}
                      />
                    </div>
                  </React.Fragment>
                ) : (
                  ""
                )}

                <div className="input-with-label text-left">
                  <span>Assistance Emails</span>
                  <div id="multiple_box">
                    {this.state.assistance_emails_arr.map((value, key) => {
                      return (
                        <div id="added_items_box" key={key}>
                          {value}
                          <span
                            aria-hidden="true"
                            style={cross}
                            onClick={(e) =>
                              this.handleRemoveItems(
                                e,
                                "assistance_emails",
                                value
                              )
                            }
                          >
                            ×
                          </span>
                        </div>
                      );
                    })}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="email"
                      style={{
                        background: "#f5f5f5",
                        border: "none",
                        width: "100%",
                        height: "50px",
                        paddingLeft: "20px",
                        fontWeight: "500",
                        marginBottom: "24px",
                        borderRadius: "0",
                      }}
                      className="col-md-12"
                      name="assistance_emails"
                      value={this.state.assistance_emails}
                      onKeyPress={(e) => {
                        if (e.charCode === 13)
                          this.handleAddMore(e, "assistance_emails");
                      }}
                      placeholder="Assistance Emails"
                      onChange={(e) => this.handleOnchange(e)}
                    />
                    <button
                      onClick={(e) =>
                        this.handleAddMore(e, "assistance_emails")
                      }
                      className="btn btn-sm btn-filled"
                      style={{ height: 50 }}
                    >
                      ADD
                    </button>
                  </div>
                </div>
                <div className="input-with-label text-left">
                  <span>Offer Telemedicine </span>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      checked={this.state.telemedicine == 1}
                      onChange={(e) => this.handleOnchange(e)}
                      name="telemedicine"
                      id="telemedicine1"
                      value="1"
                    />
                    <label className="form-check-label" htmlFor="telemedicine1">
                      &nbsp;Yes
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      checked={this.state.telemedicine == 0}
                      onChange={(e) => this.handleOnchange(e)}
                      name="telemedicine"
                      id="telemedicine2"
                      value="0"
                    />
                    <label className="form-check-label" htmlFor="telemedicine2">
                      &nbsp;No
                    </label>
                  </div>
                </div>

                <div className="input-with-label text-left">
                  <span>Disable Confirmation Emails </span>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      checked={disable_confirmation_email == 1}
                      onChange={(e) => this.handleOnchange(e)}
                      name="disable_confirmation_email"
                      id="disable_confirmation_email1"
                      value="1"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="disable_confirmation_email1"
                    >
                      &nbsp;Yes
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      checked={disable_confirmation_email != 1}
                      onChange={(e) => this.handleOnchange(e)}
                      name="disable_confirmation_email"
                      id="disable_confirmation_email2"
                      value="0"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="disable_confirmation_email2"
                    >
                      &nbsp;No
                    </label>
                  </div>
                </div>
                <hr />
                <div style={{ display: "", alignItems: "center" }}>
                  <button
                    type="submit"
                    disabled={this.state.isLoading}
                    onClick={this.handleSaveDocProfile}
                    style={{ width: 100, margin: 5 }}
                  >
                    {this.state.isLoading && (
                      <span
                        className="spinner-border text-light mr8"
                        role="status"
                      />
                    )}
                    SAVE
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div
            className="profile_backdrop"
            onClick={this.handleProfileBackdrop}
            style={{ display: this.state.profileAction ? "block" : "none" }}
          ></div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    docDetails: state.doctorDetails,
    isLoading: state.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleGetDocDetails: (data) =>
      dispatch({ type: GET_DOC_DETAILS_REQUEST, data }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
