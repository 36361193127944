import axios from "axios";
import { Baseurl } from "./Baseurl";

export const GetAPI = (path, data) => {
    // const token = localStorage.getItem("uid");
    return axios({
      method: "get",
      url: Baseurl + path,
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer ",
      },
      data: data,
    });
  };

export const PostAPI = (path, data) => {
    // const token = localStorage.getItem("uid");
    return axios({
      method: "post",
      url: Baseurl + path,
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer ",
      },
      data: data,
    });
  };

  export const uploadApi=(path,multipartData)=>{
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    return axios.post(Baseurl+path,multipartData,config)
}
