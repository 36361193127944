import React, { Component } from "react";
import { Baseurl } from "../../utils/Baseurl";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt,faTrash,faMinusCircle,faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import "../../styles/ManageDoctor.css";
import axios from "axios";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import Modal from "react-modal";
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    }
};


Modal.defaultStyles.overlay.backgroundColor = 'rgba(111, 111, 111, 0.75)';
Modal.defaultStyles.overlay.zIndex = 100;

export default class ManageDoctor extends Component{
 constructor(props){ 	
 	super(props);
 	this.state = {
 		loading: false,
 		  isModal: false,
 		drData:[],
 		doctor_id:'',
 		drUid:'',
 	}
 }

 swapLogin = (e) => {  
    let userId = localStorage.getItem("uid");
    localStorage.removeItem('uid'); 
 	const swapUid = e.target.getAttribute("data-uid");
 	// console.log('target', swapUid);
 	localStorage.setItem('uid', swapUid);
 	localStorage.setItem('Gruid', userId);
 	//this.props.history.push('/');
 	window.location.href = "/";
 }


 componentDidMount() {
 	//this.swapLogin();
    this.handleGetDoctorDetails();
 }

 handleGetDoctorDetails = () => {
    let userId = localStorage.getItem("uid");
    this.setState({ loading: true });
    axios({
      method: "get",
      url: Baseurl + `api/doc/getDetails?userId=${userId}`,
      headers: { "content-type": "application/json" },
    })
      .then((res) => {
        //  console.log("res11----->",res);
        this.setState({ doctor_id: res.data.user._id }, () => {
          this.handleGetDoctors(res.data.user.drLinkToGroup);
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        // console.log("error1--->", error);
      });
  };
 handleGetDoctors = (datarfid) => {
    this.setState({ loading: true });
    let userId = localStorage.getItem("uid");
    let data = {"refid":datarfid,grpuid:userId}	
 
 	axios({
      method: "post",
      url: Baseurl + `api/doc/get_group_doctors`,
      headers: { "content-type": "application/json" },
      data,
    }).then((res) => {       
        this.setState({ drData: res.data.data }, () => {
         
        });
      }).catch((error) => {
        this.setState({ loading: false });
       
      });
 };

 handleClickDelete = (duid)=>{ 
 	let data = {"user_id":duid} 
 	
 	axios({
      method: "post",
      url: Baseurl + `api/doc/delete_doctor`,
      headers: { "content-type": "application/json" },
      data,
    }).then((res) => {  
         this.closeModal();       
         this.handleGetDoctorDetails();         
    }).catch((error) => {
     this.setState({ loading: false,isModal: false  });    
    });
 };

 deleteDoctor = (e,drUid)=>{ 	
 	e.preventDefault(); 	 	
	this.setState({ isModal: true, drUid: drUid });
	  
 };

closeModal = () => {
    this.setState({ isModal: false });
  };

 render(){
  //  console.log(this.state.drData);
 	return(<div>
 		 <Modal
          isOpen={this.state.isModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Modal"
        >
          <h3 className="text-center"> Are you sure? </h3>
          <h5 className="text-center">
           You want to delete this doctor?
          </h5>
          <div style={{ textAlign: "center" }}>
          <button
              className="btn btn-sm btn-filled"
              style={{ height: 50 }}
              onClick={this.closeModal}
            >
              No
            </button>
            <button
              className="btn btn-sm btn-filled"
              style={{ height: 50 }}
              onClick={() => {
	            this.handleClickDelete(this.state.drUid)
	            
	        }}

            >
              Yes, Delete it!
            </button>
            
          </div>
        </Modal>
		<div className="table-wrapper">
			<div className="table-title panel panel-default">
				<div className="row panel-heading">
					<div className="col-sm-6">
						<h2>Manage <b>Providers</b></h2>
					</div>
					<div className="col-sm-6">
						<a href="/add_doctor" className="btn btn-success btnadd" data-toggle="modal"><FontAwesomeIcon icon={faPlusCircle} /> <span style={{float:'none'}}>Add New Provider</span></a>
												
					</div>
				</div>
			</div>
			<div className="table-responsive">
			<table className="table table-striped table-hover">
				<thead>
					<tr>
						<th>
							#
						</th>
						<th>Name</th>
						<th>Email</th>
						<th>primary speciality</th>
						<th>Phone</th>
						<th style={{width:'150px'}}>Actions</th>
					</tr>
				</thead>
				<tbody>
				 {this.state.drData.map((dr, key) => {
				 	 return (
					<tr key={dr.email}>
					  <td>
							{key+1}
						</td>
						<td>{dr.first_name} {dr.last_name}</td>
						<td>{dr.email}</td>
						<td>{dr.primary_speciality}</td>
						<td>{dr.mobile}</td>
						<td>
							{/*<a href="#editEmployeeModal"  className="edit" data-toggle="modal"><FontAwesomeIcon icon={faPencilAlt} /></a> */}
							&nbsp;&nbsp;&nbsp;&nbsp;
							<a href="#deleteEmployeeModal" alt="Delete" title="Delete" onClick={(e) => this.deleteDoctor(e,dr.uid)} className="delete" data-toggle="modal"><FontAwesomeIcon icon={faTrash} /></a>
							<button data-uid={dr.uid} style={{background: '#47b475', color: '#fff',marginBottom:0,marginLeft:'20px',verticalAlign:'top',borderColor:'#47b475'}} type="button" className="btn btn-primary btn-sm btn-rounded" onClick={this.swapLogin}>Log In</button> 
						</td>
					</tr>	
					 );				
					  })}
				</tbody>
			</table>
			</div>
		</div>
	</div>

 		)

 	
 }
















}