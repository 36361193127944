import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { } from '../styles/Subscription.css';
export default class subscriptiondetail extends Component {
    render() {
        return (

            <React.Fragment>
         


               
            </React.Fragment>
        )
    }
}