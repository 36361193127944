import React from "react";
import ReactPaginate from "react-paginate";
import axios from "axios";
import { Baseurl } from "../../../../../utils/Baseurl";
import { handleDateFormatMonthYear } from "../../../../../utils/DateMethod";
import { connect } from "react-redux";
class ConsultCancelledOutgoingGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      consultCancelledOutgoingData: null,
      page: 1,
      limit: 10,
      provider:null,
      loading:false
    };
  }
  getCancelledAppointments = (fullname, page, limit) => {
    this.setState({loading:true})
    axios
      .get(
        `${Baseurl}api/consult_report/consultCancelledOutgoing?doctor_fullname=${fullname.toLowerCase()}&page=${page}&limit=${limit}`
      )
      .then((res) => {
        if (res.status === 200) {
          this.setState({ consultCancelledOutgoingData: res.data, loading:false });
        }
        console.log(res, "res");
      })
      .catch((err) => {
        this.setState({loading:false})
        console.log(err, "err");
      });
  };
  // componentDidMount() {
  //   // const id = localStorage.getItem("uid");
  //   const { page, limit } = this.state;
  //  setTimeout(()=>{
  //   this.getCancelledAppointments(this.props.doctordetail.first_name +" "+ this.props.doctordetail.last_name, page, limit);
  //  },1000)
  // }
  handlePageClick = (pages) => {
    // const id = localStorage.getItem("uid");
    const { limit,provider } = this.state;
    this.setState({ page: pages.selected + 1 }, () => {
      this.getCancelledAppointments(provider, pages.selected + 1, limit);
    });
  };

  handleProviderChange = (e) => {
    const provider = e.target.value;
    const { limit,page } = this.state;
    this.setState({ provider, page: 1 }, () => {
      this.getCancelledAppointments(provider, page, limit);
    });
  };
  render() {
    const { consultCancelledOutgoingData, provider, loading } = this.state;
    const {doctordetail, subdrdata} = this.props;
        console.log(doctordetail, "doctordetail");
    return (
      <div className="consults-sent">
          <div className="col-lg-12 col-sm-12">
            <select
              value={provider}
              style={{ width: "100%" }}
              onChange={this.handleProviderChange}
            >
              <option value="">Select Provider for Consult Cancelled Outgoing</option>
              {subdrdata?.map((el, i) => {
                return (
                  <option value={el.first_name + ' ' + el.last_name}>
                    {el.first_name} {el.last_name}
                  </option>
                );
              })}
            </select>
          </div>
        <div class="col-12 mt-3">
          <div className="doc_appointment-resp_table">
            <table className="table table-striped table-bordered" id="myTable">
              <thead
                style={{
                  background: "#70ad47",
                  color: "#FFF",
                }}
              >
                <tr>
                  <th scope="col">
                    <span
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        whiteSpace: "nowrap",
                      }}
                    >
                      MONTH YEAR
                    </span>
                  </th>
                  <th scope="col" className="text-left">
                    <span
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        whiteSpace: "nowrap",
                      }}
                    >
                      CANCELLED OUTGOING APPOINTMENTS COUNT
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {loading ? <tr>
                 <td colSpan={2}>
                   <div
                     style={{
                       height: "auto",
                       width: "100%",
                       display: "flex",
                       justifyContent: "center",
                       alignItems: "center",
                     }}
                   >
                     <img
                       alt="placeholder"
                       src="https://luxysource.com/pub/static/frontend/Magento/luxysource/en_US/custom/img/preloader.gif"
                       style={{ height: 80, width: 80 }}
                     />
                   </div>
                 </td>
               </tr> :<React.Fragment>
               {consultCancelledOutgoingData?.data.length ? (
                  consultCancelledOutgoingData?.data.map((el) => {
                    return (
                      <tr key={el._id}>
                        <td nowrap="nowrap">
                          {handleDateFormatMonthYear(el._id)}
                        </td>
                        <td nowrap="nowrap" className="text-left">
                          {el.cancelledCount}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td className="text-center" colSpan={2}>
                      No data found.
                    </td>
                  </tr>
                )}
                 
                 </React.Fragment>}
                
              </tbody>
            </table>
          </div>
        </div>
        {consultCancelledOutgoingData?.totalPages ? (
          <ReactPaginate
            previousLabel={"previous"}
            nextLabel={"next"}
            pageCount={consultCancelledOutgoingData.totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={this.handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    doctordetail: state.doctorDetails,
  };
};
export default connect(mapStateToProps, null)(ConsultCancelledOutgoingGroup);
