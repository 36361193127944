import { savePDF } from '@progress/kendo-react-pdf';
import subscription from '../component/my-transaction/subscription';

class DocService {
  createPdf = (html,id) => {
    savePDF(html, { 
      paperSize: 'A4',
      fileName: `subscription-${id}.pdf`,
      margin: 50,
    })
  }
}

const Doc = new DocService();
export default Doc;