import React, { Component } from 'react';

class ErrorScreen extends Component {
    render() {
        return (
            <React.Fragment>
                <div style={error_container}>
                    <img alt="icon" src={require('../assets/logo/oops-something-went-wrong-large.png')} style={{ height: 600, width: 900 }} />
                </div>
            </React.Fragment>
        );
    }
}


const error_container = {
    display: 'flex',
    height: '100vh',
    width: '100%',
    // background:'lg',
    justifyContent: 'center',
    alignItems: 'center',

}

export default ErrorScreen;