import React, { Component } from 'react';
import { primarySpeciality } from '../../constant/speciality';
import axios from 'axios';
import { Baseurl } from '../../utils/Baseurl';
import { GET_DOC_DETAILS_REQUEST } from '../../actions/Action';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { ToastMessage } from '../../component/ToastMessage';
import '../../styles/Common.css';


class PersonalDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            first_name: '',
            last_name: '',
            email: '',
            Primaryspeciality: '',
            phone: '',
            zip: '',
            isLoading: false,
        }
    }


    UNSAFE_componentWillMount() {
        window.scrollTo(0, 0);
        let user_id = localStorage.getItem('uid');
        
        this.props.handleGetDocDetails(user_id);
    }

    /*---------------------------------------------------handle-get-personal-details-----------------------------------------------*/

    UNSAFE_componentWillReceiveProps(props) {

        if (props.docDetails) {

             let user_id = localStorage.getItem('uid');
             axios({
             method:'get',
             url:Baseurl+`api/doc/getDetails?userId=${user_id}`,
             headers:{'content-type':'application/json'}
            }).then((res)=>{
               this.setState({
                first_name: res.data.user.first_name,
                last_name: res.data.user.last_name,
                email: res.data.user.email,
                Primaryspeciality: res.data.user.primary_speciality,
                phone: res.data.user.mobile ? res.data.user.mobile.substring(res.data.user.mobile.length - 10, res.data.user.mobile.length) : '',
                zip: res.data.user.zip,
                specialityArr: res.data.user.speciality
            })
               
            }).catch((err)=>{
                console.log("doc error---->",err);
            })
            
        }
    }



    handleOnChange = (e) => {
        // let pattern = /^\d{0,10}(?:\.\d{0,2})?$/;

        // if (e.target.name === 'phone') {
        //     if (!pattern.test(e.target.value)) {
        //         this.setState({
        //             [e.target.name]: e.target.value
        //         })
        //     }
        // } else {
            this.setState({[e.target.name]: e.target.value});
        // }
        
    };



    /*---------------------------------------------------handle-update-personal-details-----------------------------------------------*/

    HandleSavePersonalDetails = (e) => {
        e.preventDefault();

        this.setState({ isLoading: true });

        const {specialityArr, Primaryspeciality} = this.state;

        const hasDocCurrentSpeciality = specialityArr.some(value => value === Primaryspeciality);

        let user_id = localStorage.getItem('uid');
        let data = {
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            primary_speciality: Primaryspeciality,
            phone: this.state.phone,
            zip: this.state.zip,
            speciality: hasDocCurrentSpeciality ? specialityArr : specialityArr.concat(Primaryspeciality)
        };

        const isSpeciality = primarySpeciality.some(value => value === Primaryspeciality);

        if (this.state.first_name === '') {
            this.setState({ isLoading: false });
            toast.error(< ToastMessage message="Enter Your First name." />, { position: toast.POSITION.TOP_CENTER });
        }
        else if (this.state.last_name === '') {
            this.setState({ isLoading: false });
            toast.error(< ToastMessage message="Enter Your Last name." />, { position: toast.POSITION.TOP_CENTER });
        }
        else if (this.state.zip === '') {
            this.setState({ isLoading: false });
            toast.error(< ToastMessage message="Enter Your Zip code." />, { position: toast.POSITION.TOP_CENTER });
        }
        else if(!isSpeciality){
            this.setState({ isLoading: false });
            toast.error(< ToastMessage message="Please select speciality from the list." />, { position: toast.POSITION.TOP_CENTER });
        } 
        else if(this.state.phone==='')
        {
            this.setState({ isLoading: false });
            toast.error(< ToastMessage message="Please enter your mobile number." />, { position: toast.POSITION.TOP_CENTER });
        }
        else if(this.state.phone.length < 10)
        {
            this.setState({ isLoading: false });
            toast.error(< ToastMessage message="Phone number must be 10 digits." />, { position: toast.POSITION.TOP_CENTER });
        }

        else {
            axios({
                method: 'post',
                url: Baseurl + `api/doc/update_personal_details?userId=${user_id}`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: data,
            }).then((res) => {
                // console.log("personal-details-updated ----->", res);

                toast.success(< ToastMessage message="Your details successfully updated." />, { position: toast.POSITION.TOP_CENTER });

                this.setState({ isLoading: false });
                this.props.handleGetDocDetails(user_id);
            }).catch((error) => {
               
                toast.error('Something wrong could not update !', { position: toast.POSITION.TOP_CENTER });
                this.setState({ isLoading: false });
            })
        }
    };



    render() {

     
        var phonenumber =this.state.phone;
        var phonefchar = phonenumber.charAt(0)
        if(phonenumber!='' && phonefchar==='+'){
            if(Baseurl==='https://api.smartappointment.com/'){
               var slice = phonenumber.split('+1');
               phonenumber = slice[1];
            }else{
              var slice = phonenumber.split('+91');
               phonenumber = slice[1];
          
            }
             
       }
       
        
        return (
            <React.Fragment>
                <div className="main-container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <form>
                                <h4>Personal Details</h4>
                                <hr />
                                <div className="input-with-label text-left">
                                    <span>Name</span>
                                    <input className="col-md-6" type="text" value={this.state.first_name} name="first_name" placeholder="First name" 
                                     onChange={(e) => this.handleOnChange(e)} 
                                     onKeyPress={(event) => (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.charCode == 8) ? true : event.preventDefault()}
                                    />
                                    <input className="col-md-6" type="text" value={this.state.last_name} name="last_name" placeholder="Last name" 
                                    onChange={(e) => this.handleOnChange(e)} 
                                    onKeyPress={(event) => (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.charCode == 8) ? true : event.preventDefault()}
                                    />
                                </div>
                                <div className="input-with-label text-left">
                                    <span>Email</span>
                                    <input disabled className="col-md-12" type="text" value={this.state.email} name="email" placeholder="Email" onChange={(e) => this.handleOnChange(e)} />
                                </div>
                                <div className="input-with-label text-left">
                                    <span>Primary Speciality</span>
                                    <div>
                                        <input className='col-md-12' type='text' list='speciality' value={this.state.Primaryspeciality}
                                               name="Primaryspeciality" placeholder='Primary speciality' onChange={(e) => this.handleOnChange(e)} />
                                        <datalist id="speciality">
                                            {
                                                primarySpeciality.map((name, index) => (
                                                    <option value={name} key={index} />
                                                ))
                                            }
                                        </datalist>
                                    </div>
                                </div>
                               
                                 <div className="input-group">
                                 <span className="input-group-addon">+1</span>
                                <input type="text" className="m_number form-control" name="phone" placeholder="Office Phone"
                                    maxLength={10}
                                    onKeyPress={(e) => (e.charCode >= 48 && e.charCode <= 57) ? true : e.preventDefault()}
                                    value={phonenumber}
                                    onChange={(e) => this.handleOnChange(e)}
                                />
                                </div>
                                <div style={{marginTop:'10px'}} className="input-with-label text-left">
                                    <span>Zip code (Location)</span>
                                    <input className="col-md-12" maxLength={5} type="text" value={this.state.zip} name="zip" placeholder="Zip code" onChange={(e) => this.handleOnChange(e)} 
                                        onKeyPress={(e) => (e.charCode >= 48 && e.charCode <= 57) ? true : e.preventDefault()}
                                    />
                                </div>
                                <hr />
                                <div style={{ display: '', alignItems: 'center' }}>
                                    <button type='submit' disabled={this.state.isLoading} onClick={this.HandleSavePersonalDetails} style={{ width: 100, margin: 5 }}>
                                        {this.state.isLoading && <span className="spinner-border text-light mr8" role="status" />}
                                        SAVE
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        docDetails: state.doctorDetails,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        handleGetDocDetails: data => dispatch({ type: GET_DOC_DETAILS_REQUEST, data }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonalDetails);
